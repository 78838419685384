<div class="dashboard-home">
    <div class="tips" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <h5 translate>TIP OF THE DAY :</h5>
            <button mat-mini-fab color="primary" fxHide.sm fxHide.xs style="right: 20px;bottom: 1px;"
                (click)="editTips=true" *ngIf="user.isAdmin&&!editTips">
                <i class="mdi mdi-pencil-outline" style="color:white;"></i>
            </button>

        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <input [(ngModel)]="tipsOfDay" *ngIf="user.isAdmin&&editTips" />
            <button mat-mini-fab color="primary" fxHide.sm fxHide.xs style="right: 20px;bottom: 1px;"
                (click)="updateTipsOfTheDay()" *ngIf="user.isAdmin&&editTips">
                <i class="mdi mdi-content-save-outline" style="color:white;"></i>
            </button>
        </div>
        <div *ngIf="!editTips">{{tipsOfDay}}</div>
    </div>
    <div class="dashboard-home-div" fxLayout="row wrap" *ngIf="!user.isAdmin">
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'profile-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-human"></i>
            </div>
            <div class="box-div-text" translate>My Profile</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-ads-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-format-line-style"></i>
            </div>
            <div class="box-div-text" translate>My Ads</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-fav-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-heart-outline"></i>
            </div>
            <div class="box-div-text" translate>Favorite Ads</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-message'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-email-outline"></i>
            </div>
            <div class="box-div-text" translate>Message</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-payment-history'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-credit-card"></i>
            </div>
            <div class="box-div-text" translate>Payment History</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-announcements'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-bullhorn"></i>
            </div>
            <div class="box-div-text" translate>Announcements</div>
        </a>

        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-alerts'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-alert-outline"></i>
            </div>
            <div class="box-div-text" translate>Manage Alerts</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'analytics'}}]" [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-chart-line-variant"></i>
            </div>
            <div class="box-div-text" translate>Analytics</div>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-support'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-lightbulb-outline"></i>
            </div>
            <div class="box-div-text" translate>Support</div>
        </a>
    </div>
    <div class="dashboard-home-div" fxLayout="row wrap" *ngIf="user.isAdmin">
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-users-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-human"></i>
            </div>
            <div class="box-div-text" translate>Manage Users</div>
            <p>
                Total: {{generalMeters?.users}}
            </p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-listings-approve-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-format-line-style"></i>
            </div>
            <div class="box-div-text" translate>Approve Listings</div>
            <p>
                Total: {{generalMeters?.approveListing}}
            </p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-listings-management-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-format-list-bulleted-square"></i>
            </div>
            <div class="box-div-text" translate>Manage Listings</div>
            <p>
                Total: {{generalMeters?.listings}}
            </p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-payments-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-credit-card"></i>
            </div>
            <div class="box-div-text" translate>Payment History</div>
            <p>Total: {{generalMeters?.payments}}</p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-message-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-email-outline"></i>
            </div>
            <div class="box-div-text" translate>Messages</div>
            <p>
               Total: {{generalMeters?.messages}}
            </p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-calculate-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-calculator-variant"></i>
            </div>
            <div class="box-div-text" translate>Leads</div>
            <p></p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-xml-feed-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-lightbulb-outline"></i>
            </div>
            <div class="box-div-text" translate>XML Feed</div>
            <p></p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-reported-listings-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-alert"></i>
            </div>
            <div class="box-div-text" translate>Reported Listing</div>
            <p>
                Total: {{generalMeters?.listingReports}}
            </p>
        </a>
        <a class="box-div" fxLayout="column" fxLayoutAlign="center center"
            [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'admin-article-dashboard'}}]"
            [routerLinkActive]="'active'">
            <div class="dashboard-home-icon-box" fxLayout="row" fxLayoutAlign="center center">
                <i class="mdi mdi-file-document-box-plus"></i>
            </div>
            <div class="box-div-text" translate>Articles</div>
            <p></p>
        </a>
    </div>
</div>