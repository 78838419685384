/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "./pagination.component";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { active: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.numberOfpage.currentPage === _v.context.$implicit)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, (_co.numberOfpage.currentPage === _v.context.$implicit)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 7, 0, currVal_2); }); }
function View_PaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 37, "ul", [["class", "pagination"], ["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i5.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i6.Directionality, i4.StyleUtils, [2, i5.LayoutGapStyleBuilder], i4.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { disabled: 0 }), i1.ɵdid(8, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { disabled: 0 }), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPage(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["First"])), (_l()(), i1.ɵeld(12, 0, null, null, 7, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(15, { disabled: 0 }), i1.ɵdid(16, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(17, { disabled: 0 }), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPage((_co.numberOfpage.currentPage - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i1.ɵdid(21, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 7, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(24, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(25, { disabled: 0 }), i1.ɵdid(26, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(27, { disabled: 0 }), (_l()(), i1.ɵeld(28, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPage((_co.numberOfpage.currentPage + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"])), (_l()(), i1.ɵeld(30, 0, null, null, 7, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(32, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(33, { disabled: 0 }), i1.ɵdid(34, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i2.NgClass]], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(35, { disabled: 0 }), (_l()(), i1.ɵeld(36, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickPage(_co.numberOfpage.totalPages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Last"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "20px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _ck(_v, 7, 0, (_co.numberOfpage.currentPage === 1)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _ck(_v, 9, 0, (_co.numberOfpage.currentPage === 1)); _ck(_v, 8, 0, currVal_4); var currVal_5 = _ck(_v, 15, 0, (_co.numberOfpage.currentPage === 1)); _ck(_v, 14, 0, currVal_5); var currVal_6 = _ck(_v, 17, 0, (_co.numberOfpage.currentPage === 1)); _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.numberOfpage.pages; _ck(_v, 21, 0, currVal_7); var currVal_8 = _ck(_v, 25, 0, (_co.numberOfpage.currentPage === _co.numberOfpage.totalPages)); _ck(_v, 24, 0, currVal_8); var currVal_9 = _ck(_v, 27, 0, (_co.numberOfpage.currentPage === _co.numberOfpage.totalPages)); _ck(_v, 26, 0, currVal_9); var currVal_10 = _ck(_v, 33, 0, (_co.numberOfpage.currentPage === _co.numberOfpage.totalPages)); _ck(_v, 32, 0, currVal_10); var currVal_11 = _ck(_v, 35, 0, (_co.numberOfpage.currentPage === _co.numberOfpage.totalPages)); _ck(_v, 34, 0, currVal_11); }, null); }
export function View_PaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["style", "width:100%;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "row"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "center center"; _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.numberOfpage.pages && _co.numberOfpage.pages.length); _ck(_v, 7, 0, currVal_4); }, null); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 573440, null, 0, i7.PaginationComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var PaginationComponentNgFactory = i1.ɵccf("td-pagination", i7.PaginationComponent, View_PaginationComponent_Host_0, { pageInfo: "pageInfo" }, { pageClickEvent: "pageClickEvent" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
