const imageUploadConst = {
  limit: 5,
  fileMaxSize: 20,
  enableApi: true,
  clipboardPaste: 2000,
  files: null,
  item: null,
  listInput: true,
  extensions: ['jpg', 'jpeg', 'png', 'gif'],
  changeInput: '<div class="fileuploader-input">' +
    '<div class="fileuploader-input-inner">' +
    '<div class="fileuploader-main-icon"></div>' +
    '<h3 class="fileuploader-input-caption"><span>${captions.feedback}</span></h3>' +
    '<p>${captions.or}</p>' +
    '<div class="fileuploader-input-button"><span>${captions.button}</span></div>' +
    '</div>' +
    '</div>',
  theme: 'dragdrop',
  thumbnails: {
    itemPrepend: false,
    removeConfirmation: true,
    canvasImage: true,
    startImageRenderer: true,
    box: '<div class="fileuploader-items">' +
      '<ul class="fileuploader-items-list"></ul>' +
      '</div>',

    // append thumbnails list to selector {null, String, jQuery Object}
    // example: 'body'
    boxAppendTo: null,

    item: '<li class="fileuploader-item file-has-popup">' +
      '<div class="columns">' +
      '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
      '<div class="column-title">' +
      '<div title="${name}">${name}</div>' +
      '<span>${size2}</span>' +
      '</div>' +
      '<div class="column-actions">' +
      '<a class="fileuploader-action fileuploader-action-sort" title="${captions.sort}"><i></i></a>' +
      '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
      '</div>' +
      '</div>' +
      '<div class="progress-bar2">${progressBar}<span></span></div>' +
      '</li>',

    // thumbnails for the preloaded files {String, Function}
    // example: '<li>${name}</li>'
    // example: function(item) { return '<li>' + item.name + '</li>'; }
    item2: '<li class="fileuploader-item file-has-popup">' +
      '<div class="columns">' +
      '<div class="column-thumbnail">${image}<span class="fileuploader-action-popup"></span></div>' +
      '<div class="column-title">' +
      '<a href="${file}" target="_blank">' +
      '<div title="${name}">${name}</div>' +
      '<span>${size2}</span>' +
      '</a>' +
      '</div>' +
      '<div class="column-actions">' +
      '<a class="fileuploader-action fileuploader-action-sort" title="${captions.sort}"><i></i></a>' +
      '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i></i></a>' +
      '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i></i></a>' +
      '</div>' +
      '</div>' +
      '</li>',
    // thumbnails selectors
    _selectors: {
      list: '.fileuploader-items-list',
      item: '.fileuploader-item',
      start: '.fileuploader-action-start',
      retry: '.fileuploader-action-retry',
      remove: '.fileuploader-action-remove',
      sorter: '.fileuploader-action-sort',
      rotate: '.fileuploader-action-rotate',
      popup: '.fileuploader-popup-preview',
      popup_open: '.fileuploader-action-popup'
    },
    onImageLoaded: function (item, listEl, parentEl, newInputEl, inputEl) {
      if (!item.html.find('.fileuploader-action-edit').length)
        item.html.find('.fileuploader-action-remove').before('<a class="fileuploader-action fileuploader-action-popup fileuploader-action-edit" title="Edit"><i></i></a>');

      // hide current thumbnail (this is only animation)
      if (item.imageIsUploading) {
        item.image.addClass('fileuploader-loading').html('');
      }
      // invalid image?
      if (item.image.hasClass('fileuploader-no-thumbnail')) {
        // callback goes here
      }

      // check image size and ratio?
      if (item.reader.node && item.reader.width > 1920 && item.reader.height > 1080 && item.reader.ratio != '16:9') {
        // callback goes here
      }
    },
    
    // onImageLoaded: function (item) {
    //   if (!item.html.find('.fileuploader-action-edit').length)
    //     item.html.find('.fileuploader-action-remove').before('<a class="fileuploader-action fileuploader-action-popup fileuploader-action-edit" title="Edit"><i></i></a>');

    //   // hide current thumbnail (this is only animation)
    //   if (item.imageIsUploading) {
    //     item.image.addClass('fileuploader-loading').html('');
    //   }
    // },
    // onItemShow: function (item) {
    //   // add sorter button to the item html
    //   item.html.find('.fileuploader-action-remove').before('<a class="fileuploader-action fileuploader-action-sort" title="Sort"><i></i></a>');
    // }
  },

  editor: {
    cropper: {
      showGrid: true,
    },
    onSave: null
    //  function(dataURL, item) {
    //   
    //   
    //   // saveEditedImage(dataURL,item);
    // }	
  },

  captions: {
    button: function (options) { return 'Browse ' + (options.limit == 1 ? 'file' : 'files'); },
    feedback: function (options) { return 'Choose ' + (options.limit == 1 ? 'file' : 'files') + ' to upload'; },
    feedback2: function (options) { return options.length + ' ' + (options.length > 1 ? ' files were' : ' file was') + ' chosen'; },
    confirm: 'Confirm',
    cancel: 'Cancel',
    name: 'Name',
    type: 'Type',
    size: 'Size',
    dimensions: 'Dimensions',
    duration: 'Duration',
    crop: 'Crop',
    rotate: 'Rotate',
    sort: 'Sort',
    download: 'Download',
    remove: 'Remove',
    drop: 'Drop the files here to Upload',
    paste: '<div class="fileuploader-pending-loader"></div> Pasting a file, click here to cancel.',
    removeConfirmation: 'Are you sure you want to remove this file?',
    errors: {
      filesLimit: 'Only ${limit} files are allowed to be uploaded.',
      filesType: 'Only ${extensions} files are allowed to be uploaded.',
      fileSize: '${name} is too large! Please choose a file up to ${fileMaxSize}MB.',
      filesSizeAll: 'Files that you chose are too large! Please upload files up to ${maxSize} MB.',
      fileName: 'File with the name ${name} is already selected.',
      folderUpload: 'You are not allowed to upload folders.'
    }
  },
  sorter: {
    selectorExclude: null,
    placeholder: null,
    scrollContainer: window,
    onSort: function(list, listEl, parentEl, newInputEl, inputEl) {
      // onSort callback
      
    }
  },
  addMore: true,
  getAllEvents: null,
  onRemove: null,
  onFileRead: null,
  dialogs: {
    alert:null
  },
  dragDrop: {
    // set the drop container {null, String, jQuery Object}
    // example: 'body'
    container: '.fileuploader-input',

    // Callback fired on entering with dragging files the drop container
    onDragEnter: function (event, listEl, parentEl, newInputEl, inputEl) {
      // callback will go here
      //
    },

    // Callback fired on leaving with dragging files the drop container
    onDragLeave: function (event, listEl, parentEl, newInputEl, inputEl) {
      // callback will go here
      //
    },

    // Callback fired on dropping the files in the drop container
    onDrop: function (event, listEl, parentEl, newInputEl, inputEl) {
      // callback will go here
      //
      //_this.fileUploadStart(event, listEl, parentEl, newInputEl, inputEl, _this)
    }
  },
  upload: {
    beforeSend: function (item, listEl, parentEl, newInputEl, inputEl) {
      // example:
      // here you can extend the upload data
      
    }
  },
}


export default imageUploadConst;