import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
var DashboardAdminReportedListingsComponent = /** @class */ (function () {
    function DashboardAdminReportedListingsComponent(dashboardService, changeDetection, utilService, dialog, enumPropertyService) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dialog = dialog;
        this.enumPropertyService = enumPropertyService;
        this.destroy$ = new Subject();
        this.reportedList = [];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.report = null;
        this.dialogRef = null;
        this.response = null;
        this.submited = false;
        this.taxonomy = null;
    }
    DashboardAdminReportedListingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.taxonomy = data;
            _this.getListOfMessages();
            _this.changeDetection.detectChanges();
        });
    };
    DashboardAdminReportedListingsComponent.prototype.getListOfMessages = function () {
        var _this = this;
        this.dashboardService.getAllReport(this.currentPage, 10).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.reportedList = data;
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        }, function (err) {
        });
    };
    DashboardAdminReportedListingsComponent.prototype.deleteEventCalled = function (reportedId) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a report!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteReport(reportedId).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + reportedId + " reported Deleted!");
                    _this.getListOfMessages();
                    _this.changeDetection.detectChanges();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardAdminReportedListingsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAdminReportedListingsComponent.prototype.showMessageDialog = function (reported) {
        this.report = reported;
        this.response = reported.response;
        this.dialogRef = this.dialog.open(this.reportedDialog, {
            panelClass: 'report-dailog',
        });
    };
    DashboardAdminReportedListingsComponent.prototype.close = function () {
        this.dialogRef.close();
        this.getListOfMessages();
    };
    DashboardAdminReportedListingsComponent.prototype.getEnumById = function (firstElement, type, secondElement, id) {
        var nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
        return nameOrNumber;
    };
    DashboardAdminReportedListingsComponent.prototype.suspendListing = function (listingId) {
        var _this = this;
        this.dashboardService.suspendListing(listingId, 12).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', 'Listing suspended! If you change your mind, make listing active from manage listing.');
        }, function (err) {
            _this.utilService.showToast('error', 'Error in suspending listing, try again!');
        });
    };
    DashboardAdminReportedListingsComponent.prototype.sendResponse = function () {
        var _this = this;
        var obj = {
            id: this.report.id,
            title: this.report.title,
            message: null,
            reportType: this.report.reportType,
            response: this.response
        };
        this.dashboardService.giveResponse(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.dialogRef.close();
        });
    };
    DashboardAdminReportedListingsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getListOfMessages();
    };
    return DashboardAdminReportedListingsComponent;
}());
export { DashboardAdminReportedListingsComponent };
