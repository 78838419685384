import { DashboardHomeComponent } from '../components/dashboard-home/dashboard-home.component';
import { DashboardMyProfileComponent } from '../components/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardManageMyAdsComponent } from '../components/dashboard-manage-my-ads/dashboard-manage-my-ads.component';
import { DashboardFavoriteAdsComponent } from '../components/dashboard-favorite-ads/dashboard-favorite-ads.component';
import { DashboardAnnouncementsComponent } from '../components/dashboard-announcements/dashboard-announcements.component';
import { DashboardMessageComponent } from '../components/dashboard-message/dashboard-message.component';
import { DashboardSupportComponent } from '../components/dashboard-support/dashboard-support.component';
import { DashboardPaymentHistoryComponent } from '../components/dashboard-payment-history/dashboard-payment-history.component';
import { DashboardAlertsComponent } from '../components/dashboard-alerts/dashboard-alerts.component';
import { DashboardAnalyticsComponent } from '../components/dashboard-analytics/dashboard-analytics.component';
import { DashboardListingReportedComponent } from '../components/dashboard-listing-reported/dashboard-listing-reported.component';

const userRouterParms = [
    {
        path: 'home-dashboard',
        component: DashboardHomeComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'profile-dashboard',
        component: DashboardMyProfileComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-my-ads-dashboard',
        component: DashboardManageMyAdsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-my-fav-dashboard',
        component: DashboardFavoriteAdsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-my-announcements',
        component: DashboardAnnouncementsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-message',
        component: DashboardMessageComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-support',
        component: DashboardSupportComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'manage-payment-history',
        component: DashboardPaymentHistoryComponent,
        outlet: 'dashboardOutlet'
    },

    {
        path: 'manage-alerts',
        component: DashboardAlertsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'analytics',
        component: DashboardAnalyticsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: 'reported-listing',
        component: DashboardListingReportedComponent,
        outlet: 'dashboardOutlet'
    }

]
export default userRouterParms;