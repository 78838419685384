import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LanguageService {
  constructor(private translateService: TranslateService) {}
  get getDefaultLanguage(): "en" | "th" | string {
    const defaultLanguage = navigator.language.includes("th") ? "th" : "en";
    return !!localStorage.getItem("language")
      ? localStorage.getItem("language")
      : defaultLanguage;
  }
  setLanguage(language: "en" | "th"): void {
    localStorage.setItem("language", language);
    this.translateService.setDefaultLang(language);
    this.translateService.use(language);
    location.reload();
  }
}
