import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FlexLayoutModule, CoreModule } from '@angular/flex-layout';
import { SharedModule } from 'src/shared/shared.module';
import { ReactiveFormsModule  } from '@angular/forms';
import { MediaModule } from 'src/media/media.module';
import { PaymentModule } from 'src/payment-component/payment.module';
import { LocationModule } from 'src/location/location.module';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { AgmCoreModule } from '@agm/core';
import { ContactModule } from 'src/contact/contact.module';
import { GalleryModule } from 'src/gallery/gallery.module';
import { ListingDetailsComponent } from './components/listing-details.component';
import { ListingService } from 'src/listing/service/listing.service';
import { LoginRegisterModule } from 'src/login-register/login-register.module';
import { UserRouteResolver } from 'src/app/user-route-resolver';

const routes: Routes = [
    
    {
      path: "listing-detail/:id",
      component: ListingDetailsComponent,
      pathMatch: 'full',
      resolve: {
        userRouteResolver: UserRouteResolver
      },
    }
  ];


@NgModule({
  declarations: [
    ListingDetailsComponent
  ],
  entryComponents:[ListingDetailsComponent],
  exports: [ListingDetailsComponent],
  imports: [
    CoreModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    MediaModule,
    PaymentModule,
    LocationModule,
    NgxScrollToFirstInvalidModule,
    AgmCoreModule,
    ContactModule,
    GalleryModule,
    MediaModule,
    RouterModule.forChild(routes),
    LoginRegisterModule
  ],
  providers:[ListingService, UserRouteResolver]
})
export class ListingDetailsModule {}
