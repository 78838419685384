import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router'
import { PaymentService } from 'src/payment-component/services/payment.service';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { MatDatepicker, MatDialog } from '@angular/material';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { UtilService } from 'src/shared/services/util.service';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { environment } from 'src/environments/environment';
const moment = _rollupMoment || _moment;

// import  * as paypal from 'paypal-checkout';
declare let paypal: any;
declare let OmiseCard: any;
@Component({
  selector: 'td-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentConfirmationComponent implements OnInit {
  mobilePaymentMathedClicked=false;
  destroy$: Subject<boolean> = new Subject();
  selectedPaymentDiv: number = 1;
  selectedPaymentBank: string = 'BAY';
  minDate = new Date();
  cardDetailsFormGroup: FormGroup = this.fb.group({
    cardNumber: [null, [Validators.required, Validators.maxLength(19)]],
    nameOnCard: [null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]],
    expiredMonth: [moment().month(), Validators.required],
    expiredYear: [moment().year(), Validators.required],
    expiredFullDate: [null, Validators.required],
    securityCode: [null, [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$")]]
  });
  selectedPackage = {
    orderItems: []
  };
  taxonomy: any = {};
  listingId: any;
  customeText: string = null;
  submited: boolean = false;
  isProduction: boolean = environment.production;
  @ViewChild(MatDatepicker, { static: false }) picker;
  fullPaymentObj: any = null;
  fees = 0;
  fixAmount = false;
  paymentMethods: any = null;
  finalAmount: number = 1;
  billId = null;
  paypalButtonAdded: boolean = false;
  barCodeImage = null;
  paypalId = null;

  paypalConfig = {
    env: environment.paypalEnv,
    client: {
      sandbox: environment.paypalKey,
      production: environment.paypalKey
    },
    commit: true,
    payment: (data, actions) => {

      return this.getValueFromObservable(this.billId).then((data) => {

        return data;
      })
        .catch(function (val) {
          //Logic after failure

        })
      // return actions.request.post(this.paymentService.paypalPayment(this.billId))
      //   .then(function(res) {
      //     // 3. Return res.id from the response
      //     return res.id;
      //   });
      // return actions.payment.create({
      //   payment: {
      //     transactions: [
      //       { amount: { total: this.getSum(7 * this.getSum(0) / 100) + this.fees * this.getSum(0) / 100, currency: 'THB' } }
      //     ]
      //   },
      //   token:this.paypalId
      // });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        //Do something when payment is successful.





        let paymentObj = {
          paymentMethodId: this.selectedPaymentDiv,
          offSiteType: null,
          token: null,
          automaticDebit: true,
          cardId: null,
          paypalPayId: data.paymentID,
          paypalPayerId: data.payerID,
          fee: this.fees ? this.fees : 0
        };

        // paymentObj.token = payment.id;
        this.paymentService.payForBill(paymentObj, this.billId).pipe(takeUntil(this.destroy$)).subscribe((paymentResponse: any) => {

          this.utilService.showToast('success', 'Payemnet Successfully done!');
          this.router.navigate([`/payment-success/${this.billId}/${this.selectedPaymentDiv}`]);
          this.submited = false;
        });
        // })
      })
    },

    onError: err => {

    }
  };

  getValueFromObservable(billId) {
    return new Promise(resolve => {
      this.paymentService.paypalPayment(billId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {

        resolve(data);
      });
    });
  }


  constructor(private route: ActivatedRoute, private paymentService: PaymentService,
    private changeDetaction: ChangeDetectorRef, private fb: FormBuilder,
    private utilService: UtilService,
    private enumPropertyService: EnumPropertyService,
    public dialog: MatDialog,
    private router: Router) { }

  get f(): any { return this.cardDetailsFormGroup.controls; }

  ngOnInit() {
    (window as any).Omise.publicKey = "pkey_5il69jga91xkzaxsoay";
    this.taxonomy = this.enumPropertyService.getEnumPropertyForClient();

    this.fullPaymentObj = this.paymentService.fullPaymentObj;

    this.route.params.subscribe(params => {

      this.billId = params.id;
    });
    if (this.paymentService.fullPaymentObj) {
      this.listingId = this.paymentService.fullPaymentObj.listingId;
    }
    this.getBillingDetails();

  }

  getBillingDetails() {
    this.paymentService.getBillDetails(this.billId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {

      this.selectedPackage = data.productsOrder;
      this.paymentMethods = data.paymentMethods;
      this.paymentMethods.forEach(element => {
        if (element.id == this.selectedPaymentDiv) {
          this.fees = element.fee;
          this.fixAmount = element.fixAmount;
        }
      });
      this.changeDetaction.detectChanges();
    })
  }

  getSum(amountToAdd = 0): number {
    // 
    let sum = 0;
    for (let i = 0; i < this.selectedPackage.orderItems.length; i++) {
      sum += this.selectedPackage.orderItems[i].amount;
    }
    // 
    // 
    return sum + amountToAdd;
  }

  submit() {
    let _this = this;
    this.submited = true;
    if (this.selectedPaymentDiv == 1 && this.cardDetailsFormGroup.invalid) {
      return;
    }

    let paymentObj = {
      paymentMethodId: this.selectedPaymentDiv,
      offSiteType: null,
      token: null,
      automaticDebit: true,
      cardId: null,
      paypalPayId: null,
      paypalPayerId: null,
      fee: this.fees ? this.fees : 0
    };
    let slectedBank = null;
    switch (this.selectedPaymentDiv) {
      case 1:
        console.log("submit 1");
        const card = {
          name: this.cardDetailsFormGroup.value.nameOnCard,
          number: parseInt(this.cardDetailsFormGroup.value.cardNumber.replace(/ /g, "")),
          expiration_month: this.cardDetailsFormGroup.value.expiredMonth,
          expiration_year: this.cardDetailsFormGroup.value.expiredYear,
          security_code: parseInt(this.cardDetailsFormGroup.value.securityCode),
        };
        (window as any).Omise.createToken("card", card, function (statusCode, response) {
          console.log("statusCode, response", statusCode, response)
          if (response.object == "error" || !response.card.security_code_check) {
            // Display an error message.
            var message_text = "SECURITY CODE CHECK FAILED!";
            if (response.object == "error") {
              message_text = response.message;
              _this.utilService.showToast('error', response.message);
            } else {
              _this.utilService.showToast('error', message_text);
            }
          } else {


            paymentObj.token = response.id;
            _this.payBill(paymentObj, slectedBank)
          };
        });
        break;
      case 2:


        switch (this.selectedPaymentBank) {

          case 'internet_banking_bay':
            slectedBank = 1;
            break;
          case 'internet_banking_bbl':
            slectedBank = 2;
            break;
          case 'internet_banking_ktb':
            slectedBank = 3;
            break;
          case 'internet_banking_scb':
            slectedBank = 4;
            break;
        }
        paymentObj.offSiteType = slectedBank;
        (window as any).Omise.createSource(this.selectedPaymentBank, {
          "amount": this.getSum(7 % this.getSum(0)),
          "currency": "THB"
        }, function (statusCode, response) {


          paymentObj.token = response.id;
          _this.payBill(paymentObj, slectedBank)

        });

        break;
      case 3:
        paymentObj.offSiteType = 5;
        this.payBill(paymentObj, 5)
        break;
      case 4:
        paymentObj.offSiteType = 6;
        // this.paymentService.paypalPayment(this.billId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
        // this.paypalId=data;
        // 
        setTimeout(() => {
          this.generatePaypalButton();
        }, 100);
        // })
        break;

    }
  }



  payBill(paymentObj, slectedBank) {
    console.log("paymentObj, slectedBank", paymentObj, slectedBank);
    setTimeout(() => {
      console.log("paymentObj, slectedBank", paymentObj, slectedBank);
      this.paymentService.payForBill(paymentObj, this.billId).pipe(takeUntil(this.destroy$)).subscribe((paymentResponse: any) => {

        if (slectedBank && slectedBank != 5) {
          window.location.href = paymentResponse.data.data;
        } else if (slectedBank && slectedBank == 5) {
          this.barCodeImage = paymentResponse.data.data;
          this.changeDetaction.detectChanges();
        } else {
          this.utilService.showToast('success', 'Payemnet Successfully done!');
          this.router.navigate([`/payment-success/${paymentResponse.data.billId}/${this.selectedPaymentDiv}`]);
        }
        this.submited = false;
      }, (err) => {

        if (err.error.errors.find(element => element == 'transactionAlreadyInProgress')) {
          this.utilService.showToast('info', 'You already paid for this order.');
        } else if (err.error.errors.find(element => element == 'IncorrectBillOrAlreadyPaid')) {
          this.utilService.showToast('info', 'You already paid for this order.');
        } else if (err.error.errors.find(element => element == 'expiration date cannot be in the past')) {
          this.utilService.showToast('error', 'Expiration date is incorrect.');
        } else if (typeof err.error == 'string') {
          this.utilService.showToast('error', 'Server error! Try again later!');
        } else {
          this.utilService.showToast('error', err);
          this.submited = false;
          this.router.navigate([`/payment-error`, paymentObj.billId], { skipLocationChange: false });
        }
      })
    }, 100)
  }

  monthSelected(params) {

    this.cardDetailsFormGroup.controls['expiredFullDate'].setValue(params);
    let month = moment(params).format('MM');
    let year = moment(params).format('YYYY');

    this.cardDetailsFormGroup.controls['expiredMonth'].setValue(month);
    this.cardDetailsFormGroup.controls['expiredYear'].setValue(year);
    this.picker.close();
  }


  showResponse() {
    const dialogRef = this.dialog.open(PaymentSuccessComponent, {
      // data: pckg
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {

      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  removePaypal() {
    $('.paypal-button').remove();
    this.paypalButtonAdded = false;
    this.changeDetaction.detectChanges();
  }
  generatePaypalButton() {
    let paypalEnv;
    this.paypalButtonAdded = true;
    this.changeDetaction.detectChanges();
    if (this.isProduction) {
      paypalEnv = "production";
    } else {
      paypalEnv = "sandbox";
    }
    paypal.Button.render(
      this.paypalConfig,
      "#paypal-button"
    );

  }
  printQr() {
    let win = window.open('');
    let img = win.document.createElement("img");
    img.src = this.barCodeImage;
    win.document.body.appendChild(img);
    img.onload = function () {
      win.print();
    };
  }

}
