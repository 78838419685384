import * as tslib_1 from "tslib";
import { OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MapsAPILoader } from '@agm/core';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { GooglemapsService } from 'src/shared/services/googlemaps/googlemaps.service';
var GenericDialogComponent = /** @class */ (function () {
    function GenericDialogComponent(data, mapsAPILoader, ngZone, changeDetection, googleMapsService, dialogRef) {
        this.data = data;
        this.mapsAPILoader = mapsAPILoader;
        this.ngZone = ngZone;
        this.changeDetection = changeDetection;
        this.googleMapsService = googleMapsService;
        this.dialogRef = dialogRef;
        this.initialPosition = { lat: 13.7563, lng: 100.5018, area: 'Central' };
        this.locationPickerConfiguration = { appearance: "outline" };
        this.selectedArea = null;
        this.address = [];
        this.coords = null;
        this.destroy$ = new Subject();
        this.showMap = false;
    }
    GenericDialogComponent.prototype.ngOnInit = function () {
        this.showMap = this.data.showMap;
        this.changeDetection.detectChanges();
    };
    GenericDialogComponent.prototype.getCoords = function (coords) {
        var _this = this;
        this.coords = tslib_1.__assign({}, coords);
        // if(this.coords.type== "location"){
        forkJoin(this.googleMapsService.reverseGeocoding(coords, "en"), this.googleMapsService.reverseGeocoding(coords, "th"))
            .pipe(takeUntil(this.destroy$), map(function (_a) {
            var en = _a[0], th = _a[1];
            _this.address = [
                {
                    latitude: _this.coords.lat,
                    longitude: _this.coords.lng,
                    languageId: 1,
                    formattedAddress: en.formatted_address,
                    postCode: 1,
                    subDistrictId: 1,
                    districtId: 2,
                    provinceId: 1,
                    countryId: 1,
                    area: _this.coords.area || _this.selectedArea
                },
                {
                    latitude: _this.coords.lat,
                    longitude: _this.coords.lng,
                    languageId: 2,
                    formattedAddress: th.formatted_address,
                    postCode: 1,
                    subDistrictId: 1,
                    districtId: 2,
                    provinceId: 1,
                    countryId: 1,
                    area: _this.coords.area || _this.selectedArea
                }
            ];
            return _this.address;
        }))
            .subscribe(function (addressData) {
            _this.address = addressData;
            _this.changeDetection.detectChanges();
            if (!_this.showMap) {
                _this.dialogRef.close(_this.address);
            }
        });
        // }else{
        //   this.address = this.coords.position;
        //     this.changeDetection.detectChanges()
        // }
    };
    GenericDialogComponent.prototype.mapAreaSelected = function (area) {
        this.selectedArea = area;
        switch (area) {
            case 'West':
                this.initialPosition.lat = 14.927136;
                this.initialPosition.lng = 99.0065353;
                break;
            case 'South':
                this.initialPosition.lat = 9.1003993;
                this.initialPosition.lng = 99.2905703;
                break;
            case 'North':
                this.initialPosition.lat = 18.7071626;
                this.initialPosition.lng = 98.9696934;
                break;
            case 'North-East':
                this.initialPosition.lat = 16.4288193;
                this.initialPosition.lng = 102.829493;
                break;
            case 'East':
                this.initialPosition.lat = 12.9214122;
                this.initialPosition.lng = 100.9122683;
                break;
        }
        this.initialPosition.area = area;
        if (!this.showMap) {
            this.changeCod();
        }
    };
    GenericDialogComponent.prototype.changeCod = function () {
        var coords = {
            lat: this.initialPosition.lat,
            lng: this.initialPosition.lng,
            area: this.initialPosition.area
        };
        this.getCoords(coords);
    };
    GenericDialogComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    return GenericDialogComponent;
}());
export { GenericDialogComponent };
