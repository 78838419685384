import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MediaService } from 'src/media/service/media.service';
import { HttpEventType } from '@angular/common/http';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'td-dashboard-admin-articles',
  templateUrl: './dashboard-admin-article.component.html',
  styleUrls: ['./dashboard-admin-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardArticlesComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  dialogRef = null;
  slectedLanguage: number = 1;
  submited: boolean = false;
  articleAddOrUpdateSuccess: boolean = false;
  updatingarticle: boolean = false;
  addarticle: FormGroup = this.fb.group({
    engTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    thTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    engDescription: ['', [Validators.required,  Validators.minLength(2)]],
    thDescription: ['', [Validators.required,  Validators.minLength(2)]],
  });
  fileupload: any;
  fileUploadProgress = null;
  mediaId = null;
  articleData = [];
  storage: string = environment.storageUrl;
  @ViewChild('addNewAnn', {static: false}) addNewAnn: TemplateRef<any>;
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  assDec = false;
  filter = null;
  articleId = null;
  constructor(private dialog: MatDialog, private fb: FormBuilder, private dashboardService: DashboardService, private mediaService: MediaService,
    private changeDetection: ChangeDetectorRef, private utilService: UtilService) { }

  ngOnInit() {
    this.getAllArticle();
  }
  getAllArticle() {
    
    this.dashboardService.searchAllArticle(this.currentPage, 10, { id: null, filter: this.filter }).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {

      
      this.articleData = data;
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllArticle();
  }

  get f(): any { return this.addarticle.controls; }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addNew(templateRef) {
    imageUploadConst.files = [];
    this.updatingarticle = false;
    this.articleAddOrUpdateSuccess = false;
    this.submited = false;
    this.addarticle.setValue({ engTitle: '', thTitle: '', engDescription: '', thDescription: '' });
    this.dialogRef = this.dialog.open(templateRef, {
      panelClass: 'add-new-dialog',

    });
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.fileUploadProgress = null;
      this.mediaId = null;
      this.assignFile();
    });
  }
  assignFile(): any {
    let _this = this;
    imageUploadConst.limit = 1;
    imageUploadConst.onFileRead = function (item, listEl, parentEl, newInputEl, inputEl) {
      
      if (item.input) {
        _this.fileUploadStart(item)
      } else {
        item.id = item.data.imageId;
      }
    };
    imageUploadConst.onRemove = function (item) {
      
      _this.deletePhoto(item)
    };

    imageUploadConst.dialogs.alert = function (message) {
      _this.utilService.showToast('success',message)
    }
    imageUploadConst.getAllEvents = function (something) {
      
    }
    imageUploadConst.editor.onSave = function (dataUrl, item) {
      
      
      _this.removeFromFiles(item);
      let blob = _this.mediaService.dataURLtoFile(dataUrl, 'a.png');

      item.file = blob;
      if (blob) {
        _this.fileUploadStart(item);
      }

    }
    setTimeout(() => {
      this.fileupload = (<any>window).$('input[name="files"]').fileuploader(
        imageUploadConst
      );
    }, 100)

  }

  fileUploadStart(item) {
    let _this = this;
    
    const formData = new FormData();
    formData.append('file', item.file);
    // callback will go here
    
    _this.fileUploadProgress = '0';
    _this.mediaService.addMedia(60, formData).pipe(takeUntil(_this.destroy$)).subscribe((events: any) => {
      
      if (events.type === HttpEventType.UploadProgress) {
        _this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
        _this.changeDetection.detectChanges();
        
        var progressBar = item.html.find('.progress-bar2');
        if (progressBar.length > 0) {
          progressBar.show();
          progressBar.find('span').html(_this.fileUploadProgress + "%");
          progressBar.find('.fileuploader-progressbar .bar').width(_this.fileUploadProgress + "%");
        }
      } else if (events.type === HttpEventType.Response) {
        _this.fileUploadProgress = '';
        
        item.name = events.body.data.name;
        item.id = events.body.data.id;
        imageUploadConst.files.push({ id: events.body.data.id });
        item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
        setTimeout(function () {
          item.html.find('.progress-bar2').fadeOut(400);
        }, 400);
        _this.mediaId = events.body.data.id;
        
        _this.changeDetection.detectChanges();
      }
    }, ({ error }) => {
      
      error ? _this.utilService.showToast('error',error.error) : _this.utilService.showToast('error','Please login to upload photo');
    })
  }

  removeFromFiles(fileName) {
    
    imageUploadConst.files.forEach((element, index) => {
      
      if (element.id == fileName.id) {
        
        imageUploadConst.files.splice(index, 1);
      }
    })
    this.mediaId = null;
    
    this.changeDetection.detectChanges();
  }

  deletePhoto(fileName: any) {
    this.mediaService.deleteMedia(fileName.id, 60).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.mediaId = null;
      this.changeDetection.detectChanges();
    }, (err) => {
      this.utilService.showToast('error','Error on media update');
    })
  }
  addArticle() {
    
    this.submited = true;

    let obj = {
      "articleDetailLanguages": [],
      "mediaId": this.mediaId,
      date: new Date()
    };
    let engLang = {
      "title": this.addarticle.value.engTitle,
      "description": this.addarticle.value.engDescription,
      "languageId": 1
    }
    obj.articleDetailLanguages = [engLang];
    if (this.addarticle.value.thTitle && this.addarticle.value.thDescription) {
      let thLang = {
        "title": this.addarticle.value.thTitle,
        "description": this.addarticle.value.thDescription,
        "languageId": 2
      }
      obj.articleDetailLanguages.push(thLang);
    }
    if ((!this.addarticle.value.engTitle || !this.addarticle.value.engDescription) && (!this.addarticle.value.thTitle || !this.addarticle.value.thDescription)) {
      this.utilService.showToast('success','Add Title and Descritpion');
      return;
    }
    if (!this.mediaId) {
      this.utilService.showToast('success','Add one image!');
      return;
    }
    this.dashboardService.saveArticle(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.articleAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Article Created!')
    }, (err) => {
      this.submited = false;
      this.utilService.showToast('error','Try again!')
    })


  }
  close() {
    this.dialogRef.close();
    this.currentPage = 1;
    this.getAllArticle();
    this.updatingarticle = false;
    this.articleAddOrUpdateSuccess = false;
    this.submited = false;
  }

  delete(ev) {
    
    this.utilService.showAcceptDeclineDialog("You are about to delete a article!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
      
      if (accepet) {
        this.dashboardService.deleteArticle(ev.id).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          
          if (data) {
            this.utilService.showToast('success',"article Deleted!")
            this.currentPage = 1;
            this.getAllArticle();
          } else {
            this.utilService.showToast('error',"Try again!")
          }
        })
      }
    })
  }
  edit(ev) {
    
    this.articleId = ev.id;
    this.updatingarticle = true;
    this.articleAddOrUpdateSuccess = false;
    ev.articleDetailLanguages.forEach(element => {
      if (element.languageId == 1) {
        this.addarticle.controls['engTitle'].setValue(element.title);
        this.addarticle.controls['engDescription'].setValue(element.title);
      } else {
        this.addarticle.controls['thTitle'].setValue(element.title);
        this.addarticle.controls['thDescription'].setValue(element.title);
      }
    });
    if (ev.media) {
      let prepairMedia = [];
      prepairMedia.push({
        name: ev.media, // file name
        type: 'image/*', // file MIME type
        id: ev.mediaId,
        file: this.storage + '/article/' + ev.media + '?' + new Date().getTime(), // file path
        data: {
          imageId: ev.mediaId,
          readerCrossOrigin: "Anonymous"
        },
        uploaded: true
      })
      this.mediaId = ev.mediaId;
      imageUploadConst.files = prepairMedia;
    }
    this.dialogRef = this.dialog.open(this.addNewAnn, {
      panelClass: 'add-new-dialog'
    })

    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.assignFile();
    });

  }
  updateArticle() {
    
    this.articleAddOrUpdateSuccess = false;
    this.submited = true;
    if (!this.mediaId) {

      return;
    }
    let obj = {
      "articleDetailLanguages": [],
      "mediaId": this.mediaId,
      date: new Date(),
      id: this.articleId
    };
    let engLang = {
      "title": this.addarticle.value.engTitle,
      "description": this.addarticle.value.engDescription,
      "languageId": 1
    }
    obj.articleDetailLanguages = [engLang];
    if (this.addarticle.value.thTitle && this.addarticle.value.thDescription) {
      let thLang = {
        "title": this.addarticle.value.thTitle,
        "description": this.addarticle.value.thDescription,
        "languageId": 2
      }
      obj.articleDetailLanguages.push(thLang);
    }

    if ((!this.addarticle.value.engTitle || !this.addarticle.value.engDescription) && (!this.addarticle.value.thTitle || !this.addarticle.value.thDescription)) {
      this.utilService.showToast('success','Add Title and Descritpion');
      return;
    }
    if (!this.mediaId) {
      this.utilService.showToast('success','Add one image!');
      return;
    }
    this.dashboardService.updateArticle(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.articleAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Article updated!')
    }, (err) => {
      this.submited = false;
      this.articleAddOrUpdateSuccess = false;
      this.utilService.showToast('error','Try Again')
    })
  }
}
