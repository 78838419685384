import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from 'src/payment-component/services/payment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { PaymentDialogComponent } from '../payment-dialog/payment-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'payment-component',
    templateUrl: 'payment.component.html',
    styleUrls: ['./payment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject();
    @Input()
    packageFromListingData: any;
    @Input()
    listingId: number = null;
    packages = [];
    @Output()
    selectedPackageEvent: EventEmitter<any> = new EventEmitter();
    selectedPackageArray: any[] = [];
    storage: string = environment.storageUrl;
    changeText: boolean = false;

    newTextForPackage: any = null;
    billingId: string = null;
    from: string = null;
    to: string = null;
    // runCheck:number=1;

    constructor(private paymentService: PaymentService, private changeDetection: ChangeDetectorRef, public dialog: MatDialog, private fb: FormBuilder) {

    }
    ngOnInit() {
        
        this.paymentService.getProducts().pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
            this.packages = data;
            this.changeDetection.detectChanges();
            if (this.packageFromListingData) {
                

                this.packageFromListingData.forEach(mainElement => {
                    let packageResult: any = {
                        selectedItem: null,
                        selectedPackageItemDetails: null,
                        customText: null,
                        selectedMainPackage: null,
                        bill: null
                    };

                    
                    packageResult.selectedMainPackage = mainElement.productId;
                    packageResult.selectedItem = mainElement.productId;
                    this.from = mainElement.bannerText;
                    // this.billingId = mainElement.bill.id;
                    // packageResult.bill = mainElement.bill;
                    packageResult.selectedPackageItemDetails = mainElement.productId;
                    packageResult.customText = mainElement.isCustomBannerText ? mainElement.bannerText : null;
                    packageResult.from = 'old';
                    

                    this.selectedPackageArray.push(packageResult);
                    this.packages.forEach(element => {
                        if (element.id == mainElement.productId) {
                            element.selected = 'old';

                        }
                    });
                });

                this.selectedPackageEvent.emit(this.selectedPackageArray)
            }
        }, (err) => {
            
        })
    }

    openDialogForAdd(pckg) {
        
        const dialogRef = this.dialog.open(PaymentDialogComponent, {
            data: pckg,
            panelClass: 'package-dialog',
        });

        dialogRef.afterClosed().subscribe(result => {
            
            if (result) {
                // this.animal = result;
                result.selectedMainPackage = pckg.id;
                // if (this.packageFromListingData.length) {
                //     
                //     this.packageFromListingData.forEach(mainElement => {
                //         if (mainElement.bill && mainElement.bill.paymentStatusId == 1 && mainElement.bill.paymentStatusId == 3) {
                //             result.from = 'old';

                //         }else{
                //             return;
                //         }
                //     })
                // }else{
                
                result.from = 'new';
                // }
                this.packages.forEach(element => {
                    if (element.id == pckg.id) {
                        element.selected = 'new';
                    }
                });
                // this.packageFromListingData=[{
                //     package:{
                //         id:pckg.id
                //     },
                //     packageItem:{
                //         name:result.selectedItem.name
                //     }
                // }];
                
                this.changeDetection.detectChanges();
                this.selectedPackageArray.push(result);
                this.selectedPackageEvent.emit(this.selectedPackageArray)
            }
        });
    }
    remove(pckg) {
        this.packages.forEach(element => {
            if (element.id == pckg.id) {
                element.selected = null;
            }
        });
        this.selectedPackageArray = this.selectedPackageArray.filter(x => {
            return x.selectedMainPackage !== pckg.id;
        })
        this.selectedPackageEvent.emit(this.selectedPackageArray)
    }

    changePakageText() {
        
        this.paymentService.updateNewText(this.listingId, this.billingId, this.from, this.newTextForPackage.name).pipe(takeUntil(this.destroy$)).subscribe((data) => {
            
            this.packageFromListingData.forEach(mainElement => {
                
                mainElement.packageItem.name = this.newTextForPackage.name
            })
            this.changeDetection.detectChanges();
        }, (err) => {
            
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}