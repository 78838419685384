import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { GenericDialogComponent } from 'src/listing/containers/generic-dialog/generic-dialog.component';
var DashboardAlertsComponent = /** @class */ (function () {
    function DashboardAlertsComponent(dashboardService, enumPropertyService, changeDetection, utilService, dialog) {
        this.dashboardService = dashboardService;
        this.enumPropertyService = enumPropertyService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dialog = dialog;
        this.alertOnOff = false;
        this.destroy$ = new Subject();
        this.alerts = [];
        this.taxonomy = {};
        this.selectedListingType = null;
        this.selectedType = null;
        this.selectedSubType = null;
        this.lat = null;
        this.lng = null;
        this.area = null;
        this.dialogRef = null;
        this.aleartSubmitted = false;
        this.alert = null;
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
    }
    DashboardAlertsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var data = _a.data;
            _this.taxonomy = data;
            _this.changeDetection.detectChanges();
            _this.getAllALerts();
        });
    };
    DashboardAlertsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAlertsComponent.prototype.getAllALerts = function () {
        var _this = this;
        this.dashboardService.getAlerts(this.currentPage, 10).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.alerts = data;
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardAlertsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllALerts();
    };
    DashboardAlertsComponent.prototype.getEnumById = function (firstElement, type, secondElement, id) {
        var nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
        return nameOrNumber;
    };
    DashboardAlertsComponent.prototype.openMapDialog = function () {
        var _this = this;
        this.dialog.open(GenericDialogComponent, {
            panelClass: 'map-dialog',
            minHeight: '500px',
            minWidth: '453px',
            data: { showMap: false, initialPosition: { lat: this.lat, lng: this.lng, area: this.area }, text: 'Start typing the location or drop the pin in the map' },
        }).afterClosed().subscribe(function (data) {
            if (data) {
                _this.lat = data[0].latitude;
                _this.lng = data[0].longitude;
                _this.area = data[0].area;
            }
        });
    };
    DashboardAlertsComponent.prototype.clearSearch = function () {
        this.area = null;
        this.lat = null;
        this.lng = null;
    };
    DashboardAlertsComponent.prototype.updateAlertEnable = function (sliderEvent, alert) {
        var _this = this;
        var obj = {
            propertyType: alert.propertyType,
            listingType: alert.listingType,
            enable: sliderEvent.checked,
            latitude: alert.latitude,
            longitude: alert.longitude,
            residentialType: alert.residentialType,
            area: alert.area,
            id: alert.id
        };
        this.dashboardService.updateAlerts(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.getAllALerts();
            _this.utilService.showToast('success', "Alert has been " + (sliderEvent.checked ? 'On' : 'Off'));
        }, function (err) {
            _this.utilService.showToast('error', "Try again!");
        });
    };
    DashboardAlertsComponent.prototype.deleteAlert = function (alertId) {
        var _this = this;
        this.dashboardService.deleteAlerts(alertId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.currentPage = 1;
            _this.getAllALerts();
            _this.utilService.showToast('success', 'Alert Deleted!');
        }, function (err) {
            _this.utilService.showToast('error', "Try again!");
        });
    };
    DashboardAlertsComponent.prototype.openAleartDialog = function (alert) {
        this.selectedListingType = alert.listingType;
        this.selectedType = this.getEnumById('propertyTypeResidentialType', null, alert.propertyType, false).name;
        this.selectedSubType = this.getEnumById('propertyTypeResidentialType', alert.propertyType, alert.residentialType, false).name;
        this.lat = alert.latitude;
        this.lng = alert.longitude;
        this.area = alert.area;
        this.alert = alert;
        this.changeDetection.detectChanges();
        this.dialogRef = this.dialog.open(this.createAleartDialog, {
            panelClass: 'create-aleart-dialog',
            width: '857px'
        });
    };
    DashboardAlertsComponent.prototype.closeAlert = function () {
        this.selectedListingType = null;
        this.selectedType = null;
        this.selectedSubType = null;
        this.area = null;
        this.lat = null;
        this.lng = null;
        this.aleartSubmitted = false;
        this.currentPage = 1;
        this.dialogRef.close();
        this.getAllALerts();
    };
    DashboardAlertsComponent.prototype.updateAlert = function () {
        var _this = this;
        var obj = {
            propertyType: this.getEnumById('propertyType', null, this.selectedType, true).id,
            listingType: this.selectedListingType,
            enable: true,
            latitude: this.lat,
            longitude: this.lng,
            residentialType: this.getEnumById('propertyTypeResidentialType', this.selectedType, this.selectedSubType, true).id,
            area: this.alert.area,
            id: this.alert.id
        };
        this.dashboardService.updateAlerts(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.aleartSubmitted = true;
            _this.utilService.showToast('success', "Alert has been updated!");
        }, function (err) {
            _this.utilService.showToast('error', "Try again!");
        });
    };
    return DashboardAlertsComponent;
}());
export { DashboardAlertsComponent };
