import { OnInit, ChangeDetectorRef, EventEmitter } from "@angular/core";
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from 'src/core/service/language/language.service';
import { LoginRegisterComponent } from 'src/login-register/container/login-register/login-register.component';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(fb, dialog, router, ref, route, loginRegisterService, authService, utilService, loadingScreenService, languageService, translateService) {
        var _this = this;
        this.fb = fb;
        this.dialog = dialog;
        this.router = router;
        this.ref = ref;
        this.route = route;
        this.loginRegisterService = loginRegisterService;
        this.authService = authService;
        this.utilService = utilService;
        this.loadingScreenService = loadingScreenService;
        this.languageService = languageService;
        this.translateService = translateService;
        this.sidenavToggle = new EventEmitter();
        this.sidenavClose = new EventEmitter();
        this.destroy$ = new Subject();
        this.location = "";
        this.selectedLoginTab = 'signIn';
        this.submitted = false;
        this.firstLoginSubmitted = false;
        this.forgetPassword = false;
        this.loginRegisterForm = this.fb.group({
            firstName: [''],
            lastName: [''],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(6)]]
        });
        this.firstDiloagForm = this.fb.group({
            userRole: [null]
        });
        this.isAuthentic = false;
        this.loading = false;
        this.userProfil = null;
        this.dialogRef = null;
        this.code = null;
        this.state = null;
        this.language = this.translateService.currentLang;
        this.percentageProfile = 0;
        this.isAdmin = false;
        this.onToggleSidenav = function () {
            _this.sidenavToggle.emit();
        };
        this.closeSideNav = function () {
            _this.sidenavClose.emit();
        };
    }
    NavbarComponent.prototype.checkAuthentication = function () {
        var _this = this;
        this.isAuthentic = this.loginRegisterService.isAuthenticated();
        if (this.isAuthentic) {
            this.getProfilePercentage();
            console.log("checkAuthentication");
            this.authService.getProfile().pipe(takeUntil(this.destroy$)).subscribe(function () {
                _this.userProfil = _this.authService.profileState.value;
                _this.isAdmin = _this.authService.profileState.value.isAdmin;
                _this.ref.detectChanges();
                if (false) {
                    _this.dialogRef = _this.dialog.open(_this.showFirstLoginDialog, {
                        panelClass: 'first-login-dialog',
                        maxHeight: '950px',
                        maxWidth: '950px',
                    });
                }
            });
        }
        this.ref.detectChanges();
    };
    NavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingScreenService.startLoading();
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).pipe(takeUntil(this.destroy$)).subscribe(function (event) {
            _this.location = event.url;
            if (_this.dialogRef) {
                _this.dialogRef.close();
            }
            _this.ref.detectChanges();
            if (!_this.location.match('/resetpassword') && !_this.location.match('/payment-success')) {
                _this.checkStatus();
            }
        });
        this.checkAuthentication();
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var lang = _a.lang;
            _this.language = lang;
            _this.ref.detectChanges();
            console.log("lang", lang);
            if (lang == 'th') {
                $("<style id='myStyle' type='text/css'> *,html,body{  font-family: \"Prompt\"!important;} </style>").appendTo("head");
            }
            else {
                $('#myStyle').remove();
            }
        });
    };
    NavbarComponent.prototype.checkStatus = function () {
        var _this = this;
        this.route.queryParamMap.pipe(filter(function (_a) {
            var keys = _a.keys;
            if (!!keys.length) {
                _this.loadingScreenService.stopLoading();
                return !!keys.length;
            }
        })).subscribe(function (params) {
            _this.code = params.params['code'];
            _this.state = params.params['state'];
            console.warn(_this.code);
            var request = {
                AccessToken: _this.code,
                Provider: 'LinkedIn'
            };
            console.log("checkStatus");
            _this.loginRegisterService.logingWithFacebook(request)
                .pipe(switchMap(function () { return _this.loginRegisterService.getProfile(); }))
                .pipe(takeUntil(_this.destroy$)).subscribe(function (data) {
                _this.checkAuthentication();
                _this.router.navigate(['/']).then(function () {
                    window.location.reload();
                });
                _this.loadingScreenService.stopLoading();
            });
        }, function (errLinkdingLogin) {
            _this.loadingScreenService.stopLoading();
        });
    };
    // ngAfterViewInit() {
    //   if (this.isAuthentic) {
    //     this.authService.callSignalR();
    //   }
    // }
    NavbarComponent.prototype.openDialog = function (templateRef, type) {
        var maxWidht = '453px';
        var maxHeight = '510px';
        var minWidth = '100px';
        var template = LoginRegisterComponent;
        var right = '225px';
        if (type == 'alreadyLogin') {
            maxWidht = '400px';
            maxHeight = '450px';
            minWidth = '280px';
            right = '345px';
            template = templateRef;
        }
        this.dialogRef = this.dialog.open(template, {
            panelClass: 'login-register-dialog',
            maxHeight: maxHeight,
            maxWidth: maxWidht,
            minWidth: minWidth,
            position: {
                right: right,
                top: '120px'
            }
        });
        this.dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                window.location.reload();
                // this.checkAuthentication();
                // this.getProfilePercentage();
            }
        });
    };
    NavbarComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    Object.defineProperty(NavbarComponent.prototype, "f", {
        get: function () { return this.loginRegisterForm.controls; },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.setLanguage = function (language) {
        console.log("language", language);
        if (language == 'th') {
            $("<style id='myStyle' type='text/css'> *,html,body{  font-family: \"Prompt\"!important;} </style>").appendTo("head");
        }
        else {
            $('#myStyle').remove();
        }
        this.languageService.setLanguage(language);
    };
    NavbarComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    NavbarComponent.prototype.getProfilePercentage = function () {
        var _this = this;
        this.loginRegisterService.getPercentageOfProfile().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            _this.percentageProfile = data;
            _this.ref.detectChanges();
        }, function (err) {
        });
    };
    NavbarComponent.prototype.logout = function () {
        this.authService.logOut();
        this.checkAuthentication();
        this.dialogRef.close();
        this.router.navigate(['/']).then(function () {
            window.location.reload();
        });
    };
    NavbarComponent.prototype.submitFirstLogin = function () {
        this.firstLoginSubmitted = true;
    };
    NavbarComponent.prototype.goto = function () {
        this.router.navigate(['/article']);
    };
    return NavbarComponent;
}());
export { NavbarComponent };
