import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { ListingService } from 'src/listing/service/listing.service';

@Component({
  selector: 'td-dashboard-favorite-ads',
  templateUrl: './dashboard-favorite-ads.component.html',
  styleUrls: ['./dashboard-favorite-ads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFavoriteAdsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  listingData: any[] = [];
  search: FormGroup = this.fb.group({
    searchText: [''],
    listingType: [null]
  })
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 2
  }
  constructor(private fb: FormBuilder, private dashboardService: DashboardService,
    private changeDetection: ChangeDetectorRef, private utilService: UtilService, private listingService: ListingService) { }

  ngOnInit() {
    this.getAllListing();
  }

  getAllListing() {
    let obj = {
      listingType: this.search.value.listingType,
      filter: this.search.value.searchText,
      favorites: true
    }

    this.dashboardService.getAllListing(this.currentPage, 10, obj)
      .pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
        

        this.listingData = data;
        this.changeDetection.detectChanges();
        this.pageInfo = pageInfo;
        this.changeDetection.detectChanges();
        
      })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllListing();
  }
  deleteEventCalled(ev: number) {
    
    this.utilService.showAcceptDeclineDialog("You are about to unfavourite a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
      
      if (accepet) {
        this.listingService.addToFav(ev).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          this.utilService.showToast('success',`#${ev} Listing Unfavorite!`);
          this.currentPage = 0;
          this.pageInfo.total = 2;
          this.getAllListing();
        }, (err) => {
          console.error(err)
          this.utilService.showToast('error',`There is an error deleting listing! Try again later.`);
        })
      }
    })
  }
  clearSearch() {
    this.search.controls.searchText.setValue('');
    this.search.controls.listingType.setValue(null);
    this.searchForListing();
  }

  searchForListing() {
    this.currentPage = 0;
    this.pageInfo.total = 2;
    this.getAllListing()
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
