/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cropper-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i12 from "ngx-image-cropper";
import * as i13 from "@angular/platform-browser";
import * as i14 from "./cropper-dialog.component";
import * as i15 from "../../../shared/services/util.service";
var styles_CropperDialogComponent = [i0.styles];
var RenderType_CropperDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CropperDialogComponent, data: {} });
export { RenderType_CropperDialogComponent as RenderType_CropperDialogComponent };
export function View_CropperDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "cropperd-dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "button-container"], ["fxLayout", "row"], ["fxLayoutAlign", "end"], ["fxLayoutGap", "4%"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["color", "accent"], ["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(7, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, [" Cancel "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), i1.ɵdid(11, 606208, null, 0, i10.MatDialogClose, [[2, i10.MatDialogRef], i1.ElementRef, i10.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, [" Accept Image "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "image-cropper", [["format", "png"]], [[4, "text-align", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 14).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_7 = (_co.loadImageFailed() !== false);
        ad = (pd_7 && ad);
    } return ad; }, i11.View_ImageCropperComponent_0, i11.RenderType_ImageCropperComponent)), i1.ɵdid(14, 573440, null, 0, i12.ImageCropperComponent, [i13.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], maintainAspectRatio: [2, "maintainAspectRatio"], aspectRatio: [3, "aspectRatio"], resizeToWidth: [4, "resizeToWidth"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", loadImageFailed: "loadImageFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "4%"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "end"; _ck(_v, 4, 0, currVal_2); var currVal_6 = "accent"; _ck(_v, 6, 0, currVal_6); var currVal_7 = ""; _ck(_v, 7, 0, currVal_7); var currVal_11 = !_co.file; var currVal_12 = "primary"; _ck(_v, 10, 0, currVal_11, currVal_12); var currVal_13 = _co.file; _ck(_v, 11, 0, currVal_13); var currVal_15 = _co.data.fileEvent; var currVal_16 = "png"; var currVal_17 = true; var currVal_18 = _co.data.aspectRatio; var currVal_19 = _co.data.width; _ck(_v, 14, 0, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19); }, function (_ck, _v) { var currVal_3 = (i1.ɵnov(_v, 6).disabled || null); var currVal_4 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 7).ariaLabel || null); _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); var currVal_8 = (i1.ɵnov(_v, 10).disabled || null); var currVal_9 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_10 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10); var currVal_14 = i1.ɵnov(_v, 14).alignImage; _ck(_v, 13, 0, currVal_14); }); }
export function View_CropperDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cropper-dialog", [], null, null, null, View_CropperDialogComponent_0, RenderType_CropperDialogComponent)), i1.ɵdid(1, 114688, null, 0, i14.CropperDialogComponent, [i15.UtilService, i10.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CropperDialogComponentNgFactory = i1.ɵccf("app-cropper-dialog", i14.CropperDialogComponent, View_CropperDialogComponent_Host_0, {}, {}, []);
export { CropperDialogComponentNgFactory as CropperDialogComponentNgFactory };
