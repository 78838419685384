/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-crop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-image-cropper/ngx-image-cropper.ngfactory";
import * as i3 from "ngx-image-cropper";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "@ngx-translate/core";
import * as i14 from "@angular/material/dialog";
import * as i15 from "./image-crop.component";
var styles_ImageCropComponent = [i0.styles];
var RenderType_ImageCropComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageCropComponent, data: {} });
export { RenderType_ImageCropComponent as RenderType_ImageCropComponent };
export function View_ImageCropComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageCropper: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "image-cropper", [["format", "png"], ["outputType", "both"]], [[4, "text-align", null]], [[null, "imageCropped"], [null, "imageLoaded"], [null, "cropperReady"], [null, "loadImageFailed"], ["window", "resize"], ["document", "mousemove"], ["document", "touchmove"], ["document", "mouseup"], ["document", "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:mousemove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).moveImg($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:touchmove" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).moveImg($event) !== false);
        ad = (pd_2 && ad);
    } if (("document:mouseup" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).moveStop() !== false);
        ad = (pd_3 && ad);
    } if (("document:touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).moveStop() !== false);
        ad = (pd_4 && ad);
    } if (("imageCropped" === en)) {
        var pd_5 = (_co.imageCropped($event) !== false);
        ad = (pd_5 && ad);
    } if (("imageLoaded" === en)) {
        var pd_6 = (_co.imageLoaded() !== false);
        ad = (pd_6 && ad);
    } if (("cropperReady" === en)) {
        var pd_7 = (_co.cropperReady() !== false);
        ad = (pd_7 && ad);
    } if (("loadImageFailed" === en)) {
        var pd_8 = (_co.loadImageFailed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, i2.View_ImageCropperComponent_0, i2.RenderType_ImageCropperComponent)), i1.ɵdid(2, 573440, [[1, 4]], 0, i3.ImageCropperComponent, [i4.DomSanitizer, i1.ChangeDetectorRef, i1.NgZone], { imageChangedEvent: [0, "imageChangedEvent"], format: [1, "format"], outputType: [2, "outputType"], maintainAspectRatio: [3, "maintainAspectRatio"], aspectRatio: [4, "aspectRatio"], resizeToWidth: [5, "resizeToWidth"], cropperMinWidth: [6, "cropperMinWidth"], onlyScaleDown: [7, "onlyScaleDown"] }, { imageCropped: "imageCropped", imageLoaded: "imageLoaded", cropperReady: "cropperReady", loadImageFailed: "loadImageFailed" }), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["fxLayout", "row"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 1720320, null, 0, i5.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i7.Directionality, i6.StyleUtils, [2, i5.LayoutGapStyleBuilder], i6.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(6, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cropped() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(9, 0, ["", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["mat-dialog-close", ""], ["mat-flat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).dialogRef.close(i1.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(13, 606208, null, 0, i14.MatDialogClose, [[2, i14.MatDialogRef], i1.ElementRef, i14.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(14, 0, ["", ""])), i1.ɵpid(131072, i13.TranslatePipe, [i13.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageChangedEvent; var currVal_2 = "png"; var currVal_3 = "both"; var currVal_4 = true; var currVal_5 = (4 / 3); var currVal_6 = 309; var currVal_7 = 109; var currVal_8 = true; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_9 = "row"; _ck(_v, 4, 0, currVal_9); var currVal_10 = "20px"; _ck(_v, 5, 0, currVal_10); var currVal_11 = "center center"; _ck(_v, 6, 0, currVal_11); var currVal_14 = "primary"; _ck(_v, 8, 0, currVal_14); var currVal_19 = "warn"; _ck(_v, 12, 0, currVal_19); var currVal_20 = ""; _ck(_v, 13, 0, currVal_20); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).alignImage; _ck(_v, 1, 0, currVal_0); var currVal_12 = (i1.ɵnov(_v, 8).disabled || null); var currVal_13 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_12, currVal_13); var currVal_15 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("Upload")); _ck(_v, 9, 0, currVal_15); var currVal_16 = (i1.ɵnov(_v, 12).disabled || null); var currVal_17 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); var currVal_18 = (i1.ɵnov(_v, 13).ariaLabel || null); _ck(_v, 11, 0, currVal_16, currVal_17, currVal_18); var currVal_21 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Cancel")); _ck(_v, 14, 0, currVal_21); }); }
export function View_ImageCropComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-image-cropper", [], null, null, null, View_ImageCropComponent_0, RenderType_ImageCropComponent)), i1.ɵdid(1, 114688, null, 0, i15.ImageCropComponent, [i14.MatDialogRef, i14.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageCropComponentNgFactory = i1.ɵccf("td-image-cropper", i15.ImageCropComponent, View_ImageCropComponent_Host_0, {}, { onAdd: "onAdd" }, []);
export { ImageCropComponentNgFactory as ImageCropComponentNgFactory };
