import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/media/service/media.service';
import { HttpEventType } from '@angular/common/http';
var DashboardAdminManageTestimonialsComponent = /** @class */ (function () {
    function DashboardAdminManageTestimonialsComponent(dashboardService, changeDetection, utilService, dialog, fb, mediaService) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dialog = dialog;
        this.fb = fb;
        this.mediaService = mediaService;
        this.destroy$ = new Subject();
        this.dialogRef = null;
        this.slectedLanguage = 1;
        this.submited = false;
        this.testimonialAddOrUpdateSuccess = false;
        this.updatingTestimonial = false;
        this.addTestimonial = this.fb.group({
            engTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
            message: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
            jobPosition: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
            location: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
        });
        this.fileUploadProgress = null;
        this.mediaIds = [];
        this.testimonialData = [];
        this.storage = environment.storageUrl;
        this.currentPage = 1;
        this.assDec = true;
        this.filter = null;
        this.testimonialId = null;
        this.pageInfo = {
            "total": 0,
            "totalPages": 1,
            "pageSize": 0,
            "currentPage": 0
        };
    }
    DashboardAdminManageTestimonialsComponent.prototype.ngOnInit = function () {
        this.getAllTestimonial();
    };
    DashboardAdminManageTestimonialsComponent.prototype.getAllTestimonial = function () {
        var _this_1 = this;
        this.dashboardService.getAllTestimonial(this.currentPage, 10, this.assDec, this.filter).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this_1.testimonialData = data;
            _this_1.changeDetection.detectChanges();
            _this_1.pageInfo = pageInfo;
            _this_1.changeDetection.detectChanges();
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllTestimonial();
    };
    Object.defineProperty(DashboardAdminManageTestimonialsComponent.prototype, "f", {
        get: function () { return this.addTestimonial.controls; },
        enumerable: true,
        configurable: true
    });
    DashboardAdminManageTestimonialsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAdminManageTestimonialsComponent.prototype.addNew = function (templateRef) {
        var _this_1 = this;
        imageUploadConst.files = [];
        this.updatingTestimonial = false;
        this.testimonialAddOrUpdateSuccess = false;
        this.submited = false;
        this.addTestimonial.setValue({ engTitle: '', message: '', jobPosition: '', location: '' });
        this.dialogRef = this.dialog.open(templateRef, {
            panelClass: 'add-new-dialog',
        });
        this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.fileUploadProgress = null;
            _this_1.mediaIds = [];
            _this_1.assignFile();
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.assignFile = function () {
        var _this_1 = this;
        var _this = this;
        imageUploadConst.limit = 1;
        imageUploadConst.onFileRead = function (item, listEl, parentEl, newInputEl, inputEl) {
            if (item.input) {
                _this.fileUploadStart(item);
            }
            else {
                item.id = item.data.imageId;
            }
        };
        imageUploadConst.onRemove = function (item) {
            _this.deletePhoto(item);
        };
        imageUploadConst.dialogs.alert = function (message) {
            _this.utilService.showToast('success', message);
        };
        imageUploadConst.getAllEvents = function (something) {
        };
        imageUploadConst.editor.onSave = function (dataUrl, item) {
            _this.removeFromFiles(item);
            var blob = _this.mediaService.dataURLtoFile(dataUrl, 'a.png');
            item.file = blob;
            if (blob) {
                _this.fileUploadStart(item);
            }
        };
        setTimeout(function () {
            _this_1.fileupload = window.$('input[name="files"]').fileuploader(imageUploadConst);
        }, 100);
    };
    DashboardAdminManageTestimonialsComponent.prototype.fileUploadStart = function (item) {
        var _this = this;
        var formData = new FormData();
        formData.append('file', item.file);
        // callback will go here
        _this.fileUploadProgress = '0';
        _this.mediaService.addMedia(50, formData).pipe(takeUntil(_this.destroy$)).subscribe(function (events) {
            if (events.type === HttpEventType.UploadProgress) {
                _this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
                _this.changeDetection.detectChanges();
                var progressBar = item.html.find('.progress-bar2');
                if (progressBar.length > 0) {
                    progressBar.show();
                    progressBar.find('span').html(_this.fileUploadProgress + "%");
                    progressBar.find('.fileuploader-progressbar .bar').width(_this.fileUploadProgress + "%");
                }
            }
            else if (events.type === HttpEventType.Response) {
                _this.fileUploadProgress = '';
                item.name = events.body.data.name;
                item.id = events.body.data.id;
                imageUploadConst.files.push({ id: events.body.data.id });
                item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
                setTimeout(function () {
                    item.html.find('.progress-bar2').fadeOut(400);
                }, 400);
                _this.mediaIds = _this.mediaIds.concat(events.body.data.id);
                _this.changeDetection.detectChanges();
            }
        }, function (_a) {
            var error = _a.error;
            error ? _this.utilService.showToast('error', error.error) : _this.utilService.showToast('error', 'Please login to upload photo');
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.removeFromFiles = function (fileName) {
        var _this_1 = this;
        imageUploadConst.files.forEach(function (element, index) {
            if (element.id == fileName.id) {
                imageUploadConst.files.splice(index, 1);
            }
        });
        this.mediaIds.forEach(function (element, index) {
            if (element == fileName.id) {
                _this_1.mediaIds.splice(index, 1);
            }
        });
        this.changeDetection.detectChanges();
    };
    DashboardAdminManageTestimonialsComponent.prototype.deletePhoto = function (fileName) {
        var _this_1 = this;
        this.mediaService.deleteMedia(fileName.id, 10).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.mediaIds = _this_1.mediaIds.filter(function (item) { return item !== fileName.id; });
            _this_1.changeDetection.detectChanges();
        }, function (err) {
            _this_1.utilService.showToast('error', 'Error on media update');
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.addTestimonials = function () {
        var _this_1 = this;
        this.submited = true;
        var obj = {
            title: this.addTestimonial.value.engTitle,
            message: this.addTestimonial.value.message,
            jobPosition: this.addTestimonial.value.jobPosition,
            location: this.addTestimonial.value.location,
            "mediaIds": this.mediaIds
        };
        if (!this.mediaIds.length) {
            this.utilService.showToast('success', 'Add one image!');
            return;
        }
        this.dashboardService.saveTestimonial(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.submited = false;
            _this_1.testimonialAddOrUpdateSuccess = true;
            _this_1.utilService.showToast('success', 'Testimonial Created!');
            _this_1.close();
        }, function (err) {
            _this_1.submited = false;
            _this_1.utilService.showToast('error', 'Try again!');
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.close = function () {
        this.dialogRef.close();
        this.currentPage = 0;
        this.pageInfo.pageSize = 1;
        this.getAllTestimonial();
        this.updatingTestimonial = false;
        this.testimonialAddOrUpdateSuccess = false;
        this.submited = false;
    };
    DashboardAdminManageTestimonialsComponent.prototype.delete = function (ev) {
        var _this_1 = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a testimonial!", "Are you sure ?", "400px")
            .pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this_1.dashboardService.deleteTestimonial(ev).pipe(takeUntil(_this_1.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    if (data) {
                        _this_1.utilService.showToast('success', "Testimonial Deleted!");
                        _this_1.currentPage = 0;
                        _this_1.pageInfo.pageSize = 1;
                        _this_1.getAllTestimonial();
                    }
                    else {
                        _this_1.utilService.showToast('error', "Try again!");
                    }
                });
            }
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.edit = function (ev) {
        var _this_1 = this;
        this.testimonialId = ev.id;
        this.updatingTestimonial = true;
        this.testimonialAddOrUpdateSuccess = false;
        this.addTestimonial.controls['engTitle'].setValue(ev.title);
        this.addTestimonial.controls['message'].setValue(ev.message);
        this.addTestimonial.controls['jobPosition'].setValue(ev.jobPosition);
        this.addTestimonial.controls['location'].setValue(ev.location);
        if (ev.medias.length) {
            var prepairMedia = [];
            prepairMedia.push({
                name: ev.medias[0].filePath,
                type: 'image/*',
                id: ev.medias[0].id,
                file: this.storage + '/testimonial/' + ev.medias[0].filePath + '?' + new Date().getTime(),
                data: {
                    imageId: ev.medias[0].id,
                    readerCrossOrigin: "Anonymous"
                },
                uploaded: true
            });
            this.mediaIds = [parseInt(ev.medias[0].id)];
            imageUploadConst.files = prepairMedia;
        }
        this.dialogRef = this.dialog.open(this.addNewTestimonial, {
            panelClass: 'add-new-dialog'
        });
        this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.assignFile();
        });
    };
    DashboardAdminManageTestimonialsComponent.prototype.updateTestimonial = function () {
        var _this_1 = this;
        this.testimonialAddOrUpdateSuccess = false;
        this.submited = true;
        if (!this.mediaIds.length) {
            return;
        }
        var obj = {
            title: this.addTestimonial.value.engTitle,
            message: this.addTestimonial.value.message,
            jobPosition: this.addTestimonial.value.jobPosition,
            location: this.addTestimonial.value.location,
            "mediaIds": this.mediaIds
        };
        this.dashboardService.updateTestimonial(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.submited = false;
            _this_1.testimonialAddOrUpdateSuccess = true;
            _this_1.utilService.showToast('success', 'Testimonial updated!');
        }, function (err) {
            _this_1.submited = false;
            _this_1.testimonialAddOrUpdateSuccess = false;
            _this_1.utilService.showToast('error', 'Try Again');
        });
    };
    return DashboardAdminManageTestimonialsComponent;
}());
export { DashboardAdminManageTestimonialsComponent };
