import { Routes } from '@angular/router';
import { UserRouteResolver } from 'src/app/user-route-resolver';
import { LoginScreenComponent } from './container/login-screen/login-screen.component';
import { ResetPasswordComponent } from './container/reset-password/reset-password.component';
var routes = [
    {
        path: "login-register",
        component: LoginScreenComponent,
        pathMatch: 'full',
        resolve: {
            userRouteResolver: UserRouteResolver
        },
    },
    {
        path: "recoverPassword/:code/:email",
        component: ResetPasswordComponent,
        resolve: {
            userRouteResolver: UserRouteResolver
        },
    }
];
var ɵ0 = {};
var LoginRegisterModule = /** @class */ (function () {
    function LoginRegisterModule() {
    }
    return LoginRegisterModule;
}());
export { LoginRegisterModule };
export { ɵ0 };
