<div class="manage-support" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
  <h5 translate>Analytics</h5>
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
    <h1 translate>Under Construction</h1>

    <p translate>Please check back for Updates</p>

    <p translate>Thank you for your Patience!</p>

    <p translate>support@thailandcommercial.com</p>
  </div>
</div>