<div class="contact-form">
  <form [formGroup]="contactFormHtml" ngxScrollToFirstInvalid *ngIf="!submitted">
      <div class="input-div" fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start stretch">
            <div fxLayout="column" >
                <input type="text" placeholder="Your Name" formControlName="name" class="form-control" 
                    [ngClass]="{ 'is-invalid': f.name.errors && submitted }" />
                <div *ngIf="f.name.errors && submitted " class="invalid-feedback">
                   
                    <div *ngIf="f.name.errors.required" translate>Name is required</div>
                </div>
            </div>
            <div fxLayout="column" >
                <input type="text" placeholder="Your Email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': f.email.errors && submitted }" />
                <div *ngIf="f.email.errors && submitted " class="invalid-feedback">
                    <div *ngIf="f.email.errors.required" translate>Email is required</div>
                    <div *ngIf="f.email.errors.email" translate>Email is not valid</div>
                    <div *ngIf="f.email.errors.minlength" translate>Email must be at least 6 characters</div>
                </div>
            </div>
            <div fxLayout="column">
                <input type="text" placeholder="Your Phone" formControlName="telephone" class="form-control" 
                    [ngClass]="{ 'is-invalid': f.telephone.errors && submitted }" />
                <div *ngIf="f.telephone.errors && submitted" class="invalid-feedback">
                    <div *ngIf="f.telephone.errors.required" translate>Phone number is required</div>
                    <div *ngIf="f.telephone.errors.minlength" translate>Phone number must be at least 10 characters</div>
                    <div *ngIf="f.telephone.errors.pattern" translate>Only Numbers</div>
                </div>
            </div>
            <div fxLayout="column">
                <textarea type="text" placeholder="Your Message" formControlName="detail" class="form-control"
                    [ngClass]="{ 'is-invalid': f.detail.errors && submitted }" >
                </textarea>
                <div *ngIf="f.detail.errors && submitted " class="invalid-feedback">
                    <div *ngIf="f.detail.errors.required" translate>Message is required</div>
                    <div *ngIf="f.detail.errors.minlength" translate>Message must be at least 6 characters</div>
                </div>
            </div>
            <!-- <re-captcha (resolved)="captchaResolve($event)" siteKey="'6LdN0LQUAAAAAD0P2y_tpk1w2Onn_6s_LBBuSwft'"></re-captcha> -->
        <button mat-flat-button class="contact-button" [color]="'primary'" (click)="onSubmit()"
        [disabled]="submitted||userOwnListing" >{{'Send Message'|translate}}</button>
      </div>
  </form>
  <div *ngIf="submitted" style="width:453px;">
      <h5 style="font-size: 15px;color: #444444;line-height: 1.5;" translate>
        Thank you for your message.  We will make every effort to reply to you the next business day (we are located in Bangkok, Thailand).  Thanks for your support, admin@thailandcommercial.com.
      </h5>
  </div>
</div>