/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./four-zero-four.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/router";
import * as i11 from "./four-zero-four.component";
var styles_FourZeroFourComponent = [i0.styles];
var RenderType_FourZeroFourComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FourZeroFourComponent, data: {} });
export { RenderType_FourZeroFourComponent as RenderType_FourZeroFourComponent };
export function View_FourZeroFourComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content-div"], ["fxLayout", "column"], ["fxLayoutAlign", "space-evenly center"], ["fxLayoutAlign.xs", "space-between center"], ["fxLayoutGap", "5%"], ["style", "margin: 40px 0;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"], "fxLayoutAlign.xs": [1, "fxLayoutAlign.xs"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["src", "assets/images/404/404.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "button", [["mat-flat-button", ""], ["style", "height:50px;width:200px;color:#fff;font-size:20px;"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(7, 16384, null, 0, i10.RouterLink, [i10.Router, i10.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(-1, 0, ["Go To Homepage"]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "5%"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "space-evenly center"; var currVal_3 = "space-between center"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_6 = "primary"; _ck(_v, 6, 0, currVal_6); var currVal_7 = _ck(_v, 8, 0, "/"); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 6).disabled || null); var currVal_5 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_4, currVal_5); }); }
export function View_FourZeroFourComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-four-zero-four", [], null, null, null, View_FourZeroFourComponent_0, RenderType_FourZeroFourComponent)), i1.ɵdid(1, 114688, null, 0, i11.FourZeroFourComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FourZeroFourComponentNgFactory = i1.ɵccf("app-four-zero-four", i11.FourZeroFourComponent, View_FourZeroFourComponent_Host_0, {}, {}, []);
export { FourZeroFourComponentNgFactory as FourZeroFourComponentNgFactory };
