import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    }
    DashboardService.prototype.getNotification = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Notifications");
    };
    DashboardService.prototype.getProfile = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "account/genericUser");
    };
    DashboardService.prototype.updateProfile = function (profile, id) {
        if (id === void 0) { id = 0; }
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "account/" + id, profile);
    };
    DashboardService.prototype.profileMediaUpload = function (formData) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Media/Upload?mediaType=20", formData);
    };
    DashboardService.prototype.deleteOldProfileImage = function (mediaId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Media?mediaId=" + mediaId + "&mediaType=20");
    };
    DashboardService.prototype.getAllListing = function (pageNumber, pageSize, listingObj) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (pageSize === void 0) { pageSize = 20; }
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Listing/Search?page=" + pageNumber + "&pageSize=" + pageSize + "&orderBy=createdAt&asc=false", listingObj);
    };
    DashboardService.prototype.deleteListing = function (id) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "listing/" + id);
    };
    DashboardService.prototype.getListOfMessageForAdmin = function (pageNumber, pageSize, obj, asc) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "SupportMessage/Search/Mine?page=" + pageNumber + "&pageSize=" + pageSize + "&asc=" + asc, obj);
    };
    DashboardService.prototype.getListOfMessageForUsers = function (pageNumber, pageSize, obj) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "SupportMessage/Search/Mine?page=" + pageNumber + "&pageSize=" + pageSize + "&asc=true", obj);
    };
    DashboardService.prototype.deleteMessage = function (id) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "SupportMessage/" + id);
    };
    DashboardService.prototype.updateMessageStatus = function (id) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "SupportMessage/" + id + "/read", {});
    };
    DashboardService.prototype.saveAnnouncement = function (obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Announcement", obj);
    };
    DashboardService.prototype.updateAnnouncement = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "Announcement", obj);
    };
    DashboardService.prototype.deleteAnnouncement = function (AnnouncementId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Announcement/" + AnnouncementId + " ");
    };
    DashboardService.prototype.getAlerts = function (currentPage, pageSize) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Alerts?page=" + currentPage + "&pageSize=" + pageSize);
    };
    DashboardService.prototype.updateAlerts = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "Alerts", obj);
    };
    DashboardService.prototype.deleteAlerts = function (alertId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Alerts/" + alertId);
    };
    DashboardService.prototype.getReportResponseFromAdmin = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "listing/Reports?orderby=createdAt&asc=false");
    };
    DashboardService.prototype.activeListingFor30Days = function (listingId) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Listing/expire/extend?listingId=" + listingId);
    };
    DashboardService.prototype.getAllBills = function (pageNumber, pageSize, obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "bills/search?page=" + pageNumber + "&pageSize=" + pageSize, obj);
    };
    //admin
    //testimonial
    DashboardService.prototype.saveTestimonial = function (obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Testimonial", obj);
    };
    DashboardService.prototype.updateTestimonial = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "Testimonial", obj);
    };
    DashboardService.prototype.deleteTestimonial = function (TestimonialId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Testimonial/" + TestimonialId + " ");
    };
    DashboardService.prototype.getAllTestimonial = function (pageNumber, pageSize, assDec, filter) {
        if (assDec === void 0) { assDec = true; }
        if (filter === void 0) { filter = null; }
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Testimonial/Search/Mine?page=" + pageNumber + "&pageSize=" + pageSize + "&asc=" + assDec, { 'filter': filter });
    };
    DashboardService.prototype.getAllReport = function (pageNumber, pageSize) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Listing/Reports?page=" + pageNumber + "&pageSize=" + pageSize + "&orderby=createdAt&asc=false");
    };
    DashboardService.prototype.deleteReport = function (reportId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "listing/report/" + reportId + " ");
    };
    DashboardService.prototype.giveResponse = function (obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "listing/report/response", obj);
    };
    DashboardService.prototype.getTipsOfTheDay = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "TipOfTheDay");
    };
    DashboardService.prototype.updateTipsOfTheDay = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "TipOfTheDay", obj);
    };
    DashboardService.prototype.suspendListing = function (listingId, statusId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "listing/" + listingId + "/status/" + statusId, {});
    };
    DashboardService.prototype.saveArticle = function (obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "articles", obj);
    };
    DashboardService.prototype.updateArticle = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "articles", obj);
    };
    DashboardService.prototype.deleteArticle = function (articleId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "articles/" + articleId + " ");
    };
    DashboardService.prototype.searchAllArticle = function (pageNumber, pageSize, obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "articles/Search/Admin?page=" + pageNumber + "&pageSize=" + pageSize + "&orderby=date&asc=false", obj);
    };
    DashboardService.prototype.getAllUserForAdmin = function (pageNumber, pageSize, obj, asc) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "account/Search?page=" + pageNumber + "&pageSize=" + pageSize + "&asc=" + asc, obj);
    };
    DashboardService.prototype.deleteUserForAdmin = function (userId) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "account/" + userId);
    };
    DashboardService.prototype.changeUserStatus = function (userId, stautsId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "account/" + userId + "/status/" + stautsId, {});
    };
    DashboardService.prototype.getNumberOfListingInApproveListing = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Listing/ammounts");
    };
    DashboardService.prototype.getApproveListing = function (pageNumber, pageSize, obj) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (pageSize === void 0) { pageSize = 20; }
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Listing/Search?page=" + pageNumber + "&pageSize=" + pageSize + "&orderby=createdAt&asc=false", obj);
    };
    DashboardService.prototype.updateListingStatus = function (listingId, statusId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "listing/" + listingId + "/status/" + statusId, {});
    };
    DashboardService.prototype.getAllAnnouncement = function (page, pageSize, obj, asc) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "announcement/Search?page=" + page + "&pageSize=" + pageSize + "&orderBy=createdAt&asc=" + asc, obj);
    };
    DashboardService.prototype.updateNotificationRead = function (notificationId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "notifications/" + notificationId + "/Read", {});
    };
    DashboardService.prototype.deleteNotification = function (id) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Notifications/" + id);
    };
    DashboardService.prototype.getBillPdf = function (billId) {
        var options = { headers: { skip: "true" } };
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
                'Accept': 'application/pdf',
                'Cache-Control': 'no-cache'
            }),
            responseType: 'blob'
        };
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Bills/" + billId + "/pdf", httpOptions);
    };
    DashboardService.prototype.sendBillToUser = function (billId) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Bills/" + billId + "/SendEmail", {});
    };
    DashboardService.prototype.getAllLeads = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "leads/Search");
    };
    DashboardService.prototype.deleteLeads = function (id) {
        return this.http.delete("" + environment.serverUrl + environment.apiUrl + "Leads/" + id);
    };
    DashboardService.prototype.sentToAgent = function (id, type) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "Leads/" + id + "/sent/" + type, {});
    };
    //if need dialog and send from platform to agents .currently not use
    DashboardService.prototype.sendLeadToAgent = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "leads", obj);
    };
    DashboardService.prototype.downloadLead = function (leadId) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
                'Accept': 'application/pdf',
                'Cache-Control': 'no-cache'
            }),
            responseType: 'blob'
        };
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "leads/" + leadId + "/Pdf", httpOptions);
    };
    DashboardService.prototype.getSingleListing = function (listingId) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Listing/" + listingId + "/Edit");
    };
    DashboardService.prototype.adminUpdatePropertyTypeOfListing = function (listingId, propertyTypeId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "listing/" + listingId + "/PropertyType/" + propertyTypeId, {});
    };
    DashboardService.prototype.adminUpdateResidentialTypeIdOfListing = function (listingId, residentialTypeId) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "listing/" + listingId + "/ResidentialType/" + residentialTypeId, {});
    };
    DashboardService.prototype.updatePropertyType = function (obj) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "Listing/AdditionalTypes", obj);
    };
    DashboardService.prototype.generalMetersForAdmin = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "metrics/general");
    };
    DashboardService.prototype.getLineGroup = function (listingId) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Share/Line/" + listingId + "/Groups");
    };
    DashboardService.prototype.shareToLine = function (listingId, groups) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "Share/Line/" + listingId, groups);
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
