<div class="dashboard-sidebar">
  <div class="photo-div">

    <a class="change-photo " (click)="changePhoto()"><i classs="mdi mdi-camera-plus"></i>{{'Change Photo'|translate}}<input type="file" #file class="file-upload" (change)="onFilesAdded($event)" /></a>

    <img [src]="defaultImage" />
  </div>
  <div class="side-menu" *ngIf="!user.isAdmin">
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'home-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-view-module"></i>
      <div class="list-text">{{'Dashboard'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'profile-dashboard'}}]"
      [routerLinkActive]="'active'">
      <i class="mdi mdi-human"></i>
      <div class="list-text">{{'My Profile'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-my-ads-dashboard'}}]"
      [routerLinkActive]="'active'">
      <i class="mdi mdi-format-list-checks"></i>
      <div class="list-text" mat-line>{{'Manage My Ads'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-my-fav-dashboard'}}]"
      [routerLinkActive]="'active'">
      <i class="mdi mdi-heart"></i>
      <div class="list-text">{{'Favorite Ads'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-my-announcements'}}]"
      [routerLinkActive]="'active'">
      <i class="mdi mdi-bullhorn"></i>
      <div class="list-text">{{'Announcement'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-message'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-email-outline"></i>
      <div class="list-text">{{'Message'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'reported-listing'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-email-outline"></i>
      <div class="list-text">{{'Reported Listing Response'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-payment-history'}}]"
      [routerLinkActive]="'active'">
      <i class="mdi mdi-credit-card"></i>
      <div class="list-text">{{'Payment History'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-alerts'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-alert-outline"></i>
      <div class="list-text">{{'Manage Alerts'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'analytics'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-chart-line-variant"></i>
      <div class="list-text">{{'Analytics'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard', {outlets:{dashboardOutlet:'manage-support'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-lightbulb-outline"></i>
      <div class="list-text">{{'Support'|translate}}</div>
    </div>
  </div>
  <div class="side-menu" *ngIf="user.isAdmin">
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'home-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-view-module"></i>
      <div class="list-text">{{'Dashboard'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-users-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-human"></i>
      <div class="list-text">{{'Manage Users'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-listings-approve-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-format-line-style"></i>
      <div class="list-text">{{'Approve Listings'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-listings-management-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-format-list-bulleted-square"></i>
      <div class="list-text">{{'Manage Listings'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-payments-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-credit-card"></i>
      <div class="list-text">{{'Payments'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-testimonials-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-pencil-outline"></i>
      <div class="list-text">{{'Testimonials'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-message-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-email-outline"></i>
      <div class="list-text">{{'Messages'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-calculate-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-calculator-variant"></i>
      <div class="list-text">{{'Leads'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-xml-feed-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-code-tags"></i>
      <div class="list-text">{{'XML Feed'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-reported-listings-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-alert"></i>
      <div class="list-text">{{'REPORTED LISTINGS '|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-article-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-file-document-box-plus"></i>
      <div class="list-text">{{'Articles'|translate}}</div>
    </div>
    <div class="list-item" fxLayout="row" fxLayoutAlign="start center"
      [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'admin-announcement-dashboard'}}]" [routerLinkActive]="'active'">
      <i class="mdi mdi-bullhorn"></i>
      <div class="list-text">{{'Announcements'|translate}}</div>
    </div>
  </div>
</div>