import { OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MediaService } from 'src/media/service/media.service';
import { HttpEventType } from '@angular/common/http';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';
var DashboardAdminAnnouncementsComponent = /** @class */ (function () {
    function DashboardAdminAnnouncementsComponent(dialog, fb, dashboardService, mediaService, changeDetection, utilService) {
        this.dialog = dialog;
        this.fb = fb;
        this.dashboardService = dashboardService;
        this.mediaService = mediaService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.destroy$ = new Subject();
        this.dialogRef = null;
        this.slectedLanguage = 1;
        this.submited = false;
        this.announcementAddOrUpdateSuccess = false;
        this.updatingAnnouncement = false;
        this.addAnnouncement = this.fb.group({
            engTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
            thTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
            engDescription: ['', [Validators.required, Validators.minLength(2)]],
            thDescription: ['', [Validators.required, Validators.minLength(2)]],
            status: [1, [Validators.required]]
        });
        this.fileUploadProgress = null;
        this.mediaIds = [];
        this.announcementData = [];
        this.storage = environment.storageUrl;
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.assDec = false;
        this.filter = null;
        this.announcementId = null;
    }
    DashboardAdminAnnouncementsComponent.prototype.ngOnInit = function () {
        this.getAllAnnouncment();
    };
    DashboardAdminAnnouncementsComponent.prototype.getAllAnnouncment = function () {
        var _this_1 = this;
        var obj = {
            id: null,
            filter: this.filter
        };
        this.dashboardService.getAllAnnouncement(this.currentPage, 10, obj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this_1.announcementData = data;
            _this_1.changeDetection.detectChanges();
            _this_1.pageInfo = pageInfo;
            _this_1.changeDetection.detectChanges();
        });
    };
    Object.defineProperty(DashboardAdminAnnouncementsComponent.prototype, "f", {
        get: function () { return this.addAnnouncement.controls; },
        enumerable: true,
        configurable: true
    });
    DashboardAdminAnnouncementsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAdminAnnouncementsComponent.prototype.addNew = function (templateRef) {
        var _this_1 = this;
        imageUploadConst.files = [];
        this.updatingAnnouncement = false;
        this.announcementAddOrUpdateSuccess = false;
        this.submited = false;
        this.addAnnouncement.patchValue({ engTitle: '', thTitle: '', engDescription: '', thDescription: '', status: 1 });
        this.dialogRef = this.dialog.open(templateRef, {
            panelClass: 'add-new-dialog',
        });
        this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.fileUploadProgress = null;
            _this_1.mediaIds = [];
            _this_1.assignFile();
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.assignFile = function () {
        var _this_1 = this;
        var _this = this;
        imageUploadConst.limit = 1;
        imageUploadConst.onFileRead = function (item, listEl, parentEl, newInputEl, inputEl) {
            if (item.input) {
                _this.fileUploadStart(item);
            }
            else {
                item.id = item.data.imageId;
            }
        };
        imageUploadConst.onRemove = function (item) {
            _this.deletePhoto(item);
        };
        imageUploadConst.dialogs.alert = function (message) {
            _this.utilService.showToast('info', message);
        };
        imageUploadConst.getAllEvents = function (something) {
        };
        imageUploadConst.editor.onSave = function (dataUrl, item) {
            _this.removeFromFiles(item);
            var blob = _this.mediaService.dataURLtoFile(dataUrl, 'a.png');
            item.file = blob;
            if (blob) {
                _this.fileUploadStart(item);
            }
        };
        setTimeout(function () {
            _this_1.fileupload = window.$('input[name="files"]').fileuploader(imageUploadConst);
        }, 100);
    };
    DashboardAdminAnnouncementsComponent.prototype.fileUploadStart = function (item) {
        var _this = this;
        var formData = new FormData();
        formData.append('file', item.file);
        // callback will go here
        _this.fileUploadProgress = '0';
        _this.mediaService.addMedia(40, formData).pipe(takeUntil(_this.destroy$)).subscribe(function (events) {
            if (events.type === HttpEventType.UploadProgress) {
                _this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
                _this.changeDetection.detectChanges();
                var progressBar = item.html.find('.progress-bar2');
                if (progressBar.length > 0) {
                    progressBar.show();
                    progressBar.find('span').html(_this.fileUploadProgress + "%");
                    progressBar.find('.fileuploader-progressbar .bar').width(_this.fileUploadProgress + "%");
                }
            }
            else if (events.type === HttpEventType.Response) {
                _this.fileUploadProgress = '';
                item.name = events.body.data.name;
                item.id = events.body.data.id;
                imageUploadConst.files.push({ id: events.body.data.id });
                item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
                setTimeout(function () {
                    item.html.find('.progress-bar2').fadeOut(400);
                }, 400);
                _this.mediaIds = _this.mediaIds.concat(events.body.data.id);
                _this.changeDetection.detectChanges();
            }
        }, function (_a) {
            var error = _a.error;
            error ? _this.utilService.showToast('error', error.error) : _this.utilService.showToast('error', 'Please login to upload photo');
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.removeFromFiles = function (fileName) {
        var _this_1 = this;
        imageUploadConst.files.forEach(function (element, index) {
            if (element.id == fileName.id) {
                imageUploadConst.files.splice(index, 1);
            }
        });
        this.mediaIds.forEach(function (element, index) {
            if (element == fileName.id) {
                _this_1.mediaIds.splice(index, 1);
            }
        });
        this.changeDetection.detectChanges();
    };
    DashboardAdminAnnouncementsComponent.prototype.deletePhoto = function (fileName) {
        var _this_1 = this;
        this.mediaService.deleteMedia(fileName.id, 40).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.mediaIds = _this_1.mediaIds.filter(function (item) { return item !== fileName.id; });
            _this_1.changeDetection.detectChanges();
        }, function (err) {
            _this_1.utilService.showToast('error', 'Error on media update');
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.addAnnouncemnet = function () {
        var _this_1 = this;
        this.submited = true;
        var obj = {
            "announcementDetailLanguage": [],
            "mediaIds": this.mediaIds
        };
        var engLang = {
            "title": this.addAnnouncement.value.engTitle,
            "description": this.addAnnouncement.value.engDescription,
            "languageId": 1
        };
        obj.announcementDetailLanguage = [engLang];
        if (this.addAnnouncement.value.thTitle && this.addAnnouncement.value.thDescription) {
            var thLang = {
                "title": this.addAnnouncement.value.thTitle,
                "description": this.addAnnouncement.value.thDescription,
                "languageId": 2
            };
            obj.announcementDetailLanguage.push(thLang);
        }
        if (!this.mediaIds.length) {
            this.utilService.showToast('success', 'Add one image!');
            return;
        }
        this.dashboardService.saveAnnouncement(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.submited = false;
            _this_1.announcementAddOrUpdateSuccess = true;
            _this_1.utilService.showToast('success', 'Announcemnet Created!');
        }, function (err) {
            _this_1.submited = false;
            _this_1.utilService.showToast('error', 'Try again!');
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.close = function () {
        this.dialogRef.close();
        this.currentPage = 1;
        this.getAllAnnouncment();
        this.updatingAnnouncement = false;
        this.announcementAddOrUpdateSuccess = false;
        this.submited = false;
    };
    DashboardAdminAnnouncementsComponent.prototype.delete = function (ev) {
        var _this_1 = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a announcement!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this_1.dashboardService.deleteAnnouncement(ev.id).pipe(takeUntil(_this_1.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    if (data) {
                        _this_1.utilService.showToast('success', "Announcement Deleted!");
                        _this_1.currentPage = 1;
                        _this_1.getAllAnnouncment();
                    }
                    else {
                        _this_1.utilService.showToast('error', "Try again!");
                    }
                });
            }
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.edit = function (ev) {
        var _this_1 = this;
        this.announcementId = ev.id;
        this.updatingAnnouncement = true;
        this.announcementAddOrUpdateSuccess = false;
        ev.announcementDetailLanguages.forEach(function (element) {
            if (element.languageId == 1) {
                _this_1.addAnnouncement.controls['engTitle'].setValue(element.title);
                _this_1.addAnnouncement.controls['engDescription'].setValue(element.description);
            }
            else {
                _this_1.addAnnouncement.controls['thTitle'].setValue(element.title);
                _this_1.addAnnouncement.controls['thDescription'].setValue(element.title);
            }
        });
        this.addAnnouncement.controls['status'].setValue(ev.status);
        this.changeDetection.detectChanges();
        if (ev.media) {
            var prepairMedia = [];
            prepairMedia.push({
                name: ev.media,
                type: 'image/*',
                id: ev.mediaId,
                file: this.storage + '/announcement/' + ev.media + '?' + new Date().getTime(),
                data: {
                    imageId: ev.mediaId,
                    readerCrossOrigin: "Anonymous"
                },
                uploaded: true
            });
            this.mediaIds = [parseInt(ev.mediaId)];
            imageUploadConst.files = prepairMedia;
        }
        this.dialogRef = this.dialog.open(this.addNewAnn, {
            panelClass: 'add-new-dialog'
        });
        this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.assignFile();
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.updateAnnouncemnet = function () {
        var _this_1 = this;
        this.announcementAddOrUpdateSuccess = false;
        this.submited = true;
        if (!this.mediaIds.length) {
            return;
        }
        var obj = {
            "announcementDetailLanguage": [],
            "mediaIds": this.mediaIds,
            id: this.announcementId,
            status: 1
        };
        var engLang = {
            "title": this.addAnnouncement.value.engTitle,
            "description": this.addAnnouncement.value.engDescription,
            "languageId": 1
        };
        obj.announcementDetailLanguage = [engLang];
        if (this.addAnnouncement.value.thTitle && this.addAnnouncement.value.thDescription) {
            var thLang = {
                "title": this.addAnnouncement.value.thTitle,
                "description": this.addAnnouncement.value.thDescription,
                "languageId": 2
            };
            obj.announcementDetailLanguage.push(thLang);
        }
        obj.status = this.addAnnouncement.value.status;
        this.dashboardService.updateAnnouncement(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.submited = false;
            _this_1.announcementAddOrUpdateSuccess = true;
            _this_1.utilService.showToast('success', 'Announcemnet updated!');
        }, function (err) {
            _this_1.submited = false;
            _this_1.announcementAddOrUpdateSuccess = false;
            _this_1.utilService.showToast('error', 'Try Again');
        });
    };
    DashboardAdminAnnouncementsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllAnnouncment();
    };
    return DashboardAdminAnnouncementsComponent;
}());
export { DashboardAdminAnnouncementsComponent };
