import { OnInit, ChangeDetectorRef, TemplateRef, OnDestroy } from "@angular/core";
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, fromEvent } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ListingService } from 'src/listing/service/listing.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { AuthService } from 'src/core/service/auth/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { UtilService } from 'src/shared/services/util.service';
var ListingDetailsComponent = /** @class */ (function () {
    function ListingDetailsComponent(enumPropertyService, activatedRoute, listingService, changeDetection, translateService, fb, dialog, utilService, loginRegisterService, authService, mediaMatcher) {
        this.enumPropertyService = enumPropertyService;
        this.activatedRoute = activatedRoute;
        this.listingService = listingService;
        this.changeDetection = changeDetection;
        this.translateService = translateService;
        this.fb = fb;
        this.dialog = dialog;
        this.utilService = utilService;
        this.loginRegisterService = loginRegisterService;
        this.authService = authService;
        this.mediaMatcher = mediaMatcher;
        this.listingId = null;
        this.destroy$ = new Subject();
        this.showShare = false;
        this.language = null;
        this.dialogRef = null;
        this.shareDialogRef = null;
        this.reportSend = false;
        this.reportForm = this.fb.group({
            reportTitle: ['', [Validators.required]],
            reportType: ['', [Validators.required]],
            message: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
        });
        this.submited = false;
        this.reportId = null;
        this.isAuthentic = false;
        this.showMore = false;
        this.ownListing = null;
        this.matcher = {
            media: null,
            matches: false,
            onchange: null
        };
        this.shareListingClick = false;
        this.lineGroups = [];
    }
    Object.defineProperty(ListingDetailsComponent.prototype, "f", {
        get: function () { return this.reportForm.controls; },
        enumerable: true,
        configurable: true
    });
    ListingDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.subscribe(function (evt) {
            console.log('event: ', evt);
            _this.onResize();
        });
    };
    ListingDetailsComponent.prototype.onResize = function () {
        this.matcher = this.mediaMatcher.matchMedia('(max-width: 960px)');
        this.changeDetection.detectChanges();
    };
    ListingDetailsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.onResize();
        this.checkAuthentication();
        this.translateService.onLangChange
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var lang = _a.lang;
            _this.language = lang == 'en' ? 1 : 2;
            _this.changeDetection.detectChanges();
        });
        this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$))
            .subscribe(function (data) {
            _this.taxonomy = data;
            if (_this.activatedRoute.snapshot.paramMap.get('id') || _this.listingId) {
                var id = _this.listingId || _this.activatedRoute.snapshot.paramMap.get('id');
                _this.listingService.getListing(id)
                    .pipe(takeUntil(_this.destroy$))
                    .subscribe(function (_a) {
                    var data = _a.data;
                    _this.ownListing = _this.authService.profileState.value && data.agentId == _this.authService.profileState.value.id;
                    _this.changeDetection.detectChanges();
                    _this.listing = data;
                    _this.changeDetection.detectChanges();
                    // this.loginRegisterService.getProfile().pipe(takeUntil(this.destroy$)).subscribe((data) => {
                    // })
                    $(function () {
                        var $sidebar = $('.floating-div'), $window = $(window), offset = $sidebar.offset(), pageHeight = $('.listing-detail').height(), topPadding = 15;
                        $window.scroll(function () {
                            if ($window.scrollTop() > offset.top) {
                                if ($window.scrollTop() <= pageHeight) {
                                    $sidebar.stop().animate({
                                        marginTop: $window.scrollTop() - offset.top + topPadding
                                    });
                                }
                                else {
                                    return;
                                }
                            }
                            else {
                                $sidebar.stop().animate({
                                    marginTop: 0
                                });
                            }
                        });
                    });
                });
            }
        }, function (err) {
        });
    };
    ListingDetailsComponent.prototype.checkAuthentication = function () {
        this.isAuthentic = this.loginRegisterService.isAuthenticated();
        this.changeDetection.detectChanges();
    };
    ListingDetailsComponent.prototype.getEnumById = function (firstElement, type, secondElement, id) {
        var nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
        return nameOrNumber;
    };
    ListingDetailsComponent.prototype.openReportDialog = function (listingId, listingName) {
        var _this = this;
        this.submited = false;
        this.reportSend = false;
        this.reportId = listingId;
        if (listingName.length) {
            this.reportForm.controls.reportTitle.setValue("Reporting #" + listingId + " " + listingName);
        }
        else {
            this.reportForm.controls.reportTitle.setValue("Reporting #" + listingId);
        }
        this.reportForm.controls.reportTitle.disable();
        this.dialogRef = this.dialog.open(this.reportDialog, {
            panelClass: 'report-dailog'
        });
        this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.submited = false;
            _this.reportSend = false;
            _this.reportForm.reset();
        });
    };
    ListingDetailsComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    ListingDetailsComponent.prototype.reportListing = function () {
        var _this = this;
        this.submited = true;
        if (this.reportForm.invalid) {
            return;
        }
        var obj = {
            message: this.reportForm.value.message,
            response: null,
            id: null,
            title: this.reportForm.value.reportTitle,
            reportType: this.reportForm.value.reportType
        };
        this.listingService.reportListing(this.reportId, obj)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (data) {
            _this.reportForm.controls.message.setValue('');
            _this.reportSend = true;
        }, function (err) {
            _this.reportSend = false;
        });
    };
    ListingDetailsComponent.prototype.openShareDialog = function () {
        this.shareDialogRef = this.dialog.open(this.shareDialog, {
            panelClass: 'share-dailog'
        });
    };
    ListingDetailsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        this.resizeSubscription$.unsubscribe();
    };
    ListingDetailsComponent.prototype.buildUrl = function (listing) {
        var subUrl = 'listing-detail';
        return environment.appUrl + "/" + subUrl + "/" + listing.id;
    };
    ListingDetailsComponent.prototype.openLineShare = function () {
        open("https://timeline.line.me/social-plugin/share?url=" + this.buildUrl(this.listing) + "&title=" + this.listing.title);
    };
    ListingDetailsComponent.prototype.shareToFb = function () {
        var obj = {
            "og:url": this.buildUrl(this.listing),
            "og:type": "website",
            "og:title": this.listing.title,
            "og:description": this.listing.description ? this.listing.description.substr(0, 20) : null,
            "og:image": environment.storageUrl + '/listing/' + this.listing.medias[0],
            "og:image:width": "450",
            "og:image:height": "298"
        };
        console.log("obj", obj);
        FB.init({
            appId: environment.facebookId,
            status: true,
            cookie: true,
            xfbml: true,
            version: "v2.4"
        });
        FB.ui({
            method: "share_open_graph",
            action_type: "og.shares",
            redirect_uri: this.buildUrl(this.listing),
            action_properties: JSON.stringify({
                object: obj
            })
        }, function (response) {
            console.log("response", response);
            if (response) {
            }
            else {
            }
        }, function (err) {
            console.log("err", err);
        });
    };
    return ListingDetailsComponent;
}());
export { ListingDetailsComponent };
