import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'td-announcements-card',
  templateUrl: './announcements-card.component.html',
  styleUrls: ['./announcements-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsCardComponent implements OnInit {
  @Input() announcement: any;
  @Input() type: any;
  @Output() deleteAnnouncement=new EventEmitter<number>();
  @Output() editAnnouncement=new EventEmitter<number>();
  storage: string = environment.storageUrl;
  constructor() { }

  ngOnInit() {
  }
  delete(announcement){
    this.deleteAnnouncement.emit(announcement);
  }
  edit(announcement){
    this.editAnnouncement.emit(announcement);
  }
}
