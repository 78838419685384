import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener
} from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { RegisterUser } from 'src/login-register/interface/loging-register.interface';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';

import { LoadingScreenService } from 'src/shared/services/loading/loading.service';

import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from 'src/core/service/language/language.service';
import { LoginRegisterComponent } from 'src/login-register/container/login-register/login-register.component';
import { environment } from 'src/environments/environment';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { fadeAnimation } from 'src/shared/animation/animation';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class NavbarComponent implements OnInit {
  @ViewChild('showFirstLoginDialog', { static: false }) showFirstLoginDialog;
  @ViewChild('languageDialog', { static: false }) languageDialog;
  @Output() public sidenavToggle = new EventEmitter();
  @Output() public sidenavClose = new EventEmitter();
  destroy$: Subject<boolean> = new Subject();
  location: string = "";
  selectedLoginTab: string = 'signIn';
  submitted = false;
  firstLoginSubmitted = false;
  forgetPassword = false;
  loginRegisterForm: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]]
  })
  firstDiloagForm: FormGroup = this.fb.group({
    userRole: [null]
  })
  isAuthentic: boolean = false;
  loading: boolean = false;
  userProfil = null;
  dialogRef = null;
  code: string = null;
  state: string = null;
  language: string = this.translateService.currentLang;
  percentageProfile: number = 0;

  loginRegisterDialogRef: MatDialogRef<LoginRegisterComponent>;
  isAdmin = false;
  constructor(private fb: FormBuilder, public dialog: MatDialog, private router: Router,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loginRegisterService: LoginRegisterService, private authService: AuthService,
    private utilService: UtilService, private loadingScreenService: LoadingScreenService,
    private languageService: LanguageService,
    private translateService: TranslateService) {

  }
  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated();

    if (this.isAuthentic) {
      this.getProfilePercentage();
      console.log("checkAuthentication")
      this.authService.getProfile().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.userProfil = this.authService.profileState.value;
        this.isAdmin = this.authService.profileState.value.isAdmin;
        this.ref.detectChanges();
        if (false) {
          this.dialogRef = this.dialog.open(this.showFirstLoginDialog, {
            panelClass: 'first-login-dialog',
            maxHeight: '950px',
            maxWidth: '950px',
          })
        }
      });
    }
    this.ref.detectChanges();
  }
  ngOnInit() {
    this.loadingScreenService.startLoading();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
      this.location = event.url;
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      this.ref.detectChanges();
      if (!this.location.match('/resetpassword') && !this.location.match('/payment-success')) {
        this.checkStatus();
      }
    });

    this.checkAuthentication();

    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ lang }) => {
        this.language = lang;
        this.ref.detectChanges();
        console.log("lang",lang)
        if (lang == 'th') {
          $("<style id='myStyle' type='text/css'> *,html,body{  font-family: \"Prompt\"!important;} </style>").appendTo("head");
        } else {
          $('#myStyle').remove();
        }
      });

  }




  checkStatus() {
    this.route.queryParamMap.pipe(filter(({ keys }: ParamMap) => {
      if (!!keys.length) {
        this.loadingScreenService.stopLoading();
        return !!keys.length;
      }
    })).subscribe((params: any) => {
      this.code = params.params['code'];
      this.state = params.params['state'];
      console.warn(this.code);

      var request = {
        AccessToken: this.code,
        Provider: 'LinkedIn'
      };
      console.log("checkStatus")
      this.loginRegisterService.logingWithFacebook(request)
        .pipe(switchMap(() => this.loginRegisterService.getProfile()))
        .pipe(takeUntil(this.destroy$)).subscribe((data) => {

          this.checkAuthentication();
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          })
          this.loadingScreenService.stopLoading();
        })
    }, (errLinkdingLogin) => {

      this.loadingScreenService.stopLoading();
    });


  }

  // ngAfterViewInit() {
  //   if (this.isAuthentic) {
  //     this.authService.callSignalR();
  //   }
  // }



  openDialog(templateRef, type: string): void {
    let maxWidht = '453px';
    let maxHeight = '510px';
    let minWidth = '100px';
    let template = LoginRegisterComponent;
    let right = '225px';
    if (type == 'alreadyLogin') {
      maxWidht = '400px';
      maxHeight = '450px';
      minWidth = '280px';
      right = '345px';
      template = templateRef;
    }
    this.dialogRef = this.dialog.open(template, {
      panelClass: 'login-register-dialog',
      maxHeight: maxHeight,
      maxWidth: maxWidht,
      minWidth: minWidth,
      position: {
        right: right,
        top: '120px'
      }
    })
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {

        window.location.reload();
        // this.checkAuthentication();
        // this.getProfilePercentage();
      }
    });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get f(): RegisterUser { return this.loginRegisterForm.controls; }


  setLanguage(language: "th" | "en") {
    console.log("language",language)
    if (language == 'th') {
      $("<style id='myStyle' type='text/css'> *,html,body{  font-family: \"Prompt\"!important;} </style>").appendTo("head");
    } else {
      $('#myStyle').remove();
    }
    this.languageService.setLanguage(language);
  }

  close() {
    this.dialogRef.close();
  }


  getProfilePercentage() {
    this.loginRegisterService.getPercentageOfProfile().pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {

      this.percentageProfile = data;
      this.ref.detectChanges();
    }, (err) => {

    })
  }

  logout() {
    this.authService.logOut();
    this.checkAuthentication();
    this.dialogRef.close();
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }


  submitFirstLogin() {
    this.firstLoginSubmitted = true;

  }

  goto() {
    this.router.navigate(['/article']);
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public closeSideNav=()=>{
    this.sidenavClose.emit();
  }


}
