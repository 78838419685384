/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./gallery.component";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: {} });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
function View_GalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "sp-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "sp-image swiper-lazy"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "background-image": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, (((("url(" + _co.storage) + "/listing/") + _v.context.$implicit) + ")")); _ck(_v, 3, 0, currVal_0); }, null); }
function View_GalleryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "sp-thumbnail swiper-lazy"]], [[8, "src", 4], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", _co.storage, "/listing/", _v.context.$implicit, ""); var currVal_1 = i1.ɵinlineInterpolate(2, "", _co.storage, "/listing/", _v.context.$implicit, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_GalleryComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slider-pro sp-horizontal"], ["id", "my-slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "sp-slides"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "sp-thumbnails"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.medias; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.medias; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-gallery", [], null, null, null, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 4308992, null, 0, i3.GalleryComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GalleryComponentNgFactory = i1.ɵccf("td-gallery", i3.GalleryComponent, View_GalleryComponent_Host_0, { medias: "medias" }, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
