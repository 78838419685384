import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Subject, of } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/core/service/auth/auth.service';
import adminRouterParms from '../container/const/admin-router-parms.const';
import userRouterParms from '../container/const/user-router-parms.const';
@Injectable()
export class RouteResolver implements Resolve<any> {
    user: any;
    destroy$: Subject<boolean> = new Subject();
    constructor(private dashboardService: DashboardService,
        private utilService: UtilService,
        private authService: AuthService,
        private router: Router) { 
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.dashboardService.getProfile().pipe(map((data:any) => {
            this.utilService.showToast('success',"Profile Loaded!", 100);
            switchMap((profileData: any) => this.authService.saveProfile(profileData.data));
                 data.data.isAdmin= this.authService.profileState.value&&this.authService.profileState.value.isAdmin?this.authService.profileState.value.isAdmin:false;
            if (data.data.isAdmin) {
                let routerConfig = this.router.config;
                // let alreadyAdded = routerConfig[0].children[1].path.match('admin-users-dashboard');
                // if (alreadyAdded) {
                routerConfig.forEach(element => {
                    if (element.path.match('dashboard')) {
                        element.children = adminRouterParms;
                    }
                })
                this.router.resetConfig(routerConfig);
                // }
            } else {
                let routerConfig = this.router.config;
                // let alreadyAdded = routerConfig[0].children[1].path.match('profile-dashboard');
                // if (alreadyAdded) {\
                // routerConfig[0].children = userRouterParms;
                // this.router.resetConfig(routerConfig);
                routerConfig.forEach(element => {
                    if (element.path.match('dashboard')) {
                        element.children = userRouterParms;
                    }
                })
                
                this.router.resetConfig(routerConfig);
                // }
            }
            return data = data.data;
        }));
    }
    reject() {
        
    }

}