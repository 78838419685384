<div class="package-selection" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h4>{{data.name}}</h4>
    <a (click)="close()"><i class="mdi mdi-close"></i></a>
  </div>
  <div>
    <form [formGroup]="packageFormGroup" ngxScrollToFirstInvalid fxLayout="column" fxLayoutGap="15px"
      fxLayoutAlign="center stretch">
      <div fxLayout="column" fxLayoutAlign="center start">
        <div *ngIf="data.id!==4" class="tab-div" fxLayout="row">
          <div (click)="selectedTab('Text From System')"
            [class.selectedTab]="packageFormGroup.value.selectMainPackage=='Text From System'" translate>Text</div>
          <div (click)="selectedTab('Custom text')"
            [class.selectedTab]="packageFormGroup.value.selectMainPackage=='Custom text'" translate>Custom text</div>
        </div>
        <!-- <select formControlName="selectMainPackage" *ngIf="data.id!==4">
          <option value="0" disabled selected="selected" hidden>Select text from the list</option>
          <option value="Text From System">Text From System</option>
          <option value="Custom text" class="custom-text">
            Custom text
          </option>
        </select> -->
        <div class="text-from-system"
          *ngIf="packageFormGroup.value.selectMainPackage=='Text From System'&&data.availableBannerTexts.length"
          fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
          <ng-container *ngFor="let packageItem of data.availableBannerTexts;let last = last;">
            <div class="label-text mat-elevation-z1" *ngIf="packageItem!='Custom text'" fxLayoutGap="20px"
              [fxFlex]="regularDistribution"
              fxFlex.sm="100"
              fxFlex.xs="100"
              [class.selectedPackage]="packageFormGroup.value.selectedPackageItem==packageItem"
              (click)="addedpackage(packageItem)">{{packageItem}}</div>
          </ng-container>
        </div>
        <div class="text-from-user" *ngIf="packageFormGroup.value.selectMainPackage=='Custom text'" fxLayout="column" fxLayoutAlign="center start" >
          <input type="text" placeholder="Fill Your Custom Text" formControlName="customText"
            (change)="changePackageToCustome()" 
            maxlength="30" />
          <div fxLayout="column" fxLayoutAlign="center end" style="width: 100%;"
            >
            <span style="color:#8CC63F;font-size: 12px;">{{30-packageFormGroup.value.customText.length}} {{'Character left'|translate}}</span>
          </div>
        </div>
        <mat-radio-group aria-label="Select an option" formControlName="selectedPackageItemDetails" class="radio-price"
          *ngIf="packageFormGroup.value.selectedPackageItem" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="start"
          fxLayout.sm="column" fxLayoutAlign.sm="start"
          fxLayoutGap="20px">
          <ng-container *ngFor="let price of data.pricing">
            <mat-radio-button
              *ngIf="packageFormGroup.value.selectMainPackage=='Text From System'&&!price.isCustomBannerText"
              value="{{price.productDuration}}" [color]="'primary'" style="color: #444444;">{{price.price}}
              Baht/{{price.productDuration}} {{'Days'|translate}}
            </mat-radio-button>
            <mat-radio-button *ngIf="packageFormGroup.value.selectMainPackage=='Custom text'&&price.isCustomBannerText"
              value="{{price.productDuration}}" [color]="'primary'" style="color: #444444;">{{price.price}}
              Baht/{{price.productDuration}} {{'Days'|translate}}
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button color="primary" mat-flat-button (click)="submit()"
          *ngIf="packageFormGroup.value.selectMainPackage=='Custom text'?packageFormGroup.value.customText&&packageFormGroup.value.selectedPackageItem:packageFormGroup.value.selectedPackageItemDetails">{{'Add'|translate}}</button>
      </div>
    </form>
  </div>
</div>