<div class="dashboard" fxLayout="row" fxLayoutAlign="center center">
  <div class="dashboard-inner" fxLayout="row" fxLayoutAlign="start start">
    <td-dashboard-side-bar></td-dashboard-side-bar>
    <div fxLayout="column" fxLayoutAlign="start stretch" style="width: 100%;">
      <div class="hello-div" fxLayout="row" fxLayoutAlign="space-between center">
        <h3  style="text-transform: capitalize;">{{'Welcome'|translate}}  {{user.firstName}}</h3>
        <div class="notifcation" id="notifcation" style="position: relative;">
          <div class="alert-div" fxLayout="row" fxLayoutAlign="center center"
            (click)="showNotificationDialog=!showNotificationDialog" matBadge="{{totalUnRead}}"
            matBadgePosition="above after" matBadgeColor="warn" matBadgeOverlap="true"><i class="mdi mdi-bell-plus"></i>
          </div>

          <div *ngIf="showNotificationDialog" class="notification-div" id="notification-div">
            <div class="arrow-up"></div>
            <div *ngIf="notifications.length">
              <div fxLayout="column" fxLayoutAlign="start stretch" *ngFor="let item of notifications" class="messagebox"
                [class.unread]="!item.read">
                <div fxLayout="row" fxLayoutAlign="space-between center" >
                  <a (click)="goto(item)">
                    <h5>{{item.message}}</h5>
                    <div fxLayout="row" fxLayoutAlign="space-between start">
                      <span style="color: #444444;">{{'Date'|translate}} : {{item.createdAt|date}}</span>

                    </div>
                  </a>
                  <button mat-mini-fab mat-flat-button [color]="'primary'" (click)="deleteNotification(item.id)"><i
                      class="mdi mdi-delete" style="font-size: 22px;color: white;"></i></button>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!notifications.length" style="color: #444444;padding: 20px;" translate>
              Nothing to show
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-center">
        <router-outlet name="dashboardOutlet"></router-outlet>
      </div>
    </div>
  </div>
</div>

<ng-template #notificationDialog>
  <mat-dialog-content>
    <div class="arrow-up"></div>
    <div *ngIf="notifications.length">
      <div fxLayout="column" fxLayoutAlign="start stretch" *ngFor="let item of notifications" style="    padding: 20px;
      background: #C9E5A4;
      border-bottom: .5px solid;">
        <div (click)="goto(item)">
          <h5>{{item.message}}</h5>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <span>{{'Date'|translate}} : {{item.createdAt|date}}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!notifications.length" translate>
      Nothing to show
    </div>
  </mat-dialog-content>
</ng-template>