<div class="manage-messages" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width:100%;">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4>Testimonial</h4>
      <button mat-flat-button [color]="'primary'" (click)="addNew(addNewTestimonial)">Add New</button>
    </div>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
        <input type="text" placeholder="Search" [(ngModel)]="filter" onblur=""/>
        <button mat-mini-fab color="primary" fxHide.sm fxHide.xs style="right: 70px;bottom: 1px;"
              (click)="currentPage=0;getAllTestimonial()" *ngIf="filter?.length">
              <i class="mdi mdi-magnify" style="color:white;"></i>
            </button>
        <select [(ngModel)]="assDec" (change)="currentPage=0;getAllTestimonial()">
          <option value='false'>Newest</option>
          <option value='true'>Oldest</option>
        </select>
    </div>
    <span *ngIf="testimonialData.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <div *ngFor="let testimonial of testimonialData">
        <div class="message-body" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="round-image" [style.background-image]="'url(' +storage+'/testimonial/'+testimonial.media + ')'"></div>
          <div class="left-text" fxFlex="70" fxLayout="column" fxLayoutAlign="space-around start">
            <h5>{{testimonial.title}} ({{testimonial.jobPosition}})</h5>
            <p>{{testimonial.message}}</p>
            <span style="font-weight: 400;font-style: italic;">{{testimonial.location}}</span>
          </div>
          <div class="right-text" fxFlex="10" fxLayout="column" fxLayoutAlign="space-evenly center">
            <div class="data">
              <p>{{testimonial.createdAt| date }}</p>
            </div>
            <button type="button" class="fab-button" mat-mini-fab aria-label="Example icon-button with a heart icon"
              (click)="delete(testimonial.id)">
              <i class="mdi mdi-delete-outline" style="color:red"></i>
            </button>
          </div>
        </div>
      </div>
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </span>
    <span *ngIf="!testimonialData.length">
      No message to show!
    </span>
  </div>

</div>



<ng-template #addNewTestimonial>
  <mat-dialog-content>
    <form [formGroup]="addTestimonial" *ngIf="!testimonialAddOrUpdateSuccess">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Add New Testimonial</h3>
        <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
      </div>
      <div class="property-overview-fields" fxLayout="column"  fxLayoutGap="20px">

        <div class="property-title" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
            <input type="text" placeholder="Name" [ngClass]="{ 'has-error': submited && f.engTitle.errors }"
              formControlName="engTitle" maxlength="70">
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
              <div fxFlex="70">
                <div *ngIf="submited && f.engTitle.errors" class="invalid-feedback">
                  <div *ngIf="f.engTitle.errors.required">Title is required</div>
                  <div *ngIf="f.engTitle.errors.minlength">Minimum 2 characters</div>
                  <div *ngIf="f.engTitle.errors.maxlength">Maximum 70 Character</div>
                </div>
              </div>
              <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                {{70-f.engTitle.value.length}} Character left</div>
            </div>
          </div>

        </div>
        <div class="property-title" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
            <input type="text" placeholder="Job Position / Company"
              [ngClass]="{ 'has-error': submited && f.jobPosition.errors }" formControlName="jobPosition"
              maxlength="70">
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
              <div fxFlex="70">
                <div *ngIf="submited && f.jobPosition.errors" class="invalid-feedback">
                  <div *ngIf="f.jobPosition.errors.required">Title is required</div>
                  <div *ngIf="f.jobPosition.errors.minlength">Minimum 2 characters</div>
                  <div *ngIf="f.jobPosition.errors.maxlength">Maximum 70 Character</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="property-title" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
            <input type="text" placeholder="Location"
              [ngClass]="{ 'has-error': submited && f.location.errors }" formControlName="location"
              maxlength="70">
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
              <div fxFlex="70">
                <div *ngIf="submited && f.location.errors" class="invalid-feedback">
                  <div *ngIf="f.location.errors.required">Title is required</div>
                  <div *ngIf="f.location.errors.minlength">Minimum 2 characters</div>
                  <div *ngIf="f.location.errors.maxlength">Maximum 70 Character</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="price-fields" fxLayout="row" fxLayoutAlign="start start">
          <div class="asking-price">
            <textarea placeholder="Message" [ngClass]="{ 'has-error': submited && f.message.errors }"
              formControlName="message" maxlength="2000"></textarea>
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">

              <div *ngIf="submited && f.message.errors" class="invalid-feedback" fxFlex="70">
                <div *ngIf="f.message.errors.required">Description is required
                </div>
                <div *ngIf="f.message.errors.minlength">Minimum 2 characters
                </div>
                <div *ngIf="f.message.errors.maxlength">Maximum 2,000 Character
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="media-fields" fxLayout="column" fxLayoutAlign="start start">
          <h3>Upload Photos (Up to 1 Images)</h3>

          <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%;">
            <div class="input-div " fxLayout="column" fxLayoutAlign="space-around stretch" style="width:100%;"
              [ngClass]="{ 'has-error': submited && !mediaIds.length }">
              <input type="file" name="files" />
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-flat-button type="button" color="primary" (click)="addTestimonials()" *ngIf="!updatingTestimonial">Add</button>
          <button mat-flat-button type="button" color="primary" (click)="updateTestimonial()" *ngIf="updatingTestimonial">Update</button>
        </div>
      </div>

    </form>
    <div *ngIf="testimonialAddOrUpdateSuccess">
      <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom:50px;"> 
        <h2>Testimonial</h2>
        <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
      </div>
      <h5>Thank you for your Testimonial. It has been uploaded to the "Testimonials" section on the home page.</h5>
    </div>
  </mat-dialog-content>
</ng-template>