import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Subject, of } from 'rxjs';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
@Injectable()
export class UserRouteResolver implements Resolve<any> {
    user: any;
    destroy$: Subject<boolean> = new Subject();
    constructor(
        private enumPropertyService: EnumPropertyService,
        private router: Router) {
        
    }

    resolve(route: ActivatedRouteSnapshot) {
        return this.enumPropertyService.getEnumProperty().subscribe((data)=>{
            
            return this.enumPropertyService.getTaxonomy;
        });
    }
    reject() {
        
    }

}