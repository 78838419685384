import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './container/components/dashboard/dashboard.component';
import { RouteResolver } from './services/reouter-resolver';
import userRouterParms from './container/const/user-router-parms.const';
import { DashboardHomeComponent } from './container/components/dashboard-home/dashboard-home.component';
import { DashboardMyProfileComponent } from './container/components/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardManageMyAdsComponent } from './container/components/dashboard-manage-my-ads/dashboard-manage-my-ads.component';
import { DashboardFavoriteAdsComponent } from './container/components/dashboard-favorite-ads/dashboard-favorite-ads.component';
import { DashboardAnnouncementsComponent } from './container/components/dashboard-announcements/dashboard-announcements.component';
import { DashboardMessageComponent } from './container/components/dashboard-message/dashboard-message.component';
import { DashboardSupportComponent } from './container/components/dashboard-support/dashboard-support.component';
import { DashboardPaymentHistoryComponent } from './container/components/dashboard-payment-history/dashboard-payment-history.component';
import { DashboardAlertsComponent } from './container/components/dashboard-alerts/dashboard-alerts.component';
import { DashboardAnalyticsComponent } from './container/components/dashboard-analytics/dashboard-analytics.component';
import { DashboardListingReportedComponent } from './container/components/dashboard-listing-reported/dashboard-listing-reported.component';
// Route resolver array

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: 'home-dashboard',
        component: DashboardHomeComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'profile-dashboard',
        component: DashboardMyProfileComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-my-ads-dashboard',
        component: DashboardManageMyAdsComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-my-fav-dashboard',
        component: DashboardFavoriteAdsComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-my-announcements',
        component: DashboardAnnouncementsComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-message',
        component: DashboardMessageComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-support',
        component: DashboardSupportComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'manage-payment-history',
        component: DashboardPaymentHistoryComponent,
        outlet: 'dashboardOutlet'
      },

      {
        path: 'manage-alerts',
        component: DashboardAlertsComponent,
        outlet: 'dashboardOutlet'
      },
      {
        path: 'analytics',
        component: DashboardAnalyticsComponent,
        outlet: 'dashboardOutlet'
      },
      {
          path: 'reported-listing',
          component: DashboardListingReportedComponent,
          outlet: 'dashboardOutlet'
      }

    ],
    resolve: {
      routeResolver: RouteResolver
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [RouteResolver]
})
export class DashboardRoutingModule { }
