import { NgModule, InjectionToken } from "@angular/core";

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    var formatString = 'MMMM YYYY';
    return moment(date).format(formatString);
  }
}
import {
  MatTabsModule,
  MatIconModule,
  MatButtonModule,
  MatRippleModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  MatRadioModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatBottomSheetModule,
  MatMenuModule,
  MatCardModule,
  MatChipsModule,
  MatBadgeModule,
  MatTableModule,
  MatExpansionModule,
  NativeDateAdapter, 
  DateAdapter, 
  MatDatepickerModule,
  MatNativeDateModule,
  MatToolbarModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatTooltipModule
} from "@angular/material";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { EcoFabSpeedDialModule } from "@ecodev/fab-speed-dial";
@NgModule({
  declarations: [],
  imports: [
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatBottomSheetModule,
    DragDropModule,
    MatMenuModule,
    MatCardModule,
    MatChipsModule,
    EcoFabSpeedDialModule,
    MatBadgeModule,
    MatTableModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  exports: [
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatBottomSheetModule,
    DragDropModule,
    MatMenuModule,
    MatCardModule,
    MatChipsModule,
    EcoFabSpeedDialModule,
    MatBadgeModule,
    MatTableModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  providers: [
    {
      provide: DateAdapter, useClass: CustomDateAdapter
    }
  ]
})
export class MaterialModule {}
