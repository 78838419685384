import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/core/service/auth/auth.service';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(dialog, route, dashboardService, changeDetection, router, authService) {
        this.dialog = dialog;
        this.route = route;
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.router = router;
        this.authService = authService;
        this.destroy$ = new Subject();
        this.notifications = [];
        this.totalUnRead = 0;
        this.currentPage = 0;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.showNotificationDialog = false;
        this.user = null;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.route.snapshot.data.routeResolver;
        this.getAllNotification();
        // if (this.authService.connection) {
        //   this.getNotficationIfSignalRCalled();
        // }
        var THIS = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).pipe(takeUntil(this.destroy$)).subscribe(function (event) {
            if (event.url.match('/dashboard')) {
                $(window).click(function () {
                    //Hide the menus if visible
                    THIS.showNotificationDialog = false;
                    THIS.changeDetection.detectChanges();
                    $('#notification-div').click(function (event) {
                        event.stopPropagation();
                    });
                    $('#notifcation').click(function (event) {
                        event.stopPropagation();
                    });
                });
            }
            else {
            }
        });
        // $(window).click(function () {
        //   //Hide the menus if visible
        //   THIS.showNotificationDialog = false;
        //   THIS.changeDetection.detectChanges();
        //   $('#notification-div').click(function (event) {
        //     event.stopPropagation();
        //   });
        //   $('#notifcation').click(function (event) {
        //     event.stopPropagation();
        //   });
        // });
        this.authService.customObservable.pipe(takeUntil(this.destroy$)).subscribe(function (res) {
            _this.currentPage = 0;
            _this.pageInfo.totalPages = 1;
            _this.getAllNotification();
        });
        if (!this.authService.connection) {
            this.authService.callSignalR();
        }
    };
    // getNotficationIfSignalRCalled() {
    //   let _this = this;
    // }
    DashboardComponent.prototype.getAllNotification = function () {
        var _this = this;
        if (this.currentPage != this.pageInfo.totalPages) {
            this.currentPage = this.currentPage + 1;
            this.dashboardService.getNotification().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
                var data = _a.data, pageInfo = _a.pageInfo;
                if (_this.currentPage == 1) {
                    _this.notifications = [];
                }
                _this.notifications = _this.notifications.concat(data.notifications);
                _this.totalUnRead = data.unread;
                _this.pageInfo = pageInfo;
                _this.changeDetection.detectChanges();
            });
        }
    };
    DashboardComponent.prototype.showAleartDialog = function (templateRef) {
        var maxWidht = '453px';
        var maxHeight = '510px';
        var minWidth = '100px';
        this.dialog.open(templateRef, {
            panelClass: 'notification-dialog',
            maxHeight: maxHeight,
            maxWidth: maxWidht,
            minWidth: minWidth,
            position: {
                right: '235px',
                top: '315px'
            }
        });
    };
    DashboardComponent.prototype.goto = function (item) {
        var _this = this;
        switch (item.type) {
            case 3:
                this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: 'admin-reported-listings-dashboard' } }]);
                break;
            case 2:
                this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: 'reported-listing' } }]);
                break;
        }
        this.dashboardService.updateNotificationRead(item.id).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.currentPage = 0;
            _this.pageInfo.totalPages = 1;
            _this.getAllNotification();
        });
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardComponent.prototype.deleteNotification = function (notificationId) {
        var _this = this;
        this.dashboardService.deleteNotification(notificationId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.currentPage = 0;
            _this.pageInfo.totalPages = 1;
            _this.getAllNotification();
        });
    };
    return DashboardComponent;
}());
export { DashboardComponent };
