import { OnInit, ChangeDetectorRef } from '@angular/core';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/shared/custom-function/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilService } from 'src/shared/services/util.service';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(loginRegisterService, changeDetection, fb, route, router, utilService) {
        this.loginRegisterService = loginRegisterService;
        this.changeDetection = changeDetection;
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.utilService = utilService;
        this.isAuthentic = null;
        this.resetPasswordForm = this.fb.group({
            password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
            cPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]]
        }, {
            validator: MustMatch('password', 'cPassword')
        });
        this.code = null;
        this.email = null;
        this.submitted = false;
        this.destroy$ = new Subject();
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkAuthentication();
        this.code = this.route.snapshot.paramMap.get("code");
        this.email = this.route.snapshot.paramMap.get("email");
        if (!this.code && !this.email) {
            this.router.navigate(['/']).then(function () {
                _this.utilService.showToast('error', 'Invalid Code! Try to get a new code!');
            });
        }
    };
    ResetPasswordComponent.prototype.checkAuthentication = function () {
        this.isAuthentic = this.loginRegisterService.isAuthenticated();
        this.changeDetection.detectChanges();
    };
    Object.defineProperty(ResetPasswordComponent.prototype, "f", {
        get: function () { return this.resetPasswordForm.controls; },
        enumerable: true,
        configurable: true
    });
    ResetPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        var obj = {
            "email": this.email,
            "code": this.code,
            "password": this.resetPasswordForm.value.cPassword
        };
        this.loginRegisterService.resetPassword(obj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.submitted = false;
            _this.utilService.showToast('success', 'Your password reset! login with your new password!');
            _this.router.navigate(['/']);
        }, function (err) {
            _this.submitted = false;
            _this.changeDetection.detectChanges();
            _this.utilService.showToast('error', 'Try again!');
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
