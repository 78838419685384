import { NgModule } from "@angular/core";
import { FileUploaderComponent } from "./components/file-uploader/file-uploader.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { SharedModule } from "src/shared/shared.module";
import { CropperDialogComponent } from "./components/cropper-dialog/cropper-dialog.component";
import { GalleryComponent } from './components/gallery/gallery.component';
import { MediaService } from './service/media.service';
import { MaterialModule } from 'src/material/material.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NewImageUploadComponent } from './components/new-image-upload/new-image-upload.component';
@NgModule({
  declarations: [FileUploaderComponent, CropperDialogComponent, GalleryComponent,NewImageUploadComponent],
  entryComponents: [CropperDialogComponent, NewImageUploadComponent],
  exports: [FileUploaderComponent, GalleryComponent, NewImageUploadComponent],
  imports: [ImageCropperModule,SharedModule, MaterialModule, CommonModule, BrowserModule, FlexLayoutModule],
  providers: [MediaService]
})
export class MediaModule {}
