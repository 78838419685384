import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingScreenComponent implements AfterViewInit, OnDestroy {


  debounceTime: number = 0;
  loading: boolean = false;
  loadingSubscription: Subscription;
  destroy$: Subject<boolean> = new Subject();
  constructor(private loadingScreenService: LoadingScreenService,private _elmRef: ElementRef,private changeDetection: ChangeDetectorRef,) {
    
  }

  ngAfterViewInit(): void {
    // this._elmRef.nativeElement.closest('body').style.overflow = 'auto!important';
    // this._elmRef.nativeElement.closest('html').style.position = 'static!important';
    $('body').addClass('bodyClass');
    $('html').addClass('htmlClass');
    
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(debounceTime(this.debounceTime),takeUntil(this.destroy$)).subscribe(
      (status: boolean) => {
        this._elmRef.nativeElement.closest('body').style.overflow  = status ? 'hidden' : 'auto';
        this.loading=status;
        this.changeDetection.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}