<div class="manage-messages" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width:100%;">
    <h5 translate>Message</h5>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
      <!-- <input type="text" placeholder="Search" />
      <select>
        <option></option>
      </select> -->
    </div>
    <span *ngIf="messageList.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" >
      <div *ngFor="let message of messageList">
        <div class="message-body" [class.unread]="message.receivedStatus ==1" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div class="left-text" fxFlex="70" fxLayout="column" fxLayoutAlign="space-around start"
            (click)="showMessageDialog(message)">
            <h5>{{message.title}}</h5>
            <p>{{getType(message.reportType)}}</p>
            <h5 style="height: 75px;">{{'Support Reply'|translate}} : <q>{{message.response}}</q></h5>
          </div>
          <div class="right-text" fxFlex="10" fxLayout="column" fxLayoutAlign="space-evenly center">
            <div class="data" (click)="showMessageDialog(message)">
              <p>{{message.createdAt| date }}</p>
            </div>
            <button type="button" class="fab-button" mat-mini-fab aria-label="Example icon-button with a heart icon"
              (click)="deleteEventCalled(message.id)">
              <i class="mdi mdi-delete-outline"></i>
            </button>
          </div>
        </div>
      </div>
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </span>
    <span *ngIf="!messageList.length" translate>
      No message to show!
    </span>
  </div>

</div>

<ng-template #messageDialog>
  <h3 matDialogTitle>{{message.title}}</h3>
  <span>{{getType(message.reportType)}}</span>
  <mat-dialog-content>
    <q>{{message.message}}</q>
    <div fxLayout="column" fxLayoutAlign="end end">
      <h5>{{'Support Reply'|translate}} : <q>{{message.response}}</q></h5>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button type="button" mat-flat-button [color]="'primary'" (click)="close()" >{{'Close'|translate}}</button>
  </mat-dialog-actions>
</ng-template>