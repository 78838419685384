import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { RegisterUser } from 'src/login-register/interface/loging-register.interface';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'td-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss']
})
export class LoginScreenComponent implements OnInit {
 
  destroy$: Subject<boolean> = new Subject();
 
  isAuthentic = false;
  constructor(private loginRegisterService: LoginRegisterService, private router: Router) { }

  ngOnInit() {
    this.checkAuthentication();
  }

  

  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated();
    if(this.isAuthentic){
      this.router.navigate(['/dashboard',{outlets:{dashboardOutlet:['home-dashboard']}}]);
    }
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
