/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accept-decline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./accept-decline.component";
var styles_AceptDeclineComponent = [i0.styles];
var RenderType_AceptDeclineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AceptDeclineComponent, data: {} });
export { RenderType_AceptDeclineComponent as RenderType_AceptDeclineComponent };
export function View_AceptDeclineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "accept-decline-dialog-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "button-container mat-dialog-actions"], ["fxLayout", "row"], ["fxLayoutAlign", "center"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(7, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(8, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(9, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["cdkFocusInitial", ""], ["color", "default"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(12, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 4, "button", [["color", "primary"], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).dialogRef.close(i1.ɵnov(_v, 17).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(17, 606208, null, 0, i4.MatDialogClose, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(18, 0, [" ", " "])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "row"; _ck(_v, 7, 0, currVal_2); var currVal_3 = "center"; _ck(_v, 8, 0, currVal_3); var currVal_7 = "default"; _ck(_v, 11, 0, currVal_7); var currVal_8 = false; _ck(_v, 12, 0, currVal_8); var currVal_13 = "primary"; _ck(_v, 16, 0, currVal_13); var currVal_14 = true; _ck(_v, 17, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.subMessage; _ck(_v, 5, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 12).ariaLabel || null); _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("No")); _ck(_v, 13, 0, currVal_9); var currVal_10 = (i1.ɵnov(_v, 16).disabled || null); var currVal_11 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); var currVal_12 = (i1.ɵnov(_v, 17).ariaLabel || null); _ck(_v, 15, 0, currVal_10, currVal_11, currVal_12); var currVal_15 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("Yes")); _ck(_v, 18, 0, currVal_15); }); }
export function View_AceptDeclineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nino-accept-decline", [], null, null, null, View_AceptDeclineComponent_0, RenderType_AceptDeclineComponent)), i1.ɵdid(1, 114688, null, 0, i11.AceptDeclineComponent, [i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AceptDeclineComponentNgFactory = i1.ɵccf("nino-accept-decline", i11.AceptDeclineComponent, View_AceptDeclineComponent_Host_0, {}, {}, []);
export { AceptDeclineComponentNgFactory as AceptDeclineComponentNgFactory };
