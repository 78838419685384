import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { FormBuilder } from '@angular/forms';
var DashboardManageMyAdsComponent = /** @class */ (function () {
    function DashboardManageMyAdsComponent(dashboardService, changeDetection, utilService, fb) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.fb = fb;
        this.destroy$ = new Subject();
        this.listingData = [];
        this.search = this.fb.group({
            searchText: [''],
            listingType: [null],
            propertyType: [null]
        });
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
    }
    DashboardManageMyAdsComponent.prototype.ngOnInit = function () {
        this.getAllListing();
    };
    DashboardManageMyAdsComponent.prototype.getAllListing = function () {
        var _this = this;
        var obj = {
            listingType: this.search.value.listingType != 'null' ? this.search.value.listingType : null,
            filter: this.search.value.searchText,
            favorites: false,
            propertyType: this.search.value.propertyType != 'null' ? this.search.value.propertyType : null
        };
        this.dashboardService.getAllListing(this.currentPage, 10, obj)
            .pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.listingData = data;
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardManageMyAdsComponent.prototype.deleteEventCalled = function (ev) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteListing(ev).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + ev + "Listing Deleted!");
                    _this.currentPage = 1;
                    _this.getAllListing();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardManageMyAdsComponent.prototype.clearSearch = function () {
        this.search.controls.searchText.setValue('');
        this.search.controls.listingType.setValue(null);
        this.searchForListing();
    };
    DashboardManageMyAdsComponent.prototype.searchForListing = function () {
        this.currentPage = 1;
        this.getAllListing();
    };
    DashboardManageMyAdsComponent.prototype.updateAllListing = function () {
        var _this = this;
        this.dashboardService.activeListingFor30Days(null).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', 'You all Expired listing updated!');
        });
    };
    DashboardManageMyAdsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllListing();
    };
    DashboardManageMyAdsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    return DashboardManageMyAdsComponent;
}());
export { DashboardManageMyAdsComponent };
