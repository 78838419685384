import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/material/material.module';
import { UtilService } from './services/util.service';
import { AceptDeclineComponent } from './components/acept-decline/accept-decline.component';
import { LoadingScreenService } from './services/loading/loading.service';
import { Safe } from './pipe/safe-html.pipe';
import { RouterModule } from "@angular/router";

import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglemapsService } from './services/googlemaps/googlemaps.service';
import { NumberFormatDirective } from './directive/number-format.directive';
import { FourZeroFourComponent } from './components/four-zero-four/four-zero-four.component';
import { TranslateModule } from '@ngx-translate/core';
import { ListingCardDashboardComponent } from './components/listing-card-dashboard/listing-card-dashboard.component';
import { AnnouncementsCardComponent } from './components/announcements-card/announcements-card.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { EventEmiterService } from './services/event-emitter.service/event-emitter.service';
import { OrderBy } from './pipe/orderBy.pipe';
import { ImagePreloader } from './directive/image-preloaded.directive';
import { AgmOverlays } from 'agm-overlays';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { SafeHtml } from './directive/safe-html';
import { FilterPipe } from './pipe/filter.pipe';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY, RecaptchaModule } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
  declarations: [
    AceptDeclineComponent,
    Safe,
    OrderBy,
    FilterPipe,
    NumberFormatDirective,
    FourZeroFourComponent,
    ListingCardDashboardComponent,
    AnnouncementsCardComponent,
    DynamicFormComponent,
    ImagePreloader,
    SafeHtml,
    PaginationComponent
  ],
  entryComponents: [DynamicFormComponent, AceptDeclineComponent, PaginationComponent],
  imports: [
    FlexLayoutModule,
    FormsModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: environment.googleMapApiKey,
      libraries: ['geometry', "places", 'drawing']
    }),
    AgmSnazzyInfoWindowModule,
    RouterModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaV3Module,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      tapToDismiss: true,
      progressBar: true,
      onActivateTick: true,
      progressAnimation: 'increasing',
    })
  ],
  exports: [PaginationComponent,AceptDeclineComponent, MaterialModule, FlexLayoutModule, CommonModule, Safe, OrderBy,
    NumberFormatDirective, FourZeroFourComponent, TranslateModule, ListingCardDashboardComponent,
    AnnouncementsCardComponent, DynamicFormComponent, ImagePreloader, SafeHtml, FilterPipe, RecaptchaV3Module],
  providers: [UtilService, DecimalPipe, LoadingScreenService, GooglemapsService, EventEmiterService, { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeAkccUAAAAAGURwpvcfE0yo9wwN2DSHAGURVmP' }]
})
export class SharedModule { }
