import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { GetListing } from 'src/listing/interface/get-listing.interface';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/shared/services/util.service';
import { ListingService } from 'src/listing/service/listing.service';
import { paymentStatusLable } from 'src/shared/const/payment-status';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MatDialog } from '@angular/material';
declare var FB: any;

@Component({
  selector: 'td-listing-card-dashboard',
  templateUrl: './listing-card-dashboard.component.html',
  styleUrls: ['./listing-card-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingCardDashboardComponent implements OnInit {
  @Input() type: string = null;
  @Input() listing: GetListing = null;
  @Output() deleteEvent = new EventEmitter<number>();
  @Output() viewListing = new EventEmitter<number>();
  @Output() getAllListing = new EventEmitter<boolean>();
  taxonomy: any;
  destroy$: Subject<boolean> = new Subject();
  storage: string = environment.storageUrl;
  mainPackage: any = null;
  last: any;
  paymentLabel: any = paymentStatusLable;
  lineGroups = [];
  shareListingClick = false;
  shareDialogRef = null;
  @ViewChild('shareDialog', { static: false }) shareDialog: TemplateRef<any>;
  constructor(public enumPropertyService: EnumPropertyService, private router: Router,
    private changeDetaction: ChangeDetectorRef,
    private listingService: ListingService,
    private dashboardSerivce: DashboardService,
    private utilService: UtilService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.taxonomy = data
      this.changeDetaction.detectChanges();
    });
  }
  delete(listingId) {
    this.deleteEvent.emit(listingId);
  }
  edit() {
    this.dashboardSerivce.getSingleListing(this.listing.id).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      if (!data.address.subDistrictId) {
        data.address.subDistrictId = 1;
      }
      if (!data.address.districtId) {
        data.address.districtId = 1;
      }
      if (!data.address.provinceId) {
        data.address.provinceId = 1;
      }
      this.listingService.listingData = data;

      this.router.navigate(['/add-listing']);
    })
  }
  getEnumById(firstElement, type, secondElement, id) {
    let nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);

    return nameOrNumber;
  }
  goto() {
    if (this.listing.listingStatus != 11) {
      this.router.navigate(["../listing-detail", this.listing.id]);
    }
  }
  giveBackListing(listing) {
    this.viewListing.emit(listing);
  }
  approveOrSuspend(listingId, stsusId) {
    this.dashboardSerivce.updateListingStatus(listingId, stsusId).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.utilService.showToast('success', 'Listing Updated');
      this.getAllListing.emit(true);
    })
  }
  activeListingFor30Days(listingId) {
    this.dashboardSerivce.activeListingFor30Days(listingId).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.utilService.showToast('success', 'Listing Validity Updated');
      this.getAllListing.emit(true);
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  buildUrl(listing) {
    const subUrl = 'listing-detail';
    return `${environment.appUrl}/${subUrl}/${listing.id}`;
  }
  openShareDialog() {
    this.shareDialogRef = this.dialog.open(this.shareDialog, {
      panelClass: 'share-dailog'
    });
  }
  shareToFb() {
    let obj={
      "og:url" : this.buildUrl(this.listing),
      "og:type": "website",
      "og:title": this.listing.title,
      "og:description": this.listing.description?this.listing.description.substr(0, 20):null,
      "og:image": environment.storageUrl + '/listing/' + this.listing.filePath,
      "og:image:width": "450",
      "og:image:height": "298"
    };
    FB.init({
      appId: environment.facebookId,
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4"
    });
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        redirect_uri: this.buildUrl(this.listing),
        action_properties: JSON.stringify({
            object:obj
          })
      },
      (response) => {

        if (response) {

        } else {

        }
      }, (err) => {

      }
    );
  }
  openLineShare() {
    open(`https://timeline.line.me/social-plugin/share?url=${this.buildUrl(this.listing)}&title=${this.listing.title}`);
  }
  getGroup() {

    this.dashboardSerivce.getLineGroup(this.listing.id).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      console.log("data", data);
      data.forEach(element => {
        if (element.disable) {
          element.checked = true;
        }
      });
      this.lineGroups = data;
      this.shareListingClick = true;
    })
  }
  shareToLine() {
    this.utilService.showAcceptDeclineDialog("You are about to share a listing in line groups!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {

      if (accepet) {
        let group = [];
        console.log(this.lineGroups)
        this.lineGroups.forEach((element) => {
          if (element.checked) {
            group.push(element.id);
          }
        })
        if (group.length) {
          this.dashboardSerivce.shareToLine(this.listing.id, group).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
            console.log("data", data);
            this.shareListingClick = false;
            this.utilService.showToast('success', 'Your Listing Share To Selected Line Group.')
          })
        } else {
          this.utilService.showToast('error', 'No group selected!')
        }
      }
    }
  )}
}
