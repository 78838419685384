import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/core/service/auth/auth.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  public dialogRef: null;
  destroy$: Subject<boolean> = new Subject();
  notifications = [];
  totalUnRead = 0;
  currentPage: number = 0;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  showNotificationDialog = false;
  user: any = null;

  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private changeDetection: ChangeDetectorRef,
    private router: Router, private authService: AuthService) {

  }

  ngOnInit() {
    this.user = this.route.snapshot.data.routeResolver;
    this.getAllNotification();
    
    // if (this.authService.connection) {
    //   this.getNotficationIfSignalRCalled();
    // }
    let THIS = this;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
      if (event.url.match('/dashboard')) {
        $(window).click(function () {
          //Hide the menus if visible
          THIS.showNotificationDialog = false;
          THIS.changeDetection.detectChanges();
          $('#notification-div').click(function (event) {
            event.stopPropagation();
          });
          $('#notifcation').click(function (event) {
            event.stopPropagation();
          });
        });
      } else {

      }

    });
    // $(window).click(function () {
    //   //Hide the menus if visible
    //   THIS.showNotificationDialog = false;
    //   THIS.changeDetection.detectChanges();
    //   $('#notification-div').click(function (event) {
    //     event.stopPropagation();
    //   });
    //   $('#notifcation').click(function (event) {
    //     event.stopPropagation();
    //   });
    // });

    this.authService.customObservable.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentPage = 0;
      this.pageInfo.totalPages = 1;
      this.getAllNotification();
    })
    if (!this.authService.connection) {
      this.authService.callSignalR();
    }
  }


  // getNotficationIfSignalRCalled() {
  //   let _this = this;

  // }

  getAllNotification() {
    if (this.currentPage != this.pageInfo.totalPages) {
      this.currentPage = this.currentPage + 1;
      this.dashboardService.getNotification().pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
        
        if (this.currentPage == 1) {
          this.notifications = [];
        }
        this.notifications = [...this.notifications, ...data.notifications];
        this.totalUnRead = data.unread;
        this.pageInfo = pageInfo;
        this.changeDetection.detectChanges();
      })
    }
  }

  showAleartDialog(templateRef: TemplateRef<any>): void {

    let maxWidht = '453px';
    let maxHeight = '510px';
    let minWidth = '100px';
    this.dialog.open(templateRef, {
      panelClass: 'notification-dialog',
      maxHeight: maxHeight,
      maxWidth: maxWidht,
      minWidth: minWidth,
      position: {
        right: '235px',
        top: '315px'
      }
    })

  }
  goto(item) {
    switch (item.type) {
      case 3:
        this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: 'admin-reported-listings-dashboard' } }]);
        break;
      case 2:
        this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: 'reported-listing' } }]);
        break;
    }
    this.dashboardService.updateNotificationRead(item.id).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.currentPage = 0;
      this.pageInfo.totalPages = 1;
      this.getAllNotification();
    })
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  deleteNotification(notificationId) {
    this.dashboardService.deleteNotification(notificationId).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.currentPage = 0;
      this.pageInfo.totalPages = 1;
      this.getAllNotification();
    })
  }

}
