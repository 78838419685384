import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';
var DashboardAdminCalculateHistoryComponent = /** @class */ (function () {
    function DashboardAdminCalculateHistoryComponent(dashboardService, changeDetection, utilService) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dataSource = [];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.displayedColumns = ['clculateFor', 'languag', 'area', 'requiredMinSqm', 'requiredMaxSqm', 'date', 'action'];
        this.storage = environment.storageUrl;
        this.destroy$ = new Subject;
        this.downloadFile = function (blob, fileName) {
            var link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            window.addEventListener('focus', function (e) { return URL.revokeObjectURL(link.href); }, { once: true });
        };
    }
    DashboardAdminCalculateHistoryComponent.prototype.ngOnInit = function () {
        this.getAllLeads();
    };
    DashboardAdminCalculateHistoryComponent.prototype.getAllLeads = function () {
        var _this = this;
        this.dashboardService.getAllLeads().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.dataSource = data;
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardAdminCalculateHistoryComponent.prototype.sendLeadToAgent = function () {
        var obj = {
            "Id": null,
            "AssignedAgentId": null
        };
        this.dashboardService.sendLeadToAgent(obj).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
        });
    };
    DashboardAdminCalculateHistoryComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAdminCalculateHistoryComponent.prototype.delete = function (element) {
        var _this = this;
        this.dashboardService.deleteLeads(element.id).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.currentPage = 0;
            _this.getAllLeads();
            _this.utilService.showToast('success', "Lead deleted!");
        }, function (err) {
            _this.utilService.showToast('error', "Error try tagain!");
        });
    };
    DashboardAdminCalculateHistoryComponent.prototype.sentToAgent = function (element) {
        var _this = this;
        this.dashboardService.sentToAgent(element.id, element.sentToAgent ? false : true).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.currentPage = 0;
            _this.getAllLeads();
            _this.utilService.showToast('success', "Updated!");
        }, function (err) {
            _this.utilService.showToast('error', "Error try tagain!");
        });
    };
    DashboardAdminCalculateHistoryComponent.prototype.downloadLead = function (leadId) {
        var _this = this;
        this.dashboardService.downloadLead(leadId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.downloadFile(data, 'Lead.pdf');
        });
    };
    DashboardAdminCalculateHistoryComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllLeads();
    };
    return DashboardAdminCalculateHistoryComponent;
}());
export { DashboardAdminCalculateHistoryComponent };
