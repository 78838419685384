import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'td-dashboard-admin-messages',
  templateUrl: './dashboard-admin-messages.component.html',
  styleUrls: ['./dashboard-admin-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminMessagesComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();

  messageList = [];
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  @ViewChild('messageDialog', {static: false}) secondDialog: TemplateRef<any>;
  message = null;
  assDec = false;
  constructor(private dashboardService: DashboardService, private changeDetection: ChangeDetectorRef,
    private utilService: UtilService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getListOfMessages();
  }

  getListOfMessages() {
    let obj = {
      filter: null
    }
    this.dashboardService.getListOfMessageForAdmin(this.currentPage,10,obj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
      
      this.messageList = data;
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();


    }, (err) => {
      
    })

  }

  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getListOfMessages();
  }
  deleteEventCalled(messageId: number) {
    
    this.utilService.showAcceptDeclineDialog("You are about to delete a messsage!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
      
      if (accepet) {
        this.dashboardService.deleteMessage(messageId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          this.utilService.showToast('success',`#${messageId}Message Deleted!`);
          this.messageList.forEach((elt, index) => {
            if (elt.id == messageId) {
              this.messageList.splice(index, 1);
            }
          });
          
          this.changeDetection.detectChanges();
        }, (err) => {
          console.error(err)
          this.utilService.showToast('error',`There is an error deleting listing! Try again later.`);
        })
      }
    })

  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showMessageDialog(message) {
    
    this.message = message;
    this.dialog.open(this.secondDialog, { data: { message: message } });
    this.dashboardService.updateMessageStatus(message.id).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.messageList.forEach((elt) => {
        if (elt.id == message.id) {
          elt.status = 2;
        }
      });
      this.changeDetection.detectChanges();
    })
  }
}
