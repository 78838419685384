<mat-nav-list class="side-nav">
  <div *ngIf="isAuthentic">
    <div fxLayout="column" fxLayoutAlign="center center"  style="margin: 20px;">
      <!-- <div class="background-img"></div> -->
      <img src="assets/images/user/dashboard-avtar.png" class="background-img" />
      <div>{{userProfil?.firstName}} {{userProfil?.lastName}}</div>
    </div>


    <a mat-list-item [routerLink]="['/add-listing']" class="add-listing-button" (click)="onSidenavClose()"
      fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <i class="mdi mdi-map-marker"></i>
      <span class="nav-caption">{{'Add Listing' | translate}}</span></a>
    <a mat-list-item routerLink="/" (click)="onSidenavClose()" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="10px">
      <i class="mdi mdi-home"></i> <span class="nav-caption">Home</span>
    </a>
    <!-- <a mat-list-item *ngIf="!isAuthentic" (click)="openDialog(myDialog,'myDialog');onSidenavClose()" fxLayout="row"
      fxLayoutAlign="start center" fxLayoutGap="10px">
      <i class="mdi mdi-account-plus"></i>{{'Sign in / Register' | translate}}</a> -->
    <a mat-list-item (click)="logout();onSidenavClose()" *ngIf="isAuthentic" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="10px"><i class="mdi mdi-logout"></i>
      <span class="nav-caption">{{'Logout' | translate}}</span></a>
  </div>
  <div class="loging-register-dialog" *ngIf="!isAuthentic">
    <td-login-register [notDialog]="true" (loginResponse)="checkAuthentication()"></td-login-register>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="language-icon" [ngStyle]="{
      'background-image': language == 'th'?'url(/assets/images/language/lang.png)': 'url(/assets/images/language/thai.png)'
      }" (click)="setLanguage(language== 'en'?'th':'en')"></div>
  </div>
</mat-nav-list>




<ng-template #myDialog>
  <mat-dialog-content>
    <form [formGroup]="loginRegisterForm" (ngSubmit)="onSubmit()">
      <div *ngIf="!forgetPassword">
        <div class="selection-button" fxLayout="row" fxLayoutAlign="center center">
          <a [class.selectedTab]="selectedLoginTab=='signIn'" (click)="selectedLoginTab='signIn'" fxLayout="row"
            fxLayoutAlign="center center">{{'Sign In' | translate}}</a>
          <a [class.selectedTab]="selectedLoginTab=='register'" (click)="selectedLoginTab='register'" fxLayout="row"
            fxLayoutAlign="center center">{{'Register' | translate}}</a>
        </div>
        <div class="login-div" fxLayout="column" fxLayoutAlign="center center">
          <a class="facebook" fxLayout="row" fxLayoutAlign="space-evenly center">
            <i class="mdi mdi-facebook"></i>
            {{selectedLoginTab=='signIn'?'LOGIN':'REGISTER'}} WITH FACEBOOK
          </a>
          <a class="linkedin" fxLayout="row" fxLayoutAlign="space-evenly center" (click)="loginWithLinkdinAccount()">
            <i class="mdi mdi-linkedin"></i>
            {{selectedLoginTab=='signIn'?'LOGIN':'REGISTER' | translate}} {{'WITH LINKEDIN' | translate}}
          </a>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <hr>
          <p>{{'or' | translate}}</p>
          <hr>
        </div>
        <div class="input-div" fxLayout="column" fxLayoutAlign="center center">
          <input type="text" placeholder="Email" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
            <div *ngIf="f.email.errors.email">{{'Email must be a valid email address' | translate}}</div>
          </div>
          <input type="password" placeholder="Password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">{{'Password is required' | translate}}</div>
            <div *ngIf="f.password.errors.minlength">{{'Password must be at least 6 characters' | translate}}</div>
          </div>
          <button mat-flat-button class="login-button" [color]="'primary'"
            [disabled]="submitted">{{selectedLoginTab=='signIn'?'LOGIN':'REGISTER' | translate}}</button>
          <a class="forget-password" (click)="forgetPassword=true">{{'Forget Password ?' | translate}}</a>
        </div>
      </div>
      <div *ngIf="forgetPassword">
        <div class="input-div" fxLayout="column" fxLayoutAlign="center center">
          <input type="text" placeholder="Email" formControlName="email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
            <div *ngIf="f.email.errors.email">{{'Email must be a valid email address' | translate}}</div>
          </div>

          <button mat-flat-button class="login-button" [color]="'primary'"
            [disabled]="submitted">{{'Send Password Link' | translate}}</button>
          <a class="forget-password" (click)="forgetPassword=false">{{'Back To Login' | translate}}</a>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>

<ng-template #alreadyLogin>
  <mat-dialog-content>
    <mat-list role="list">
      <mat-list-item role="listitem" (click)="goto('dashboardhome')">{{'Dashboard' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'profile-dashboard'}}]"
        [routerLinkActive]="'active'">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%;">
          <span>{{'My Profile' | translate}} </span>

          <span class="badge" ngif>{{percentageProfile}}%</span>
        </div>
      </mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-ads-dashboard'}}]"
        [routerLinkActive]="'active'">{{'Manage My Ads' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-fav-dashboard'}}]"
        [routerLinkActive]="'active'">{{'Favorite Ads' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-message'}}]"
        [routerLinkActive]="'active'">{{'Message' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-payment-history'}}]"
        [routerLinkActive]="'active'">{{'Payment History' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-support'}}]"
        [routerLinkActive]="'active'">{{'Support' | translate}}</mat-list-item>
      <mat-list-item role="listitem" (click)="logout()">{{'Logout' | translate}}</mat-list-item>
    </mat-list>
  </mat-dialog-content>
</ng-template>

<ng-template #showFirstLoginDialog>
  <mat-dialog-content>
    <form [formGroup]="firstDiloagForm" (ngSubmit)="submitFirstLogin()">
      <div fxLayout="column" fxLayoutAlign="start start" style="margin-bottom: 50px;">
        <h5>{{'What is your role?' | translate}}</h5>
        <select placeholder="Please Select" [ngClass]="{ 'has-error': firstLoginSubmitted && f.userRole.errors }"
          formControlName="userRole">
          <option value="1">{{'Property Agent' | translate}}</option>
          <option value="2">{{'Owner' | translate}}</option>
        </select>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" *ngIf="firstDiloagForm.get('userRole').value">
        <h5>{{'Please Confirm your primary expertise (maximum of 3)' | translate}}</h5>
        <div class="available-box choose-search-type" fxLayout="row wrap" fxLayoutAlign="center">
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/residential-income.svg" />
            </div>
            <h3>{{'Multifamily' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/office.svg" />
            </div>
            <h3>{{'Office' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/retail.svg" />
            </div>
            <h3>{{'Retail' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/industrial.svg" />
            </div>
            <h3>{{'Industrial' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/hospitality.svg" />
            </div>
            <h3>{{'Hospitality' | translate}}</h3>
          </div>

          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/speciality.svg" />
            </div>
            <h3>{{'Specialty' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/land.svg" />
            </div>
            <h3>{{'Land' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/businesses.svg" />
            </div>
            <h3>{{'Businesses' | translate}}</h3>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 70px;">
        <button mat-flat-button [color]="'primary'">{{'Submit' | translate}}</button>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>