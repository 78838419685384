<div class="manage-fav-ads" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
    <h5 translate>Manage My Favorite Ads</h5>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <form [formGroup]="search" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <input fxFlex="60" type="text" placeholder="Search" formControlName="searchText" />
        <button mat-mini-fab color="primary" fxHide.sm fxHide.xs style="right: 60px;" (click)="clearSearch()"
          *ngIf="search.get('searchText').value">
          <i class="mdi mdi-close" style="color:white;"></i>
        </button>
        <select fxFlex="20" formControlName="listingType">
          <option value="2" translate>For Rent</option>
          <option value="1" translate>For Sale</option>
        </select>
        <button fxFlex="20" type="button" mat-flat-button [color]="'primary'" (click)="searchForListing()">{{'Search Listing'|translate}}</button>
      </form>
    </div>
    <div *ngIf="listingData.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <div *ngFor="let listing of listingData " style="width:99%;">

        <td-listing-card-dashboard type="fav-listing" [listing]="listing" (deleteEvent)="deleteEventCalled($event)">
        </td-listing-card-dashboard>
      </div>
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </div>
    <div *ngIf="!listingData.length" translate>
      You did not make any listing favorite.
    </div>

  </div>

</div>