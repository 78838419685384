import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { ListingService } from 'src/listing/service/listing.service';
var DashboardFavoriteAdsComponent = /** @class */ (function () {
    function DashboardFavoriteAdsComponent(fb, dashboardService, changeDetection, utilService, listingService) {
        this.fb = fb;
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.listingService = listingService;
        this.destroy$ = new Subject();
        this.listingData = [];
        this.search = this.fb.group({
            searchText: [''],
            listingType: [null]
        });
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
    }
    DashboardFavoriteAdsComponent.prototype.ngOnInit = function () {
        this.getAllListing();
    };
    DashboardFavoriteAdsComponent.prototype.getAllListing = function () {
        var _this = this;
        var obj = {
            listingType: this.search.value.listingType,
            filter: this.search.value.searchText,
            favorites: true
        };
        this.dashboardService.getAllListing(this.currentPage, 10, obj)
            .pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.listingData = data;
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardFavoriteAdsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllListing();
    };
    DashboardFavoriteAdsComponent.prototype.deleteEventCalled = function (ev) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to unfavourite a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.listingService.addToFav(ev).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + ev + " Listing Unfavorite!");
                    _this.currentPage = 0;
                    _this.pageInfo.total = 2;
                    _this.getAllListing();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardFavoriteAdsComponent.prototype.clearSearch = function () {
        this.search.controls.searchText.setValue('');
        this.search.controls.listingType.setValue(null);
        this.searchForListing();
    };
    DashboardFavoriteAdsComponent.prototype.searchForListing = function () {
        this.currentPage = 0;
        this.pageInfo.total = 2;
        this.getAllListing();
    };
    DashboardFavoriteAdsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    return DashboardFavoriteAdsComponent;
}());
export { DashboardFavoriteAdsComponent };
