/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./payment-error.component";
import * as i11 from "@angular/router";
var styles_PaymentErrorComponent = [i0.styles];
var RenderType_PaymentErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentErrorComponent, data: {} });
export { RenderType_PaymentErrorComponent as RenderType_PaymentErrorComponent };
export function View_PaymentErrorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { wrapper: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["paymentError", 1]], null, 13, "div", [["class", "payment-error"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "mdi mdi-alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Payment Failed"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(9, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Please try again"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 3, 0, currVal_1); var currVal_2 = ""; _ck(_v, 6, 0, currVal_2); var currVal_3 = ""; _ck(_v, 9, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 12).disabled || null); var currVal_5 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("Try Again")); _ck(_v, 13, 0, currVal_7); }); }
export function View_PaymentErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-payment-error", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PaymentErrorComponent_0, RenderType_PaymentErrorComponent)), i1.ɵdid(1, 114688, null, 0, i10.PaymentErrorComponent, [i11.ActivatedRoute, i11.Router, i1.Renderer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentErrorComponentNgFactory = i1.ɵccf("td-payment-error", i10.PaymentErrorComponent, View_PaymentErrorComponent_Host_0, {}, {}, []);
export { PaymentErrorComponentNgFactory as PaymentErrorComponentNgFactory };
