import { Injectable } from "@angular/core";
import { IdbService } from "../idb/idb.service";
import { Observable, from, BehaviorSubject, of, interval, Subscriber, Subject } from "rxjs";
import { switchMap, map, tap, takeUntil } from "rxjs/operators";
import { DB } from "idb";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { tokenData } from 'src/login-register/interface/token.interface';
import { environment } from 'src/environments/environment';

declare const signalR: any;
@Injectable()
export class AuthService {
  profileState: BehaviorSubject<any> = new BehaviorSubject(null);
  authenticationState: BehaviorSubject<boolean> = new BehaviorSubject(
    !!this.token
  );
  connection = null;
  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();

  constructor(
    private idbService: IdbService,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) {
    
  }
  saveAuthToken(token: tokenData) {
    
    localStorage.setItem("token", JSON.stringify(token));
    return of(token).pipe(
      tap(() => this.authenticationState.next(true)),
      tap(() => this.token)
    );
  }
  logOut() {
    return of(localStorage.removeItem("token")).pipe(
      switchMap(() => this.deleteProfile()),
      tap(() => this.authenticationState.next(false))
    );
  }
  redirectLogin(
    message: string = "Please login or register to continue",
    state: string = "authentication/login"
  ) {
    this.matSnackBar.open(message, null, { duration: 3000 });
    setTimeout(() => this.router.navigate([state]), 500);
  }
  get token() {
    const token = !!localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
    if (!token) {
      this.deleteProfile().subscribe();
    } 
    return token;
  }
  getProfile() {
    return this.idbService.instance().pipe(
      switchMap((db: DB) =>
        from(db
          .transaction("profile")
          .objectStore("profile")
          .get("me"))
      ),
      map((data: any) => {
        return this.profileState.next(data)
      })
    );
  }

  saveProfile(data) {
    return this.idbService.instance().pipe(
      switchMap((db: DB) => {
        const tx = db.transaction("profile", "readwrite");
        tx.objectStore("profile").put({
          idbuid: "me",
          ...data
        });
        return from(tx.complete).pipe(tap(() => this.profileState.next(data)));
      })
    )
  }
  deleteProfile() {
    
    return this.idbService.instance().pipe(switchMap((db: DB) => {
      
      const tx = db.transaction("profile", "readwrite");
      tx.objectStore("profile").delete("me");
      return from(tx.complete).pipe(tap(() => this.profileState.next(null)));
    }));
  }

  callSignalR() {
    let _this = this;
    
    
    if (!this.connection && (window as any).signalR.VERSION) {
      
      this.connection = new (window as any).signalR.HubConnectionBuilder().withUrl(`${environment.serverUrl}hubs/notifications`, {
        accessTokenFactory: () => this.token.access_token,
      }).configureLogging((window as any).signalR.LogLevel.Information).build();

      this.connection.on("ReceiveNotification", function (notificationMessage) {
        //do something when receive a new notification message
        
        _this.customSubject.next(true);

      });
      this.connection.start().then(function () {
        //do something when connection is established
        
        _this.customSubject.next(true);
      }).catch(function (err) {
        
        return console.error(err.toString());
      });
    }
  }





  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }


}
