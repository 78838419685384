/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./new-image-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./new-image-upload.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../service/media.service";
var styles_NewImageUploadComponent = [i0.styles];
var RenderType_NewImageUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewImageUploadComponent, data: {} });
export { RenderType_NewImageUploadComponent as RenderType_NewImageUploadComponent };
function View_NewImageUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" Upload progress: ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileUploadProgress; _ck(_v, 1, 0, currVal_0); }); }
function View_NewImageUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-preview mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["height", "300"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_NewImageUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadedFilePath; _ck(_v, 1, 0, currVal_0); }); }
export function View_NewImageUploadComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "col-md-6 offset-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose File"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["name", "image"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.fileProgress($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImageUploadComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImageUploadComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewImageUploadComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileUploadProgress; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.previewUrl; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.uploadedFilePath; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_NewImageUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-new-image-upload", [], null, null, null, View_NewImageUploadComponent_0, RenderType_NewImageUploadComponent)), i1.ɵdid(1, 114688, null, 0, i3.NewImageUploadComponent, [i4.HttpClient, i1.ChangeDetectorRef, i5.MediaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewImageUploadComponentNgFactory = i1.ɵccf("td-new-image-upload", i3.NewImageUploadComponent, View_NewImageUploadComponent_Host_0, {}, {}, []);
export { NewImageUploadComponentNgFactory as NewImageUploadComponentNgFactory };
