import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener, Renderer } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'td-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentErrorComponent implements OnInit {
  @ViewChild('paymentError', {static: false}) wrapper: ElementRef;
  @HostListener('window:resize')
  onWindowResize() {
    
    this.onResize();

  }
  billId = null;
  constructor(private route: ActivatedRoute, private router: Router,
    private renderer: Renderer) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      
      this.billId = params.id;
    });
    this.onResize();
  }
  onResize() {
    // resize the container for the google map

    
    if (this.wrapper) {
      this.renderer.setElementStyle(

        this.wrapper.nativeElement, 'height',
        (window.innerHeight - (120 + 447)) + 'px'
      );
    }
  }
  reload() {
    location.reload();
  }
  goBack() {
    this.router.navigate([`/payment-confirmation`, this.billId], { skipLocationChange: false });
  }
}
