import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { MouseEvent, MapsAPILoader } from '@agm/core';
// @ts-ignore
import { } from 'googlemaps';
import { FormControl } from '@angular/forms';
import { Coords } from 'src/core/interfaces/coords.interface';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { GooglemapsService } from 'src/shared/services/googlemaps/googlemaps.service';
import { Address } from '../../interface/listing.interface';


@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDialogComponent implements OnInit {
  initialPosition: Coords = { lat: 13.7563, lng: 100.5018, area: 'Central' };
  locationPickerConfiguration: {
    appearance?: "legacy" | "standard" | "fill" | "outline";
  } = { appearance: "outline" };
  selectedArea = null;
  address=[];
  coords: any = null;
  destroy$: Subject<boolean> = new Subject();
  showMap:boolean=false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone, private changeDetection: ChangeDetectorRef,
    private googleMapsService: GooglemapsService, public dialogRef: MatDialogRef<GenericDialogComponent>) {
    
    
  }


  ngOnInit() {
    this.showMap=this.data.showMap;
    this.changeDetection.detectChanges();
  }

  getCoords(coords:any) {
    
    
    this.coords = { ...coords };
    
    // if(this.coords.type== "location"){
      forkJoin(
        this.googleMapsService.reverseGeocoding(coords, "en"),
        this.googleMapsService.reverseGeocoding(coords, "th")
      )
        .pipe(takeUntil(this.destroy$), map(([en, th]) => {
          
          this.address = [
            {
              latitude: this.coords.lat,
              longitude: this.coords.lng,
              languageId: 1,
              formattedAddress: en.formatted_address,
              postCode: 1, 
              subDistrictId:1, 
              districtId:2, 
              provinceId:1,
              countryId:1,
              area:this.coords.area||this.selectedArea
            },
            {
              latitude: this.coords.lat,
              longitude: this.coords.lng,
              languageId: 2,
              formattedAddress: th.formatted_address,
              postCode: 1, 
              subDistrictId:1, 
              districtId:2, 
              provinceId:1,
              countryId:1,
              area:this.coords.area||this.selectedArea
            }
          ];
          return this.address;
        }))
        .subscribe((addressData) => {
          
          this.address = addressData;
          this.changeDetection.detectChanges();
          if(!this.showMap){
            this.dialogRef.close(this.address);
          }
        });
    // }else{
    //   this.address = this.coords.position;
    //     this.changeDetection.detectChanges()
    // }
    

  }

  mapAreaSelected(area: string) {
    this.selectedArea = area;
    
    switch (area) {
      case 'West':
        this.initialPosition.lat = 14.927136;
        this.initialPosition.lng = 99.0065353;
        break;
      case 'South':
        this.initialPosition.lat = 9.1003993;
        this.initialPosition.lng = 99.2905703;
        break;
      case 'North':
        this.initialPosition.lat = 18.7071626;
        this.initialPosition.lng = 98.9696934;
        break;
      case 'North-East':
        this.initialPosition.lat = 16.4288193;
        this.initialPosition.lng = 102.829493;
        break;
      case 'East':
        this.initialPosition.lat = 12.9214122;
        this.initialPosition.lng = 100.9122683;
        break;
    }
    this.initialPosition.area = area;
    if(!this.showMap){
      this.changeCod();
    }
  }
  changeCod(){
    let coords={
        lat:this.initialPosition.lat,
        lng:this.initialPosition.lng,
        area:this.initialPosition.area
    }
    this.getCoords(coords)
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

