import { OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
var DashboardMessageComponent = /** @class */ (function () {
    function DashboardMessageComponent(dashboardService, changeDetection, utilService, dialog) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dialog = dialog;
        this.destroy$ = new Subject();
        this.messageList = [];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
        this.message = null;
        this.dialogRef = null;
    }
    DashboardMessageComponent.prototype.ngOnInit = function () {
        this.getListOfMessages();
    };
    DashboardMessageComponent.prototype.getListOfMessages = function () {
        var _this = this;
        var obj = {
            filter: null
        };
        this.dashboardService.getListOfMessageForUsers(this.currentPage, 10, obj).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.messageList = data;
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        }, function (err) {
        });
    };
    DashboardMessageComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getListOfMessages();
    };
    DashboardMessageComponent.prototype.deleteEventCalled = function (messageId) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a messsage!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteMessage(messageId).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + messageId + "Message Deleted!");
                    _this.messageList.forEach(function (elt, index) {
                        if (elt.id == messageId) {
                            _this.messageList.splice(index, 1);
                        }
                    });
                    _this.changeDetection.detectChanges();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardMessageComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardMessageComponent.prototype.showMessageDialog = function (message) {
        var _this = this;
        this.message = message;
        this.dialogRef = this.dialog.open(this.secondDialog, { data: { message: message } });
        this.dashboardService.updateMessageStatus(message.id).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.messageList.forEach(function (elt) {
                if (elt.id == message.id) {
                    elt.status = 2;
                }
            });
            _this.changeDetection.detectChanges();
        });
    };
    DashboardMessageComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return DashboardMessageComponent;
}());
export { DashboardMessageComponent };
