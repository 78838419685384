
import { Observable, of } from "rxjs";
import { CanActivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthService } from '../service/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}
  canActivate(): Observable<boolean> {
    return this.checkAuthData();
  }

  checkAuthData(): Observable<boolean> {
    return of(this.authService.token).pipe(
      map((token: string) => (!!token ? true : false)),
      tap(token => {
        if (!token) {
          this.authService.redirectLogin();
        }
      }),
      switchMap((isAuth: boolean) => of(isAuth))
    );
  }
}
