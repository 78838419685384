<div class="manage-support" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
  <h5 translate>Message</h5>
  <form [formGroup]="contactFormHtml" ngxScrollToFirstInvalid>
    <div class="support-form" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-evenly stretch">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between start">
        <div fxLayout="column" fxFlex="50">
          <input  type="text" placeholder="Name" formControlName="name" 
            [ngClass]="{ 'is-invalid': f.name.errors && submitted }" />
          <div *ngIf="f.name.errors && submitted " class="invalid-feedback">

            <div *ngIf="f.name.errors.required" translate>Name is required</div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50"fxFlex="50">
          <input  type="text" placeholder="Email" formControlName="email" 
            [ngClass]="{ 'is-invalid': f.email.errors && submitted }" />
          <div *ngIf="f.email.errors && submitted " class="invalid-feedback">
            <div *ngIf="f.email.errors.required" translate>Email is required</div>
            <div *ngIf="f.email.errors.email" translate>Email is not valid</div>
            <div *ngIf="f.email.errors.minlength" translate>Email must be at least 6 characters</div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between start">
        
        <div fxLayout="column" fxFlex="49">
          <input  type="text" placeholder="Telephone" formControlName="telephone" 
            [ngClass]="{ 'is-invalid': f.telephone.errors && submitted }" />
          <div *ngIf="f.telephone.errors && submitted" class="invalid-feedback">
            <div *ngIf="f.telephone.errors.required" translate>Phone number is required</div>
            <div *ngIf="f.telephone.errors.minlength" translate>Phone number must be at least 10 characters</div>
            <div *ngIf="f.telephone.errors.pattern" translate>Only Numbers</div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
        <div fxLayout="column">
          <input type="text" placeholder="Subject" formControlName="subject" 
            [ngClass]="{ 'is-invalid': f.subject.errors && submitted }" />
          <div *ngIf="f.subject.errors && submitted " class="invalid-feedback">
            <div *ngIf="f.subject.errors.required" translate>Subject is required</div>
            <div *ngIf="f.subject.errors.minlength" translate>Subject must be at least 6 characters</div>
          </div>
        </div>
        <div fxLayout="column">
          <textarea placeholder="Details" rows="10" formControlName="detail" 
            [ngClass]="{ 'is-invalid': f.detail.errors && submitted }"></textarea>
          <div *ngIf="f.detail.errors && submitted " class="invalid-feedback">
            <div *ngIf="f.detail.errors.required" translate>Message is required</div>
            <div *ngIf="f.detail.errors.minlength" translate>Message must be at least 9 characters</div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-flat-button [color]="'primary'" (click)="onSubmit()">{{'Send'|translate}}</button>
      </div>
    </div>
  </form>
</div>