import { OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { UtilService } from 'src/shared/services/util.service';
import { MustMatch } from 'src/shared/custom-function/helper';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
var DashboardMyProfileComponent = /** @class */ (function () {
    function DashboardMyProfileComponent(fb, changeDetection, authService, loadingScreenService, utilService, dashboardService, route) {
        this.fb = fb;
        this.changeDetection = changeDetection;
        this.authService = authService;
        this.loadingScreenService = loadingScreenService;
        this.utilService = utilService;
        this.dashboardService = dashboardService;
        this.route = route;
        this.type = [];
        this.updateProfileForm = this.fb.group({
            email: [{ value: null, disabled: true }],
            firstName: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
            lastName: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
            phoneNumber: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(9)]],
            userRoleId: [null, [Validators.required]],
            password: [null,],
            confirmPassword: [null],
            languageId: [null, [Validators.required]],
            userSpecialities: [[], [Validators.required]],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        this.isAuthentic = false;
        this.submited = false;
        this.destroy$ = new Subject();
    }
    DashboardMyProfileComponent.prototype.ngOnInit = function () {
        this.loadingScreenService.startLoading();
        this.data = this.route.snapshot.data.routeResolver;
        var array = [];
        this.data.userSpecialities.forEach(function (element) {
            array.push(element.propertyType);
        });
        array ? this.updateProfileForm.controls['userSpecialities'].setValue(array) : this.updateProfileForm.controls['userSpecialities'].setValue([]);
        array ? this.type = array : this.type = [];
        this.updateProfileForm.controls['firstName'].setValue(this.data.firstName);
        this.updateProfileForm.controls['lastName'].setValue(this.data.lastName);
        this.updateProfileForm.controls['phoneNumber'].setValue(this.data.phoneNumber);
        this.updateProfileForm.controls['userRoleId'].setValue(this.data.userRole);
        this.updateProfileForm.controls['languageId'].setValue(this.data.languageId);
        this.updateProfileForm.controls['email'].setValue(this.data.email);
        this.loadingScreenService.stopLoading();
    };
    Object.defineProperty(DashboardMyProfileComponent.prototype, "f", {
        get: function () { return this.updateProfileForm.controls; },
        enumerable: true,
        configurable: true
    });
    DashboardMyProfileComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submited = true;
        this.loadingScreenService.startLoading();
        this.updateProfileForm.controls['userSpecialities'].setValue(this.type);
        var profileObj = {
            firstName: this.updateProfileForm.value.firstName,
            lastName: this.updateProfileForm.value.lastName,
            email: this.updateProfileForm.value.email,
            password: this.updateProfileForm.value.password ? this.updateProfileForm.value.password : null,
            userRoleId: parseInt(this.updateProfileForm.value.userRoleId),
            languageId: parseInt(this.updateProfileForm.value.languageId),
            userSpecialities: this.updateProfileForm.value.userSpecialities,
            phoneNumber: this.updateProfileForm.value.phoneNumber,
        };
        if (this.updateProfileForm.invalid) {
            this.loadingScreenService.stopLoading();
            this.utilService.showToast('error', "You need to provide more information about the profile!");
            return;
        }
        this.dashboardService.updateProfile(profileObj, 0).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', "Your profile updated!");
            _this.loadingScreenService.stopLoading();
        }, function (err) {
            _this.utilService.showToast('error', "Error updating your profile, try again later!");
            _this.loadingScreenService.stopLoading();
        });
    };
    DashboardMyProfileComponent.prototype.chnageUserSpecialities = function (type) {
        var i = this.type.indexOf(type);
        if (i === -1) {
            if (this.type.length === 3) {
                this.utilService.showToast('error', "Maximum 3 allowed");
            }
            else {
                this.type.push(type);
                this.changeDetection.detectChanges();
            }
        }
        else {
            this.type.splice(i, 1);
            this.changeDetection.detectChanges();
        }
        this.updateProfileForm.controls['userSpecialities'].setValue(this.type);
        this.changeDetection.detectChanges();
    };
    DashboardMyProfileComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    return DashboardMyProfileComponent;
}());
export { DashboardMyProfileComponent };
