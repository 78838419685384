import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ListingService } from 'src/listing/service/listing.service';
declare var FB: any;
@Component({
  selector: 'td-dashboard-manage-listings',
  templateUrl: './dashboard-admin-manage-listings.component.html',
  styleUrls: ['./dashboard-admin-manage-listings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminManageListingsComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject();
  listingData: any[] = [];
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  search: FormGroup = this.fb.group({
    searchText: [''],
    listingStatus: [1],
    propertyType: [null]
  })
  dialogRef = null;
  listingId = null;
  listing = null;
  taxonomy: any;
  storage: string = environment.storageUrl;
  advanceSearch: FormGroup = this.fb.group({
    residentialType: this.fb.array([]),
    propertyType: this.fb.array([])
  });
  shareDialogRef = null;
  @ViewChild('viewListingDialog', { static: false }) viewListingDialog: TemplateRef<any>;
  @ViewChild('updatePropertyTypeDialog', { static: false }) updatePropertyTypeDialog: TemplateRef<any>;
  @ViewChild('shareDialog', { static: false }) shareDialog: TemplateRef<any>;
  selectedPropertyType = {
    id: null,
    name: null,
    sub: []
  };
  selectedSubPropertyType = {
    id: null,
    name: null,
    sub: []
  };
  userId = null;
  listingAdditionalTypes = [];
  constructor(private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private changeDetection: ChangeDetectorRef,
    private utilService: UtilService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private enumPropertyService: EnumPropertyService,
    private router: Router, private listingService: ListingService) { }


  ngOnInit() {

    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.taxonomy = data
      this.getAllListing();
      this.changeDetection.detectChanges();
    });
  }

  getAllListing() {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    }
    let obj = {
      listingStatus: this.search.value.listingStatus,
      filter: this.search.value.searchText,
      userId: this.userId,
      propertyType: this.search.value.propertyType != 'null' ? this.search.value.propertyType : null
    }

    this.dashboardService.getApproveListing(this.currentPage, 10, obj)
      .pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {


        this.listingData = data;
        this.changeDetection.detectChanges();
        this.pageInfo = pageInfo;
        this.changeDetection.detectChanges();
      })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllListing();
  }
  deleteEventCalled(ev: number) {
    this.utilService.showAcceptDeclineDialog("You are about to delete a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$))
      .subscribe((accepet) => {

        if (accepet) {
          this.dashboardService.deleteListing(ev).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
            this.utilService.showToast('success', `#${ev}Listing Deleted!`);
            this.getAllListing();
          }, (err) => {
            console.error(err)
            this.utilService.showToast('error', `There is an error deleting listing! Try again later.`);
          })
        }
      })
  }
  openPropertypeChangeDialog(ev) {

    this.listingId = ev.id;
    this.listing = ev;
    this.dialogRef = this.dialog.open(this.updatePropertyTypeDialog, {
      panelClass: 'openPropertypeChangeDialog',
      data: { listingId: ev.id }
    })
  }
  viewListingEventCalled(ev) {

    this.listingId = ev.id;
    this.listing = ev;
    this.dialogRef = this.dialog.open(this.viewListingDialog, {
      panelClass: 'view-listing-dialog',
      data: { listingId: ev.id }
    })
  }
  approveOrSuspend(listingId, stsusId) {
    this.dashboardService.updateListingStatus(listingId, stsusId).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.getAllListing();
      this.utilService.showToast('success', 'Listing status changed!')
    })
  }
  close() {
    this.dialogRef.close();
  }
  searchForListing() {
    this.currentPage = 0;
    this.pageInfo.totalPages = 1;
    this.getAllListing();
  }
  getEnumById(firstElement,type, secondElement, id) {
    let nameOrNumber = this.enumPropertyService.getEnumById(firstElement,type, secondElement, id);

    return nameOrNumber;
  }
  goto(listingId) {
    this.router.navigate(["../listing-detail", listingId]);
  }
  edit(listingId) {
    this.dashboardService.getSingleListing(listingId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      if (!data.address.subDistrictId) {
        data.address.subDistrictId = 1;
      }
      if (!data.address.districtId) {
        data.address.districtId = 1;
      }
      if (!data.address.provinceId) {
        data.address.provinceId = 1;
      }
      this.listingService.listingData = data;

      this.router.navigate(['/add-listing']);
    })
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  updatePropertyTypeListing(listingId, propertyType) {
    this.dashboardService.adminUpdatePropertyTypeOfListing(listingId, propertyType.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.selectedPropertyType = propertyType;
        this.utilService.showToast('success', `Property Type Updated!`);
        this.updateResidentialTypeListing(listingId, null)
      });
  }
  updateResidentialTypeListing(listingId, residentialType) {
    this.dashboardService.adminUpdateResidentialTypeIdOfListing(listingId, residentialType ? residentialType.id : null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.selectedSubPropertyType = residentialType;
        this.getAllListing();
        this.utilService.showToast('success', `Type Updated!`);
      });
  }

  copy(item) {
    console.log("item", item);
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    console.log("listener", listener);
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.utilService.showToast('success', 'Email Coppied!')
  }


  onChange(event, property) {
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    let residentArray = [];
    if (property.sub) {
      property.sub.forEach(element => {
        residentArray.push(element.id);
      });
    }
    if (event.checked) {
      propertyType.push(new FormControl(event.source.value));
      if (residentArray.length) {
        this.onChangeResident(null, residentArray, 'add')
      }
    } else {
      const i = propertyType.controls.findIndex(x => x.value === event.source.value);
      propertyType.removeAt(i);
      if (residentArray.length) {
        this.onChangeResident(null, residentArray, 'delete')
      }
    }

  }
  onChangeResident(event = null, array = [], type = null, mainPropertyId = null) {
    const residentType = <FormArray>this.advanceSearch.get('residentialType') as FormArray;
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    if (array.length && type == 'add') {
      array.forEach(x => residentType.push(new FormControl(x)));
      this.changeDetection.detectChanges();
    } else if (array.length && type == 'delete') {
      array.forEach(mainElement => {
        const i = residentType.controls.findIndex(x => x.value === mainElement);
        residentType.removeAt(i);
        this.changeDetection.detectChanges();
      })
    } else {
      if (event.checked) {
        const i = residentType.controls.findIndex(x => x.value === event.source.value);
        if (i == -1) {
          this.taxonomy.propertyTypeResidentialType.map((data: any) => {
            if (data.id == mainPropertyId) {
              data.indeterminate = true;
              this.changeDetection.detectChanges();
            }
          });
          propertyType.push(new FormControl(mainPropertyId));
          residentType.push(new FormControl(event.source.value));
        }
      } else {
        const i = residentType.controls.findIndex(x => x.value === event.source.value);
        residentType.removeAt(i);

        this.taxonomy.propertyTypeResidentialType.map((data: any) => {

          if (data.id == mainPropertyId) {
            data.indeterminate = true;
            this.changeDetection.detectChanges();
          }
        })
      }
    }
  }


  propertyTypeUpdated() {
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    const residentType = <FormArray>this.advanceSearch.get('residentialType') as FormArray;
    this.listingAdditionalTypes = [];
    this.taxonomy.propertyTypeResidentialType.forEach(element => {
      propertyType.value.forEach(propertyTypeValue => {
        if (element.id == propertyTypeValue) {

          if (element.sub&&element.sub.length) {
            element.sub.forEach(subElement => {
              residentType.value.forEach(residentTypeSubElement => {
                if (subElement.id == residentTypeSubElement) {
                  let a = {
                    propertyType: element.id,
                    residentialType: subElement.id
                  }
                  this.listingAdditionalTypes.push(a);
                }
              });
            });
          } else {
            let a = {
              propertyType: element.id,
              residentialType: null
            }
            this.listingAdditionalTypes.push(a);
          }
        }
      })
    });
    let obj = {
      listingId: this.listingId,
      listingAdditionalTypes: this.listingAdditionalTypes
    };
    setTimeout(() => {
      this.dashboardService.updatePropertyType(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        console.log("data", data);
        this.getAllListing();
        this.utilService.showToast('success', `Additional property type added to listing!`);
        this.dialogRef.close();
      })
    },200)
  }

  openShareDialog(listing) {
    this.listing=listing;
    console.log("this.listing",this.listing)
    this.shareDialogRef = this.dialog.open(this.shareDialog, {
      panelClass: 'share-dailog'
    });
  }

  buildUrl(listing) {
    const subUrl = 'listing-detail';
    return `${environment.appUrl}/${subUrl}/${listing.id}`;
  }
  openLineShare(listing) {
    open(`https://timeline.line.me/social-plugin/share?url=${this.buildUrl(listing)}&title=${listing.title}`);
  }
  
  shareToFb(listing) {
    let obj={
      "og:url" : this.buildUrl(listing),
      "og:type": "website",
      "og:title": listing.title,
      "og:description": listing.description?listing.description.substr(0, 20):'',
      "og:image": environment.storageUrl + '/listing/' + listing.filePath,
      "og:image:width": "450",
      "og:image:height": "298"
    };
    console.log("obj",obj);
    FB.init({
      appId: environment.facebookId,
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4"
    });
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        redirect_uri: this.buildUrl(listing),
        action_properties: JSON.stringify({
          object:obj
        })
      },
      (response) => {
        console.log("response",response);
        if (response) {

        } else {

        }
      }, (err) => {
        console.log("err",err);
      }
    );
  }
}
