import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Listing } from '../interface/listing.interface';

@Injectable()
export class ListingService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  listingData:any;
  constructor(public http: HttpClient) { }

  submitListing(listing: Listing) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}listing`, listing)
  }
  getListing(listingId: string) {
    const options = { headers: { skip: "true" } };
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}`)
  }

  updateListing(listing: Listing){
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing`, listing)

  }

  addToFav(ListingId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${ListingId}/favorite`, {})
  }

  reportListing(ListingId, obj){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}listing/${ListingId}/report`, obj)
  }
}
