import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit,
  OnInit
} from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { from, Observable, Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
// @ts-ignore
import {} from "@types/googlemaps";
import { Coords } from 'src/core/interfaces/coords.interface';
import { UtilService } from 'src/shared/services/util.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: "nino-location-picker",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./location-picker.component.html",
  styleUrls: ["./location-picker.component.scss"]
})
export class LocationPickerComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input()
  configuration: {
    appearance?: "legacy" | "standard" | "fill" | "outline";
    placeholder: string;
  };
  @Input()
  text="Start typing the location of your listing or drop the pin in the map";
  @Input()
  position: Coords = null;
  autocomplete: any = null;
  zoom: number = 14;
  @Output()
  getCoordenates: EventEmitter<Coords> = new EventEmitter();
  destroy$: Subject<boolean> = new Subject();
  location:string=null;
  constructor(
    private changeDetection: ChangeDetectorRef,
    private mapsApiLoader: MapsAPILoader,
    private utilService: UtilService,
    private router: Router,
  ) {
    
  }
  ngOnInit() {
    
   
  }
  ngAfterContentInit() {
    $(".pac-container").remove();
    this.mapsApiLoaded()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.googleMapInit();
      });
  }
  googleMapInit(){
    this.autocomplete = new google.maps.places.Autocomplete(
      document.querySelector("#googleInput"),
      { componentRestrictions: { country: "th" } }
    );
    this.autocomplete.addListener("place_changed", () =>
      this.autocompleteHandler()
    );
    
    
      if(this.router.url=='/'){
        setTimeout(function(){ 
          let element = document.getElementsByClassName('pac-container')
          element[0].className +=' Home-pac-container';
        }, 300);
      }
    
    
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  mapsApiLoaded(): Observable<any> {
    return from(this.mapsApiLoader.load());
  }
  getMarkerPosition({ coords }: { coords: Coords }) {
    this.setPosition(coords);
  }
  clickedMarker(ev){
    
    this.setPosition({
      lat: ev.latitude,
      lng: ev.longitude,
      area:''
    });
  }
  getMyLocation() {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) =>
        this.setPosition({ lat: coords.latitude, lng: coords.longitude, area:'' }),
      () =>
        this.utilService.showToast('error',
          `Please enable geolocalization to use this feature`
        )
    );
  }
  private autocompleteHandler() {
    const place = this.autocomplete.getPlace();
    if (!place.geometry) {
      this.utilService.showToast('error',`Place not available`);
      return;
    }
    this.setPosition({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      area:''
    });
    
  }
  private setPosition(position: Coords) {
    
    this.position = position;
    this.zoom = 16;
    this.getCoordenates.emit(this.position);
    this.changeDetection.detectChanges();
  }
}
