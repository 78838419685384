import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener, Renderer, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/payment-component/services/payment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'td-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentSuccessComponent implements OnInit {
  @ViewChild('paymentSuccess', {static: false}) wrapper: ElementRef;
  @HostListener('window:resize')
  onWindowResize() {
    this.onResize();
  }
  billId = null;
  billingDetail: any;
  destroy$: Subject<boolean> = new Subject();
  fees = 0;
  fixAmount = false;
  selectedPackage = {
    orderItems: [],
    fee:0
  };
  paymentMethods: any = null;
  selectedPaymentMethod: any = null;
  constructor(private route: ActivatedRoute, private paymentService: PaymentService, private changeDetection: ChangeDetectorRef,
    private renderer: Renderer, private router: Router) {

  }

  ngOnInit() {
    this.onResize();
    this.billId = this.route.snapshot.paramMap.get("id");
    // this.selectedPaymentMethod = this.route.snapshot.paramMap.get("selectedPaymentMethod");
    this.paymentService.getBillDetails(this.billId).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      
      this.selectedPackage = data.productsOrder;
      this.paymentMethods = data.productsOrder.paymentMethodId;
      this.fees =this.selectedPackage.fee;
      this.fees =this.selectedPackage.fee;
      console.log(data)
      // this.paymentMethods.forEach(element => {
      //   if (element.id==this.selectedPaymentMethod) {
      //     this.fees = element.fee;
      //     this.fixAmount = element.fixAmount;
      //   }
      // });
      this.changeDetection.detectChanges();
    })
    

  }
 
  getSum(amountToAdd = 0): number {
    // 
    let sum = 0;
    for (let i = 0; i < this.selectedPackage.orderItems.length; i++) {
      sum += this.selectedPackage.orderItems[i].amount;
    }
    // 
    // 
    return sum + amountToAdd;
  }

  onResize() {
    // resize the container for the google map

    
    if (this.wrapper) {
      this.renderer.setElementStyle(

        this.wrapper.nativeElement, 'height',
        (window.innerHeight - (120 + 447)) + 'px'
      );
    }
  }
  goto(type) {
    
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
