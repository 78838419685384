<div class="payment-confirmation" *ngIf="billId&&taxonomy | async as taxo" fxShow fxHide.xs fxHide.gt-xs fxHide.sm
  fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
  <div class="payment-confirmation-div" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="payment-confirmation-sidebar" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="header" fxLayout="column" fxLayoutAlign="center start">
        <div class="price-div" *ngIf="selectedPackage?.orderItems">{{getSum(7*getSum(0)/100)+fees| number: '1.2-2'}} THB
        </div>
        <p>Total Payment Due</p>
      </div>
      <ng-container *ngIf="selectedPackage">

        <div class="package-div" fxLayout="column" fxLayoutAlign="center stretch"
          *ngFor="let item of selectedPackage?.orderItems">
          <div class="package-div-box" fxLayout="column" fxLayoutAlign="center stretch">
            <div class="first-div" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="package-name-div">{{item.product}}</div>
              <div class="price-div">{{item.amount}} THB</div>
            </div>
            <div class="second-div" *ngIf="item.name!='Hilighted Ad'">
              {{item.bannerText}}
            </div>
          </div>
        </div>
      </ng-container>
      <div class="total-price-div" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center end">
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">Sub Total</div>
          <div class="second-div">{{getSum(0)}} THB</div>
        </div>

        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">7% VAT</div>
          <div class="second-div">{{7*getSum(0)/100 | number: '1.2-2'}} THB</div>
        </div>
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center" *ngIf="fees">
          <div class="first-div">Bank Service fee</div>
          <div class="second-div">{{fees}} THB</div>
        </div>
        <!-- <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center"
          *ngIf="fees&&!fixAmount">
          <div class="first-div">Service fee</div>
          <div class="second-div">{{fees*getSum(0)/100}} THB</div>
        </div> -->
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">Total</div>
          <div class="second-div" style="font-weight: bold;">{{getSum(7*getSum(0)/100)+fees| number: '1.2-2'}} THB</div>
        </div>
      </div>
    </div>
    <div class="payment-div">
      <div fxLayout="row">
        <div *ngFor="let item of paymentMethods;">
          <a class="card-selection-tab" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center"
            (click)="selectedPaymentDiv=item.id;fees=item.fee;fixAmount=item.fixAmount;removePaypal();barCodeImage=null;"
            *ngIf="item.id!=4" [class.selected-card-div]="selectedPaymentDiv==item.id">
            <i class="mdi mdi-credit-card-multiple" *ngIf="item.id==1"></i>
            <i class="mdi mdi-cash-usd" *ngIf="item.id==2"></i>
            <i class="mdi mdi-bank" *ngIf="item.id==3"></i>
            <span>{{item.name}}</span>
          </a>
          <a class="card-selection-tab" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center"
            (click)="selectedPaymentDiv=item.id;fees=item.fee;fixAmount=item.fixAmount;barCodeImage=null;generatePaypalButton()"
            *ngIf="item.id==4" [class.selected-card-div]="selectedPaymentDiv==item.id">
            <i class="mdi mdi-paypal" *ngIf="item.id==4"></i>
            <span>{{item.name}}</span>
          </a>
        </div>

      </div>

      <div class="card-input-detail-div" fxLayout="column" fxLayoutAlign="center center">

        <div *ngIf="selectedPaymentDiv==1">
          <div class="card-container"></div>
          <form [formGroup]="cardDetailsFormGroup" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center stretch"
            ngxScrollToFirstInvalid
            card
            container=".card-container"
            card-width="500"
            [messages]="messages"
            [placeholders]="placeholders"
            [masks]="masks"
            formatting="false"
            debug="true">
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center stretch">
              <div class="card-label" translate>Card Number</div>
              <input type="text" formControlName="cardNumber" maxlength="19" card-number
                [ngClass]="{ 'has-error': submited && f.cardNumber.errors }" />
              <div *ngIf="submited&&f.cardNumber.errors" class="invalid-feedback">
                <div *ngIf="f.cardNumber.errors.required" translate>Card number is required</div>
                <div *ngIf="f.cardNumber.errors.maxlength" translate>Maximum 16 Character</div>
                <!-- <div *ngIf="f.cardNumber.errors.pattern" translate>Only Numbers</div> -->
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center stretch">
              <div class="card-label" translate>Name on Card</div>
              <input type="text" formControlName="nameOnCard" card-name
                [ngClass]="{ 'has-error': submited && f.nameOnCard.errors }" />
              <div *ngIf="submited&&f.nameOnCard.errors" class="invalid-feedback">
                <div *ngIf="f.nameOnCard.errors.required" translate>Card name is required</div>
                <div *ngIf="f.nameOnCard.errors.maxlength" translate>Maximum 30 Character</div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="5px" [fxFlex]="50" style="position: relative;">
                <div class="card-label" translate>Expired Date</div>
                <div style="position: relative;">
                  <input matInput [matDatepicker]="picker" placeholder="Choose a month" (change)="monthSelected($event)" card-expiry
                    [min]="minDate" formControlName="expiredFullDate" disabled style="border-right: 1px solid #dbdbdb;">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker (monthSelected)="monthSelected($event)" startView="multi-year"
                    disabled="false">
                  </mat-datepicker>
                  <div *ngIf="submited&&f.expiredFullDate.errors" class="invalid-feedback">
                    <div *ngIf="f.expiredFullDate.errors.required" translate>Expired Date is required</div>
                  </div>
                </div>
              </div>

              <div fxLayout="column" fxLayoutGap="5px" [fxFlex]="50">
                <div class="card-label" translate>Security Code</div>
                <input type="text" formControlName="securityCode" maxlength="3" card-cvc
                  [ngClass]="{ 'has-error': submited && f.securityCode.errors }" />
                <div *ngIf="submited&&f.securityCode.errors" class="invalid-feedback">
                  <div *ngIf="f.securityCode.errors.required" translate>Security Code is required</div>
                  <div *ngIf="f.securityCode.errors.maxlength" translate>Maximum 3 Character</div>
                  <div *ngIf="f.securityCode.errors.pattern" translate>Only Numbers</div>
                </div>
              </div>
            </div>
          </form>
          <!-- <div class="card-container"></div> -->
<!-- <form card
  container=".card-container"
  card-width="500"
  [messages]="messages"
  [placeholders]="placeholders"
  [masks]="masks"
  formatting="false"
  debug="true">

  <input type="text" name="number" card-number/>
  <input type="text" name="first-name" card-name/>
  <input type="text" name="last-name" card-name/>
  <input type="text" name="expiry" card-expiry/>
  <input type="text" name="cvc" card-cvc/>
</form> -->
          
        </div>
        <div fxLayout="row" fxLayoutAlign="space-evenly start" *ngIf="selectedPaymentDiv==2" style="width: 100%;">
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center"
            (click)="selectedPaymentBank='internet_banking_bay'"
            [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_bay'">
            <img src="assets/images/payment/payment1.png" />
            <div translate>BAY Krungsri Online</div>
          </div>
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center"
            (click)="selectedPaymentBank='internet_banking_bbl'"
            [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_bbl'">
            <img src="assets/images/payment/payment2.png" />
            <div translate>BBL Bualuang iBanking</div>
          </div>
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center"
            (click)="selectedPaymentBank='internet_banking_ktb'"
            [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_ktb'">
            <img src="assets/images/payment/payment3.png" />
            <div translate>KTB KTB Netbank</div>
          </div>
          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center"
            (click)="selectedPaymentBank='internet_banking_scb'"
            [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_scb'">
            <img src="assets/images/payment/payment4.png" />
            <div translate>SCB SCB Easy Net</div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedPaymentDiv==3"
          style="width: 100%;position: relative;">
          <img src="assets/images/payment/payment5.png" width="178" height="178" *ngIf="!barCodeImage" />
          <img [src]="barCodeImage" *ngIf="barCodeImage" id="printqr" />

          <div class="help-div" fxLayout="row" fxLayoutAlign="center center" *ngIf="!barCodeImage"
            style="right: 86px; top: 20px; width: 209px;">
            <div class="arrow-left"></div>
            <div class="help" translate>
              Click Checkout Botton we will generate Barcode for you
            </div>

          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="selectedPaymentDiv==4" style="width: 100%;">

          <img src="assets/images/payment/payment6.png" />
          <p style="width:46%" translate>Clicking bellow "Generate Order" button will create your order for paypal and
            PayPal Checkout button will appear. Please click the Payapl Checkout button and follow the instruction.
          </p>
        </div>
        <div class="paypal-button-container">
          <div id="paypal-button"></div>
        </div>
        <div class="payment-button" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center"
          *ngIf="!paypalButtonAdded">
          <a [routerLink]="['/listing-detail',listingId]"
            style="width:100%;text-decoration: none;color: red;text-align: center;" translate>Cancel and go to
            Listing</a>
          <button mat-flat-button [color]="'primary'" (click)="printQr()"
            *ngIf="barCodeImage">{{'Print'|translate}}</button>
          <button mat-flat-button id="paypal-button" [color]="'primary'" *ngIf="!barCodeImage"
            (click)="submit()">{{selectedPaymentDiv==4?'Generate Order of':'Checkout'}}
            {{getSum(7*getSum(0)/100)+fees | number: '1.2-2'}}
            THB</button>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
          <img src="assets/images/payment/omise-logo.png" />
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <p style="font-size: 12px;" *ngIf="barCodeImage" translate>NOTE: Once paid by QR code in Tesco Lotus and
            Thailand Commercial get payment your label will be live automaticly.</p>
          <p style="font-size: 12px;" translate>NOTE: Do not press F5 or refresh or press back button until the
            payment process is done successful.</p>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="payment-confirmation" *ngIf="billId&&taxonomy | async as taxo" fxHide fxShow.xs fxShow.gt-xs fxShow.sm
  fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
  <div class="payment-confirmation-div" fxLayout="row" fxLayoutAlign="space-between start" fxLayout.xs="column"
    fxLayout.sm="column" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="center center">
    <div class="payment-confirmation-sidebar" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center"
      *ngIf="!mobilePaymentMathedClicked">
      <div class="header" fxLayout="column" fxLayoutAlign="center start">
        <div class="price-div" *ngIf="selectedPackage?.orderItems">{{getSum(7*getSum(0)/100)+fees| number: '1.2-2'}} THB
        </div>
        <p>Total Payment Due</p>
      </div>
      <ng-container *ngIf="selectedPackage">

        <div class="package-div" fxLayout="column" fxLayoutAlign="center stretch"
          *ngFor="let item of selectedPackage?.orderItems">
          <div class="package-div-box" fxLayout="column" fxLayoutAlign="center stretch">
            <div class="first-div" fxLayout="row" fxLayoutAlign="space-between center">
              <div class="package-name-div">{{item.product}}</div>
              <div class="price-div">{{item.amount}} THB</div>
            </div>
            <div class="second-div" *ngIf="item.name!='Hilighted Ad'">
              {{item.bannerText}}
            </div>
          </div>
        </div>
      </ng-container>
      <div class="total-price-div" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center end">
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">Sub Total</div>
          <div class="second-div">{{getSum(0)}} THB</div>
        </div>

        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">7% VAT</div>
          <div class="second-div">{{7*getSum(0)/100 | number: '1.2-2'}} THB</div>
        </div>
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center" *ngIf="fees">
          <div class="first-div">Bank Service fee</div>
          <div class="second-div">{{fees}} THB</div>
        </div>
        <!-- <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center"
          *ngIf="fees&&!fixAmount">
          <div class="first-div">Service fee</div>
          <div class="second-div">{{fees*getSum(0)/100}} THB</div>
        </div> -->
        <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
          <div class="first-div">Total</div>
          <div class="second-div" style="font-weight: bold;">{{getSum(7*getSum(0)/100)+fees| number: '1.2-2'}} THB</div>
        </div>
      </div>
    </div>
    <div class="payment-div">
      <div fxLayout="row" fxLayout.xs="row wrap" fxLayout.sm="row wrap" fxLayoutAlign="center center" *ngIf="!mobilePaymentMathedClicked">
        <div *ngFor="let item of paymentMethods;">
          <a class="card-selection-tab" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center"
            (click)="mobilePaymentMathedClicked=true;selectedPaymentDiv=item.id;fees=item.fee;fixAmount=item.fixAmount;removePaypal();barCodeImage=null;"
            *ngIf="item.id!=4" [class.selected-card-div]="selectedPaymentDiv==item.id">
            <i class="mdi mdi-credit-card-multiple" *ngIf="item.id==1"></i>
            <i class="mdi mdi-cash-usd" *ngIf="item.id==2"></i>
            <i class="mdi mdi-bank" *ngIf="item.id==3"></i>
            <span>{{item.name}}</span>
          </a>
          <a class="card-selection-tab" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center"
            (click)="mobilePaymentMathedClicked=true;selectedPaymentDiv=item.id;fees=item.fee;fixAmount=item.fixAmount;barCodeImage=null;"
            *ngIf="item.id==4" [class.selected-card-div]="selectedPaymentDiv==item.id">
            <i class="mdi mdi-paypal" *ngIf="item.id==4"></i>
            <span>{{item.name}}</span>
          </a>
        </div>

      </div>
      <div class="card-input-detail-div" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="mobilePaymentMathedClicked">
        <div fxLayout="row" fxLayoutAlign="end end" style="width: 100%;">
          <i class="mdi mdi-close" (click)="mobilePaymentMathedClicked=false" style="font-size: 38px;color: #8cc63f;"></i>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%;margin-bottom: 18px;color:#464343;">
          <i class="mdi mdi-credit-card-multiple" *ngIf="selectedPaymentDiv==1"  style="font-size:50px;"></i>
          <i class="mdi mdi-cash-usd" *ngIf="selectedPaymentDiv==2"  style="font-size:50px;"></i>
          <i class="mdi mdi-bank" *ngIf="selectedPaymentDiv==3"  style="font-size:50px;"></i>
          <i class="mdi mdi-paypal" *ngIf="selectedPaymentDiv==4"  style="font-size:50px;"></i>
          <span *ngIf="selectedPaymentDiv==1" style="font-size:20px;">Credit Card</span>
          <span *ngIf="selectedPaymentDiv==2" style="font-size:20px;">Online Direct Deposit</span>
          <span *ngIf="selectedPaymentDiv==3" style="font-size:20px;">Counter Payment</span>
          <span *ngIf="selectedPaymentDiv==4" style="font-size:20px;">Paypal</span>
        </div>
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <div *ngIf="selectedPaymentDiv==1">
            <form [formGroup]="cardDetailsFormGroup" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center stretch"
              ngxScrollToFirstInvalid>
              <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center stretch">
                <div class="card-label" translate>Card Number</div>
                <input type="text" formControlName="cardNumber" maxlength="16"
                  [ngClass]="{ 'has-error': submited && f.cardNumber.errors }" />
                <div *ngIf="submited&&f.cardNumber.errors" class="invalid-feedback">
                  <div *ngIf="f.cardNumber.errors.required" translate>Card number is required</div>
                  <div *ngIf="f.cardNumber.errors.maxlength" translate>Maximum 16 Character</div>
                  <div *ngIf="f.cardNumber.errors.pattern" translate>Only Numbers</div>
                </div>
              </div>
              <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center stretch">
                <div class="card-label" translate>Name on Card</div>
                <input type="text" formControlName="nameOnCard"
                  [ngClass]="{ 'has-error': submited && f.nameOnCard.errors }" />
                <div *ngIf="submited&&f.nameOnCard.errors" class="invalid-feedback">
                  <div *ngIf="f.nameOnCard.errors.required" translate>Card name is required</div>
                  <div *ngIf="f.nameOnCard.errors.maxlength" translate>Maximum 30 Character</div>
                </div>
              </div>
              <div fxLayout="column" fxLayoutGap="5px" [fxFlex]="50" style="position: relative;">
                <div class="card-label" translate>Expired Date</div>
                  <input matInput [matDatepicker]="picker" placeholder="Choose a month" (change)="monthSelected($event)"
                    [min]="minDate" formControlName="expiredFullDate" disabled style="border-right: 1px solid #dbdbdb;">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker (monthSelected)="monthSelected($event)" startView="multi-year"
                    disabled="false">
                  </mat-datepicker>
                  <div *ngIf="submited&&f.expiredFullDate.errors" class="invalid-feedback">
                    <div *ngIf="f.expiredFullDate.errors.required" translate>Expired Date is required</div>
                  </div>
              </div>

              <div fxLayout="column" fxLayoutGap="5px" [fxFlex]="50">
                <div class="card-label" translate>Security Code</div>
                <input type="text" formControlName="securityCode" maxlength="3"
                  [ngClass]="{ 'has-error': submited && f.securityCode.errors }" />
                <div *ngIf="submited&&f.securityCode.errors" class="invalid-feedback">
                  <div *ngIf="f.securityCode.errors.required" translate>Security Code is required</div>
                  <div *ngIf="f.securityCode.errors.maxlength" translate>Maximum 3 Character</div>
                  <div *ngIf="f.securityCode.errors.pattern" translate>Only Numbers</div>
                </div>

              </div>
            </form>

          </div>
          <div fxLayout="column" fxLayoutAlign="space-evenly start" *ngIf="selectedPaymentDiv==2" style="width: 100%;">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="width: 100%;padding-right: 10px;"
              (click)="selectedPaymentBank='internet_banking_bay'"
              [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_bay'">
              <img src="assets/images/payment/payment1.png" />
              <div translate>BAY Krungsri Online</div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="width: 100%;padding-right: 10px;"
              (click)="selectedPaymentBank='internet_banking_bbl'"
              [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_bbl'">
              <img src="assets/images/payment/payment2.png" />
              <div translate>BBL Bualuang iBanking</div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="width: 100%;padding-right: 10px;"
              (click)="selectedPaymentBank='internet_banking_ktb'"
              [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_ktb'">
              <img src="assets/images/payment/payment3.png" />
              <div translate>KTB KTB Netbank</div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center" style="width: 100%;padding-right: 10px;"
              (click)="selectedPaymentBank='internet_banking_scb'"
              [class.selectedPaymentClass]="selectedPaymentBank=='internet_banking_scb'">
              <img src="assets/images/payment/payment4.png" />
              <div translate>SCB SCB Easy Net</div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="selectedPaymentDiv==3"
            style="width: 100%;position: relative;">
            <img src="assets/images/payment/payment5.png" width="178" height="178" *ngIf="!barCodeImage" />
            <img [src]="barCodeImage" *ngIf="barCodeImage" id="printqr" />

            <div class="help-div" fxLayout="row" fxLayoutAlign="center center" *ngIf="!barCodeImage"
              style="right: 86px; top: 20px; width: 209px;">
              <div class="help" translate>
                Click Checkout Botton we will generate Barcode for you
              </div>

            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="selectedPaymentDiv==4" style="width: 100%;">

            <img src="assets/images/payment/payment6.png" style="width:50%"/>
            <p  translate>Clicking bellow "Generate Order" button will create your order for paypal and
              PayPal Checkout button will appear. Please click the Payapl Checkout button and follow the instruction.
            </p>
          </div>
          <div class="paypal-button-container">
            <div id="paypal-button"></div>
          </div>
          <div class="payment-button" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center"
            *ngIf="!paypalButtonAdded">
            
            <button mat-flat-button [color]="'primary'" (click)="printQr()"
              *ngIf="barCodeImage">{{'Print'|translate}}</button>
            <button mat-flat-button id="paypal-button" [color]="'primary'" *ngIf="!barCodeImage"
              (click)="submit()">{{selectedPaymentDiv==4?'Generate Order of':'Checkout'}}
              {{getSum(7*getSum(0)/100)+fees | number: '1.2-2'}}
              THB</button>
              <a [routerLink]="['/listing-detail',listingId]"
              style="width:100%;text-decoration: none;color: red;text-align: center;" translate>Cancel and go to
              Listing</a>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center">
            <img src="assets/images/payment/omise-logo.png" />
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <p style="font-size: 12px;" *ngIf="barCodeImage" translate>NOTE: Once paid by QR code in Tesco Lotus and
              Thailand Commercial get payment your label will be live automaticly.</p>
            <p style="font-size: 12px;" translate>NOTE: Do not press F5 or refresh or press back button until the
              payment process is done successful.</p>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>


<div class="payment-confirmation" *ngIf="!billId">
  <h3 translate>No package and listing selected. Page will redirect to listing management Screen. Select edit listing
    add package and pay.</h3>
</div>