import * as tslib_1 from "tslib";
import { IdbService } from "../idb/idb.service";
import { Observable, from, BehaviorSubject } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../idb/idb.service";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
var EnumPropertyService = /** @class */ (function () {
    function EnumPropertyService(idbService, matSnackBar, router, http) {
        this.idbService = idbService;
        this.matSnackBar = matSnackBar;
        this.router = router;
        this.http = http;
        this.enumPropertyState = new BehaviorSubject(null);
        this.getEnumProperty();
    }
    EnumPropertyService.prototype.getEnumProperty = function () {
        var _this = this;
        var options = { headers: { skip: "true" } };
        return this.http.get(environment.serverUrl + "graphql/EnumProperty", options).pipe(switchMap(function (taxonomyData) { return _this.saveEnumProperty(taxonomyData); }), switchMap(function () { return _this.getEnumPropertyForClient(); })
        // .subscribe((data: Observable<any>) => {
        //   console.time("Time this");
        //    
        //   this.saveEnumProperty(data);
        );
    };
    EnumPropertyService.prototype.saveEnumProperty = function (data) {
        var _this = this;
        console.time("Time this");
        return this.idbService.instance().pipe(switchMap(function (db) {
            var tx = db.transaction("taxonomy", "readwrite");
            tx.objectStore("taxonomy").put(tslib_1.__assign({ idbuid: "taxonomy" }, data));
            console.timeEnd("Time this");
            return from(tx.complete).pipe(tap(function () { return _this.enumPropertyState.next(data); }));
        }));
        // let dbPromise = this.idbService.instance().subscribe((db: DB) => {
        //   const tx = db.transaction("taxonomy", "readwrite");
        //   tx.objectStore("taxonomy").put({
        //     idbuid: "taxonomy",
        //     ...data
        //   });
        //   this.getEnumPropertyForClient();
        //   return from(tx.complete).pipe(tap(() => this.enumPropertyState.next(data)));
        // })
    };
    Object.defineProperty(EnumPropertyService.prototype, "getTaxonomy", {
        get: function () {
            return this.taxonomy;
        },
        enumerable: true,
        configurable: true
    });
    EnumPropertyService.prototype.getEnumPropertyForClient = function () {
        var _this = this;
        return this.idbService.instance().pipe(switchMap(function (db) {
            return from(db
                .transaction("taxonomy")
                .objectStore("taxonomy")
                .get("taxonomy"));
        }), map(function (data) {
            _this.enumPropertyState.next(data);
            return _this.taxonomy = data;
        }));
    };
    EnumPropertyService.prototype.getEnumRoot = function (firstElement, secondElement, nameOrId) {
        return this.taxonomy[firstElement].find(function (elt) {
            var result;
            if (nameOrId) {
                if (elt.name == secondElement) {
                    result = elt;
                }
            }
            else {
                if (elt.id == secondElement) {
                    result = elt;
                }
            }
            return result;
        });
    };
    EnumPropertyService.prototype.getEnumById = function (firstElement, type, secondElement, nameOrId) {
        if (type === void 0) { type = null; }
        var result;
        this.taxonomy[firstElement].find(function (elt) {
            if (typeof type == 'string') {
                if (nameOrId) {
                    if (elt.name == type) {
                        elt.sub.find(function (subElt) {
                            if (subElt.name == secondElement) {
                                result = subElt;
                            }
                        });
                    }
                }
                else {
                    if (elt.name == type) {
                        elt.sub.find(function (subElt) {
                            if (subElt.id == secondElement) {
                                result = subElt;
                            }
                        });
                    }
                }
            }
            else if (typeof type == 'number') {
                if (nameOrId) {
                    if (elt.id == type) {
                        elt.sub.find(function (subElt) {
                            if (subElt.name == secondElement) {
                                result = subElt;
                            }
                        });
                    }
                }
                else {
                    if (elt.id == type) {
                        elt.sub.find(function (subElt) {
                            if (subElt.id == secondElement) {
                                result = subElt;
                            }
                        });
                    }
                }
            }
            else {
                if (nameOrId) {
                    if (elt.name == secondElement) {
                        result = elt;
                    }
                }
                else {
                    if (elt.id == secondElement) {
                        result = elt;
                    }
                }
            }
        });
        return result;
    };
    EnumPropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnumPropertyService_Factory() { return new EnumPropertyService(i0.ɵɵinject(i1.IdbService), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.HttpClient)); }, token: EnumPropertyService, providedIn: "root" });
    return EnumPropertyService;
}());
export { EnumPropertyService };
