import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from '../../services/article.service';
import { UtilService } from 'src/shared/services/util.service';
import { ActivatedRoute } from '@angular/router';
var PublicArticleComponent = /** @class */ (function () {
    function PublicArticleComponent(articleService, changeDetection, utilService, activatedRoute) {
        this.articleService = articleService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.activatedRoute = activatedRoute;
        this.articleData = [];
        this.article = null;
        this.destroy$ = new Subject();
        this.storage = environment.storageUrl;
        this.assDec = false;
        this.filterObj = {
            id: null,
            filter: null
        };
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
        this.numberOfpage = [];
        this.articleId = null;
        window.scrollTo(0, 0);
    }
    PublicArticleComponent.prototype.ngOnInit = function () {
        this.getAllArticle();
        this.articleId = this.activatedRoute.snapshot.paramMap.get('id');
    };
    PublicArticleComponent.prototype.getAllArticle = function () {
        var _this = this;
        this.articleService.getPublicArticle(this.currentPage, 12, this.filterObj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.articleData = data;
            if (_this.articleId) {
                _this.articleData.forEach(function (element) {
                    if (element.id == _this.articleId) {
                        _this.article = element;
                    }
                });
            }
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.numberOfpage = [];
            for (var i = 0; i < _this.pageInfo.totalPages; i++) {
                _this.numberOfpage.push(i + 1);
                _this.changeDetection.detectChanges();
            }
        });
    };
    PublicArticleComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllArticle();
    };
    PublicArticleComponent.prototype.showFull = function (item) {
        this.article = item;
    };
    PublicArticleComponent.prototype.buildUrl = function (article) {
        var subUrl = 'article';
        return environment.appUrl + "/" + subUrl + "/" + article.id;
    };
    PublicArticleComponent.prototype.shareToLine = function (article) {
        open("https://timeline.line.me/social-plugin/share?url=" + this.buildUrl(article) + "&title=" + article.title);
    };
    PublicArticleComponent.prototype.shareToFb = function (article) {
        var obj = {
            "og:url": this.buildUrl(article),
            "og:type": "website",
            "og:title": article.title,
            "og:description": article.description ? article.description.substr(0, 20) : null,
            "og:image": environment.storageUrl + '/article/' + article.media,
            "og:image:width": "450",
            "og:image:height": "298"
        };
        FB.init({
            appId: environment.facebookId,
            status: true,
            cookie: true,
            xfbml: true,
            version: "v2.4"
        });
        FB.ui({
            method: "share_open_graph",
            action_type: "og.shares",
            redirect_uri: this.buildUrl(article),
            action_properties: JSON.stringify({
                object: obj
            })
        }, function (response) {
            if (response) {
            }
            else {
            }
        }, function (err) {
        });
    };
    return PublicArticleComponent;
}());
export { PublicArticleComponent };
