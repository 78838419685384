<div fxLayout="row" fxLayoutAlign="center center" style="width:100%;">
  <div fxLayout="row" fxLayoutAlign="center center">
    <ul *ngIf="numberOfpage.pages && numberOfpage.pages.length" class="pagination" fxLayout="row"
      fxLayoutAlign="center center" fxLayoutGap="20px">
      <li [ngClass]="{disabled:numberOfpage.currentPage === 1}">
        <a (click)="onClickPage(1)">First</a>
      </li>
      <li [ngClass]="{disabled:numberOfpage.currentPage === 1}">
        <a (click)="onClickPage(numberOfpage.currentPage - 1)">Previous</a>
      </li>
      <li *ngFor="let page of numberOfpage.pages" [ngClass]="{active:numberOfpage.currentPage === page}">
        <a (click)="onClickPage(page)">{{page}}</a>
      </li>
      <li [ngClass]="{disabled:numberOfpage.currentPage === numberOfpage.totalPages}">
        <a (click)="onClickPage(numberOfpage.currentPage + 1)">Next</a>
      </li>
      <li [ngClass]="{disabled:numberOfpage.currentPage === numberOfpage.totalPages}">
        <a (click)="onClickPage(numberOfpage.totalPages)">Last</a>
      </li>
    </ul>

  </div>
</div>