import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './component/contact.component';
import { SharedModule } from 'src/shared/shared.module';

import { ReactiveFormsModule  } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { ContactService } from './service/contact.service.service';

@NgModule({
  declarations: [ContactComponent],
  entryComponents: [ContactComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RecaptchaModule
  ],
  exports: [ContactComponent],
  providers:[ContactService]
})
export class ContactModule { }
