import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { PaymentComponent } from './components/payment-component/payment.components';
import { PaymentService } from './services/payment.service';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { PaymentErrorComponent } from './components/payment-error/payment-error.component';
import { BrowserModule } from '@angular/platform-browser';
import { CardModule } from 'ngx-card/ngx-card';

const routes: Routes = [
  {
    path: "payment-confirmation/:id",
    component: PaymentConfirmationComponent
  },
  {
    path: "payment-success/:id",
    component: PaymentSuccessComponent
  },
  {
    path: "payment-error/:id",
    component: PaymentErrorComponent
  },
];

@NgModule({
  declarations: [
    PaymentComponent,
    PaymentDialogComponent,
    PaymentConfirmationComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent
  ],
  entryComponents: [PaymentDialogComponent, PaymentConfirmationComponent,PaymentErrorComponent],
  exports: [ PaymentComponent , FormsModule, ReactiveFormsModule, PaymentDialogComponent],
  imports: [
    CardModule,
    FlexLayoutModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    NgxScrollToFirstInvalidModule,
    RouterModule.forChild(routes)
  ],
  providers:[PaymentService]
})
export class PaymentModule {}
