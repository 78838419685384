import { Injectable } from "@angular/core";
import { IdbService } from "../idb/idb.service";
import { Observable, from, BehaviorSubject, of } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";
import { DB } from "idb";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnumPropertyService {
  enumPropertyState: BehaviorSubject<any> = new BehaviorSubject(null);
  taxonomy: any;
  constructor(
    private idbService: IdbService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private http: HttpClient
  ) {
    this.getEnumProperty();
  }

  getEnumProperty() {
    const options = { headers: { skip: "true" } };
    return this.http.get(`${environment.serverUrl}graphql/EnumProperty`, options).pipe(
      switchMap((taxonomyData) => this.saveEnumProperty(taxonomyData)),
      switchMap(() => this.getEnumPropertyForClient())

      // .subscribe((data: Observable<any>) => {
      //   console.time("Time this");
      //    
      //   this.saveEnumProperty(data);
    )
  }

  saveEnumProperty(data) {

    console.time("Time this");
    return this.idbService.instance().pipe(
      switchMap((db: DB) => {
        const tx = db.transaction("taxonomy", "readwrite");
        tx.objectStore("taxonomy").put({
          idbuid: "taxonomy",
          ...data
        });
        console.timeEnd("Time this");
        return from(tx.complete).pipe(tap(() => this.enumPropertyState.next(data)));
      })
    )
    // let dbPromise = this.idbService.instance().subscribe((db: DB) => {

    //   const tx = db.transaction("taxonomy", "readwrite");
    //   tx.objectStore("taxonomy").put({
    //     idbuid: "taxonomy",
    //     ...data
    //   });
    //   this.getEnumPropertyForClient();
    //   return from(tx.complete).pipe(tap(() => this.enumPropertyState.next(data)));
    // })
  }
  get getTaxonomy() {
    return this.taxonomy;
  }
  getEnumPropertyForClient(): Observable<any> {
    return this.idbService.instance().pipe(
      switchMap((db: DB) =>
        from(db
          .transaction("taxonomy")
          .objectStore("taxonomy")
          .get("taxonomy"))
      ),
      map((data: any) => {

        this.enumPropertyState.next(data)
        return this.taxonomy = data;
      })
    );
  }
  getEnumRoot(firstElement: string, secondElement: string | number, nameOrId: boolean) {
    return this.taxonomy[firstElement].find(elt => {
      let result: any;
      if (nameOrId) {
        if (elt.name == secondElement) {
          result = elt;
        }
      } else {
        if (elt.id == secondElement) {
          result = elt;
        }
      }
      return result;
    })
  }
  getEnumById(firstElement: string, type = null, secondElement: string | number, nameOrId: boolean) {
    let result: any;
    this.taxonomy[firstElement].find(elt => {
      if (typeof type == 'string') {
        if (nameOrId) {
          if (elt.name == type) {
            elt.sub.find(subElt => {
              if (subElt.name == secondElement) {
                result = subElt;
              }
            })
          }
        } else {
          if (elt.name == type) {
            elt.sub.find(subElt => {
              if (subElt.id == secondElement) {
                result = subElt;
              }
            })
          }
        }
      } else if (typeof type == 'number') {
        if (nameOrId) {
          if (elt.id == type) {
            elt.sub.find(subElt => {
              if (subElt.name == secondElement) {
                result = subElt;
              }
            })
          }
        } else {
          if (elt.id == type) {
            elt.sub.find(subElt => {
              if (subElt.id == secondElement) {
                result = subElt;
              }
            })
          }
        }
      } else {
        if (nameOrId) {
          if (elt.name == secondElement) {
            result = elt;
          }
        } else {
          if (elt.id == secondElement) {
            result = elt;
          }
        }
      }

    })
    return result;
  }

}