import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentInit, OnDestroy } from '@angular/core';
import { Listing, ListingDetailLanguage } from 'src/listing/interface/listing.interface';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent, MatDialog } from '@angular/material';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { FileUploaderConfiguration } from 'src/media/interfaces/file-uploader-configuration.interface';
import { MediaService } from 'src/media/service/media.service';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { Subject } from 'rxjs';
import { GenericDialogComponent } from '../../generic-dialog/generic-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/core/service/auth/auth.service';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { ListingService } from 'src/listing/service/listing.service';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PaymentService } from 'src/payment-component/services/payment.service';
import { HttpEventType } from '@angular/common/http';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';





@Component({
  selector: 'app-add-listing',
  templateUrl: 'add-listing.component.html',
  styleUrls: ['./add-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddListingComponent implements OnInit, AfterContentInit, OnDestroy {
  storage: string = environment.storageUrl;
  isProduction: boolean = environment.production;
  selectedPropertyType: string = null;
  selectedType: string = null;
  selectedSubType: string = null;
  listing: Listing = null;
  listingData: any = null;
  slectedLanguage: number = 1;
  slectedLanguageDescription: number = 1;
  shareToProperdi: boolean = true;
  addListingForm: FormGroup = this.fb.group({
    propertyType: [null],
    isOwner: [false],
    hidePhoneNumbers: [true],
    numBeds: [null],
    numBaths: [null],
    propertySize: [null],
    numUnits: [null],
    occupancyRate: [null],
    holdType: [0],
    funishedState: [0],
    shareToProperDi: [this.shareToProperdi],
    price: [null],
    preferredContact: [1, [Validators.required]],
    userId: [null],
    contact: null,
    address: [null],
    listingDetails: [null],
    ageYear: [null],
    zoningUses: [null],
    wareHouse: [0],
    totalRentPerMonth: [null],
    minimumYearContract: [null],
    transferCost: [null],
    grossIncomePerMonth: [null],
    availableForRent: [1],
    annualCashFlow: [null],
    annualRevenue: [null],
    numParking: [0],
    numEmployees: [null],
    landSize: [null],
    listingStatus: [1],
    askingPriceUponRequest: [false],
    uponRequestAnnualRevenu: [false],
    uponRequestAnnualCashFlow: [false],
    uponRequestPremisesRent: [false],
    uponRequestGrossIncomePerMonth: [false],
    showPropertyLocationOnMap: new FormControl(true),
    location: [null, [Validators.required]],
    engTitle: ['', [Validators.maxLength(70), Validators.minLength(2)]],
    thTitle: ['', [Validators.maxLength(70), Validators.minLength(2)]],
    engDescription: ['', [Validators.minLength(6)]],
    thDescription: ['', [Validators.minLength(2)]],
    contactName: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    contactEmail: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2), Validators.email]],
    contactPhone: [null, [Validators.required, Validators.maxLength(12), Validators.minLength(9)]]
  });
  listingDetails: ListingDetailLanguage[] = [];
  fileUplaoderConfiguration: FileUploaderConfiguration = {
    width: 800,
    height: 600,
    fileSize: 10,
    aspectRatio: 16 / 9,
    crop: false
  };
  medias: string[] = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  keywords: string[] = [];
  taxonomy: any = {};
  isAuthentic: boolean = false;
  submited: boolean = false;
  mediaIds: number[] = [];
  destroy$: Subject<boolean> = new Subject();
  imgURL: any = null;
  addedPakcage: any;
  fileUploadProgress: any = null;
  fileupload: any;
  packageFromListingData: any = null;
  oldThis = this;
  constructor(private fb: FormBuilder, private mediaService: MediaService,
    private changeDetection: ChangeDetectorRef,
    private enumPropertyService: EnumPropertyService,
    private dialog: MatDialog,
    private loginRegisterService: LoginRegisterService,
    private authService: AuthService,
    private listingService: ListingService,
    private loadingScreenService: LoadingScreenService,
    private utilService: UtilService,
    private router: Router,
    private paymentService: PaymentService,
    private dashboardService: DashboardService) {
    this.assignFile();
    window.scroll(0, 0);
    // this.addListingForm.reset();
  }
  get f(): any { return this.addListingForm.controls; }

  ngOnInit() {
    this.addListingForm.value.uponRequestAnnualRevenu = null;
    this.addListingForm.value.uponRequestAnnualCashFlow = null;
    this.addListingForm.value.uponRequestPremisesRent = null;
    this.addListingForm.value.uponRequestGrossIncomePerMonth = null;
    this.taxonomy = this.enumPropertyService.getEnumPropertyForClient();
    this.checkAuthentication();
    this.checkUponRequest();
    if (this.listingService.listingData) {
      this.listingData = this.listingService.listingData;
      this.changeDetection.detectChanges();
      // if (imageUploadConst) {
      this.prepairListing()
      // }
    } else {
      // this.assignFile();
      imageUploadConst.files = null;
      if (this.isAuthentic) {
        this.dashboardService.getProfile()
          .pipe(takeUntil(this.destroy$))
          .subscribe(({ data }: any) => {

            // tslint:disable-next-line: max-line-length
            data && data.email ? this.addListingForm.controls.contactEmail.setValue(data.email) : this.addListingForm.controls.contactEmail.setValue(null);
            // tslint:disable-next-line: max-line-length
            data && data.phoneNumber ? this.addListingForm.controls.contactPhone.setValue(data.phoneNumber) : this.addListingForm.controls.contactPhone.setValue(null);
            // tslint:disable-next-line: max-line-length
            data && data.firstName ? this.addListingForm.controls.contactName.setValue(data.firstName) : this.addListingForm.controls.contactName.setValue(null);
          }, (err) => {

          })
      }
    }
  }
  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated();
  }

  ngAfterContentInit() {
    // this.assignFile();
  }

  prepairListing() {


    this.addListingForm = this.fb.group({
      propertyType: [this.listingData.propertyType],
      isOwner: [this.listingData.isOwner],
      numBeds: [this.listingData.numBeds],
      numBaths: [this.listingData.numBaths],
      propertySize: [this.listingData.propertySize],
      numUnits: [this.listingData.numUnits],
      holdType: [this.listingData.holdType],
      funishedState: [this.listingData.funishedState],

      shareToProperDi: [true],
      price: [this.listingData.price],
      preferredContact: [this.listingData.contact.preferredContact],
      userId: [this.authService.profileState.value.id],
      hidePhoneNumbers: [this.listingData.contact.hidePhoneNumber],
      askingPriceUponRequest: [this.listingData.askingPriceUponRequest],
      uponRequestAnnualRevenu: [this.listingData.uponRequestAnnualRevenu],
      uponRequestAnnualCashFlow: [this.listingData.uponRequestAnnualCashFlow],
      uponRequestPremisesRent: [this.listingData.uponRequestPremisesRent],
      uponRequestGrossIncomePerMonth: [this.listingData.uponRequestGrossIncomePerMonth],
      numParking: this.listingData.numParking,
      contact: [this.listingData.contact],
      address: [this.listingData.address],
      listingDetails: [this.listingData.listingDetailLanguage],
      showPropertyLocationOnMap: [this.listingData.showPropertyLocationOnMap],
      location: [this.listingData.address.addressDetailLanguages[0].formattedAddress, [Validators.required]],
      engTitle: ['english title', [Validators.maxLength(70), Validators.minLength(2)]],
      thTitle: ['thai title', [Validators.maxLength(70), Validators.minLength(2)]],
      engDescription: ['english description', [Validators.minLength(6)]],
      thDescription: ['thail description', [Validators.minLength(2)]],
      contactName: [this.listingData.contact.name, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
      contactEmail: [this.listingData.contact.email,
      [Validators.required, Validators.maxLength(70), Validators.minLength(2), Validators.email]],
      contactPhone: [this.listingData.contact.phoneNumber, [Validators.required]],
      wareHouse: [this.listingData.wareHouse],
      totalRentPerMonth: [this.listingData.totalRentPerMonth],
      zoningUses: [this.listingData.zoningUses],
      occupancyRate: [this.listingData.occupancyRate],
      ageYear: [this.listingData.ageYear],
      grossIncomePerMonth: [this.listingData.grossIncomePerMonth],
      annualCashFlow: [this.listingData.annualCashFlow],
      annualRevenue: [this.listingData.annualRevenue],
      numEmployees: [this.listingData.numEmployees],
      listingStatus: [this.listingData.listingStatus],
      landSize: [this.listingData.landSize],
      minimumYearContract: [this.listingData.minimumYearContract],
      transferCost: [this.listingData.transferCost],
    });
    this.listingData.listingDetailLanguage.forEach(element => {
      if (element.languageId == 1) {
        this.addListingForm.controls.engTitle.setValue(element.title);
        this.addListingForm.controls.engDescription.setValue(element.description);
      }
      if (element.languageId == 2) {
        this.addListingForm.controls.thTitle.setValue(element.title);
        this.addListingForm.controls.thDescription.setValue(element.description);
      }
    });
    this.addListingForm.controls.listingDetails.setValue(this.listingData.listingDetails);
    this.selectedPropertyType = this.listingData.listingType;
    this.selectedType = this.enumPropertyService.getEnumById('propertyTypeResidentialType', null, this.listingData.propertyType, false).name;

    this.selectedSubType = this.listingData.residentialType ? this.enumPropertyService.getEnumById('propertyTypeResidentialType', this.listingData.propertyType, this.listingData.residentialType, false).name : null;
    this.keywords = this.listingData.keyWords ? this.listingData.keyWords.match(/[^,\s?]+/g) : [];
    const prepairMedia = [];
    this.listingData.medias.forEach(element => {
      prepairMedia.push({
        name: element.filePath, // file name
        type: 'image/*', // file MIME type
        id: element.id,
        file: this.storage + '/listing/' + element.filePath, // file path
        data: {
          imageId: element.id,
          readerCrossOrigin: "'Anonymous'"
        },
        uploaded: true
      });
      // tslint:disable-next-line: radix
      this.mediaIds.push(parseInt(element.id));
    });

    imageUploadConst.files = prepairMedia;
    console.log("this.fileupload", this.fileupload);
    this.changeDetection.detectChanges();



    this.packageFromListingData = this.listingData.listingProducts;


    this.checkUponRequest();
  }

  checkUponRequest() {
    this.addListingForm.get('askingPriceUponRequest').valueChanges.subscribe(checked => {
      checked ? this.addListingForm.get('price').disable() : this.addListingForm.get('price').enable()
    });
    this.addListingForm.get('uponRequestAnnualRevenu').valueChanges.subscribe(checked => {
      checked ? this.addListingForm.get('annualRevenue').disable() : this.addListingForm.get('annualRevenue').enable()
    });
    this.addListingForm.get('uponRequestAnnualCashFlow').valueChanges.subscribe(checked => {
      checked ? this.addListingForm.get('annualCashFlow').disable() : this.addListingForm.get('annualCashFlow').enable()
    });
    this.addListingForm.get('uponRequestPremisesRent').valueChanges.subscribe(checked => {
      checked ? this.addListingForm.get('totalRentPerMonth').disable() : this.addListingForm.get('totalRentPerMonth').enable()
    });
    this.addListingForm.get('uponRequestGrossIncomePerMonth').valueChanges.subscribe(checked => {
      checked ? this.addListingForm.get('grossIncomePerMonth').disable() : this.addListingForm.get('grossIncomePerMonth').enable()
    });
    this.addListingForm.value.location ? this.addListingForm.get('location').enable() : this.addListingForm.get('location').disable();
    if (!this.addListingForm.value.engTitle.length && !this.addListingForm.value.thTitle.length) {
      this.addListingForm.get("engTitle").setValidators(Validators.required);
      this.addListingForm.get("thTitle").setValidators(Validators.required);
    }
    if (!this.addListingForm.value.thDescription.length && !this.addListingForm.value.engDescription.length) {
      this.addListingForm.get("thDescription").setValidators(Validators.required);
      this.addListingForm.get("engDescription").setValidators(Validators.required);
    } else {
      if ((!this.addListingForm.value.thDescription.length && !this.addListingForm.value.engDescription.length) && this.addListingForm.value.thTitle.length) {
        this.addListingForm.get("thDescription").setValidators(Validators.required);
      }
      if ((!this.addListingForm.value.thDescription.length && !this.addListingForm.value.engDescription.length) && this.addListingForm.value.engTitle.length) {
        this.addListingForm.get("engDescription").setValidators(Validators.required);
      }
    }
  }
  add(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.keywords.push(value.trim());

    }
    if (input) {
      input.value = '';
    }
  }

  remove(keyword: any): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }
  assignFile(): any {
    const THIS = this;
    imageUploadConst.files = [];
    imageUploadConst.onFileRead = (item => {
      if (item.input) {
        if (this.isAuthentic) {
          THIS.fileUploadStart(item);
        } else {
          imageUploadConst.files = [];

          item.deleted = true;
          item.remove();
          THIS.utilService.showToast('error', "Please Login to upload Image!")
        }
      } else {
        item.uploaded = true;
        item.id = item.data.imageId;
      }
    });
    imageUploadConst.onRemove = (item => {

      if (!item.deleted) {
        THIS.deletePhoto(item);
      }
    });
    imageUploadConst.dialogs.alert = (message => {
      THIS.utilService.showToast('success', message);
    });
    imageUploadConst.sorter.onSort = (list => {

      if (list.length) {
        THIS.sortingFile(list);
      }
    });
    imageUploadConst.getAllEvents = (something => {

    });
    imageUploadConst.editor.onSave = ((dataUrl, item) => {
      if (this.isAuthentic) {
        THIS.removeFromFiles(item);
        const blob = THIS.mediaService.dataURLtoFile(dataUrl, 'a.png');
        item.file = blob;
        if (blob) {

          THIS.fileUploadStart(item);

        }
      } else {
        THIS.utilService.showToast('error', "Please Login to upload Image!")
      }
    });
    setTimeout(() => {

      this.fileupload = (window as any).$('.fileUploader').fileuploader(imageUploadConst);

    }, 1000);
  }
  sortingFile(list) {
    this.mediaIds = [];
    list.forEach(element => {
      this.mediaIds.push(element.id);
    });
  }
  removeFromFiles(fileName) {

    imageUploadConst.files.forEach((element, index) => {
      if (element.id === fileName.id) {
        imageUploadConst.files.splice(index, 1);
      }
    });
    this.mediaIds.forEach((element, index) => {
      if (element === fileName.id) {
        this.mediaIds.splice(index, 1);
      }
    });
    this.changeDetection.detectChanges();
  }


  fileUploadStart(item) {
    const THIS = this;
    const formData = new FormData();
    formData.append('file', item.file);
    THIS.fileUploadProgress = '0';
    THIS.mediaService.addMedia(10, formData).pipe(takeUntil(THIS.destroy$)).subscribe((events: any) => {
      if (events.type === HttpEventType.UploadProgress) {
        THIS.fileUploadProgress = Math.round(events.loaded / events.total * 100);
        THIS.changeDetection.detectChanges();
        const progressBar = item.html.find('.progress-bar2');
        if (progressBar.length > 0) {
          progressBar.show();
          progressBar.find('span').html(THIS.fileUploadProgress + '%');
          progressBar.find('.fileuploader-progressbar .bar').width(THIS.fileUploadProgress + '%');
        }
      } else if (events.type === HttpEventType.Response) {
        THIS.fileUploadProgress = '';
        item.name = events.body.data.name;
        item.id = events.body.data.id;
        imageUploadConst.files.push({ id: events.body.data.id });
        item.uploaded = true;
        item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
        setTimeout(() => {
          item.html.find('.progress-bar2').fadeOut(400);
        }, 400);
        THIS.mediaIds = [...THIS.mediaIds, ...events.body.data.id];
        THIS.changeDetection.detectChanges();
      }
    }, ({ error }) => {
      error ? THIS.utilService.showToast('error', error.error) : THIS.utilService.showToast('error', 'Please login to upload photo');
    });
  }




  deletePhoto(fileName: any) {
    this.mediaService.deleteMedia(fileName.id, 10).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.medias = this.medias.filter((item: any) => item.id !== fileName.id);
      this.mediaIds = this.mediaIds.filter((item: any) => item !== fileName.id);
      this.changeDetection.detectChanges();
    }, () => {
      this.utilService.showToast('error', 'Error on media update');
    });
  }
  orderMedia(media: string[]) {
    this.medias = [...media];
  }

  submit() {
    this.submited = true;
    if (this.isAuthentic) {
      this.loadingScreenService.startLoading();

      if (!this.addListingForm.value.engTitle.length && !this.addListingForm.value.thTitle.length) {
        this.addListingForm.get("engTitle").setValidators(Validators.required);
        this.addListingForm.get("thTitle").setValidators(Validators.required);
      } else {
        this.addListingForm.get("engTitle").setErrors(null);
        this.addListingForm.get("thTitle").setErrors(null);
      }
      if (!this.addListingForm.value.thDescription.length && !this.addListingForm.value.engDescription.length) {
        this.addListingForm.get("thDescription").setValidators(Validators.required);
        this.addListingForm.get("engDescription").setValidators(Validators.required);
      } else {
        if ((!this.addListingForm.value.thDescription.length && this.addListingForm.value.thTitle.length) || (this.addListingForm.value.thDescription.length && !this.addListingForm.value.thTitle.length)) {
          this.addListingForm.get("thDescription").setValidators(Validators.required);
        } else {
          this.addListingForm.get("engDescription").setErrors(null);
        }
        if ((!this.addListingForm.value.engDescription.length && this.addListingForm.value.engTitle.length) || (this.addListingForm.value.engDescription.length && !this.addListingForm.value.engTitle.length)) {
          this.addListingForm.get("engDescription").setValidators(Validators.required);
        } else {
          this.addListingForm.get("thDescription").setErrors(null);
        }
      }
      const engAdded = (el) => el.languageId === 1;
      const thaiAdded = (el) => el.languageId === 2;
      if (this.listingDetails.some(engAdded) && (this.addListingForm.value.engTitle.length && this.addListingForm.value.engDescription.length)) {
        this.listingDetails.map(x => {
          if (x.languageId === 1) {
            x.title = this.addListingForm.value.engTitle;
            x.description = this.addListingForm.value.engDescription;
            x.languageId = 1;
          }
          return x;
        });
      } else if (this.addListingForm.value.engTitle.length && this.addListingForm.value.engDescription.length) {
        // tslint:disable-next-line: max-line-length
        this.listingDetails.push({ title: this.addListingForm.value.engTitle, description: this.addListingForm.value.engDescription, languageId: 1 });
      }
      if (this.listingDetails.some(thaiAdded) && (this.addListingForm.value.thTitle.length && this.addListingForm.value.thDescription.length)) {
        this.listingDetails.map(x => {
          if (x.languageId === 2) {
            x.title = this.addListingForm.value.thTitle;
            x.description = this.addListingForm.value.thDescription;
            x.languageId = 2;
          }
          return x;
        })
      } else if (this.addListingForm.value.thTitle.length && this.addListingForm.value.thDescription.length) {
        // tslint:disable-next-line: max-line-length
        this.listingDetails.push({ title: this.addListingForm.value.thTitle, description: this.addListingForm.value.thDescription, languageId: 2 });
      }

      this.addListingForm.controls.listingDetails.setValue(this.listingDetails);

      this.listing = {
        funishedState: this.addListingForm.value.funishedState ? this.addListingForm.value.funishedState : null,
        holdType: this.addListingForm.value.holdType ? this.addListingForm.value.holdType : null,
        keyWords: this.keywords.length ? this.keywords.toString() : null,
        landSize: this.utilService.removeComma(this.addListingForm.value.landSize),
        listingType: this.selectedPropertyType,
        numBaths: this.utilService.removeComma(this.addListingForm.value.numBaths),
        numBeds: this.utilService.removeComma(this.addListingForm.value.numBeds),
        numUnits: this.utilService.removeComma(this.addListingForm.value.numUnits),
        price: this.utilService.removeComma(this.addListingForm.value.price),
        propertySize: this.utilService.removeComma(this.addListingForm.value.propertySize),
        // tslint:disable-next-line: max-line-length

        shareToProperDi: this.shareToProperdi,
        userId: this.authService.profileState.value.id,
        isOwner: this.addListingForm.value.isOwner,
        propertylocationonmap: this.addListingForm.value.showPropertyLocationOnMap,
        mediaIds: this.mediaIds,
        numParking: this.utilService.removeComma(this.addListingForm.value.numParking) ? this.utilService.removeComma(this.addListingForm.value.numParking) : null,
        propertyType: this.enumPropertyService.getEnumById('propertyTypeResidentialType', null, this.selectedType, true).id,
        residentialType: this.selectedSubType ? this.enumPropertyService.getEnumById('propertyTypeResidentialType', this.selectedType, this.selectedSubType, true).id : null,
        address: this.addListingForm.value.address,
        minimumYearContract: this.utilService.removeComma(this.addListingForm.value.minimumYearContract),
        transferCost: this.utilService.removeComma(this.addListingForm.value.transferCost),
        availableForRent: this.addListingForm.value.availableForRent,
        listingStatus: this.addListingForm.value.listingStatus,
        askingPriceUponRequest: this.addListingForm.value.askingPriceUponRequest,
        uponRequestAnnualRevenu: this.addListingForm.value.uponRequestAnnualRevenu,
        uponRequestAnnualCashFlow: this.addListingForm.value.uponRequestAnnualCashFlow,
        uponRequestPremisesRent: this.addListingForm.value.uponRequestPremisesRent,
        uponRequestGrossIncomePerMonth: this.addListingForm.value.uponRequestGrossIncomePerMonth,
        contact: {
          name: this.addListingForm.value.contactName,
          email: this.addListingForm.value.contactEmail,
          phoneNumber: this.addListingForm.value.contactPhone,
          hidePhoneNumber: this.addListingForm.value.hidePhoneNumbers,
          preferredContact: this.addListingForm.value.preferredContact
        },
        listingDetailLanguage: this.addListingForm.value.listingDetails,
        wareHouse: this.addListingForm.value.wareHouse ? this.addListingForm.value.wareHouse : null,
        totalRentPerMonth: this.utilService.removeComma(this.addListingForm.value.totalRentPerMonth),
        zoningUses: this.addListingForm.value.zoningUses,
        occupancyRate: this.utilService.removeComma(this.addListingForm.value.occupancyRate),
        ageYear: this.utilService.removeComma(this.addListingForm.value.ageYear),
        grossIncomePerMonth: this.utilService.removeComma(this.addListingForm.value.grossIncomePerMonth),
        annualCashFlow: this.utilService.removeComma(this.addListingForm.value.annualCashFlow),
        annualRevenue: this.utilService.removeComma(this.addListingForm.value.annualRevenue),
        numEmployees: this.utilService.removeComma(this.addListingForm.value.numEmployees),
        listingProducts: []
      };
      if (this.addListingForm.invalid) {
        this.findInvalidControls();
        this.loadingScreenService.stopLoading();
        this.utilService.showToast('error', 'You need to provide more information about the listing!');
        return;
      }
      if (!this.mediaIds.length) {
        this.loadingScreenService.stopLoading();
        this.utilService.showToast('error', 'You need upload Image for your listing!');
        return;
      }
      if (this.addedPakcage) {

        this.addedPakcage.forEach(element => {
          if (element.from == 'new') {
            const productObj = {
              productId: element.selectedMainPackage,
              bannerText: element.customText ? element.customText : element.selectedItem,
              isCustomBannerText: element.customText ? true : false,
              productDuration: element.selectedPackageItemDetails
            };
            if (element.selectedMainPackage == 4) {
              productObj.bannerText = "";
            }
            this.listing.listingProducts.push(productObj);
          }
        });
      }



      if (this.listingData) {
        this.listing.id = this.listingData.id;
        this.updateListing();
      } else {
        this.postListing();
      }
    } else {
      this.utilService.showToast('error', 'You need to login before posting listing!');
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.addListingForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    // return invalid;
  }

  postListing() {
    this.listingService.submitListing(this.listing).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {

      this.loadingScreenService.stopLoading();
      this.utilService.showToast('success', `Your listing submitted with listin Id #${data.id} for review!`);
      this.changeDetection.detectChanges();
      this.listingService.listingData = null;
      if (data.billId) {
        const obj = {
          billId: data.billId,
          listingId: data.id,
          from: 'new'
        }
        this.paymentService.fullPaymentObj = obj;
        this.router.navigate([`/payment-confirmation/${data.billId}`]);
      } else {
        this.router.navigate([`/listing-detail/${data.id}`]);
      }
    }, (err) => {

      this.loadingScreenService.stopLoading();
      this.utilService.showToast('error', err);
    });
  }

  updateListing() {
    this.listingService.updateListing(this.listing).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      this.loadingScreenService.stopLoading();
      this.utilService.showToast('success', `Your listing updated`);
      this.changeDetection.detectChanges();
      if (data.billId) {
        const obj = {
          billId: data.billId,
          listingId: data.id,
          from: 'old'
        }
        this.paymentService.fullPaymentObj = obj;
        this.router.navigate([`/payment-confirmation/${data.billId}`]);
      } else {
        this.router.navigate([`/listing-detail/${data.id}`]);
      }
      this.listingService.listingData = null;
    }, (err) => {
      this.loadingScreenService.stopLoading();
      this.utilService.showToast('error', err);
    });
  }

  openMapDialog() {

    this.dialog.open(GenericDialogComponent, {
      panelClass: 'map-dialog',
      minHeight: '500px',
      minWidth: '453px',
      data: { showMap: true },
    }).afterClosed().subscribe(data => {
      if (data) {
        console.log("data", data);
        let address = {
          "latitude": data[0].latitude,
          "longitude": data[0].longitude,
          "addressDetailLanguages": [

          ],
          "postCode": data[0].postCode,
          "subDistrictId": data[0].subDistrictId,
          "districtId": data[0].districtId,
          "provinceId": data[0].provinceId,
          "countryId": data[0].countryId,
          "area": data[0].area,
        }
        data.forEach(element => {
          address.addressDetailLanguages.push({ languageId: element.languageId, formattedAddress: element.formattedAddress })
        });
        this.addListingForm.controls.address.setValue(address);
        this.addListingForm.controls.location.setValue(data[0].formattedAddress);
        this.addListingForm.get('location').enable();
      }
    });
  }

  getPackage(ev) {
    this.addedPakcage = ev;
  }

  addForMockupPayment() {

    // .forEach(element => {
    //   let packageObj = {
    //     ListingId: 35,
    //     PackageItemDetailId: element.selectedMainPackage,
    //     PackageTypeId: parseInt(element.selectedPackageItemDetails),
    //     PaymentMethodId: 1
    //   }
    //   this.paymentService.createOrder(packageObj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
    //     
    //   })
    // });
    // this.router.navigate([`/payment-confirmation`,this.addedPakcage]);
  }

  mockup() {
    if (this.isAuthentic) {

      this.addListingForm = this.fb.group({
        propertyType: [1],
        isOwner: [false],
        numBeds: [2, [Validators.required]],
        numBaths: [1, [Validators.required]],
        propertySize: [222, [Validators.required]],
        numUnits: [3, [Validators.required]],
        holdType: [1, [Validators.required]],
        funishedState: [1, [Validators.required]],
        shareToProperDi: [true],
        price: [50000, [Validators.required]],
        preferredContact: [1, [Validators.required]],
        userId: [this.authService.profileState.value.id],
        hidePhoneNumbers: [false],
        askingPriceUponRequest: [false],
        numParking: this.addListingForm.value.numParking,
        contact: '',
        address: [null],
        listingDetails: [null],
        showPropertyLocationOnMap: [true],
        location: [null, [Validators.required]],
        engTitle: ['english title', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
        thTitle: ['thai title', [Validators.maxLength(70), Validators.minLength(2)]],
        engDescription: ['english description', [Validators.required, Validators.minLength(6)]],
        thDescription: ['thail description', [Validators.minLength(2)]],
        contactName: ['pradyot', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
        contactEmail: ['pradyot.ghosh@properdi.com',
          [Validators.required, Validators.maxLength(70), Validators.minLength(2), Validators.email]],
        contactPhone: ['1234567890', [Validators.required, Validators.maxLength(10), Validators.minLength(2)]],
        wareHouse: [null],
        totalRentPerMonth: [null],
        zoningUses: [null],
        occupancyRate: [null],
        ageYear: [null],
        grossIncomePerMonth: [null],
        annualCashFlow: [null],
        annualRevenue: [null],
        numEmployees: [null],
        listingStatus: [1],
        uponRequestPremisesRent: [false]
      });

      const engAdded = (el) => el.languageId === 1;
      const thaiAdded = (el) => el.languageId === 2;
      if (this.listingDetails.some(engAdded)) {
        this.listingDetails.map(x => {
          if (x.languageId === 1) {
            x.title = this.addListingForm.value.engTitle;
            x.description = this.addListingForm.value.engDescription;
            x.languageId = 1;
          }
          return x;
        });
      } else {
        // tslint:disable-next-line: max-line-length
        this.listingDetails.push({ title: this.addListingForm.value.engTitle, description: this.addListingForm.value.engDescription, languageId: 1 });
      }
      if (this.addListingForm.value.thTitle && this.addListingForm.value.thDescription) {
        if (this.listingDetails.some(thaiAdded)) {
          this.listingDetails.map(x => {
            if (x.languageId === 2) {
              x.title = this.addListingForm.value.thTitle;
              x.description = this.addListingForm.value.thDescription;
              x.languageId = 2;
            }
            return x;
          });
        } else {
          // tslint:disable-next-line: max-line-length
          this.listingDetails.push({ title: this.addListingForm.value.thTitle, description: this.addListingForm.value.thDescription, languageId: 2 });
        }
      }
      this.addListingForm.controls.listingDetails.setValue(this.listingDetails);
    } else { this.utilService.showToast('error', 'You need to login before posting listing!'); }

  }
  trackElement(index: number, element: any) {
    return element != 3 ? element.id : null
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.listingService.listingData = null;
  }

}
