import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'td-dashboard-manage-my-ads',
  templateUrl: './dashboard-manage-my-ads.component.html',
  styleUrls: ['./dashboard-manage-my-ads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardManageMyAdsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  listingData: any[] = [];
  search: FormGroup = this.fb.group({
    searchText: [''],
    listingType: [null],
    propertyType: [null]
  })
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 2
  }
  
  constructor(private dashboardService: DashboardService, private changeDetection: ChangeDetectorRef, private utilService: UtilService, private fb: FormBuilder) { }

  ngOnInit() {
    this.getAllListing();
  }

  getAllListing() {
    let obj = {
      listingType: this.search.value.listingType != 'null' ? this.search.value.listingType : null,
      filter: this.search.value.searchText,
      favorites: false,
      propertyType: this.search.value.propertyType != 'null' ? this.search.value.propertyType : null
    }
    this.dashboardService.getAllListing(this.currentPage, 10, obj)
      .pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {

        this.listingData = data;
        this.changeDetection.detectChanges();
        this.pageInfo = pageInfo;
        this.changeDetection.detectChanges();

      })
  }
  deleteEventCalled(ev: number) {

    this.utilService.showAcceptDeclineDialog("You are about to delete a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {

      if (accepet) {
        this.dashboardService.deleteListing(ev).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          this.utilService.showToast('success', `#${ev}Listing Deleted!`);
          this.currentPage = 1;
          this.getAllListing();
        }, (err) => {
          console.error(err)
          this.utilService.showToast('error', `There is an error deleting listing! Try again later.`);
        })
      }
    })
  }

  clearSearch() {
    this.search.controls.searchText.setValue('');
    this.search.controls.listingType.setValue(null);
    this.searchForListing();
  }

  searchForListing() {
    this.currentPage = 1;
    this.getAllListing()
  }

  updateAllListing() {
    this.dashboardService.activeListingFor30Days(null).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.utilService.showToast('success', 'You all Expired listing updated!')
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllListing();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  

}
