<div class="articles-screen">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 20px;">
    <h1 *ngIf="!article" translate>Articles</h1>
    <h1 *ngIf="article"><span>{{article.title}}</span>
    </h1>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <h3 *ngIf="!article" translate>Sort by</h3>
      <select *ngIf="!article" [(ngModel)]="assDec" (change)="getAllArticle()">
        <option value='false' translate>Newest</option>
        <option value='true' translate>Oldest</option>
      </select>
      <a (click)="article=null" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <i *ngIf="article" class="mdi mdi-view-module" style="margin-top: 10px!important;font-size: 36px;"></i>
        <span *ngIf="article" style="font-size: 18px;" translate>View All articles</span>
      </a>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="!article&&articleData">
    <div class="article-card" *ngFor="let item of articleData" fxFlex="22">
      <div class="image" [ngStyle]="{'background': 'url('+storage+'/article/'+item.media+')'}"></div>
      <div class="add-padding">
        <h3>{{item?.title}}</h3>
      </div>
      <div class="add-padding" fxLayout="row" fxLayoutAlign="space-between center"
        style="height: 10px;margin: 0 0 10px 5px;">
        <p class="date">{{item.date | date}}</p>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="showFull(item)"><span translate>Continue Reading</span> <i
            class="mdi mdi-chevron-right"></i></a>
      </div>
    </div>

  </div>
  <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)" *ngIf="!article&&articleData&&numberOfpage.length!=0" > </td-pagination>
  
  
  <div *ngIf="article" class="article-card" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px"
    style="padding: 20px;">

    <div fxFlex="60" class="image"
      [ngStyle]="{'background': 'url('+storage+'/article/'+article.media+')','height': '500px'}">
    </div>
    <div fxFlex="40" fxLayout="column" fxLayoutAlign="start stretch">
      <p class="date" style="text-align: right;">{{article.date  | date}}</p>
      <p class="description">{{article.description}}</p>
      <div fxLayout="row" fxLayoutAlign="end" class="share-div" fxLayoutGap="20px">
        <a (click)="shareToFb(article)"><img src="assets/images/social-icons/facebook.png" /></a>
        <a (click)="shareToLine(article)"> <img src="assets/images/social-icons/line.png" /></a>
        <a target="_blank"
          href="https://twitter.com/intent/tweet?url={{buildUrl(article)}}&text={{article?.title}}%0A{{article?.description.substr(0, 50)}}%0A&hashtags=thailandcommercial"><img
            src="assets/images/social-icons/twitter.png" /></a>
        <a target="_blank"
          href="http://www.linkedin.com/shareArticle?mini=true&url={{buildUrl(article)}}&title={{article?.title}}&summary={{article?.description.substr(0, 50)}}&source=thailandcommercial.com"><img
            src="assets/images/social-icons/linkedin.png" /></a>
      </div>
    </div>
  </div>
  <div *ngIf="!article&&!articleData" class="article-card" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px"
    style="padding: 20px;">
    <h1 translate>No articles to show </h1>
  </div>
</div>