/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shared/components/loading-component/component/loading-screen.component.ngfactory";
import * as i2 from "../shared/components/loading-component/component/loading-screen.component";
import * as i3 from "../shared/services/loading/loading.service";
import * as i4 from "../layout/containers/layout.component.ngfactory";
import * as i5 from "../layout/containers/layout.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/cdk/layout";
import * as i8 from "../core/service/enum-property/enum-property.service";
import * as i9 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-screen", [], null, null, null, i1.View_LoadingScreenComponent_0, i1.RenderType_LoadingScreenComponent)), i0.ɵdid(1, 4374528, null, 0, i2.LoadingScreenComponent, [i3.LoadingScreenService, i0.ElementRef, i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-layout", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LayoutComponent_0, i4.RenderType_LayoutComponent)), i0.ɵdid(3, 770048, null, 0, i5.LayoutComponent, [i6.Router, i7.MediaMatcher, i8.EnumPropertyService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i9.AppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
