import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { ActivatedRoute } from '@angular/router';
var DashboardListingReportedComponent = /** @class */ (function () {
    function DashboardListingReportedComponent(dashboardService, changeDetection, utilService, dialog, enumPropertyService, activatedRoute) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.dialog = dialog;
        this.enumPropertyService = enumPropertyService;
        this.activatedRoute = activatedRoute;
        this.destroy$ = new Subject();
        this.messageList = [];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
        this.message = null;
        this.dialogRef = null;
        this.reportedId = null;
    }
    DashboardListingReportedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enumPropertyService.getEnumProperty().subscribe(function (data) {
            _this.getListOfMessages();
        });
        // this.reportedId=this.activatedRoute.snapshot.paramMap.get('id');
    };
    DashboardListingReportedComponent.prototype.getListOfMessages = function () {
        var _this = this;
        this.dashboardService.getReportResponseFromAdmin().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.messageList = data;
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        }, function (err) {
        });
    };
    DashboardListingReportedComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getListOfMessages();
    };
    DashboardListingReportedComponent.prototype.deleteEventCalled = function (messageId) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a messsage!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteMessage(messageId).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + messageId + "Message Deleted!");
                    _this.currentPage = 1;
                    _this.getListOfMessages();
                    _this.changeDetection.detectChanges();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardListingReportedComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardListingReportedComponent.prototype.getType = function (id) {
        return this.enumPropertyService.getEnumById('reportType', null, id, false).name;
    };
    DashboardListingReportedComponent.prototype.showMessageDialog = function (message) {
        this.message = message;
        this.dialogRef = this.dialog.open(this.secondDialog, { panelClass: 'response-dialog', data: { message: message } });
    };
    DashboardListingReportedComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    return DashboardListingReportedComponent;
}());
export { DashboardListingReportedComponent };
