<mat-dialog-content [class.login-register-dialog]="notDialog">
  <form [formGroup]="loginRegisterForm" (ngSubmit)="onSubmit()">
    <div *ngIf="!forgetPassword">
      <div class="selection-button" fxLayout="row" fxLayoutAlign="center center">
        <a [class.selectedTab]="selectedLoginTab=='signIn'" (click)="selectedLoginTab='signIn'" fxLayout="row"
          fxLayoutAlign="center center">{{'Sign In' | translate}}</a>
        <a [class.selectedTab]="selectedLoginTab=='register'" (click)="selectedLoginTab='register'" fxLayout="row"
          fxLayoutAlign="center center">{{'Register' | translate}}</a>
      </div>
      <div class="login-div" fxLayout="column" fxLayoutAlign="center center">
        <a class="facebook" fxLayout="row" fxLayoutAlign="space-evenly center" (click)="loginWithFacebook()">
          <i class="mdi mdi-facebook"></i>
          {{selectedLoginTab=='signIn'?'LOGIN':'REGISTER' |translate}} {{'WITH FACEBOOK'|translate}}
        </a>

        <a class="linkedin" fxLayout="row" fxLayoutAlign="space-evenly center" (click)="loginWithLinkdinAccount()">
          <i class="mdi mdi-linkedin"></i>
          {{selectedLoginTab=='signIn'?'LOGIN':'REGISTER' | translate}} {{'WITH LINKEDIN' | translate}}
        </a>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <hr>
        <p>{{'or' | translate}}</p>
        <hr>
      </div>
      <div class="input-div" fxLayout="column" fxLayoutAlign="center center">
        <input type="text" placeholder="Email" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': f.email.errors }" />
        <div *ngIf="f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
          <div *ngIf="f.email.errors.email">{{'Email must be a valid email address' | translate}}</div>
        </div>
        <input type="password" placeholder="Password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': f.password.errors }" />
        <div *ngIf="f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">{{'Password is required' | translate}}</div>
          <div *ngIf="f.password.errors.minlength">{{'Password must be at least 6 characters' | translate}}</div>
        </div>
        <button mat-flat-button class="login-button" [color]="'primary'"
          [disabled]="submitted">{{selectedLoginTab=='signIn'?'LOGIN':'REGISTER' | translate}}</button>
        <a class="forget-password" (click)="forgetPassword=true">{{'Forgot password ?' | translate}}</a>
      </div>
    </div>
    <div *ngIf="forgetPassword">
      <div class="input-div" fxLayout="column" fxLayoutAlign="center center">
        <input type="text" placeholder="Email" formControlName="email" class="form-control"
          [ngClass]="{ 'is-invalid': f.email.errors }" />
        <div *ngIf="f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.required">{{'Email is required' | translate}}</div>
          <div *ngIf="f.email.errors.email">{{'Email must be a valid email address' | translate}}</div>
        </div>
        <re-captcha [size]="'invisible'" #invisibleCaptcha [siteKey]="reCaptchaKey"></re-captcha>

        <button mat-flat-button class="login-button" [color]="'primary'"
          [disabled]="submitted">{{'Send Password Link' | translate}}</button>
        <a class="forget-password" (click)="forgetPassword=false">{{'Back To Login' | translate}}</a>
      </div>
    </div>
  </form>
</mat-dialog-content>