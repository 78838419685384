<div class="payment-success" #paymentSuccess fxLayout="column" fxLayoutAlign="space-around center" *ngIf="selectedPackage">
  <i class="mdi mdi-check-circle-outline"></i>
  <h1 
  [ngStyle]="{'width':paymentMethods==2 ? '500px' : '',
  'font-size':paymentMethods==2 ? '23px' : ''}">{{paymentMethods==2?'We are checking payment with the bank. Once payment confirmed, your purchased package will be live. Thank you!':'Payment Success'}}</h1>
  <div class="payment-confirmation-sidebar" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div class="header" fxLayout="column" fxLayoutAlign="center start">
      <div class="price-div" *ngIf="selectedPackage?.orderItems">{{getSum(7*getSum(0)/100)+fees| number: '1.2-2'}} THB</div>
      <p translate>Total Payment</p>
    </div>
    <ng-container *ngIf="selectedPackage">

      <div class="package-div" fxLayout="column" fxLayoutAlign="center stretch"
        *ngFor="let item of selectedPackage?.orderItems">
        <div class="package-div-box" fxLayout="column" fxLayoutAlign="center stretch">
          <div class="first-div" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="package-name-div">{{item.product}}</div>
            <div class="price-div">{{item.amount}} THB</div>
          </div>
          <div class="second-div" *ngIf="item.name!='Hilighted Ad'">
            {{item.bannerText}}
          </div>
        </div>
      </div>
    </ng-container>
    <div class="total-price-div" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center end">
      <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
        <div class="first-div" translate>Sub Total</div>
        <div class="second-div">{{getSum(0)}} THB</div>
      </div>
      <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center"
        *ngIf="fees&&fixAmount">
        <div class="first-div" translate>Service fee</div>
        <div class="second-div">{{fees}} THB</div>
      </div>
      
      <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
        <div class="first-div" translate>7% VAT</div>
        <div class="second-div">{{7*getSum(0)/100 | number: '1.2-2'}} THB</div>
      </div>
      <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center"
        *ngIf="fees&&!fixAmount">
        <div class="first-div" translate>Bank Service fee</div>
        <div class="second-div">{{fees}} THB</div>
      </div>
      <div class="total-price" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly center">
        <div class="first-div" translate>Total</div>
        <div class="second-div" style="font-weight: bold;">{{getSum(7*getSum(0)/100)+fees | number: '1.2-2'}} THB</div>
      </div>
    </div>
  </div>
  <div class="button-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-around center">
    <button mat-flat-button id="paypal-button" [color]="'primary'" [routerLink]="['/add-listing']">{{'Start Over'|translate}}</button>
    <!-- <button mat-flat-button id="paypal-button" [color]="'primary'" [routerLink]="['/listing-detail',selectedPackage?.orderItems[0]?.listingId]" >View Listing</button> -->
    <button mat-flat-button id="paypal-button" [color]="'primary'" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:['home-dashboard']}}]">{{'My Dashboard'|translate}}</button>
    
    <!-- <button mat-flat-button id="paypal-button" [color]="'primary'" [routerLink]="['/']">Homepage</button> -->
  </div>
  
</div>