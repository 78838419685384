<div class="manage-users" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
  <h4>Manage Users</h4>
  <div class="filter-div" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center">
    <input fxFlex="60" type="text" placeholder="Search Name" [(ngModel)]="filter" />
    <button mat-mini-fab color="primary" style="right: 70px;bottom: 1px;" 
      (click)="currentPage=0;getAllUsers();searchClicked=true;" *ngIf="!searchClicked">
      <i class="mdi mdi-magnify" style="color:white;"></i>
    </button>
    <button mat-mini-fab color="primary" style="right: 70px;bottom: 1px;" 
      (click)="searchClicked=false;filter=null;currentPage=0;getAllUsers();" *ngIf="searchClicked">
      <i class="mdi mdi-close" style="color:white;"></i>
    </button>
    <select fxFlex="20" [(ngModel)]="platform" (change)="currentPage=0;getAllUsers()">
      <option value='null'>Show All</option>
      <option value='1'>ThailandCommerce</option>
      <option value='2'>ProperDee</option>
      <option value='3'>Ilovecondo</option>
      <option value='4'>BahtSold</option>
    </select>
    <select fxFlex="20" [(ngModel)]="assDec" (change)="currentPage=0;getAllUsers()">
      <option value='false'>Newest</option>
      <option value='true'>Oldest</option>
    </select>
  </div>
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <i class="mdi mdi-circle" style="color:red;font-size: 20px;"></i>
      <div>Admin</div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <i class="mdi mdi-circle" style="color:#8cc63f;font-size: 20px;"></i>
      <div>User</div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
      <i class="mdi mdi-circle" style="color:purple;font-size: 20px;"></i>
      <div>Support</div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" *ngIf="users.length">
    <!-- <div style="width:99%;"> -->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px"
        style="border-bottom: 1px solid #ece2e2;padding-bottom: 10px;">
        <div style="width: 50px;"></div>
        <div style="font-weight: bolder;font-size: 20px;">First Name</div>
        <div style="font-weight: bolder;font-size: 20px;">Last Name</div>
        <div style="font-weight: bolder;font-size: 20px;">Date/From</div>
        <div style="width: 145px;">Action</div>
      </div>
    <!-- </div> -->
    <div *ngIf="users.length">
      <!-- <div style="width:99%;"> -->

        <div fxLayout="row" fxLayoutAlign="space-between center"
          style="border-bottom: 1px solid #ece2e2;padding-bottom: 20px;padding-top: 20px;" *ngFor="let user of users" >

          <div class="round-image" [ngStyle]="{
              'background-image': user.media?'url('+storage+user.media+')': '',
               'border':user.color ? '3px solid '+user.color:'3px solid red'
              }">
          </div>
          <span style="margin-right:20px;">{{user.firstName}}</span>
          <span style="margin-right:20px;">{{user.lastName}}</span>
          <span style="margin-right:20px;">
            {{user.createdAt | date}}<br>
            {{user.platformDescription}}
          </span>
          <div fxLayout="column" fxLayoutAlign="center center" style="width: 115px;">
            <button mat-flat-button [color]="'primary'" (click)="openUserDialog(user)" style="width: 115px;"><i
                class="mdi mdi-square-edit-outline" style="font-size: 22px;color: white;"></i></button>
            <button mat-flat-button [color]="'primary'" (click)="seeListing(user)" style="width: 115px;margin-top:10px;">See Listing</button>
          </div>
        </div>
      <!-- </div> -->
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </div>
  </div>
  <div *ngIf="!users.length">
    No user to show.
  </div>
</div>


<ng-template #updateUserDialog>
  <mat-dialog-content>
    <form [formGroup]="userForm" *ngIf="!submited">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap2="20px">
        <h3>User Details</h3>
        <button mat-icon-button (click)="close()"><i class="mdi mdi-close" style="width:20px"></i></button>
      </div>
      <div class="property-overview-fields" fxLayout="row wrap">

        <div class="property-title" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>First Name</h5>
            <input type="text" placeholder="First Name" [ngClass]="{ 'has-error': submited && f.firstName.errors }"
              formControlName="firstName" maxlength="70">

          </div>

        </div>
        <div class="property-title" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>Last Name</h5>
            <input type="text" placeholder="Last Name" [ngClass]="{ 'has-error': submited && f.lastName.errors }"
              formControlName="lastName" maxlength="70">

          </div>

        </div>
        <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>Email</h5>
            <input type="text" placeholder="Email" [ngClass]="{ 'has-error': submited && f.email.errors }"
              formControlName="email" maxlength="70">



          </div>

        </div>
        <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>Telephone</h5>
            <input type="text" placeholder="Telephone" [ngClass]="{ 'has-error': submited && f.telephone.errors }"
              formControlName="telephone" maxlength="70">


          </div>


        </div>
        <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">

          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>What is Your Role ?</h5>
            <!-- <input type="text" placeholder="Role" [ngClass]="{ 'has-error': submited && f.role.errors }"
              formControlName="role" maxlength="70"> -->
            <select placeholder="Please Select" formControlName="role"
              [ngClass]="{ 'has-error': submited && f.role.errors }">

              <option [value]="holdType.value"
                *ngFor="let holdType of [{value:0,name:'None'},{value:1,name:'Property Agent'}, {value:2,name:'Owner/Landlord'}, {value:3,name:'Tenant'}, {value:4,name:'Just Looking'}]">
                {{holdType.name}}</option>
            </select>

          </div>
          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>User Status</h5>
            <select [ngClass]="{ 'has-error': submited && f.status.errors }" formControlName="status">
              <option [value]="'1'">Active</option>
              <option [value]="'2'">Suspend</option>
            </select>



          </div>
        </div>

        <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">

          <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
            <h5>Make user as ?</h5>
            <!-- <input type="text" placeholder="Role" [ngClass]="{ 'has-error': submited && f.role.errors }"
              formControlName="role" maxlength="70"> -->
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
              <div *ngFor="let holdType of platformRoles">
                <mat-checkbox [(ngModel)]="holdType.checked" color="primary" [ngModelOptions]="{standalone: true}"
                  [disabled]="holdType.disabled">{{holdType.name}}
                </mat-checkbox>
              </div>
            </div>
          </div>

        </div>



        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3>Change Password</h3>
          </div>
          <div fxLayout="row">
            <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center"
              style="margin-right:20px;">
              <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
                <h5>Password</h5>
                <input type="text" placeholder="password" [ngClass]="{ 'has-error': submited && f.password.errors }"
                  formControlName="password" maxlength="70">

                <div fxFlex="70" fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">

                  <div *ngIf="submited && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Description is required
                    </div>
                    <div *ngIf="f.password.errors.minlength">Minimum 2 characters
                    </div>
                    <div *ngIf="f.password.errors.maxlength">Maximum 2,000 Character
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="price-fields" fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
              <div class="input-div" fxLayout="column" fxLayoutAlign="start start">
                <h5>Re-Type Password</h5>
                <input type="text" placeholder="Re-Type Password"
                  [ngClass]="{ 'has-error': submited && f.cPassword.errors }" formControlName="cPassword"
                  maxlength="70">

                <div fxFlex="70" fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">

                  <div *ngIf="submited && f.cPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.cPassword.errors.required">Description is required
                    </div>
                    <div *ngIf="f.cPassword.errors.minlength">Minimum 2 characters
                    </div>
                    <div *ngIf="f.cPassword.errors.maxlength">Maximum 2,000 Character
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:20px;">
        <a style="color:red;font-size:12px;" (click)="deleteUser(user.id)">Delete User</a>
        <div fxFlex></div>
        <button mat-flat-button type="button" color="primary" (click)="updateUser()">Update</button>
        <div fxFlex></div>
      </div>
    </form>
    <div *ngIf="submited">
      <h5>User details updated!</h5>
    </div>
  </mat-dialog-content>
</ng-template>