<div class="manage-payment-history" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
    <h5>Leads</h5>
    <!-- <span>**NOTE: Billing id is first 4 character and last 4 character.</span> -->
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="clculateFor">
      <th mat-header-cell *matHeaderCellDef> Calculate For </th>
      <td mat-cell *matCellDef="let element"> {{element.clculateFor==1?'Office':'Retail'}} </td>
    </ng-container>
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
    <ng-container matColumnDef="languag">
      <th mat-header-cell *matHeaderCellDef>Prefered Lanaguge </th>
      <td mat-cell *matCellDef="let element">
        {{element.preferedLanguage}} </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef> Area </th>
      <td mat-cell *matCellDef="let element" style="text-transform: capitalize;">
        {{element.area}}
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="requiredMinSqm">
      <th mat-header-cell *matHeaderCellDef> Min Sqm. </th>
      <td mat-cell *matCellDef="let element"> {{element.requiredMinSqm}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="requiredMaxSqm">
      <th mat-header-cell *matHeaderCellDef> Max Sqm. </th>
      <td mat-cell *matCellDef="let element"> {{element.requiredMaxSqm}} </td>
    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Created At </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt   | date:'short':'UTC'}} </td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
      <td mat-cell *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" style="margin: 20px 0;">
          <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 150px;"
            (click)="sentToAgent(element)">{{element.sentToAgent?'Unsent':'Sent To Agent'}}</button>
          <button mat-flat-button [color]="'primary'" (click)="downloadLead(element.id)"
            style="margin-left:10px;width: 50px;"><i class="mdi mdi-download" style="color:white;"></i></button>
          <button mat-flat-button [color]="'primary'" (click)="delete(element)" style="margin-left:10px;width: 50px;"><i
              class="mdi mdi-delete" style="color:white;"></i></button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
</div>