import { NgModule } from '@angular/core';
import { SharedModule } from 'src/shared/shared.module';
import { MaterialModule } from 'src/material/material.module';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginRegisterComponent } from './container/login-register/login-register.component';
import { LoginRegisterService } from './service/login-register.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material';
import { Routes, RouterModule } from '@angular/router';
import { UserRouteResolver } from 'src/app/user-route-resolver';
import { LoginScreenComponent } from './container/login-screen/login-screen.component';
import { ResetPasswordComponent } from './container/reset-password/reset-password.component';
import { RecaptchaV3Module, RecaptchaModule, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

const routes: Routes = [

  {
    path: "login-register",
    component: LoginScreenComponent,
    pathMatch: 'full',
    resolve: {
      userRouteResolver: UserRouteResolver
    },
  },
  {
    path: "recoverPassword/:code/:email",
    component: ResetPasswordComponent,
    resolve: {
      userRouteResolver: UserRouteResolver
    },
  }
];

@NgModule({
  declarations: [LoginRegisterComponent, LoginScreenComponent, ResetPasswordComponent],
  entryComponents: [LoginRegisterComponent, LoginScreenComponent, ResetPasswordComponent],
  exports: [LoginRegisterComponent, LoginScreenComponent],
  imports: [SharedModule, MatDialogModule, CommonModule, FlexLayoutModule, ReactiveFormsModule, RouterModule.forChild(routes), RecaptchaModule, RecaptchaV3Module],
  providers: [LoginRegisterService, { provide: MatDialogRef, useValue: {} },{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeAkccUAAAAAGURwpvcfE0yo9wwN2DSHAGURVmP' }]
})
export class LoginRegisterModule { }
