import * as tslib_1 from "tslib";
import { IdbService } from "../idb/idb.service";
import { from, BehaviorSubject, of, Subject } from "rxjs";
import { switchMap, map, tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(idbService, matSnackBar, router) {
        this.idbService = idbService;
        this.matSnackBar = matSnackBar;
        this.router = router;
        this.profileState = new BehaviorSubject(null);
        this.authenticationState = new BehaviorSubject(!!this.token);
        this.connection = null;
        this.customSubject = new Subject();
        this.customObservable = this.customSubject.asObservable();
    }
    AuthService.prototype.saveAuthToken = function (token) {
        var _this_1 = this;
        localStorage.setItem("token", JSON.stringify(token));
        return of(token).pipe(tap(function () { return _this_1.authenticationState.next(true); }), tap(function () { return _this_1.token; }));
    };
    AuthService.prototype.logOut = function () {
        var _this_1 = this;
        return of(localStorage.removeItem("token")).pipe(switchMap(function () { return _this_1.deleteProfile(); }), tap(function () { return _this_1.authenticationState.next(false); }));
    };
    AuthService.prototype.redirectLogin = function (message, state) {
        var _this_1 = this;
        if (message === void 0) { message = "Please login or register to continue"; }
        if (state === void 0) { state = "authentication/login"; }
        this.matSnackBar.open(message, null, { duration: 3000 });
        setTimeout(function () { return _this_1.router.navigate([state]); }, 500);
    };
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            var token = !!localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
            if (!token) {
                this.deleteProfile().subscribe();
            }
            return token;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.getProfile = function () {
        var _this_1 = this;
        return this.idbService.instance().pipe(switchMap(function (db) {
            return from(db
                .transaction("profile")
                .objectStore("profile")
                .get("me"));
        }), map(function (data) {
            return _this_1.profileState.next(data);
        }));
    };
    AuthService.prototype.saveProfile = function (data) {
        var _this_1 = this;
        return this.idbService.instance().pipe(switchMap(function (db) {
            var tx = db.transaction("profile", "readwrite");
            tx.objectStore("profile").put(tslib_1.__assign({ idbuid: "me" }, data));
            return from(tx.complete).pipe(tap(function () { return _this_1.profileState.next(data); }));
        }));
    };
    AuthService.prototype.deleteProfile = function () {
        var _this_1 = this;
        return this.idbService.instance().pipe(switchMap(function (db) {
            var tx = db.transaction("profile", "readwrite");
            tx.objectStore("profile").delete("me");
            return from(tx.complete).pipe(tap(function () { return _this_1.profileState.next(null); }));
        }));
    };
    AuthService.prototype.callSignalR = function () {
        var _this_1 = this;
        var _this = this;
        if (!this.connection && window.signalR.VERSION) {
            this.connection = new window.signalR.HubConnectionBuilder().withUrl(environment.serverUrl + "hubs/notifications", {
                accessTokenFactory: function () { return _this_1.token.access_token; },
            }).configureLogging(window.signalR.LogLevel.Information).build();
            this.connection.on("ReceiveNotification", function (notificationMessage) {
                //do something when receive a new notification message
                _this.customSubject.next(true);
            });
            this.connection.start().then(function () {
                //do something when connection is established
                _this.customSubject.next(true);
            }).catch(function (err) {
                return console.error(err.toString());
            });
        }
    };
    AuthService.prototype.retryFailedRequests = function () {
        // retry the requests. this method can
        // be called after the token is refreshed
    };
    return AuthService;
}());
export { AuthService };
