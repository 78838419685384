import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LoadingScreenService = /** @class */ (function () {
    function LoadingScreenService() {
        this._loading = false;
        this.loadingStatus = new Subject();
    }
    Object.defineProperty(LoadingScreenService.prototype, "loading", {
        get: function () {
            return this._loading;
        },
        set: function (value) {
            this._loading = value;
            this.loadingStatus.next(value);
        },
        enumerable: true,
        configurable: true
    });
    LoadingScreenService.prototype.startLoading = function () {
        this.loading = true;
    };
    LoadingScreenService.prototype.stopLoading = function () {
        this.loading = false;
    };
    LoadingScreenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingScreenService_Factory() { return new LoadingScreenService(); }, token: LoadingScreenService, providedIn: "root" });
    return LoadingScreenService;
}());
export { LoadingScreenService };
