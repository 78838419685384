import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';
import { ContactService } from 'src/contact/service/contact.service.service';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';

@Component({
  selector: 'td-dashboard-support',
  templateUrl: './dashboard-support.component.html',
  styleUrls: ['./dashboard-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardSupportComponent implements OnInit {
  contactFormHtml: FormGroup = this.fb.group({
    name: [null, [Validators.required,Validators.minLength(2)]],
    email: [null, [Validators.required, Validators.email]],
    telephone: [null, [
      Validators.required,
      Validators.pattern("^[0-9]*$")
    ]],
    detail: [null, [Validators.required,Validators.maxLength(256), Validators.minLength(9)]],
    UserIdReceived: [null],
    subject: ['Message To Support for ']
  })
  submitted: boolean = false;
  destroy$: Subject<boolean> = new Subject();
  user=null;
  constructor(private changeDetection: ChangeDetectorRef,
    private dashboardService : DashboardService,
    private authService: AuthService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private contactService: ContactService) { }

  ngOnInit() {
    this.dashboardService.getProfile().pipe(takeUntil(this.destroy$)).subscribe(({data}:any)=>{
      this.user=data
      this.contactFormHtml.controls['email'].setValue(this.user.email);
      this.contactFormHtml.controls['telephone'].setValue(this.user.phoneNumber);
      this.contactFormHtml.controls['name'].setValue(this.user.firstName + this.user.lastName);
    })
  }

  get f(): any { return this.contactFormHtml.controls; }

  onSubmit() {
    
    
    this.submitted = true;
    if (this.contactFormHtml.invalid) {
      
      return;
    }
    // stop here if form is invalid
    
    // if (this.user.id) {
    //   
    //   this.contactFormHtml.controls['UserIdReceived'].setValue(this.user.id);
    // }
    this.contactService.sendMessage(this.contactFormHtml.value).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.contactFormHtml.reset()
      this.utilService.showToast('success','Message Send!');
      this.submitted = false;
    }, (err) => {
      
      this.submitted = false;
      if (err.error.error[0] == 'Can not send message to your own listing.') {
        this.utilService.showToast('error','Can not send message to your own listing');
      } else {
        this.utilService.showToast('error','Error sending message! Try again later');
      }

    })

  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
