import { OnInit } from "@angular/core";
import { UtilService } from "src/shared/services/util.service";
var CropperDialogComponent = /** @class */ (function () {
    function CropperDialogComponent(utilService, data) {
        this.utilService = utilService;
        this.data = data;
        this.file = null;
        this.base64Img = null;
    }
    CropperDialogComponent.prototype.ngOnInit = function () { };
    CropperDialogComponent.prototype.imageCropped = function (_a) {
        var file = _a.file, base64 = _a.base64;
        if (file.size / 1024 / 1024 < this.data.fileSize) {
            this.file = file;
            this.base64Img = base64;
        }
        else {
            this.utilService.showToast('error', "The file is to big must be less than " + this.data.fileSize + "MB please select another file");
        }
    };
    CropperDialogComponent.prototype.loadImageFailed = function () {
        this.utilService.showToast('error', "Image couldn't been selected please pick another image and ensure is a jpg or png file");
    };
    CropperDialogComponent.prototype.imageLoaded = function () { };
    return CropperDialogComponent;
}());
export { CropperDialogComponent };
