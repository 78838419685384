import { EventEmitter, OnDestroy, ChangeDetectorRef, AfterContentInit, OnInit } from "@angular/core";
import { MapsAPILoader } from "@agm/core";
import { from, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UtilService } from 'src/shared/services/util.service';
import { Router } from '@angular/router';
var LocationPickerComponent = /** @class */ (function () {
    function LocationPickerComponent(changeDetection, mapsApiLoader, utilService, router) {
        this.changeDetection = changeDetection;
        this.mapsApiLoader = mapsApiLoader;
        this.utilService = utilService;
        this.router = router;
        this.text = "Start typing the location of your listing or drop the pin in the map";
        this.position = null;
        this.autocomplete = null;
        this.zoom = 14;
        this.getCoordenates = new EventEmitter();
        this.destroy$ = new Subject();
        this.location = null;
    }
    LocationPickerComponent.prototype.ngOnInit = function () {
    };
    LocationPickerComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        $(".pac-container").remove();
        this.mapsApiLoaded()
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            _this.googleMapInit();
        });
    };
    LocationPickerComponent.prototype.googleMapInit = function () {
        var _this = this;
        this.autocomplete = new google.maps.places.Autocomplete(document.querySelector("#googleInput"), { componentRestrictions: { country: "th" } });
        this.autocomplete.addListener("place_changed", function () {
            return _this.autocompleteHandler();
        });
        if (this.router.url == '/') {
            setTimeout(function () {
                var element = document.getElementsByClassName('pac-container');
                element[0].className += ' Home-pac-container';
            }, 300);
        }
    };
    LocationPickerComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    LocationPickerComponent.prototype.mapsApiLoaded = function () {
        return from(this.mapsApiLoader.load());
    };
    LocationPickerComponent.prototype.getMarkerPosition = function (_a) {
        var coords = _a.coords;
        this.setPosition(coords);
    };
    LocationPickerComponent.prototype.clickedMarker = function (ev) {
        this.setPosition({
            lat: ev.latitude,
            lng: ev.longitude,
            area: ''
        });
    };
    LocationPickerComponent.prototype.getMyLocation = function () {
        var _this = this;
        navigator.geolocation.getCurrentPosition(function (_a) {
            var coords = _a.coords;
            return _this.setPosition({ lat: coords.latitude, lng: coords.longitude, area: '' });
        }, function () {
            return _this.utilService.showToast('error', "Please enable geolocalization to use this feature");
        });
    };
    LocationPickerComponent.prototype.autocompleteHandler = function () {
        var place = this.autocomplete.getPlace();
        if (!place.geometry) {
            this.utilService.showToast('error', "Place not available");
            return;
        }
        this.setPosition({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            area: ''
        });
    };
    LocationPickerComponent.prototype.setPosition = function (position) {
        this.position = position;
        this.zoom = 16;
        this.getCoordenates.emit(this.position);
        this.changeDetection.detectChanges();
    };
    return LocationPickerComponent;
}());
export { LocationPickerComponent };
