<div class="manage-messages" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width:100%;">
    <h4>Reported Listing</h4>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">

    </div>
    <span *ngIf="reportedList.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <div *ngFor="let report of reportedList">
        <div class="message-body" [class.unread]="report.receivedStatus ==1" fxLayout="row"
          fxLayoutAlign="space-between center">
          <div class="left-text" fxFlex="70" fxLayout="column" fxLayoutAlign="space-around start"
            (click)="showMessageDialog(report)">
            <h5>{{report.message}}</h5>
          </div>
          <div class="right-text" fxFlex="10" fxLayout="column" fxLayoutAlign="space-evenly center">
            <div class="data" (click)="showMessageDialog(report)">
              <p>{{report.createdAt| date }}</p>
            </div>
            <div class="fab-div" fxLayout="row" fxLayoutAlign="end end">
                <eco-fab-speed-dial direction="left" animationMode="scale">
                  <eco-fab-speed-dial-trigger spin="true">
                    <button mat-fab [color]="'primary'"><i class="mdi mdi-dots-horizontal" style="color:white;"></i></button>
                  </eco-fab-speed-dial-trigger>
        
                  <eco-fab-speed-dial-actions>
                    <button class="fab-button" mat-mini-fab (click)="deleteEventCalled(report.id)"><i class="mdi mdi-delete-outline"></i></button>
                    <button class="fab-button" mat-mini-fab (click)="showMessageDialog(report)"><i
                        class="mdi mdi-square-edit-outline"></i></button>
                  </eco-fab-speed-dial-actions>
                </eco-fab-speed-dial>
              </div>
            <!-- <button type="button" class="fab-button" mat-mini-fab aria-label="Example icon-button with a heart icon"
              (click)="deleteEventCalled(report.id)">
              <i class="mdi mdi-delete-outline"></i>
            </button> -->
          </div>
        </div>
      </div>
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </span>
    <span *ngIf="!reportedList.length">
      None reported on any listing.
    </span>
  </div>

</div>

<ng-template #reportedDialog>

  <mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 matDialogTitle>
        Listing Reported</h3>
      <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
    </div>
    <div class="property-overview-fields" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="row" fxLayoutGap="20px" *ngIf="report?.title">
        <label>Title : </label>
        <div>{{report?.title}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" *ngIf="report?.reportType">
        <label>Subject : </label>
        <div>{{getEnumById('reportType',null, report?.reportType,false).name}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" *ngIf="report?.message">
        <label>Message : </label>
        <div>{{report?.message}}</div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px"  *ngIf="report?.response">
        <label>Admin response : </label>
        <div>{{report?.response}}</div>
      </div>
      <div class="price-fields" fxLayout="row" fxLayoutAlign="start start" *ngIf="!report?.response">
        <div class="asking-price">
          <textarea placeholder="Response" [ngClass]="{ 'has-error': submited && !response }" [(ngModel)]="response"
            maxlength="2000" style="height:120px;"></textarea>
          <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">

            <div *ngIf="submited && !response" class="invalid-feedback" fxFlex="70">
              Response is required
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-flat-button type="button" color="primary" (click)="suspendListing(report?.listingId)">Suspend Listing</button>
        <a mat-flat-button type="button" color="primary" href="/listing-detail/{{report?.listingId}}" target="_blank"
          fxLayout="row" fxLayoutAlign="center center">View Listing</a>
        <button mat-flat-button type="button" color="primary" (click)="sendResponse()" *ngIf="!report.response"
          [disabled]="!response">Send Response</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>