import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { Router } from '@angular/router';
var DashboardPaymentHistoryComponent = /** @class */ (function () {
    function DashboardPaymentHistoryComponent(dashboardService, utilService, changeDetection, router) {
        this.dashboardService = dashboardService;
        this.utilService = utilService;
        this.changeDetection = changeDetection;
        this.router = router;
        this.dataSource = [];
        this.displayedColumns = ['id', 'customerName', 'date', 'amount', 'symbol', 'status'];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.destroy$ = new Subject;
        this.fromDate = new Date(2018, 0, 1);
        this.toDate = new Date();
        this.downloadFile = function (blob, fileName) {
            var link = document.createElement('a');
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            window.addEventListener('focus', function (e) { return URL.revokeObjectURL(link.href); }, { once: true });
        };
    }
    DashboardPaymentHistoryComponent.prototype.ngOnInit = function () {
        this.getAllBills();
    };
    DashboardPaymentHistoryComponent.prototype.getAllBills = function () {
        var _this = this;
        var obj = {
            from: this.fromDate,
            to: this.toDate
        };
        this.dashboardService.getAllBills(this.currentPage, 10, obj).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.dataSource = data;
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardPaymentHistoryComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllBills();
    };
    DashboardPaymentHistoryComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardPaymentHistoryComponent.prototype.getBillPdf = function (id) {
        var _this = this;
        this.dashboardService.getBillPdf(id).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.downloadFile(data, 'invoice.pdf');
            // var url = window.URL.createObjectURL(data);
            // window.open(url);
        });
    };
    DashboardPaymentHistoryComponent.prototype.sendBill = function (billId) {
        var _this = this;
        this.dashboardService.sendBillToUser(billId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', 'Bill Send to User');
        });
    };
    DashboardPaymentHistoryComponent.prototype.tryToPayAgain = function (billId) {
        this.router.navigate(["/payment-confirmation/" + billId]);
    };
    return DashboardPaymentHistoryComponent;
}());
export { DashboardPaymentHistoryComponent };
