import { Directive, HostListener, AfterViewInit, Input } from "@angular/core";
import { NgControl, AbstractControl } from "@angular/forms";
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: "[numberFormat]"
})
export class NumberFormatDirective implements AfterViewInit {
  @Input()
  maxValue: number = Number.MAX_SAFE_INTEGER;
  @Input()
  validate: boolean = false;
  constructor(private decimalPipe: DecimalPipe, private ngControl: NgControl) {}
  ngAfterViewInit() {
    if (this.validate) {
      this.ngControl.control.setValidators((control: AbstractControl) => {
        let value = control.value.replace(/\s+/g, "");
        value = value.replace(/[^\d]+/g, "");
        value = value.replace(/,/gi, "");
        return Number(value) > this.maxValue ? { maxValue: true } : null;
      });
    }
  }

  @HostListener("input", ["$event"])
  onKeyInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\s+/g, "");
    value = value.replace(/[^\d]+/g, "");
    value = value.replace(/,/gi, "");
    if (Number(value) > this.maxValue) {
      input.value = this.decimalPipe.transform(this.maxValue, "1.0-0");
    } else {
      value = this.decimalPipe.transform(value, "1.0-0");
      input.value = value;
    }
  }
  @HostListener("blur", ["$event"])
  onBlur(event) {
    const input = event.target as HTMLInputElement;
    // input.value = input.value.replace(/,/gi, "");
    // if (input.value === "") {
    //   this.ngControl.control.setValue(null);
    // } else {
    //   this.ngControl.control.setValue(Number(input.value));
    // }
  }
  @HostListener("keydown", ["$event"])
  onKeyDown(ev: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(ev.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (ev.keyCode === 65 && (ev.ctrlKey || ev.metaKey)) ||
      // Allow: Ctrl+C
      (ev.keyCode === 67 && (ev.ctrlKey || ev.metaKey)) ||
      // Allow: Ctrl+V
      (ev.keyCode === 86 && (ev.ctrlKey || ev.metaKey)) ||
      // Allow: Ctrl+X
      (ev.keyCode === 88 && (ev.ctrlKey || ev.metaKey)) ||
      // Allow: home, end, left, right
      (ev.keyCode >= 35 && ev.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (ev.shiftKey || (ev.keyCode < 48 || ev.keyCode > 57)) &&
      (ev.keyCode < 96 || ev.keyCode > 105)
    ) {
      ev.preventDefault();
    }
  }
}
