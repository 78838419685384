import { NgModule } from "@angular/core";
import { LocationPickerComponent } from "./components/location-picker/location-picker.component";
import { AgmCoreModule } from "@agm/core";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [SharedModule, AgmCoreModule,
    RouterModule],
  exports: [LocationPickerComponent],
  declarations: [LocationPickerComponent]
})
export class LocationModule {}
