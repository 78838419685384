import { Component, OnInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ViewChild } from "@angular/core";
import {
  NavigationEnd,
  Router
} from '@angular/router';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';

import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject, Observable, Subscription, fromEvent } from 'rxjs';
import * as $ from 'jquery';
import { fadeAnimation } from 'src/shared/animation/animation';
import { MediaMatcher } from '@angular/cdk/layout';


@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation] // register the animation
})
export class LayoutComponent implements OnInit, OnChanges {

  destroy$: Subject<boolean> = new Subject();
  @ViewChild('sidenav', { static: true }) sidenav: any;
  public isShowingRouteLoadIndicator: boolean;
  location: string = "";

  resizeObservable$: Observable<any>;
  resizeSubscription$: Subscription;
  matcher = {
    media: null,
    matches: false,
    onchange: null
  };
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset > 60) {
      console.log("this.location", this.location);
      let element = document.getElementById('navbar-container');
      element.classList.add('sticky');
      $(".login-register-dialog").each(function () {
        $(this).addClass("login-register-dialog-add-margin");
      });
      if (this.location == '/add-listing' ||
        this.location.match('/dashboard') ||
        this.location.match('/listing-detail/') ||
        this.location.match('/404') ||
        this.location.match('/search') ||
        this.location.match('/payment-confirmation') ||
        this.location.match('/calculate') ||
        this.location.match('/payment-error') ||
        this.location.match('/payment-success') ||
        this.location.match('/announcements') ||
        this.location.match('/article') ||
        this.location.match('/login-register') || this.location.match('/recoverPassword')) {
        let element2 = document.getElementById('layout-content-container');
        element2.classList.add('add-margin-top-to-layout');
        
      }
    } else {
      let element = document.getElementById('navbar-container');
      element.classList.remove('sticky');
      $(".login-register-dialog").each(function () {
        $(this).removeClass("login-register-dialog-add-margin");
      });
      if (this.location == '/add-listing' ||
        this.location.match('/dashboard') ||
        this.location.match('/listing-detail/') ||
        this.location.match('/404') ||
        this.location.match('/search') ||
        this.location.match('/payment-confirmation') ||
        this.location.match('/calculate') ||
        this.location.match('/payment-error') ||
        this.location.match('/payment-success') ||
        this.location.match('/announcements') ||
        this.location.match('/article') ||
        this.location.match('/login-register') || this.location.match('/recoverPassword')) {
        let element2 = document.getElementById('layout-content-container');
        element2.classList.remove('add-margin-top-to-layout');
        
      }
    }
  }
  constructor(private router: Router, public mediaMatcher: MediaMatcher,
    public enumPropertyService: EnumPropertyService, private ref: ChangeDetectorRef, ) {
    //   this.isShowingRouteLoadIndicator = false;
    //   var asyncLoadCount = 0;
    //   this.router.events.subscribe(
    //     ( event: RouterEvent ) : void => {

    //         if ( event instanceof RouteConfigLoadStart ) {

    //             asyncLoadCount++;

    //         } else if ( event instanceof RouteConfigLoadEnd ) {

    //             asyncLoadCount--;

    //         }

    //         // If there is at least one pending asynchronous config load request,
    //         // then let's show the loading indicator.
    //         // --
    //         // CAUTION: I'm using CSS to include a small delay such that this loading
    //         // indicator won't be seen by people with sufficiently fast connections.
    //         if(!! asyncLoadCount){
    //           this.loadingScreenService.startLoading();
    //         }else{
    //           this.loadingScreenService.stopLoading();
    //         }

    //     }
    // );

    // this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event) => {
    //   switch (true) {
    //     case event instanceof NavigationStart: {
    //       break;
    //     }
    //     case event instanceof NavigationEnd:
    //     case event instanceof NavigationCancel:
    //     case event instanceof NavigationError: {
    //       break;
    //     }
    //     default: {
    //       break;
    //     }
    //   }
    // });


    var visible = false;
    //Check to see if the window is top if not then display button
    $(window).scroll(function () {
      var scrollTop = $(this).scrollTop();
      if (!visible && scrollTop > 100) {
        $(".scrollToTop").fadeIn();
        visible = true;
      } else if (visible && scrollTop <= 100) {
        $(".scrollToTop").fadeOut();
        visible = false;
      }
    });


  }

  topClick() {
    $("html, body").animate({
      scrollTop: 0
    }, 800);
    return false;
  }

  ngOnInit() {
    this.sidenavCloseOrOpen(false);
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.pipe(takeUntil(this.destroy$)).subscribe(evt => {
      console.log('event: ', evt);
      this.onResize();
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
      this.location = event.url;
      this.ref.detectChanges();

    });
    this.onResize();
  }
  onResize() {
    this.matcher = this.mediaMatcher.matchMedia('(max-width: 960px)');

    this.ref.detectChanges();
  }

  ngOnChanges() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
      this.location = event.url;
      this.ref.detectChanges();

    });
  }
  sidenavCloseOrOpen(type) {
    console.log("type", type)
    if (type) {
      $(".mat-drawer-container").each(function () {
        $(this).removeClass("mat-drawer-container-override");
      });
    } else {
      $(".mat-drawer-container").each(function () {
        $(this).addClass("mat-drawer-container-override");
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
