<div class="manage-listing-card" *ngIf="taxonomy ">
  <div fxLayout="column" fxLayoutAlign="start start">
    <div class="listing-card-inside-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
      <ng-container *ngFor="let billingItem of listing.listingProducts;let i = index;">
        <div [class.highlighted]="billingItem.productId==4" *ngIf="billingItem.productId==4"></div>
      </ng-container>
      <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50" style="z-index: 1;">
        <div class="image-div" fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch" (click)="goto()"
          style="cursor: pointer;">
          <div class="image" [ngStyle]="{'background-image': 'url(' + storage + '/listing/' + listing?.filePath + ')'}">
          </div>
          <div *ngIf="listing.listingStatus==4" fxLayout="column" fxLayoutAlign="center center"
            style="color: red;font-size: 50px;background: #98939359;position: absolute;top: 0;bottom: 0;left: 0;right: 0;">
            {{'SOLD'|translate}}
          </div>
          <div *ngIf="listing.listingStatus==5" fxLayout="column" fxLayoutAlign="center center"
            style="color: red;font-size: 50px;background: #98939359;position: absolute;top: 0;bottom: 0;left: 0;right: 0;">
            {{'RENTED'|translate}}
          </div>
          <div *ngIf="listing.listingStatus==12" fxLayout="column" fxLayoutAlign="center center"
            style="color: red;font-size: 50px;background: #98939359;position: absolute;top: 0;bottom: 0;left: 0;right: 0;">
            {{'SUSPENDED'|translate}}
          </div>
          <div *ngIf="listing.listingStatus==11" fxLayout="column" fxLayoutAlign="center center"
            style="color: red;font-size: 50px;background: #98939359;position: absolute;top: 0;bottom: 0;left: 0;right: 0;">
            {{'UNDER REVIEW'|translate}}
          </div>
          <ng-container *ngFor="let billingItem of listing.listingProducts;let i = index;">

            <div class="top-banner" *ngIf="billingItem.productId==1">
              {{billingItem?.bannerText}}
            </div>
            <div class="digonal-banner" *ngIf="billingItem.productId==2">
              {{billingItem?.bannerText}}
            </div>
            <div class="bottom-banner" *ngIf="billingItem.productId==3">
              {{billingItem?.bannerText}}
            </div>


          </ng-container>

        </div>

        <span style="color: #f44336; padding: 10px;">Listing Id:
          {{listing.id}}</span>

      </div>
      <div class="listing-side-body" fxFlex="50" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="start start">
        <h5 (click)="goto()" style="cursor: pointer;">{{listing.title}}</h5>
        <p (click)="goto()" style="cursor: pointer;"><i
            class="mdi mdi-mdi mdi-map-marker"></i>{{listing.formattedAddress}}</p>
        <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.propertyType">
          <div translate>Category</div>
          <div class="text-div-right-text">{{getEnumById('propertyType',null,listing?.propertyType,false).name}}</div>
        </div>
        <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.residentialType">
          <div translate>Type</div>
          <div class="text-div-right-text">{{getEnumById('residentialType',null,listing?.residentialType,false).name}}
          </div>
        </div>
        <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.propertySize">
          <div translate>Usable Area (sm)</div>
          <div class="text-div-right-text">{{listing?.propertySize | number: '1.0-0'}}</div>
        </div>
        <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.numUnits">
          <div translate>#Units/Rooms</div>
          <div class="text-div-right-text">{{listing?.numUnits | number: '1.0-0'}}</div>
        </div>
        <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start"
          *ngIf="listing?.propertySize&&listing?.price">
          <div translate>Price/SqM (baht)</div>
          <div class="text-div-right-text">{{listing?.price/listing?.propertySize | number: '1.0-0'}}</div>
        </div>
      </div>
    </div>
    <div class="listing-card-price" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between start"
      style="margin-top:10px;">
      <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">

        <p *ngIf="listing?.createdAt">{{'Date Listed'|translate}} {{listing?.createdAt | date }}</p>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <div class="price" *ngIf="listing?.price">{{listing?.price | number: '1.0-0' }} THB</div>
          <div class="price" *ngIf="!listing?.price" translate>Price Upon Request</div>
          <div style="position: absolute; right: 0;top: 0;">
            <button mat-fab color="primary" (click)="openShareDialog()"><i class="mdi mdi-share"
                style="color: #ffffff;"></i></button>
          </div>
        </div>
      </div>
      <div fxFlex="70" *ngIf="type=='fav-listing'||type=='listing-management-admin'" fxLayout="row" fxLayoutGap="20px"
        style="padding-right: 10px;" fxLayoutAlign="space-between center">
        <button mat-mini-fab [color]="'primary'" *ngIf="type=='fav-listing'" (click)="delete(listing.id)"><i
            class="mdi mdi-heart"></i></button>
        <button mat-flat-button [color]="'primary'" style="width:100%;" *ngIf="type!='listing-management-admin'"
          (click)="goto()">{{'View Property'|translate}}</button>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;" *ngIf="type!='fav-listing'">
          <button mat-flat-button [color]="'primary'" style="width: 100px;margin-left:10px;"
            *ngIf="type=='listing-management-admin'"
            (click)="giveBackListing(listing)">{{'View Property'|translate}}</button>
          <button mat-flat-button [color]="'primary'" style="width: 100px;margin-left:10px;"
            *ngIf="type=='listing-management-admin'"
            (click)="approveOrSuspend(listing.id,1)">{{'Approve Listing'|translate}}</button>
          <button mat-flat-button [color]="'primary'" style="width: 100px;" *ngIf="type=='listing-management-admin'"
            (click)="approveOrSuspend(listing.id,12)">{{'Suspend Listing'|translate}}</button>
          <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
            (click)="edit()">{{'Edit'|translate}}</button>
          <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
            (click)="delete(listing.id)">{{'Delete'|translate}}</button>
        </div>
      </div>
      <div fxFlex="75" *ngIf="type!='fav-listing'&&type!='listing-management-admin'" fxLayout="row" fxLayoutGap="20px"
        style="padding-right: 10px;" fxLayoutAlign="space-evenly center">
        <button mat-flat-button [color]="'primary'" style="min-width: 265px;margin-left:10px;"
          (click)="activeListingFor30Days(listing.id)"
          *ngIf="listing.listingStatus==9&&listing.listingStatus!=4&&listing.listingStatus!=12&&listing.listingStatus!=5">{{'EXTEND 30 DAYS'|translate}}
        </button>
        <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
          (click)="approveOrSuspend(listing.id,4)"
          *ngIf="listing.listingType==1&&listing.listingStatus!=4&&listing.listingStatus!=12&&listing.listingStatus!=11">{{'Sold'|translate}}</button>
        <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
          (click)="approveOrSuspend(listing.id,5)"
          *ngIf="listing.listingType==2&&listing.listingStatus!=5&&listing.listingStatus!=12&&listing.listingStatus!=11">{{'Rented'|translate}}</button>
        <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
          *ngIf="listing.listingStatus==1" (click)="edit()">{{'Edit'|translate}}</button>
        <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
          (click)="delete(listing.id)">{{'Delete'|translate}}</button>
        <button mat-flat-button [color]="'primary'" style="width:220px;" (click)="approveOrSuspend(listing.id,11)"
          *ngIf="listing.listingStatus==12">{{'Re-submit For Approval'|translate}}</button>

      </div>

    </div>
  </div>
</div>
<ng-template #shareDialog>
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div translate>Share To</div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!shareListingClick">
        <a (click)="shareToFb()"><img src="assets/images/social-icons/facebook.png" /></a>
        <a (click)="getGroup()"> <img src="assets/images/social-icons/line.png" /></a>
        <a target="_blank"
          href="https://twitter.com/intent/tweet?url={{buildUrl(listing)}}&text={{listing?.title}}%0A{{listing?.description?.substr(0, 50)}}%0A&hashtags=thailandcommercial"><img
            src="assets/images/social-icons/twitter.png" /></a>
        <a target="_blank"
          href="http://www.linkedin.com/shareArticle?mini=true&url={{buildUrl(listing)}}&title={{listing?.title}}&summary={{listing?.description?.substr(0, 50)}}&source=thailandcommercial.com"><img
            src="assets/images/social-icons/linkedin.png" /></a>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="shareListingClick">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center"  fxFlex="20">
          <mat-checkbox *ngFor="let group of lineGroups" class="example-margin" [(ngModel)]="group.checked" fxFlex="50"
            color="primary" [ngModelOptions]="{standalone: true}" [checked]="group.disable" [disabled]="group.disable">
            {{group.name}}
          </mat-checkbox>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <button mat-flat-button type="button" color="primary" (click)="shareListingClick=false"
            style="margin-top: 20px;margin-left: 20px;">Cancel</button>
          <button mat-flat-button type="button" color="primary" (click)="shareToLine()" [disabled]="!lineGroups.length"
            style="margin-top: 20px;margin-left: 20px;">LINE Groups</button>
          <button mat-flat-button type="button" color="primary" (click)="openLineShare()"
            style="margin-top: 20px;margin-left: 20px;">Your Timeline</button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>