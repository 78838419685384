import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';
import { ContactService } from 'src/contact/service/contact.service.service';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
var DashboardSupportComponent = /** @class */ (function () {
    function DashboardSupportComponent(changeDetection, dashboardService, authService, utilService, fb, contactService) {
        this.changeDetection = changeDetection;
        this.dashboardService = dashboardService;
        this.authService = authService;
        this.utilService = utilService;
        this.fb = fb;
        this.contactService = contactService;
        this.contactFormHtml = this.fb.group({
            name: [null, [Validators.required, Validators.minLength(2)]],
            email: [null, [Validators.required, Validators.email]],
            telephone: [null, [
                    Validators.required,
                    Validators.pattern("^[0-9]*$")
                ]],
            detail: [null, [Validators.required, Validators.maxLength(256), Validators.minLength(9)]],
            UserIdReceived: [null],
            subject: ['Message To Support for ']
        });
        this.submitted = false;
        this.destroy$ = new Subject();
        this.user = null;
    }
    DashboardSupportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dashboardService.getProfile().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            _this.user = data;
            _this.contactFormHtml.controls['email'].setValue(_this.user.email);
            _this.contactFormHtml.controls['telephone'].setValue(_this.user.phoneNumber);
            _this.contactFormHtml.controls['name'].setValue(_this.user.firstName + _this.user.lastName);
        });
    };
    Object.defineProperty(DashboardSupportComponent.prototype, "f", {
        get: function () { return this.contactFormHtml.controls; },
        enumerable: true,
        configurable: true
    });
    DashboardSupportComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.contactFormHtml.invalid) {
            return;
        }
        // stop here if form is invalid
        // if (this.user.id) {
        //   
        //   this.contactFormHtml.controls['UserIdReceived'].setValue(this.user.id);
        // }
        this.contactService.sendMessage(this.contactFormHtml.value).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.contactFormHtml.reset();
            _this.utilService.showToast('success', 'Message Send!');
            _this.submitted = false;
        }, function (err) {
            _this.submitted = false;
            if (err.error.error[0] == 'Can not send message to your own listing.') {
                _this.utilService.showToast('error', 'Can not send message to your own listing');
            }
            else {
                _this.utilService.showToast('error', 'Error sending message! Try again later');
            }
        });
    };
    DashboardSupportComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    return DashboardSupportComponent;
}());
export { DashboardSupportComponent };
