import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/core/service/language/language.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/service/language/language.service";
var ArticleService = /** @class */ (function () {
    function ArticleService(http, languageService) {
        this.http = http;
        this.languageService = languageService;
        this.options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    }
    ArticleService.prototype.getPublicArticle = function (page, pageSize, obj, asc) {
        var options = { headers: new HttpHeaders().set('Accept-Language', this.languageService.getDefaultLanguage) };
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "articles/Search?page=" + page + "&pageSize=" + pageSize + "&orderBy=date&asc=" + asc, obj, options);
    };
    ArticleService.prototype.getAllAnnouncementPublic = function (page, pageSize, asc, obj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "announcement/Search/Public?page=" + page + "&pageSize=" + pageSize + "&orderBy=createdAt&asc=" + asc, obj);
    };
    ArticleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ArticleService_Factory() { return new ArticleService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LanguageService)); }, token: ArticleService, providedIn: "root" });
    return ArticleService;
}());
export { ArticleService };
