<div class="add-listing-container" *ngIf="taxonomy | async as taxo">
    <div class="add-listing-div" *ngIf="isAuthentic">
        <form [formGroup]="addListingForm" ngxScrollToFirstInvalid>
            <div class="add-listing" fxLayout="column" fxLayoutAlign="start start">
                <div class="add-listing-heading">
                    <h1>{{listingData?'Update Listing Details':'Add Listing Details' | translate}}</h1>
                </div>
                <div *ngIf="!isProduction">
                    <a (click)="mockup()">Mockup</a>
                </div>
                <div>
                    <p translate>Property</p>
                </div>
                <div class="property-type" fxLayout="row" fxLayoutAlign="start start">
                    <ng-container *ngFor="let lT of taxo.listingType;trackBy: trackElement" style="width: 100%;">
                        <div class="property-type-div" *ngIf="lT.id!=3">
                            <div class="box" [class.selected-box]="selectedPropertyType==lT.id"
                                (click)="selectedPropertyType=lT.id;selectedType=null; selectedSubType=null;"
                                fxLayout="column" fxLayoutAlign="center center">
                                <div class="box-circle" fxLayout="column" fxLayoutAlign="center center">
                                    <i class="mdi mdi-home-group" *ngIf="lT.id==1"></i>
                                    <i class="mdi mdi-door-open" *ngIf="lT.id==2"></i>
                                </div>
                                <p>{{lT.name  | translate}}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="selectedPropertyType">
                    <p translate>Select Type</p>
                </div>
                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="selectedPropertyType"
                    style="position: relative;">
                    <div class="help-div" fxLayout="row" fxLayoutAlign="center center"
                        style="position: absolute; left: -239px; top: 20px; width: 209px;"
                        *ngIf="selectedType=='Multifamily'">
                        <div class="help" translate>
                            Limited to residential property that is income producing only.  If you want to search or
                            list residential property for sale or rent, please see TeeYuu.com
                        </div>
                        <div class="arrow-right"></div>
                    </div>

                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.disabled]="selectedPropertyType===2" [class.selected]="selectedType=='Multifamily'"
                        (click)="selectedType='Multifamily';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/residential-income.svg" *ngIf="selectedPropertyType!==2" />
                            <img src="/assets/images/icons/residential-income-disabled.svg"
                                *ngIf="selectedPropertyType===2" />
                        </div>
                        <p translate>Multifamily</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Office'" (click)="selectedType='Office';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/office.svg" />
                        </div>
                        <p translate>Office</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Retail'" (click)="selectedType='Retail';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/retail.svg" />
                        </div>
                        <p translate>Retail</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Industrial'"
                        (click)="selectedType='Industrial';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/industrial.svg" />
                        </div>
                        <p translate>Industrial</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Hospitality'"
                        (click)="selectedType='Hospitality';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/hospitality.svg" />
                        </div>
                        <p translate>Hospitality</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Specialty'"
                        (click)="selectedType='Specialty';selectedSubType=null;assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/speciality.svg" />
                        </div>
                        <p translate>Specialty</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Land'"
                        (click)="selectedType='Land';selectedSubType=null;assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/land.svg" />
                        </div>
                        <p translate>Land</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedType=='Businesses'"
                        (click)="selectedType='Businesses';selectedSubType=null">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/icons/businesses.svg" />
                        </div>
                        <p translate>Businesses</p>
                    </div>
                </div>

                <div *ngIf="selectedType&&selectedType!=='Specialty'&&selectedType!=='Land'">
                    <p>{{selectedType}}</p>
                </div>
                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="selectedType=='Multifamily'">
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Condo'" (click)="selectedSubType='Condo';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-office-building"></i>
                        </div>
                        <p translate>Condo</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='House/Villa'"
                        (click)="selectedSubType='House/Villa';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home"></i>
                        </div>
                        <p translate>House/Villa</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Townhouse'"
                        (click)="selectedSubType='Townhouse';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <p translate>Townhouse</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Apartment'"
                        (click)="selectedSubType='Apartment';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-modern"></i>
                        </div>
                        <p translate>Apartment</p>
                    </div>
                </div>
                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" style="width:100%;"
                    *ngIf="selectedType=='Office'||selectedType=='Retail'">
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"  fxFlex="{{selectedType=='Office'?33:25}}" 
                        fxFlex.xs="{{selectedType=='Office'?33:50}}"
                        fxFlex.sm="{{selectedType=='Office'?33:50}}"
                        [class.selected]="selectedSubType=='Single Building'"
                        (click)="selectedSubType='Single Building';assignFile()" >
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-office-building"></i>
                        </div>
                        <p translate>Single Building</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center" fxFlex="{{selectedType=='Office'?33:25}}" 
                        fxFlex.xs="{{selectedType=='Office'?33:50}}"
                        fxFlex.sm="{{selectedType=='Office'?33:50}}"
                        [class.selected]="selectedSubType=='Shophouse'"
                        (click)="selectedSubType='Shophouse';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home"></i>
                        </div>
                        <p translate>Shophouse</p>
                    </div>
                    <div class="box" fxLayout="column" *ngIf="selectedType!=='Retail'" fxFlex="33"
                        fxLayoutAlign="center center" [class.selected]="selectedSubType=='Office Complex'"
                        (click)="selectedSubType='Office Complex';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <p translate>Office Complex</p>
                    </div>
                    <div class="box" fxLayout="column" *ngIf="selectedType=='Retail'" 
                        fxLayoutAlign="center center" [class.selected]="selectedSubType=='Convenience Center'"
                        (click)="selectedSubType='Convenience Center';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <p translate>Convenience Center</p>
                    </div>
                    <div class="box" fxLayout="column" *ngIf="selectedType=='Retail'" 
                        fxLayoutAlign="center center" [class.selected]="selectedSubType=='Shopping Mall'"
                        (click)="selectedSubType='Shopping Mall';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-modern"></i>
                        </div>
                        <p translate>Shopping Mall</p>
                    </div>
                </div>

                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" style="width:100%;"
                    *ngIf="selectedType=='Industrial'">

                    <div class="box" fxLayout="column" fxLayoutAlign="center center" fxFlex="33"
                        [class.selected]="selectedSubType=='Single-Story'"
                        (click)="selectedSubType='Single-Story';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-office-building"></i>
                        </div>
                        <p translate>Single-Story</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center" fxFlex="33"
                        [class.selected]="selectedSubType=='Multi-Story'"
                        (click)="selectedSubType='Multi-Story';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <p translate>Multi-Story</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center" fxFlex="33"
                        [class.selected]="selectedSubType=='Factory'" (click)="selectedSubType='Factory';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-factory"></i>
                        </div>
                        <p translate>Factory</p>
                    </div>
                </div>

                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" style="width:100%;"
                    *ngIf="selectedType=='Hospitality'">

                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Single Hotel'"
                        (click)="selectedSubType='Single Hotel';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-office-building"></i>
                        </div>
                        <p translate>Single Hotel</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Resort'" (click)="selectedSubType='Resort';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-city-outline"></i>
                        </div>
                        <p translate>Resort</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Boutique Hotel'"
                        (click)="selectedSubType='Boutique Hotel';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-modern"></i>
                        </div>
                        <p translate>Boutique Hotel</p>
                    </div>
                    <div class="box" fxLayout="column" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Hostel'" (click)="selectedSubType='Hostel';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-bed-empty"></i>
                        </div>
                        <p translate>Hostel</p>
                    </div>
                </div>
                <div *ngIf="selectedType=='Specialty'||selectedType=='Land'" class="help-div" fxLayout="row"
                    fxLayoutAlign="center center">
                    <div class="help" style="width: 100%;">
                        {{selectedType=='Specialty'?'Specialty- or Special Purpose - property includes real estate that does not fit into the other categories. Examples include Medical/Dental, Clinics, Self-Storage, Amusement Parks, Bowling Alleys, Operating Farms, Retirement Homes, Hospitals, and many others.':'Land is generally bare land available for development or farming. Any Land that includes income/rental should be listed in the “Specialty” category' | translate}}
                    </div>
                </div>

                <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" style="width:100%;"
                    *ngIf="selectedType=='Businesses'">

                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Restaurant/Bar'"
                        (click)="selectedSubType='Restaurant/Bar';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-glass-cocktail"></i>
                        </div>
                        <p translate>Restaurant/Bar</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Spa/Massage'"
                        (click)="selectedSubType='Spa/Massage';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-spa-outline"></i>
                        </div>
                        <p translate>Spa/Massage</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Food/Drink Stall/Cart'"
                        (click)="selectedSubType='Food/Drink Stall/Cart';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-food"></i>
                        </div>
                        <p translate>Food/Drink Stall/Cart</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Services'"
                        (click)="selectedSubType='Services';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-settings-outline"></i>
                        </div>
                        <p translate>Services</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Health & Beauty'"
                        (click)="selectedSubType='Health & Beauty';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-shoe-heel"></i>
                        </div>
                        <p translate>Health & Beauty</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Education'"
                        (click)="selectedSubType='Education';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-school"></i>
                        </div>
                        <p translate>Education</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Entertainment'"
                        (click)="selectedSubType='Entertainment';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-music-circle-outline"></i>
                        </div>
                        <p translate>Entertainment</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Manufacturing & Agriculture'"
                        (click)="selectedSubType='Manufacturing & Agriculture';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-home-variant"></i>
                        </div>
                        <p translate>Manufacturing & Agriculture</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Franchisee'"
                        (click)="selectedSubType='Franchisee';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-axis-arrow"></i>
                        </div>
                        <p translate>Franchisee</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Logistics'"
                        (click)="selectedSubType='Logistics';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-worker"></i>
                        </div>
                        <p translate>Logistics</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Financial'"
                        (click)="selectedSubType='Financial';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-cash-multiple"></i>
                        </div>
                        <p translate>Financial</p>
                    </div>
                    <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
                        [class.selected]="selectedSubType=='Other'" (click)="selectedSubType='Other';assignFile()">
                        <div class="circle" fxLayout="column" fxLayoutAlign="center center">
                            <i class="mdi mdi-arrow-decision-outline"></i>
                        </div>
                        <p translate>Other</p>
                    </div>
                </div>

            </div>
            <div *ngIf="selectedSubType||selectedType=='Specialty'||selectedType=='Land'">
                <div class="property-overview">
                    <div class="property-overview-heading">
                        <h1 translate>Property Overview</h1>
                    </div>
                </div>
                <div class="property-overview-fields">
                    <h3 translate>Ad Title</h3>
                    <div class="chosse-language" fxLayout="row" fxLayoutAlign="start center"
                        fxLayoutAlign.xs="center stretch" fxLayoutAlign.sm="center stretch">
                        <a [class.selected]="slectedLanguage==1" (click)="slectedLanguage=1" translate>English</a>
                        <a [class.selected]="slectedLanguage==2" (click)="slectedLanguage=2" translate>Thai
                            (optional)</a>
                    </div>
                    <div class="property-title" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="slectedLanguage==1">
                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs fxShow.gt-xs
                        fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                            <div class="help" translate>
                                Write a descriptive title highlighting keypoints to improve its visibility.
                            </div>
                        </div>
                        
                        <div class="input-div" fxLayout="column" fxLayoutAlign="start end" fxLayout.xs="row wrap" fxLayout.sm="row wrap">
                            <input type="text" placeholder="Title" 
                                [ngClass]="{ 'has-error': submited && f.engTitle.errors }" formControlName="engTitle"
                                maxlength="70">
                            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                                <div fxFlex="70">
                                    <div *ngIf="submited && f.engTitle.errors" class="invalid-feedback">
                                        <div *ngIf="f.engTitle.errors.required" translate>Title is required</div>
                                        <div *ngIf="f.engTitle.errors.minlength" translate>Minimum 2 characters</div>
                                        <div *ngIf="f.engTitle.errors.maxlength" translate>Maximum 70 Character</div>
                                    </div>
                                </div>
                                <div class="input-limit" style="margin-top: 10px;" fxFlex="25">
                                    {{70-f.engTitle.value.length}} {{'Character left'|translate}}</div>
                            </div>
                        </div>
                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                        fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                            <div class="arrow-left"></div>
                            <div class="help" translate>
                                Write a descriptive title highlighting keypoints to improve its visibility.
                            </div>
                        </div>
                    </div>
                    <div class="property-title" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="slectedLanguage==2">
                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs fxShow.gt-xs
                        fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                            <div class="help" translate>
                                Write a descriptive title highlighting keypoints to improve its visibility.
                            </div>
                        </div>
                        <div class="input-div" fxLayout="column" fxLayoutAlign="start end" fxLayout.xs="row wrap" fxLayout.sm="row wrap">
                            <input type="text" placeholder="Title" 
                                [ngClass]="{ 'has-error': submited && f.thTitle.errors }" formControlName="thTitle"
                                maxlength="70">
                            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                                <div fxFlex="70">
                                    <div *ngIf="submited && f.thTitle.errors" class="invalid-feedback">
                                        <div *ngIf="f.thTitle.errors.required" translate>Title is required</div>
                                        <div *ngIf="f.thTitle.errors.minlength" translate>Minimum 2 characters</div>
                                        <div *ngIf="f.thTitle.errors.maxlength" translate>Maximum 70 Character</div>
                                    </div>
                                </div>
                                <div class="input-limit" style="margin-top: 10px;" fxFlex="25">
                                    {{70-f.thTitle.value.length}} {{'Character left'|translate}}</div>
                            </div>
                        </div>
                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                        fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                            <div class="arrow-left"></div>
                            <div class="help" translate>
                                Write a descriptive title highlighting keypoints to improve its visibility.
                            </div>
                        </div>
                    </div>
                    <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs fxShow.gt-xs
                    fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                        <div class="help" translate>
                            The more specific the details you enter, the easier it will be for buyers to search for your ad.
                        </div>
                    </div>
                    <h3>{{'Asking'|translate}} <span *ngIf="selectedPropertyType==1" translate>Price</span>
                        <span *ngIf="selectedPropertyType==2" translate>Rent/Month</span>

                    </h3>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="space-between end">

                        <div class="asking-price include-error-hight">
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="askingPriceUponRequest">{{'Price Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" placeholder="Price" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.price.errors }" formControlName="price"
                                    numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.price.errors }" translate>
                                    Baht</div>
                            </div>
                            <div *ngIf="submited && f.price.errors" class="invalid-feedback">
                                <div *ngIf="f.price.errors.required" translate>Asking Price is required</div>
                                <div *ngIf="f.price.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.price.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>

                        </div>
                        <!--Not Show for office-->
                        <div class="asking-price second-div include-error-hight"
                            *ngIf="selectedType!=='Office'&&selectedType!=='Retail'&&selectedType!=='Industrial'&&
                            selectedType!=='Hospitality'&&selectedType!=='Specialty'&&selectedType!=='Land'&&selectedType!=='Businesses'">
                            <h3 translate>Number of Bedrooms (Total)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 340px;"
                                    [ngClass]="{ 'has-error': submited && f.numBeds.errors }" formControlName="numBeds"
                                    numberFormat>
                            </div>
                            <div *ngIf="submited && f.numBeds.errors" class="invalid-feedback">
                                <div *ngIf="f.numBeds.errors.required" translate>Number of Bedrooms is required</div>
                                <div *ngIf="f.numBeds.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numBeds.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <!-- For OFfice Only -->
                        <div class="asking-price second-div include-error-hight" *ngIf="selectedType=='Office'||selectedType=='Retail'||selectedType=='Industrial'||selectedType=='Hospitality'
                            ||selectedType=='Specialty'||selectedType=='Land'||selectedType=='Businesses'">
                            <h3 translate>Total Usable Area (sm)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.propertySize.errors }"
                                    formControlName="propertySize" numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.propertySize.errors }">SQM
                                </div>
                            </div>
                            <div *ngIf="submited && f.propertySize.errors" class="invalid-feedback">
                                <div *ngIf="f.propertySize.errors.required" translate>Property Size is required</div>
                                <div *ngIf="f.propertySize.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.propertySize.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>


                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                        fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                            <div class="arrow-left"></div>
                            <div class="help" translate>
                                The more specific the details you enter, the easier it will be for buyers to search for your ad.
                            </div>
                        </div>
                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center" *ngIf="selectedType!=='Office'&&selectedType!=='Retail'&&selectedType!=='Industrial'&&selectedType!=='Hospitality'&&
                            selectedType!=='Specialty'&&selectedType!=='Land'&&selectedType!=='Businesses'">
                        <!-- Not Show For Office -->
                        <div class="asking-price include-error-hight">
                            <h3 translate>Number of Bathrooms (Total)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 340px;"
                                    [ngClass]="{ 'has-error': submited && f.numBaths.errors }"
                                    formControlName="numBaths" numberFormat>
                                <!-- <select type="number" placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.numBaths.errors }"
                                    formControlName="numBaths">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select> -->
                            </div>
                            <div *ngIf="submited && f.numBaths.errors" class="invalid-feedback">
                                <div *ngIf="f.numBaths.errors.required" translate>Number of Bathrooms is required</div>
                                <div *ngIf="f.numBaths.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numBaths.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Property Size (sm)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.propertySize.errors }"
                                    formControlName="propertySize" numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.propertySize.errors }">SQM
                                </div>
                            </div>
                            <div *ngIf="submited && f.propertySize.errors" class="invalid-feedback">
                                <div *ngIf="f.propertySize.errors.required" translate>Property Size is required</div>
                                <div *ngIf="f.propertySize.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.propertySize.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType!=='Office'&&selectedType!=='Retail'&&selectedType!=='Industrial'&&
                            selectedType!=='Hospitality'&&selectedType!=='Specialty'&&selectedType!=='Land'&&selectedType!=='Businesses'">
                        <!-- Not Show For Office -->
                        <div class="asking-price include-error-hight">
                            <h3 translate>Total Rentable Units</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 340px;"
                                    [ngClass]="{ 'has-error': submited && f.numUnits.errors }" maxlength="255"
                                    formControlName="numUnits" numberFormat>
                                <!-- <select type="number" placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.numUnits.errors }"
                                    formControlName="numUnits">
                                    <option value="" disabled selected>Please Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                </select> -->
                            </div>
                            <div *ngIf="submited && f.numUnits.errors" class="invalid-feedback">
                                <div *ngIf="f.numUnits.errors.required" translate>Total Rentable Units is required</div>
                                <div *ngIf="f.numUnits.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numUnits.errors.maxlength" translate>Maximum 255 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Freehold or Leasehold</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.holdType.errors }"
                                    formControlName="holdType">
                                    <option value="0" selected disabled hidden>Please Select</option>
                                    <option value="{{holdType.id}}" *ngFor="let holdType of taxo.holdType" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{holdType.name }}</option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.holdType.errors" class="invalid-feedback">
                                <div *ngIf="f.holdType.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.holdType.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.holdType.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>


                    <div class="price-fields" fxLayout="row" fxLayout.xs="column"  fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Industrial'&&selectedPropertyType!==2">

                        <div class="asking-price  include-error-hight">
                            <h3 translate>% Warehouse</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.wareHouse.errors }"
                                    formControlName="wareHouse">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="{{warehouseData}}"
                                        *ngFor="let warehouseData of [10,20,30,40,50,60,70,80,90,100]" style=" width: 380px;
                                        padding: 16px;
                                        border-radius: 2px;
                                        border: 1px solid #dbdbdb;
                                        color: #444444;
                                        font-size: 15px;">{{warehouseData}}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.wareHouse.errors" class="invalid-feedback">
                                <div *ngIf="f.wareHouse.errors.required" translate>Warehouse % is required</div>
                                <div *ngIf="f.wareHouse.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.wareHouse.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Land Size (sm)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.landSize.errors }"
                                    formControlName="landSize" numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.landSize.errors }">SQM
                                </div>
                            </div>
                            <div *ngIf="submited && f.landSize.errors" class="invalid-feedback">
                                <div *ngIf="f.landSize.errors.required" translate>Property Size is required</div>
                                <div *ngIf="f.landSize.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.landSize.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>


                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Businesses'">

                        <div class="asking-price  include-error-hight">
                            <h3 translate>Annual Revenue (baht)</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestAnnualRevenu">{{'Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;width: 274px;"
                                    [ngClass]="{ 'has-error': submited && f.annualRevenue.errors }"
                                    formControlName="annualRevenue" numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.annualRevenue.errors }">
                                    THB
                                </div>
                            </div>
                            <div *ngIf="submited && f.annualRevenue.errors" class="invalid-feedback">
                                <div *ngIf="f.annualRevenue.errors.required" translate>Annual Revenue is required</div>
                                <div *ngIf="f.annualRevenue.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.annualRevenue.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Annual Cash Flow (baht)</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestAnnualCashFlow">{{'Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;width: 274px;"
                                    [ngClass]="{ 'has-error': submited && f.annualCashFlow.errors }"
                                    formControlName="annualCashFlow" numberFormat>
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.annualCashFlow.errors }">
                                    THB
                                </div>
                            </div>
                            <div *ngIf="submited && f.annualCashFlow.errors" class="invalid-feedback">
                                <div *ngIf="f.annualCashFlow.errors.required" translate>Freehold or Leasehold is
                                    required</div>
                                <div *ngIf="f.annualCashFlow.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.annualCashFlow.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>



                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType!=='Land'&&selectedPropertyType!==2">

                        <div class="asking-price  include-error-hight" *ngIf="selectedType!=='Businesses'&&selectedType!=='Specialty'&&selectedType!=='Retail'&&selectedType!=='Industrial'
                            ||selectedType=='Hospitality'"
                            fxLayoutAlign="{{selectedType=='Multifamily'?'end':'start'}} start" fxLayout="column">
                            <h3 translate>Furnished</h3>
                            <div fxLayout="row" fxLayoutAlign="center center" class="full-width">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.funishedState.errors }"
                                    formControlName="funishedState">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="{{funished.id}}" *ngFor="let funished of taxo.funishedState" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{funished.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight" *ngIf="selectedType!=='Businesses'&&selectedType!=='Specialty'&&selectedType!=='Retail'&&selectedType!=='Industrial'
                            &&selectedType!=='Hospitality'&&selectedType!=='Office'">
                            <h3 translate>Total Rent/Month (Baht)</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestPremisesRent">{{'Upon Request'|translate}}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;width: 274px;"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }"
                                    formControlName="totalRentPerMonth" numberFormat>
                                <div class="baht-div"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }">
                                    THB
                                </div>
                            </div>
                            <div *ngIf="submited && f.totalRentPerMonth.errors" class="invalid-feedback">
                                <div *ngIf="f.totalRentPerMonth.errors.required" translate>Total Rent/Month is required
                                </div>
                                <div *ngIf="f.totalRentPerMonth.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.totalRentPerMonth.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <!-- For Office Only and Retail -->
                        <div class="asking-price include-error-hight" *ngIf="selectedType=='Businesses'||selectedType=='Office'||selectedType=='Retail'||selectedType=='Industrial'
                            ||selectedType=='Industrial'||selectedType=='Hospitality'||selectedType=='Specialty'"
                            [ngClass]="{'second-div':selectedType=='Office'||selectedType=='Hospitality'}">
                            <h3 translate>Freehold or Leasehold</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.holdType.errors }"
                                    formControlName="holdType">
                                    <option value="0" selected disabled hidden translate>Please Select</option>
                                    <option value="{{holdType.id}}" *ngFor="let holdType of taxo.holdType" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{holdType.name}}</option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.holdType.errors" class="invalid-feedback">
                                <div *ngIf="f.holdType.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.holdType.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.holdType.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight" *ngIf="selectedType=='Businesses'">
                            <h3 translate># staff</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 340px;"
                                    [ngClass]="{ 'has-error': submited && f.numEmployees.errors }"
                                    formControlName="numEmployees" numberFormat>
                            </div>
                            <div *ngIf="submited && f.numEmployees.errors" class="invalid-feedback">
                                <div *ngIf="f.numEmployees.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.numEmployees.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numEmployees.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>

                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Businesses'">

                        <div class="asking-price  include-error-hight">
                            <h3 style="margin-top: 56px;" translate>Age of Business (years)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 346px;"
                                    [ngClass]="{ 'has-error': submited && f.ageYear.errors }" formControlName="ageYear"
                                    numberFormat>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Premises Rent/Mo</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestPremisesRent">{{'Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;width: 270px;"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }"
                                    formControlName="totalRentPerMonth" numberFormat>
                                <div class="baht-div"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }">THB
                                </div>
                            </div>
                            <div *ngIf="submited && f.totalRentPerMonth.errors" class="invalid-feedback">
                                <div *ngIf="f.totalRentPerMonth.errors.required" translate>Freehold or Leasehold is
                                    required</div>
                                <div *ngIf="f.totalRentPerMonth.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.totalRentPerMonth.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>



                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Hospitality'&&selectedType!=='Office'&&selectedType!=='Retail'&&selectedType!=='Multifamily'&&selectedType!=='Industrial'&&selectedType!=='Land'">
                        <div class="asking-price include-error-hight">
                            <h3 translate>Units/Rooms</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 346px;"
                                    [ngClass]="{ 'has-error': submited && f.numUnits.errors }"
                                    formControlName="numUnits" numberFormat>

                                <!-- <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.numUnits.errors }"
                                    formControlName="numUnits">
                                    <option value="" disabled selected>Please Select</option>
                                    <option value="{{numUnits}}" *ngFor="let numUnits of [0,1,2,3,4,5]">{{numUnits}}
                                    </option>
                                </select> -->
                            </div>
                            <div *ngIf="submited && f.numUnits.errors" class="invalid-feedback">
                                <div *ngIf="f.numUnits.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.numUnits.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numUnits.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Occupancy Rate (%)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.occupancyRate.errors }"
                                    formControlName="occupancyRate">
                                <div class="baht-div" [ngClass]="{ 'has-error': submited && f.occupancyRate.errors }">%
                                </div>
                            </div>
                            <div *ngIf="submited && f.occupancyRate.errors" class="invalid-feedback">
                                <div *ngIf="f.occupancyRate.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.occupancyRate.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.occupancyRate.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                    </div>

                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center" *ngIf="selectedType=='Hospitality'&&selectedType!=='Office'&&selectedType!=='Retail'&&
                            selectedType!=='Multifamily'&&selectedType!=='Industrial'&&selectedType!=='Land'">
                        <div class="asking-price include-error-hight" fxLayout="column" fxLayoutAlign="end start">
                            <div></div>
                            <h3 translate>Age (years)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center" class="full-width">
                                <input type="text" [ngClass]="{ 'has-error': submited && f.ageYear.errors }"
                                    style="width: 350px;" formControlName="ageYear" numberFormat>
                            </div>
                            <div *ngIf="submited && f.ageYear.errors" class="invalid-feedback">
                                <div *ngIf="f.ageYear.errors.required" translate>Freehold or Leasehold is required</div>
                                <div *ngIf="f.ageYear.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.ageYear.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Gross Income/Month</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestGrossIncomePerMonth">{{'Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" placeholder="Price" style="border-right-width: 0px!important;"
                                    [ngClass]="{ 'has-error': submited && f.grossIncomePerMonth.errors }"
                                    formControlName="grossIncomePerMonth" numberFormat>
                                <div class="baht-div"
                                    [ngClass]="{ 'has-error': submited && f.grossIncomePerMonth.errors }" translate>Baht
                                </div>
                            </div>
                            <div *ngIf="submited && f.grossIncomePerMonth.errors" class="invalid-feedback">
                                <div *ngIf="f.grossIncomePerMonth.errors.required" translate>Freehold or Leasehold is
                                    required
                                </div>
                                <div *ngIf="f.grossIncomePerMonth.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.grossIncomePerMonth.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>


                    </div>



                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center" *ngIf="selectedType=='Office'||selectedType=='Retail'||selectedType=='Industrial'||selectedType=='Specialty'||selectedType=='Land'
                        ||selectedType=='Businesses'">

                        <div class="asking-price include-error-hight" *ngIf="selectedPropertyType==2">
                            <h3 translate>Mimimum contract (years)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 347px;"
                                    [ngClass]="{ 'has-error': submited && f.minimumYearContract.errors }"
                                    formControlName="minimumYearContract" numberFormat>
                            </div>
                            <div *ngIf="submited && f.minimumYearContract.errors" class="invalid-feedback">
                                <div *ngIf="f.minimumYearContract.errors.required" translate>Total rent per month is
                                    required
                                </div>
                                <div *ngIf="f.minimumYearContract.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.minimumYearContract.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight"
                            *ngIf="(selectedType=='Retail'||selectedType=='Industrial'||selectedType=='Specialty'||selectedType=='Land'||selectedType=='Businesses')&&selectedPropertyType===2">
                            <h3 translate>Key Money (Baht)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 347px;"
                                    [ngClass]="{ 'has-error': submited && f.transferCost.errors }"
                                    formControlName="transferCost" numberFormat>
                            </div>
                            <div *ngIf="submited && f.transferCost.errors" class="invalid-feedback">
                                <div *ngIf="f.transferCost.errors.required" translate>Total rent per month is required
                                </div>
                                <div *ngIf="f.transferCost.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.transferCost.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight"
                            *ngIf="selectedType=='Office'&&selectedPropertyType==2">
                            <h3 translate>Furnished</h3>
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch"
                                fxLayout.xs="column" fxLayoutAlign.sm="center stretch"
                                fxLayout.sm="column">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.funishedState.errors }"
                                    formControlName="funishedState">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="{{funished.id}}" *ngFor="let funished of taxo.funishedState" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{funished.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Land'">
                        <div class="asking-price include-error-hight">
                            <h3 translate>Zoning/Uses</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" [ngClass]="{ 'has-error': submited && f.zoningUses.errors }"
                                    style="width: 350px;" formControlName="zoningUses">
                            </div>
                            <div *ngIf="submited && f.zoningUses.errors" class="invalid-feedback">
                                <div *ngIf="f.zoningUses.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.zoningUses.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.zoningUses.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Hospitality'&&selectedPropertyType==2">
                        <div class="asking-price include-error-hight">
                            <h3 translate>Key Money</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 347px;"
                                    [ngClass]="{ 'has-error': submited && f.transferCost.errors }"
                                    formControlName="transferCost" numberFormat>
                            </div>
                            <div *ngIf="submited && f.transferCost.errors" class="invalid-feedback">
                                <div *ngIf="f.transferCost.errors.required" translate>Total rent per month is required
                                </div>
                                <div *ngIf="f.transferCost.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.transferCost.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight">
                            <h3 translate>Available</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.availableForRent.errors }"
                                    formControlName="availableForRent">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="1" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;" translate>Immediately</option>
                                    <option value="2" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;" translate>30 Days</option>
                                    <option value="3" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;" translate>60+ days</option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.availableForRent.errors" class="invalid-feedback">
                                <div *ngIf="f.availableForRent.errors.required" translate>Total rent per month is
                                    required</div>
                                <div *ngIf="f.availableForRent.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.availableForRent.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Hospitality'&&selectedPropertyType==2">
                        <div class="asking-price include-error-hight">
                            <h3 translate>Furnished</h3>
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch"
                                fxLayout.xs="column" fxLayoutAlign.sm="center stretch"
                                fxLayout.sm="column">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.funishedState.errors }"
                                    formControlName="funishedState">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="{{funished.id}}" *ngFor="let funished of taxo.funishedState" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{funished.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center">
                        <!-- For Office Only and Retail -->
                        <div class="asking-price include-error-hight"
                            *ngIf="selectedType=='Businesses'&&selectedPropertyType==2">
                            <h3 translate>Freehold or Leasehold</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.holdType.errors }"
                                    formControlName="holdType">
                                    <option value="0" selected disabled hidden translate>Please Select</option>
                                    <option value="{{holdType.id}}" *ngFor="let holdType of taxo.holdType" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{holdType.name}}</option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.holdType.errors" class="invalid-feedback">
                                <div *ngIf="f.holdType.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.holdType.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.holdType.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>

                        <div class="asking-price second-div include-error-hight"
                            *ngIf="selectedType=='Businesses'&&selectedPropertyType==2">
                            <h3 translate># of Staff</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="width: 340px;"
                                    [ngClass]="{ 'has-error': submited && f.numEmployees.errors }"
                                    formControlName="numEmployees" numberFormat>
                            </div>
                            <div *ngIf="submited && f.numEmployees.errors" class="invalid-feedback">
                                <div *ngIf="f.numEmployees.errors.required" translate>Freehold or Leasehold is required
                                </div>
                                <div *ngIf="f.numEmployees.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numEmployees.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                    </div>
                    <!-- For Office Only -->
                    <div class="price-fields" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start center"
                        *ngIf="selectedType=='Office'&&selectedType!=='Retail'&&selectedType!=='Multifamily'&&selectedType!=='Industrial'">
                        <div class="asking-price include-error-hight">
                            <h3 translate>Parking Included (stalls)</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <select placeholder="Please Select"
                                    [ngClass]="{ 'has-error': submited && f.numParking.errors }"
                                    formControlName="numParking">
                                    <option value="0" disabled selected hidden translate>Please Select</option>
                                    <option value="{{parking}}" *ngFor="let parking of [0,1,2,3,4,5]" style=" width: 380px;
                                    padding: 16px;
                                    border-radius: 2px;
                                    border: 1px solid #dbdbdb;
                                    color: #444444;
                                    font-size: 15px;">
                                        {{parking}}<span *ngIf="parking===5">+</span></option>
                                </select>
                            </div>
                            <div *ngIf="submited && f.numParking.errors" class="invalid-feedback">
                                <div *ngIf="f.numParking.errors.required" translate>Number of parking is required</div>
                                <div *ngIf="f.numParking.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.numParking.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="asking-price second-div include-error-hight"
                            *ngIf="selectedType!=='Office'&&selectedPropertyType==2">
                            <h3 translate>Total Rent/Month (Baht)</h3>
                            <h3 style="width: 376px;">
                                <mat-checkbox [color]="'primary'" style="color: #444444;"
                                    formControlName="uponRequestPremisesRent">{{'Upon Request'|translate}}
                                </mat-checkbox>
                            </h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" style="border-right-width: 0px!important;width: 274px;"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }"
                                    formControlName="totalRentPerMonth" numberFormat>
                                <div class="baht-div"
                                    [ngClass]="{ 'has-error': submited && f.totalRentPerMonth.errors }">THB
                                </div>
                            </div>
                            <div *ngIf="submited && f.totalRentPerMonth.errors" class="invalid-feedback">
                                <div *ngIf="f.totalRentPerMonth.errors.required" translate>Total rent per month is
                                    required</div>
                                <div *ngIf="f.totalRentPerMonth.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.totalRentPerMonth.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                    </div>


                    <div class="price-fields" fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign="start start"
                        style="margin-top: 50px;">
                        <h3 translate>Description</h3>
                        <div class="asking-price " fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center stretch" fxLayoutAlign.xs="center stretch">
                            
                            <div class="chosse-language" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center stretch" fxLayoutAlign.xs="center stretch">
                                <a [class.selected]="slectedLanguageDescription==1"
                                    (click)="slectedLanguageDescription=1" translate>English</a>
                                <a [class.selected]="slectedLanguageDescription==2"
                                    (click)="slectedLanguageDescription=2" translate>Thai (optional)</a>
                            </div>
                            
                            <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs
                                fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                                <div class="help" translate>
                                    Be as descriptive as possible. This will help your ad come up in more searches.
                                    No external Links/URLS are allowed.
                                </div>
                            </div>
                            <div class="chosse-language full-width" fxLayout="row" fxLayoutAlign="start center"
                                *ngIf="slectedLanguageDescription==1">
                                <div class="input-div full-width" fxLayout="column" fxLayoutAlign="start end">
                                    <textarea placeholder="Description" 
                                  
                                        [ngClass]="{ 'has-error': submited && f.engDescription.errors }"
                                        formControlName="engDescription"></textarea>
                                    <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                                        <div fxFlex="70">
                                            <div *ngIf="submited && f.engDescription.errors" class="invalid-feedback">
                                                <div *ngIf="f.engDescription.errors.required" translate>Description is
                                                    required
                                                </div>
                                                <div *ngIf="f.engDescription.errors.minlength" translate>Minimum 2
                                                    characters
                                                </div>
                                                <!-- <div *ngIf="f.engDescription.errors.maxlength" translate>Maximum 2,000 Character
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                                            {{2000-f.engDescription.value.length}} {{'Character left'|translate}}</div> -->
                                    </div>
                                </div>
                                <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                                fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                                    <div class="arrow-left"></div>
                                    <div class="help" translate>
                                        Be as descriptive as possible. This will help your ad come up in more searches.
                                        No external Links/URLS are allowed.
                                    </div>
                                </div>
                            </div>
                            <div class="chosse-language  full-width" fxLayout="row" fxLayoutAlign="start center"
                                *ngIf="slectedLanguageDescription==2">
                                <div class="input-div full-width" fxLayout="column" fxLayoutAlign="start end">
                                    <textarea placeholder="Description"
                                    
                                        [ngClass]="{ 'has-error': submited && f.thDescription.errors }"
                                        formControlName="thDescription"></textarea>
                                    <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                                        <div fxFlex="70">
                                            <div *ngIf="submited && f.thDescription.errors" class="invalid-feedback">
                                                <div *ngIf="f.thDescription.errors.required" translate>Description is
                                                    required
                                                </div>
                                                <div *ngIf="f.thDescription.errors.minlength" translate>Minimum 2
                                                    characters</div>
                                                <!-- <div *ngIf="f.thDescription.errors.maxlength" translate>Maximum 2,000 Character
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                                            {{2000-f.thDescription.value.length}} {{'Character left'|translate}}</div> -->
                                    </div>
                                </div>
                                <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                                fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                                    <div class="arrow-left"></div>
                                    <div class="help" translate>
                                        Be as descriptive as possible. This will help your ad come up in more searches.
                                        No external Links/URLS are allowed.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="keywords-fields" fxLayout="row" fxLayoutAlign="start start">
                        <div class="asking-keywords" fxLayout="column" fxLayoutAlign="start stretch"
                            style="width:100%;">
                            <h3 translate>Key Search words</h3>
                            <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs
                            fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                                <div class="help" translate>
                                    Key Words drive people to your listing when they use a search engine like
                                    Google, Yahoo, Bing, etc. Think of words that people would use to search for
                                    your listing and insert here
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="input-div" fxLayout="column" fxLayoutAlign="start stretch">
                                    <mat-form-field class="example-chip-list" [color]="'#dbdbdb'" appearance="outline">
                                        <mat-chip-list #chipList>
                                            <mat-chip *ngFor="let keyword of keywords" [selectable]="selectable"
                                                [removable]="removable" (removed)="remove(keyword)">
                                                {{keyword}}
                                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Key Search words" [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="addOnBlur"
                                                (matChipInputTokenEnd)="add($event)">
                                        </mat-chip-list>
                                    </mat-form-field>
                                </div>
                                <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                                fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                                    <div class="arrow-left"></div>
                                    <div class="help" translate>
                                        Key Words drive people to your listing when they use a search engine like
                                        Google, Yahoo, Bing, etc. Think of words that people would use to search for
                                        your listing and insert here
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="media-fields" fxLayout="column" fxLayoutAlign="start start">
                        <h3 translate>Upload Photos (Up to 5 Images)</h3>
                        <div fxLayout="column" fxLayoutGap="20px" fxHide fxShow.xs
                        fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                            <div class="help-div" fxLayout="row" fxLayoutAlign="center center" >
                                
                                <div class="help" translate>
                                    We strongly suggest you to upload landscape images only otherwise, portrait
                                    images will be cropped.
                                </div>
                            </div>
                            <div class="help-div" fxLayout="row" fxLayoutAlign="center center">
                                
                                <div class="help" translate>
                                    Choose nice looking photos to help create interest in your ad. Click & drag to
                                    arrange the order of the images. The very top image will be used as your main
                                    image.
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="full-width">
                            <div class="input-div full-width" fxLayout="column" fxLayoutAlign="space-around stretch"
                                [ngClass]="{ 'has-error': submited && !mediaIds.length }">
                                <input type="file" name="files" class="fileUploader" />
                            </div>
                            <div fxLayout="column" fxLayoutGap="20px" fxShow fxHide.xs fxHide.gt-xs
                            fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                                <div class="help-div" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="arrow-left"></div>
                                    <div class="help" translate>
                                        We strongly suggest you to upload landscape images only otherwise, portrait
                                        images will be cropped.
                                    </div>
                                </div>
                                <div class="help-div" fxLayout="row" fxLayoutAlign="center center">
                                    <div class="arrow-left"></div>
                                    <div class="help" translate>
                                        Choose nice looking photos to help create interest in your ad. Click & drag to
                                        arrange the order of the images. The very top image will be used as your main
                                        image.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submited && !mediaIds.length" class="invalid-feedback" style="width: 100%;">
                            <div translate>Upload property image is required</div>
                        </div>
                    </div>

                    <div class="location-fields" fxLayout="column" fxLayoutAlign="start start">
                        <h3 translate>Location</h3>
                        <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxHide fxShow.xs
                        fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                            
                            <div class="help" translate>
                                Choose your location from map then every thing will be automatic filled in
                            </div>
                        </div>
                        <div class="input-div full-width">
                            <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
                                <div fxLayout="row" fxLayoutAlign="start start" class="full-width" >
                                    <div class="location-field full-width"  fxLayout="row" fxLayoutAlign="start start" fxLayout.xs="column" fxLayout.sm="column">
                                        <input type="text" [ngClass]="{ 'has-error': submited && f.location.errors }"
                                            formControlName="location" />
                                        <a class="show-on-map" (click)="openMapDialog()" translate>Show On Map</a>
                                    </div>
                                    <div class="help-div" fxLayout="row" fxLayoutAlign="center center" fxShow fxHide.xs fxHide.gt-xs
                                    fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                                        <div class="arrow-left"></div>
                                        <div class="help" translate>
                                            Choose your location from map then every thing will be automatic filled in.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="submited && f.location.errors" class="invalid-feedback"
                                    style="width: 100%;margin-top: -20px; margin-bottom: 10px;">
                                    <div *ngIf="f.location.errors.required" translate>Location is required</div>
                                </div>
                            </div>

                        </div>
                        <!-- <p>
                            <mat-checkbox [color]="'primary'" style="color: #444444;" id="showPropertyLocationOnMap"
                                formControlName="showPropertyLocationOnMap">I want to show my property location on Map
                            </mat-checkbox>
                        </p> -->
                    </div>

                </div>
                <div class="property-overview">
                    <div class="property-overview-heading">
                        <h1 translate>Contact Details</h1>
                    </div>
                </div>
                <div class="property-overview-fields">
                    <div class="contact-details" fxLayout="row" fxLayoutAlign="space-between end" fxLayout.xs="column" fxLayoutAlign.xs="start start" fxLayout.sm="column" fxLayoutAlign.sm="start start">
                        <div class="include-error-hight">
                            <h3 translate>Contact Name</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" placeholder="Contact Name"
                                    [ngClass]="{ 'has-error': submited && f.contactName.errors }"
                                    formControlName="contactName">
                            </div>
                            <div *ngIf="submited && f.contactName.errors" class="invalid-feedback" style="width: 100%;">
                                <div *ngIf="f.contactName.errors.required" translate>Contact name is required</div>
                                <div *ngIf="f.contactName.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.contactName.errors.maxlength" translate>Maximum 70 Character</div>
                            </div>
                        </div>
                        <div class="include-error-hight">
                            <h3 translate>Email</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" placeholder="Contact Email"
                                    [ngClass]="{ 'has-error': submited && f.contactEmail.errors }"
                                    formControlName="contactEmail">
                            </div>
                            <div *ngIf="submited && f.contactEmail.errors" class="invalid-feedback"
                                style="width: 100%;">
                                <div *ngIf="f.contactEmail.errors.required" translate>Contact email is required</div>
                                <div *ngIf="f.contactEmail.errors.minlength" translate>Minimum 2 characters</div>
                                <div *ngIf="f.contactEmail.errors.maxlength" translate>Maximum 70 Character</div>
                                <div *ngIf="f.contactEmail.errors.email" translate>Input a correct email</div>
                            </div>
                        </div>

                    </div>
                    <div class="contact-details" fxLayout="row" fxLayoutAlign="start end" fxLayout.xs="column" fxLayoutAlign.xs="start start" fxLayout.sm="column" fxLayoutAlign.sm="start start">
                        <div class="include-error-hight">
                            <h3 translate>Phone Number</h3>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <input type="text" placeholder="Phone Number"
                                    [ngClass]="{ 'has-error': submited && f.contactPhone.errors }"
                                    formControlName="contactPhone">
                            </div>
                            <div *ngIf="submited && f.contactPhone.errors" class="invalid-feedback"
                                style="width: 100%;">
                                <div *ngIf="f.contactPhone.errors.required" translate>Contact phone number is required
                                </div>
                                <div *ngIf="f.contactPhone.errors.minlength" translate>Minimum 9 characters</div>
                                <div *ngIf="f.contactPhone.errors.maxlength" translate>Maximum 12 Character</div>
                            </div>
                        </div>

                        <div class="hide-phone-class " fxLayout="column"
                            fxLayoutAlign="center center" fxLayoutAlign.xs="start start" fxLayoutAlign.sm="start start">
                            <mat-checkbox [color]="'primary'" style="color: #444444;"
                                formControlName="hidePhoneNumbers">{{'Hide phone number on ad'|translate}}
                            </mat-checkbox>
                        </div>

                    </div>
                    <div class="contact-details" fxLayout="row" fxLayoutAlign="start end">
                        <div class="include-error-hight">
                            <h3 translate>What is your preferred contact?</h3>
                            <mat-radio-group aria-label="Select an option" formControlName="preferredContact" fxLayout.xs="column" fxLayout.sm="column">
                                <mat-radio-button [value]="1" [color]="'primary'" style="color: #444444;" checked>
                                    {{'Email'|translate}}
                                </mat-radio-button>
                                <mat-radio-button [value]="2" [color]="'primary'" style="color: #444444;">
                                    {{'Mobile Call'|translate}}
                                </mat-radio-button>
                                <mat-radio-button [value]="3" [color]="'primary'" style="color: #444444;">
                                    {{'Mobile Text'|translate}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf="submited && f.preferredContact.errors" class="invalid-feedback"
                                style="width: 100%;">
                                <div *ngIf="f.preferredContact.errors.required" translate>Select a preferred contact
                                    method</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="property-overview">
                    <div class="property-overview-heading payment-div">
                        <h1 translate>Get Noticed</h1>
                        <p translate>Economical Banner Ads and color contrast are two of the most cost-effective ways to
                            market your listing. And they work.</p>
                        <payment-component (selectedPackageEvent)="getPackage($event)"
                            [packageFromListingData]="packageFromListingData" [listingId]="listingData?.id">
                        </payment-component>
                    </div>

                </div>
                <div class="properdi-package" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
                    <h1 translate>FREE LISTING ON OUR PARTNER’S SITE</h1>
                    <div class="inside-div" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayout.sm="column" fxLayoutAlign.sm="center center">
                        <img src="assets/images/logo/properdi-logo.png" />
                        <div fxLayout="column" fxLayoutAlign="start start" class="title-description-div"
                        >
                            <h1 translate>Post your listing to ProperDee</h1>
                            <p translate>
                                For maximum exposure, we will also post your listing for FREE on our premium sister
                                website ProperDee
                            </p>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center center" style="margin-right: 50px;">
                            <h1 style="color:#8CC63F;" translate>Free</h1>
                            <button type="button" mat-flat-button [color]="'primary'" *ngIf="shareToProperdi==true"
                                (click)="shareToProperdi=false">
                                <i class="mdi mdi-check"></i></button>

                            <button type="button" mat-flat-button [color]="'primary'" *ngIf="shareToProperdi==false"
                                (click)="shareToProperdi=true" style="font-size: 16px;">
                                {{'Add'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="post-listing-div" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxLayout.xs="column" fxLayout.sm="column">
                    <button type="button" mat-flat-button [color]="'primary'" 
                        (click)="submit()">{{listingData?'Update Listing':'Post Your Ad' |translate}}</button>
                    <div style="line-height: 1.5; margin-left: 50px; font-size: 13px;">
                        {{'By clicking "Post Your Ad", you acknowledge you have read - and you fully agree - to the'|translate}}
                        <a href="assets/doc/Listing-Policy.pdf" target="_blank"
                            style="text-decoration: none;color: #8cc63f;" translate>Listing Policy</a>
                        <span style="margin-right:10px;">,</span>
                        <a href="assets/doc/Terms-of-Service.pdf" target="_blank"
                            style="margin-right:10px;text-decoration: none;color: #8cc63f;" translate>Terms of
                            Service</a>
                        <span style="margin-right:10px;">& </span>
                        <a href="assets/doc/Privacy-Policy.pdf" target="_blank"
                            style="margin-right:10px;text-decoration: none;color: #8cc63f;" translate>Privacy Policy</a>
                    </div>
                    <!-- <button mat-flat-button [color]="'primary'" (click)="addForMockupPayment()">Submit Mokup
                        Payment</button> -->
                </div>
            </div>
        </form>
    </div>
    <div class="login-bg" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isAuthentic">
        <h2>{{'Sign in or Register'|translate}}<br> {{'to add your listings.'|translate}}</h2>
        <div class="loging-register-dialog">
            <td-login-register [notDialog]="true" (loginResponse)="checkAuthentication()"></td-login-register>
        </div>
    </div>
</div>