import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';

@Component({
  selector: 'td-dashboard-admin-reported-listings',
  templateUrl: './dashboard-admin-reported-listings.component.html',
  styleUrls: ['./dashboard-admin-reported-listings.component.scss']
})
export class DashboardAdminReportedListingsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();

  reportedList = [
  ];
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  @ViewChild('reportedDialog', {static: false}) reportedDialog: TemplateRef<any>;
  report = null;
  dialogRef = null;
  response = null;
  submited = false;
  taxonomy = null;
  constructor(private dashboardService: DashboardService, private changeDetection: ChangeDetectorRef,
    private utilService: UtilService, private dialog: MatDialog, private enumPropertyService: EnumPropertyService) { }

  ngOnInit() {
    
    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.taxonomy = data;
      this.getListOfMessages();
      this.changeDetection.detectChanges();
    });
  }

  getListOfMessages() {
    this.dashboardService.getAllReport(this.currentPage, 10).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
      

      this.reportedList = data;
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
    }, (err) => {
      
    })
  }

  deleteEventCalled(reportedId: number) {
    
    this.utilService.showAcceptDeclineDialog("You are about to delete a report!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
      
      if (accepet) {
        this.dashboardService.deleteReport(reportedId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          this.utilService.showToast('success',`#${reportedId} reported Deleted!`);
          this.getListOfMessages();
          this.changeDetection.detectChanges();
        }, (err) => {
          console.error(err)
          this.utilService.showToast('error',`There is an error deleting listing! Try again later.`);
        })
      }
    })

  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  showMessageDialog(reported) {
    
    this.report = reported;
    this.response = reported.response;
    this.dialogRef = this.dialog.open(this.reportedDialog, {
      panelClass: 'report-dailog',
    });
  }
  close() {
    this.dialogRef.close();
    this.getListOfMessages();
  }
  getEnumById(firstElement,type, secondElement, id) {
    let nameOrNumber = this.enumPropertyService.getEnumById(firstElement,type,  secondElement, id);
    return nameOrNumber;
  }
  suspendListing(listingId) {
    this.dashboardService.suspendListing(listingId, 12).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.utilService.showToast('success','Listing suspended! If you change your mind, make listing active from manage listing.');
    }, (err) => {
      this.utilService.showToast('error','Error in suspending listing, try again!');
    })
  }
  sendResponse() {
    const obj = {
      id: this.report.id,
      title: this.report.title,
      message: null,
      reportType: this.report.reportType,
      response: this.response
    }
    this.dashboardService.giveResponse(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.dialogRef.close();
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getListOfMessages();
  }
}
