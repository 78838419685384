const form_template = [
    {
        "type": "textBox",
        "formControl": "name",
        "placeholder": "Name"
    },
    {
        "type": "textBox",
        "formControl": "email",
        "placeholder": "Email"
    },
    {
        "type": "textBox",
        "formControl": "telephone",
        "placeholder": "Telephone"
    },
    {
        "type": "textBox",
        "formControl": "subject",
        "placeholder": "Subject"
    },
    {
      "type":"textArea",
      "formControl":"details",
      "placeholder": "Details"
    },
    {
        "type":"select",
        "formControl":"add",
        "placeholder": "Please select your text option",
        "options":["1",2]
      },
]
export default form_template

  // {
    //   "type":"textBox",
    //   "label":"favorite book",
    //   "options":["Jane Eyre","Pride and Prejudice","Wuthering Heights"]