import { TranslateService } from "@ngx-translate/core";
var LanguageService = /** @class */ (function () {
    function LanguageService(translateService) {
        this.translateService = translateService;
    }
    Object.defineProperty(LanguageService.prototype, "getDefaultLanguage", {
        get: function () {
            var defaultLanguage = navigator.language.includes("th") ? "th" : "en";
            return !!localStorage.getItem("language")
                ? localStorage.getItem("language")
                : defaultLanguage;
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.setLanguage = function (language) {
        localStorage.setItem("language", language);
        this.translateService.setDefaultLang(language);
        this.translateService.use(language);
        location.reload();
    };
    return LanguageService;
}());
export { LanguageService };
