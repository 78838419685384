import * as tslib_1 from "tslib";
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
var moment = _rollupMoment || _moment;
var CustomDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateAdapter, _super);
    function CustomDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        var formatString = 'MMMM YYYY';
        return moment(date).format(formatString);
    };
    return CustomDateAdapter;
}(NativeDateAdapter));
export { CustomDateAdapter };
import { NativeDateAdapter } from "@angular/material";
var MaterialModule = /** @class */ (function () {
    function MaterialModule() {
    }
    return MaterialModule;
}());
export { MaterialModule };
