import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ImageCropComponent } from 'src/image-crop/image-crop.component';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardUser } from 'src/app/dashboard/interface/dashboard-user';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { UtilService } from 'src/shared/services/util.service';
import { MediaService } from 'src/media/service/media.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'td-dashboard-side-bar',
  templateUrl: './dashboard-side-bar.component.html',
  styleUrls: ['./dashboard-side-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardSideBarComponent implements OnInit {
  defaultImage: any = 'assets/images/user/dashboard-avtar.png';
  @ViewChild('file', {static: false}) file;
  public files: Set<File> = new Set();

  imageChangedEvent: any = '';
  showCropper = false;
  destroy$: Subject<boolean> = new Subject();
  user: DashboardUser;
  profileImage: any;
  constructor(private changeDetection: ChangeDetectorRef, public dialog: MatDialog, private dashboardService: DashboardService,
    private loadingScreenService: LoadingScreenService,
    private utilService: UtilService, private mediaService: MediaService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadingScreenService.startLoading();
    if (this.route.snapshot.data.routeResolver) {
      this.user = this.route.snapshot.data.routeResolver;
      
      this.defaultImage = this.user.medias.length && this.mediaService.getProfileImage(this.user.medias).filePath ?
        environment.storageUrl + '/user/' + this.mediaService.getProfileImage(this.user.medias).filePath : 'assets/images/user/dashboard-avtar.png';
      this.changeDetection.detectChanges();
      
      this.loadingScreenService.stopLoading();
    } else {
      
      this.loadingScreenService.stopLoading();
    }
  }

  changePhoto() {
    
    this.file.nativeElement.click();
  }
  onFilesAdded(ev: Event) {
    this.loadingScreenService.startLoading();
    this.imageChangedEvent = ev;

    let dialogRef = this.dialog.open(ImageCropComponent, {
      panelClass: 'transparent',
      width: '900px',
      data: { file: ev }
    })
    const sub = dialogRef.componentInstance.onAdd.pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        
        this.loadingScreenService.stopLoading();
        this.defaultImage = data.ev.base64;
        this.changeDetection.detectChanges();
        this.profileImage = this.user.medias.filter((data) => {
          if (data.mediaTypeId == 20) {
            return data;
          } else {
            return null;
          }
        })
        
        if (data.cropButtonClick) {
          this.loadingScreenService.startLoading();
          if (this.profileImage.length) {
            this.deleteOldImage(this.profileImage[0].id)
          }
          this.uploadImage(data.ev.file);
          dialogRef.close();
        }

      }, (err) => {
        
        this.loadingScreenService.stopLoading();
        dialogRef.close();
      });

  }

  deleteOldImage(mediaId: number) {
    this.dashboardService.deleteOldProfileImage(mediaId).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
    });
  }
  uploadImage(file: File) {
    let formData = new FormData();
    formData.append('profileImage', file);
    this.dashboardService.profileMediaUpload(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        
        this.loadingScreenService.stopLoading();
      })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
