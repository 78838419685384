import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { UtilService } from 'src/shared/services/util.service';
import { MustMatch } from 'src/shared/custom-function/helper';
import { takeUntil, map, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'td-dashboard-my-profile',
  templateUrl: './dashboard-my-profile.component.html',
  styleUrls: ['./dashboard-my-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardMyProfileComponent implements OnInit {
  type: number[] = [];
  updateProfileForm: FormGroup = this.fb.group({
    email: [{ value: null, disabled: true }],
    firstName: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    lastName: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    phoneNumber: [null, [Validators.required, Validators.maxLength(70), Validators.minLength(9)]],
    userRoleId: [null, [Validators.required]],
    password: [null,],
    confirmPassword: [null],
    languageId: [null, [Validators.required]],
    userSpecialities: [[], [Validators.required]],

  }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  isAuthentic: boolean = false;
  submited: boolean = false;
  data: any;
  destroy$: Subject<boolean> = new Subject();
  constructor(private fb: FormBuilder,
    private changeDetection: ChangeDetectorRef, private authService: AuthService, private loadingScreenService: LoadingScreenService,
    private utilService: UtilService, private dashboardService: DashboardService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loadingScreenService.startLoading();

    this.data = this.route.snapshot.data.routeResolver;
    let array = [];
    this.data.userSpecialities.forEach(element => {
      array.push(element.propertyType);
    });
    array ? this.updateProfileForm.controls['userSpecialities'].setValue(array) : this.updateProfileForm.controls['userSpecialities'].setValue([]);
    array ? this.type = array : this.type = [];
    this.updateProfileForm.controls['firstName'].setValue(this.data.firstName);
    this.updateProfileForm.controls['lastName'].setValue(this.data.lastName);
    this.updateProfileForm.controls['phoneNumber'].setValue(this.data.phoneNumber);
    this.updateProfileForm.controls['userRoleId'].setValue(this.data.userRole);
    this.updateProfileForm.controls['languageId'].setValue(this.data.languageId);
    this.updateProfileForm.controls['email'].setValue(this.data.email)

    this.loadingScreenService.stopLoading();

  }
  public get f() { return this.updateProfileForm.controls; }


  onSubmit() {
    this.submited = true;
    this.loadingScreenService.startLoading();
    
    
    this.updateProfileForm.controls['userSpecialities'].setValue(this.type);
    let profileObj = {
      firstName: this.updateProfileForm.value.firstName,
      lastName: this.updateProfileForm.value.lastName,
      email: this.updateProfileForm.value.email,
      password: this.updateProfileForm.value.password ? this.updateProfileForm.value.password : null,
      userRoleId: parseInt(this.updateProfileForm.value.userRoleId),
      languageId: parseInt(this.updateProfileForm.value.languageId),
      userSpecialities: this.updateProfileForm.value.userSpecialities,
      phoneNumber: this.updateProfileForm.value.phoneNumber,
    }
    if (this.updateProfileForm.invalid) {
      this.loadingScreenService.stopLoading();
      this.utilService.showToast('error',"You need to provide more information about the profile!");

      return;
    }
    this.dashboardService.updateProfile(profileObj,0).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.utilService.showToast('success',"Your profile updated!");
      this.loadingScreenService.stopLoading();
    }, (err) => {
      
      this.utilService.showToast('error',"Error updating your profile, try again later!");
      this.loadingScreenService.stopLoading();
    })
  }

  chnageUserSpecialities(type: number) {
    var i = this.type.indexOf(type);
    if (i === -1) {
      if (this.type.length === 3) {
        this.utilService.showToast('error',"Maximum 3 allowed")
      } else {
        this.type.push(type);
        this.changeDetection.detectChanges();
      }
    }
    else {
      this.type.splice(i, 1);
      this.changeDetection.detectChanges();
    }

    this.updateProfileForm.controls['userSpecialities'].setValue(this.type);
    this.changeDetection.detectChanges();
  }
   
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
