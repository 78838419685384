import { from } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
var GooglemapsService = /** @class */ (function () {
    function GooglemapsService() {
    }
    GooglemapsService.prototype.reverseGeocoding = function (_a, language) {
        var lat = _a.lat, lng = _a.lng;
        if (language === void 0) { language = "en"; }
        return from(fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&language=" + language + "&key=" + environment.googleMapApiKey, { method: "GET" }).then(function (response) { return response.json(); })).pipe(map(function (_a) {
            var results = _a.results;
            return (!!results.length ? results[0] : null);
        }));
    };
    GooglemapsService.prototype.buildGoogleMapsAddress = function (adresses) {
        return adresses
            .filter(function (address) {
            return !address.types.some(function (type) {
                return type === "street_number" ||
                    type === "route" ||
                    type === "country" ||
                    type === "postal_code" ||
                    type === "bus_station" ||
                    type === "establishment" ||
                    type === "point_of_interest" ||
                    type === "transit_station";
            });
        })
            .reduce(function (acu, _a, index, arr) {
            var long_name = _a.long_name;
            return acu + ("" + long_name + (index !== arr.length - 1 ? ", " : ""));
        }, "");
    };
    return GooglemapsService;
}());
export { GooglemapsService };
