<div class="manage-payment-history" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
    <h5>Payment History</h5>
    
  </div>
  <div class="filter" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
    <label>Date</label>
    <div>
      <input matInput [matDatepicker]="picker" placeholder="From" [(ngModel)]="fromDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div>
      <input matInput [matDatepicker]="picker1" placeholder="To" [(ngModel)]="toDate">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </div>
    <div>
      <button mat-flat-button [color]="'primary'" (click)="currentPage=0;getAllBills()">Search</button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Invoice No. </th>
      <td mat-cell *matCellDef="let element">
        {{element.number}} </td>
    </ng-container>
    <!-- Position Column -->
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Billing ID </th>
      <td mat-cell *matCellDef="let element">
        {{element.id.substring(0, 4)}}....{{element.id.substr(element.id.length - 4)}} </td>
    </ng-container> -->
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef> Customer Name </th>
      <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'short':'UTC'}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Amount </th>
      <td mat-cell *matCellDef="let element"> {{element.finalPrice}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Payment Method </th>
      <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Status </th>
      <td mat-cell *matCellDef="let element">
        <div class="fan-buttons" fxLayout="row" fxLayoutAlign="end center">
          <span>{{element.paymentStatus}} </span>
          
          <button class="fab-button edit" mat-mini-fab [color]="'primary'" (click)="getBillPdf(element.id)"
            style="margin-left:10px;" matTooltip="Download bill as pdf" matTooltipPosition="above"><i class="mdi mdi-download" style="color:white;"></i></button>

        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
</div>