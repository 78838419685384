import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  constructor(public http: HttpClient) { }

  getNotification() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Notifications`)
  }

  getProfile() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}account/genericUser`)
  }
  updateProfile(profile, id = 0) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}account/${id}`, profile)
  }

  profileMediaUpload(formData) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Media/Upload?mediaType=20`, formData)
  }
  deleteOldProfileImage(mediaId: number) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Media?mediaId=${mediaId}&mediaType=20`)
  }
  getAllListing(pageNumber = 1, pageSize = 20, listingObj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Listing/Search?page=${pageNumber}&pageSize=${pageSize}&orderBy=createdAt&asc=false`, listingObj)
  }

  deleteListing(id: number) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}listing/${id}`)
  }

  getListOfMessageForAdmin(pageNumber = 1, pageSize = 10, obj,asc) {

    return this.http.post(`${environment.serverUrl}${environment.apiUrl}SupportMessage/Search/Mine?page=${pageNumber}&pageSize=${pageSize}&asc=${asc}`, obj)
  }
  getListOfMessageForUsers(pageNumber = 1, pageSize = 10, obj) {

    return this.http.post(`${environment.serverUrl}${environment.apiUrl}SupportMessage/Search/Mine?page=${pageNumber}&pageSize=${pageSize}&asc=true`, obj)
  }


  deleteMessage(id: number) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}SupportMessage/${id}`)
  }
  updateMessageStatus(id) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}SupportMessage/${id}/read`, {})
  }

  saveAnnouncement(obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Announcement`, obj);
  }
  updateAnnouncement(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}Announcement`, obj);
  }
  deleteAnnouncement(AnnouncementId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Announcement/${AnnouncementId} `);
  }

  getAlerts(currentPage, pageSize) {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Alerts?page=${currentPage}&pageSize=${pageSize}`);
  }
  updateAlerts(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}Alerts`, obj);
  }
  deleteAlerts(alertId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Alerts/${alertId}`);
  }

  getReportResponseFromAdmin() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}listing/Reports?orderby=createdAt&asc=false`)
  }

  activeListingFor30Days(listingId) {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Listing/expire/extend?listingId=${listingId}`)
  }
  getAllBills(pageNumber, pageSize, obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}bills/search?page=${pageNumber}&pageSize=${pageSize}`, obj)
  }

  //admin

  //testimonial
  saveTestimonial(obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Testimonial`, obj);
  }
  updateTestimonial(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}Testimonial`, obj);
  }
  deleteTestimonial(TestimonialId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Testimonial/${TestimonialId} `);
  }
  getAllTestimonial(pageNumber: number, pageSize: number, assDec: boolean = true, filter: string = null) {

    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Testimonial/Search/Mine?page=${pageNumber}&pageSize=${pageSize}&asc=${assDec}`, { 'filter': filter });
  }

  getAllReport(pageNumber, pageSize) {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Listing/Reports?page=${pageNumber}&pageSize=${pageSize}&orderby=createdAt&asc=false`);
  }

  deleteReport(reportId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}listing/report/${reportId} `);
  }

  giveResponse(obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}listing/report/response`, obj);
  }

  getTipsOfTheDay() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}TipOfTheDay`)
  }
  updateTipsOfTheDay(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}TipOfTheDay`, obj)
  }
  suspendListing(listingId: number, statusId: number) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}/status/${statusId}`, {})
  }
  saveArticle(obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}articles`, obj);
  }
  updateArticle(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}articles`, obj);
  }
  deleteArticle(articleId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}articles/${articleId} `);
  }

  searchAllArticle(pageNumber: number, pageSize: number, obj) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}articles/Search/Admin?page=${pageNumber}&pageSize=${pageSize}&orderby=date&asc=false`, obj);
  }

  getAllUserForAdmin(pageNumber: number, pageSize: number, obj, asc) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}account/Search?page=${pageNumber}&pageSize=${pageSize}&asc=${asc}`, obj)
  }
  deleteUserForAdmin(userId) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}account/${userId}`)
  }

  changeUserStatus(userId, stautsId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}account/${userId}/status/${stautsId}`, {});
  }
  getNumberOfListingInApproveListing() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Listing/ammounts`)
  }
  getApproveListing(pageNumber = 1, pageSize = 20, obj) {

    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Listing/Search?page=${pageNumber}&pageSize=${pageSize}&orderby=createdAt&asc=false`, obj)
  }
  updateListingStatus(listingId, statusId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}/status/${statusId}`, {})
  }
  getAllAnnouncement(page, pageSize, obj, asc) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}announcement/Search?page=${page}&pageSize=${pageSize}&orderBy=createdAt&asc=${asc}`, obj)
  }

  updateNotificationRead(notificationId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}notifications/${notificationId}/Read`, {})
  }
  deleteNotification(id) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Notifications/${id}`)
  }

  getBillPdf(billId) {
    const options = { headers: { skip: "true" } };
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf',
        'Cache-Control': 'no-cache'
      }),

      responseType: 'blob' as 'json'
    };
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Bills/${billId}/pdf`, httpOptions)
  }
  sendBillToUser(billId) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Bills/${billId}/SendEmail`, {});
  }
  getAllLeads() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}leads/Search`)
  }
  deleteLeads(id) {
    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Leads/${id}`)
  }
  sentToAgent(id, type) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}Leads/${id}/sent/${type}`, {})
  }
  //if need dialog and send from platform to agents .currently not use
  sendLeadToAgent(obj) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}leads`, obj)
  }
  downloadLead(leadId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf',
        'Accept': 'application/pdf',
        'Cache-Control': 'no-cache'
      }),

      responseType: 'blob' as 'json'
    };
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}leads/${leadId}/Pdf`, httpOptions)
  }
  getSingleListing(listingId) {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Listing/${listingId}/Edit`);
  }

  adminUpdatePropertyTypeOfListing(listingId, propertyTypeId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}/PropertyType/${propertyTypeId}`, {});
  }
  adminUpdateResidentialTypeIdOfListing(listingId, residentialTypeId) {
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}/ResidentialType/${residentialTypeId}`, {});
  }

  updatePropertyType(obj){
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}Listing/AdditionalTypes`, obj);
  }

  generalMetersForAdmin(){
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}metrics/general`)
  }
  getLineGroup(listingId){
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Share/Line/${listingId}/Groups`)
  }
  
  shareToLine(listingId, groups){
    
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Share/Line/${listingId}`,groups)
  }
}
