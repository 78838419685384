import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { UtilService } from "src/shared/services/util.service";
import { MAT_DIALOG_DATA } from "@angular/material";
import { FileUploaderConfiguration } from "src/media/interfaces/file-uploader-configuration.interface";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-cropper-dialog",
  template: `
    <div class="cropperd-dialog-container">
      <div
        class="button-container"
        fxLayout="row"
        fxLayoutAlign="end"
        fxLayoutGap="4%"
      >
        <button mat-raised-button mat-dialog-close color="accent">
          Cancel
        </button>
        <button
          mat-raised-button
          [disabled]="!file"
          [mat-dialog-close]="file"
          color="primary"
        >
          Accept Image
        </button>
      </div>
      <image-cropper
        [imageChangedEvent]="data.fileEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="data.aspectRatio"
        [resizeToWidth]="data.width"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      >
      </image-cropper>
    </div>
  `,
  styleUrls: ["./cropper-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropperDialogComponent implements OnInit {
  file: Blob = null;
  base64Img: string = null;
  constructor(
    private utilService: UtilService,
    @Inject(MAT_DIALOG_DATA) public data: FileUploaderConfiguration
  ) {}

  ngOnInit() {}
  imageCropped({ file, base64 }: ImageCroppedEvent) {
    if (file.size / 1024 / 1024 < this.data.fileSize) {
      this.file = file;
      this.base64Img = base64;
    } else {
      this.utilService.showToast('error',
        `The file is to big must be less than ${
          this.data.fileSize
        }MB please select another file`
      );
    }
  }
  loadImageFailed() {
    this.utilService.showToast('error',
      `Image couldn't been selected please pick another image and ensure is a jpg or png file`
    );
  }
  imageLoaded() {}
}
