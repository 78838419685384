import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/core/service/language/language.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
constructor(public http: HttpClient, private languageService: LanguageService) { }

  getPublicArticle(page,pageSize,obj,asc){
    const options = { headers: new HttpHeaders().set('Accept-Language', this.languageService.getDefaultLanguage) };
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}articles/Search?page=${page}&pageSize=${pageSize}&orderBy=date&asc=${asc}`,obj,options)
  }
  getAllAnnouncementPublic(page,pageSize,asc,obj){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}announcement/Search/Public?page=${page}&pageSize=${pageSize}&orderBy=createdAt&asc=${asc}`,obj)
  }
}
