import { Injectable } from "@angular/core";
import { MatSnackBar, MatDialog } from "@angular/material";
import { AceptDeclineComponent } from "../components/acept-decline/accept-decline.component";
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class UtilService {
  isDesktop: boolean = matchMedia('(min-width: 960px)').matches;
  constructor(private matSnackBar: MatSnackBar, private dialog: MatDialog, private toastr: ToastrService) { }
  showToast(type: string = 'success', message: string, duration: number = 3000, title: string = '', ) {
    this.toastr[type](title, message, {
      timeOut: duration
    });
  }

  showAcceptDeclineDialog(message: string, subMessage: string = "Are you sure ?", width: string = "250px") {
    return this.dialog
      .open(AceptDeclineComponent, {
        width,
        data: { message, subMessage }
      })
      .afterClosed();
  }
  getPreviousElementId(elementId: string, arr: any[]): string {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === elementId) {
        const { id } = arr[i - 1] || arr[0];
        return id;
      }
    }
  }
  getNextElementId(elementId: string, arr: any[]): string {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === elementId) {
        const { id } = arr[i + 1] || arr[0];
        return id;
      }
    }
  }

  numberFormatter(num) {
    if (num >= 1000000000) {
      return `${Math.floor(num / 1000000000)}G`;
    }
    if (num >= 1000000) {
      return `${Math.floor(num / 1000000)}M`;
    }
    if (num >= 1000) {
      return `${Math.floor(num / 1000)}K`;
    }
    return num;
  }
  removeComma(number) {
    let input = number;
    if (input === "") {
      return null;
    } else if (typeof input == 'string') {
      input = input.replace(/\,/g, '');
      return Number(input);
    } else if (typeof input == 'number') {
      return input;
    }

  }
}
