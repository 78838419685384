/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-admin-xml-feed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-admin-xml-feed.component";
var styles_DashboardAdminXmlFeedComponent = [i0.styles];
var RenderType_DashboardAdminXmlFeedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardAdminXmlFeedComponent, data: {} });
export { RenderType_DashboardAdminXmlFeedComponent as RenderType_DashboardAdminXmlFeedComponent };
export function View_DashboardAdminXmlFeedComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" dashboard-admin-xml-feed works!\n"]))], null, null); }
export function View_DashboardAdminXmlFeedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-dashboard-admin-xml-feed", [], null, null, null, View_DashboardAdminXmlFeedComponent_0, RenderType_DashboardAdminXmlFeedComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardAdminXmlFeedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardAdminXmlFeedComponentNgFactory = i1.ɵccf("td-dashboard-admin-xml-feed", i2.DashboardAdminXmlFeedComponent, View_DashboardAdminXmlFeedComponent_Host_0, {}, {}, []);
export { DashboardAdminXmlFeedComponentNgFactory as DashboardAdminXmlFeedComponentNgFactory };
