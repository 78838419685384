<div class="location-picker-container" *ngIf="position">

  <div class="separator-xs">
    <div class="map-container" fxLayout="column">
      <div class="location-button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end end">
        <button (click)="getMyLocation()" mat-mini-fab color="primary">
          <i class="mdi mdi-crosshairs"></i>
        </button>
      </div>
      <agm-map [streetViewControl]="true" [zoomControl]="false" [gestureHandling]="'greedy'" [latitude]="position.lat"
        [longitude]="position.lng" [zoom]="zoom" [disableDefaultUI]="false" (mapClick)="getMarkerPosition($event)"
        [mapTypeControl]='true'>
        <agm-marker [markerDraggable]="true" (dragEnd)="getMarkerPosition($event)" (markerClick)="clickedMarker($event)"
          [latitude]="position.lat" [longitude]="position.lng"></agm-marker>
      </agm-map>
    </div>
  </div>
  <div class="separator-xs" style="margin: 20px 0;     line-height: 1.5;
  font-weight: bolder;
  font-size: 20px;" translate>
    Or
  </div>
  <div fxLayout="column" class="google-input-container">

    <h5 translate>Enter a location</h5>
    <input [placeholder]="configuration?.placeholder || null" id="googleInput" type="text" matInput />
    <p style="line-height: 1.5;
    font-weight: bolder;
    font-size: 20px;">
      {{text}}</p>
  </div>
</div>