/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-admin-extra.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dashboard-admin-extra.component";
var styles_DashboardAdminExtraComponent = [i0.styles];
var RenderType_DashboardAdminExtraComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardAdminExtraComponent, data: {} });
export { RenderType_DashboardAdminExtraComponent as RenderType_DashboardAdminExtraComponent };
export function View_DashboardAdminExtraComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" dashboard-admin-extra works!\n"]))], null, null); }
export function View_DashboardAdminExtraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-dashboard-admin-extra", [], null, null, null, View_DashboardAdminExtraComponent_0, RenderType_DashboardAdminExtraComponent)), i1.ɵdid(1, 114688, null, 0, i2.DashboardAdminExtraComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardAdminExtraComponentNgFactory = i1.ɵccf("td-dashboard-admin-extra", i2.DashboardAdminExtraComponent, View_DashboardAdminExtraComponent_Host_0, {}, {}, []);
export { DashboardAdminExtraComponentNgFactory as DashboardAdminExtraComponentNgFactory };
