export const environment = {
  production: true,
  serverUrl: "https://thaiprop-api-uat.azurewebsites.net/",
  apiUrl: "api/",
  graphQlApi:"graphql/",
  storageUrl: "https://thaidoormediauat.blob.core.windows.net",
  googleMapApiKey: "AIzaSyD8q8f_lSiHhP1za-ybs9vOrE8jc22fBg4",
  websocketUrl: 'wss://echo.websocket.org/',
  appUrl: 'https://thailandcommercial.com',
  facebookId: '679254455937587',
  linkedinId: '81cxvmmartx6cl',
  reCaptchaKey: '6Lcq98cUAAAAAMbrrB2rzT-IHnyQqVnFcMm2ahPQ',
  paypalKey: 'Ad8-E7bqPpTOtXIdfKNsemaJbhBfNoBE2KljJt1k56BxGo_4JAX5sn8MZNDL6nB7rhKqsR6TTdvoe8Oz',
  paypalEnv: 'production'
};
