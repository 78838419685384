import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(http) {
        this.http = http;
        this.options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
        this.fullPaymentObj = null;
    }
    PaymentService.prototype.getPackageDetails = function (packageId, packageItemId, packageItemDetailId) {
        if (packageId === void 0) { packageId = null; }
        if (packageItemId === void 0) { packageItemId = null; }
        if (packageItemDetailId === void 0) { packageItemDetailId = null; }
        var packageObject = "{\"query\" : \"{ packages(id:" + packageId + ") {  id name description packageTypeId createdAt updatedAt medias{id filePath } packageItems(id:" + packageItemId + "){id name order createdAt updatedAt packageId  medias{id filePath } packageItemDetails(id:" + packageItemDetailId + "){ id order name price days createdAt updatedAt packageItemId  medias{id filePath } }} } }   \"}";
        return this.http.post(environment.serverUrl + "graphql/Package", packageObject, this.options);
    };
    PaymentService.prototype.getBillDetails = function (billId) {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Bills/" + billId + "/PaymentPreview");
    };
    PaymentService.prototype.createOrder = function (orderObj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "orders", orderObj, this.options);
    };
    PaymentService.prototype.payForBill = function (paymentObj, billid) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "payments/bills/" + billid + "/pay", paymentObj, this.options);
    };
    // getBillDetails(billId){
    //   let billObject=`{
    //     "query" : '{ billPackageDetails ( billId: \"${billId}\") 
    //     {  price listing{id}   }}'
    // }`
    //   // let billObject = `{"query" : "{ billPackageDetails (billId : \"${billId}\"   ){  billId  listingId   } }  "}`;
    //   return this.http.post(`${environment.serverUrl}graphql/billPackageDetail`, billObject, this.options)
    // }
    PaymentService.prototype.updateNewText = function (listingId, billId, from, to) {
        return this.http.put("" + environment.serverUrl + environment.apiUrl + "listing/" + listingId + "/bill/" + billId + "/" + from + "/" + to, {});
    };
    PaymentService.prototype.getProducts = function () {
        return this.http.get("" + environment.serverUrl + environment.apiUrl + "Products");
    };
    PaymentService.prototype.paypalPayment = function (billId) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "payments/paypal/" + billId + "/payment", {});
    };
    PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
