/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../login-register/login-register.component.ngfactory";
import * as i6 from "../login-register/login-register.component";
import * as i7 from "../../service/login-register.service";
import * as i8 from "../../../shared/services/util.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/forms";
import * as i11 from "ng-recaptcha/recaptcha/recaptcha-v3.service";
import * as i12 from "@angular/common";
import * as i13 from "./login-screen.component";
import * as i14 from "@angular/router";
var styles_LoginScreenComponent = [i0.styles];
var RenderType_LoginScreenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginScreenComponent, data: {} });
export { RenderType_LoginScreenComponent as RenderType_LoginScreenComponent };
function View_LoginScreenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "login-bg"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(4, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Sign in or Register"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "loging-register-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td-login-register", [], null, [[null, "loginResponse"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginResponse" === en)) {
        var pd_0 = (_co.checkAuthentication() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_LoginRegisterComponent_0, i5.RenderType_LoginRegisterComponent)), i1.ɵdid(8, 245760, null, 0, i6.LoginRegisterComponent, [i7.LoginRegisterService, i8.UtilService, i9.MatDialogRef, i10.FormBuilder, i1.ChangeDetectorRef, i11.ReCaptchaV3Service], { notDialog: [0, "notDialog"] }, { loginResponse: "loginResponse" })], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_3 = true; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_LoginScreenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginScreenComponent_1)), i1.ɵdid(1, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isAuthentic; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoginScreenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td-login-screen", [], null, null, null, View_LoginScreenComponent_0, RenderType_LoginScreenComponent)), i1.ɵdid(1, 245760, null, 0, i13.LoginScreenComponent, [i7.LoginRegisterService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginScreenComponentNgFactory = i1.ɵccf("td-login-screen", i13.LoginScreenComponent, View_LoginScreenComponent_Host_0, {}, {}, []);
export { LoginScreenComponentNgFactory as LoginScreenComponentNgFactory };
