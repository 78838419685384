<div class="announcements-screen">
  <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 20px;">
    <h1 *ngIf="!announcement" translate>Announcements</h1>
    <h1 *ngIf="announcement"><span>{{announcement.title}}</span>
    </h1>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <h3 *ngIf="!announcement" translate>Sort by</h3>
      <select *ngIf="!announcement" [(ngModel)]="assDec" (change)="getAllAnnouncment()">
        <option value='false' translate>Newest</option>
        <option value='true'>Oldest</option>
      </select>
      <a (click)="announcement=null" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <i *ngIf="announcement" class="mdi mdi-view-module" style="margin-top: 10px!important;font-size: 36px;"></i>
        <span *ngIf="announcement" style="font-size: 18px;" translate>View All Announcements</span>
      </a>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="!announcement&&announcementsData">
    <div class="announcement-card" *ngFor="let item of announcementsData" fxFlex="22">
      <div class="image" [ngStyle]="{'background': 'url('+storage+'/announcement/'+item.media+')'}"></div>
      <div class="add-padding">
        <h3>{{item?.title}}</h3>
      </div>
      <div class="add-padding" fxLayout="row" fxLayoutAlign="space-between center"
        style="height: 10px;margin: 0 0 10px 5px;">
        <p class="date">{{item.createdAt | date}}</p>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="showFull(item)"><span translate>Continue Reading</span>
          <i class="mdi mdi-chevron-right"></i></a>
      </div>
    </div>
  </div>
  <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)" *ngIf="!announcement&&announcementsData&&numberOfpage.length!=0" > </td-pagination>
  
  <div *ngIf="announcement" class="announcement-card" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px"
    style="padding: 20px;">

    <div fxFlex="60" class="image"
      [ngStyle]="{'background': 'url('+storage+'/announcement/'+announcement.media+')','height': '500px'}">
    </div>
    <div fxFlex="40" fxLayout="column" fxLayoutAlign="start stretch">
      <p class="date" style="text-align: right;">{{announcement.createdAt  | date}}</p>
      <p class="description">{{announcement.description}}</p>
      <div fxLayout="row" fxLayoutAlign="end"  class="share-div" fxLayoutGap="20px">
        <a (click)="shareToFb(announcement)"><img src="assets/images/social-icons/facebook.png" /></a>
        <a (click)="shareToLine(announcement)"> <img src="assets/images/social-icons/line.png" /></a>
        <a target="_blank"
          href="https://twitter.com/intent/tweet?url={{buildUrl(announcement)}}&text={{announcement?.title}}%0A{{announcement?.description.substr(0, 50)}}%0A&hashtags=thailandcommercial"><img
            src="assets/images/social-icons/twitter.png" /></a>
        <a target="_blank"
          href="http://www.linkedin.com/shareArticle?mini=true&url={{buildUrl(announcement)}}&title={{announcement?.title}}&summary={{announcement?.description.substr(0, 50)}}&source=thailandcommercial.com"><img
            src="assets/images/social-icons/linkedin.png" /></a>
      </div>
    </div>
  </div>
  <div *ngIf="!announcement&&!announcementsData" class="announcement-card" fxLayout="row" fxLayoutAlign="start start"
    fxLayoutGap="20px" style="padding: 20px;">
    <h1 translate>No Announcements to show </h1>
  </div>
</div>