import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArticleService } from 'src/article-announcemnets/services/article.service';
import { ActivatedRoute } from '@angular/router';
declare var FB: any;
@Component({
  selector: 'td-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementComponent implements OnInit {
  announcementsData = [];
  announcement = null;
  destroy$: Subject<boolean> = new Subject();
  storage: string = environment.storageUrl;
  assDec = false;
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 2
  }
  numberOfpage = [];
  announcementId = null;
  constructor(private articleService: ArticleService, private changeDetection: ChangeDetectorRef, private activatedRoute: ActivatedRoute) { window.scrollTo(0, 0) }

  ngOnInit() {
    this.getAllAnnouncment();
    this.announcementId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  getAllAnnouncment() {

    let obj = {
      id: null,
      filter: null
    }
    this.articleService.getAllAnnouncementPublic(this.currentPage, 12, this.assDec, obj).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {

      this.announcementsData = data;
      if (this.announcementId) {
        this.announcementsData.forEach((element) => {
          if (element.id == this.announcementId) {
            this.announcement = element;
          }
        })
      }
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.numberOfpage = [];
      for (var i = 0; i < this.pageInfo.totalPages; i++) {
        this.numberOfpage.push(i + 1);
        this.changeDetection.detectChanges();
      }

    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllAnnouncment();
  }
  showFull(item) {
    this.announcement = item;
  }
  buildUrl(announcement) {
    const subUrl = 'announcements';
    return `${environment.appUrl}/${subUrl}/${announcement.id}`;
  }
  shareToLine(announcement) {
    open(`https://timeline.line.me/social-plugin/share?url=${this.buildUrl(announcement)}&title=${announcement.title}`);
  }
  shareToFb(announcement) {
    let obj={
      "og:url" : this.buildUrl(announcement),
      "og:type": "website",
      "og:title": announcement.title,
      "og:description": announcement.description?announcement.description.substr(0, 20):null,
      "og:image": environment.storageUrl + '/announcement/' + announcement.media,
      "og:image:width": "450",
      "og:image:height": "298"
    };
    FB.init({
      appId: environment.facebookId,
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4"
    });
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        redirect_uri: this.buildUrl(announcement),
        action_properties: JSON.stringify({
          object: obj
        })
      },
      (response) => {

        if (response) {

        } else {

        }
      }, (err) => {

      }
    );
  }

}
