import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'td-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentDialogComponent implements OnInit {
  packageFormGroup :FormGroup=this.fb.group({
    selectMainPackage:['Text From System'],
    selectedPackageItem:[null,Validators.required],
    customText:['', [Validators.maxLength(30), Validators.minLength(2)]],
    selectedPackageItemDetails:[null,Validators.required]
  });
  regularDistribution = 100 / 3 + '%';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PaymentDialogComponent>,private fb:FormBuilder, 
        private changeDetection: ChangeDetectorRef) { 
          this.packageFormGroup.controls['selectedPackageItem'].setValue(0, {onlySelf: true});
        }

  ngOnInit() {
    

    if(this.data.id==4){
      this.packageFormGroup.controls['selectedPackageItem'].setValue(this.data);
      this.packageFormGroup.controls['selectMainPackage'].setValue('Text From System');
      this.changeDetection.detectChanges();
      
      
    }
  }
  selectedTab(tab){
    this.packageFormGroup.controls['selectMainPackage'].setValue(tab);
  }
  
  submit(){
    if (this.packageFormGroup.invalid) {
      
      
      // this.utilService.showToast("You need to provide more information about the listing!");

      return;
    }
    
    let obj={
      selectedItem:this.packageFormGroup.value.selectedPackageItem,
      selectedPackageItemDetails:this.packageFormGroup.value.selectedPackageItemDetails,
      customText:this.packageFormGroup.value.customText?this.packageFormGroup.value.customText:null
    }
    this.dialogRef.close(obj);
  }
  close(){
    this.dialogRef.close();
  }
  changePackageToCustome(){
    this.packageFormGroup.controls['selectedPackageItem'].setValue(this.data);
    this.changeDetection.detectChanges();
    // this.data.packageItems.forEach(element => {
    //   if(element.name=='Custom text'){
    //     this.packageFormGroup.controls['selectedPackageItem'].setValue(element);
    //     this.changeDetection.detectChanges();
    //   }
    // });
  }
  addedpackage(packageItem){
    this.packageFormGroup.controls['selectedPackageItem'].setValue(packageItem);
    this.changeDetection.detectChanges();
  }
}
