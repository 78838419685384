import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'td-dashboard-payment-histories',
  templateUrl: './dashboard-admin-payment-histories.component.html',
  styleUrls: ['./dashboard-admin-payment-histories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminPaymentHistoriesComponent implements OnInit {

  dataSource: any[] = [
  ];
  currentPage = 0;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  displayedColumns: string[] = ['id', 'customerName', 'date', 'amount', 'symbol', 'status'];

  destroy$: Subject<boolean> = new Subject;
  fromDate = new Date(2018, 0, 1);
  toDate = new Date();
  constructor(private dashboardService: DashboardService, private utilService: UtilService, private changeDetection: ChangeDetectorRef) { }

  ngOnInit() {
    this.getAllBills();
  }

  getAllBills() {
    let obj = {
      from: this.fromDate,
      to: this.toDate
    }
    this.dashboardService.getAllBills(this.currentPage, 10, obj).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {


      this.dataSource = data;
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
    })
  }
  getBillPdf(id) {
    this.dashboardService.getBillPdf(id).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.downloadFile(data, 'invoice.pdf')
      // var url = window.URL.createObjectURL(data);
      // window.open(url);
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllBills();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
  }
  // sendBill(billId){
  //   this.dashboardService.sendBillToUser(billId).pipe(takeUntil(this.destroy$)).subscribe((data)=>{
  //     
  //     this.utilService.showToast('Bill Send to User');
  //   })
  // }

}
