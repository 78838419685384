<div class="footer-container" fxLayout="row wrap" fxLayoutAlign="space-evenly start">
    <div>
        <a [routerLink]="['/']">
            <img src="assets/images/logo/thaicom.svg" alt="" height="150">
        </a>
        <div style="margin-top:10px;text-align: center;line-height: 1.5;" fxShow fxHide.xs fxHide.gt-xs
        fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
            <a href="assets/doc/Privacy-Policy.pdf" target="_blank" style="text-decoration: none;color: #8cc63f;"
                translate>Privacy Policy</a>
            <span style="margin-right:10px;">,</span>
            <a href="assets/doc/Listing-Policy.pdf" target="_blank"
                style="margin-right:10px;text-decoration: none;color: #8cc63f;" translate>Listing Policy</a><br>
            <span style="margin-right:10px;">& </span>
            <a href="assets/doc/Terms-of-Service.pdf" target="_blank"
                style="margin-right:10px;text-decoration: none;color: #8cc63f;" translate>Terms of Service</a>
        </div>
    </div>


    <div class="footer-box" fxLayout="column" fxLayoutAlign="space-between start" fxShow fxHide.xs fxHide.gt-xs
    fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
        <h3 translate>Partners</h3>
        <a href="#" target="_blank">
            <p translate>TeeYuu.com</p>
        </a>
        <a href="https://properdee.com/" target="_blank">
            <p translate>ProperDee.com</p>
        </a>
        <!-- <a href="https://ilovecondo.net/" target="_blank">
            <p translate>ilovecondo.net</p>
        </a> -->
        <a href="https://www.bahtsold.com/" target="_blank">
            <p translate>BahtSold.com</p>
        </a>
        <p> </p>
    </div>
    <div class="footer-box" fxLayout="column" fxLayoutAlign="space-between start" fxShow fxHide.xs fxHide.gt-xs
    fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
        <h3 translate>Company</h3>
        <p translate>Thailandcommercial.com is owned</p>
        <p translate>by Commercial Services Co., Ltd.,</p>
        <p translate>a Thailand registered company</p>
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px" style="margin-top: 20px;">
            <a href="https://www.facebook.com/thailandcommercial/"><img src="assets/images/social-icons/facebook.png" /></a>
            <a href="https://www.instagram.com/thailandcommercial/"> <img src="assets/images/social-icons/instagram.png" /></a>
            <a target="_blank"
            href="https://twitter.com/thailandcomm"><img
                    src="assets/images/social-icons/twitter.png" /></a>
            <a target="_blank"
            href="https://www.linkedin.com/in/thailandcommercial/"><img
                    src="assets/images/social-icons/linkedin.png" /></a>
        </div>
    </div>
    <div class="footer-box" fxLayout="column" fxLayoutAlign="space-between start" fxShow fxHide.xs fxHide.gt-xs
    fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
        <h3 translate>Contact Us</h3>
        <p translate>47 Srisothorn Place Building, 3rd Floor,</p>
        <p translate>Unit 3E, Soi Sukhumvit 23, Sukhumvit Rd,</p>
        <p translate>Klongtoey nuea, Wattana, Bangkok 10110,</p>
        <p translate>Thailand</p>
        <p translate>Phone Number: +66 (0)94.197.1410</p>
        <button mat-button (click)="openMapDialog()">{{'Send Us An Email'|translate}}</button>
    </div>
    <div fxHide fxShow.xs fxShow.gt-xs
    fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
        <button mat-fab color="primary" (click)="openMapDialog()"><i class="mdi mdi-email"></i></button>
    </div>
</div>