import { Component, OnInit, Inject, EventEmitter, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'td-image-cropper',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {

  @Output() onAdd = new EventEmitter<any>(true);
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropButtonClick: boolean = false;
  @ViewChild(ImageCropperComponent, {static: false}) imageCropper;
  constructor(public dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    
    this.imageChangedEvent = this.data.file;
  }

  ngOnInit() {

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    
    this.onAdd.emit({ ev: event, cropButtonClick: this.cropButtonClick });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  cropped() {
    this.cropButtonClick = true;
    this.imageCropper.crop();
  }


}
