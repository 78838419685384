import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FourZeroFourComponent } from 'src/shared/components/four-zero-four/four-zero-four.component';
import { UserRouteResolver } from './user-route-resolver';

const routes: Routes = [
 
  {
    path: "",
    loadChildren: "../home/home.module#HomeModule",
    resolve: {
      userRouteResolver: UserRouteResolver
    },
  },
  
  {
    path: "404",
    component: FourZeroFourComponent
  },
  { path: '**', redirectTo: '404'},
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
  providers: [UserRouteResolver]
})
export class AppRoutingModule { }
