<div class="resetpassword-bg" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isAuthentic">

  <div class="reset-password-div">
    <h2 translate>Reset Your Password</h2>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">


      <div class="input-div" fxLayout="column" fxLayoutAlign="center center">
        <input type="password" placeholder="Password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid':  f.password.touched && f.password.errors }" />
        <div *ngIf=" f.password.touched && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required" translate>Password is required
          </div>
          <div *ngIf="f.password.errors.minlength" translate>Minimum 6 characters
          </div>
          <div *ngIf="f.password.errors.maxlength" translate>Maximum 14 Character
          </div>
        </div>
        <input type="password" placeholder="Confirm Password" formControlName="cPassword" class="form-control"
          [ngClass]="{ 'is-invalid':  f.cPassword.touched && f.cPassword.errors }" />
        <div *ngIf=" f.cPassword.touched && f.cPassword.errors" class="invalid-feedback">
          <div *ngIf="f.cPassword.errors.required" translate>Confirm password is required
          </div>
          <div *ngIf="f.cPassword.errors.minlength" translate>Minimum 6 characters
          </div>
          <div *ngIf="f.cPassword.errors.maxlength" translate>Maximum 14 Character
          </div>
          <div *ngIf="f.cPassword.errors.mustMatch" translate>Password and confirm password doesn't matched
          </div>
        </div>
        <button mat-flat-button class="login-button" [color]="'primary'" [disabled]="submitted||resetPasswordForm.invalid">
          {{'Change password'|translate}}</button>
      </div>
    </form>
  </div>
</div>