<mat-toolbar class="navbar-container" id="navbar-container" [class.navbar-nothomepage]="location=='/add-listing'|| 
        location.match('/dashboard')||
        location.match('/listing-detail/')||
        location.match('/404')||
        location.match('/search')||
        location.match('/payment-confirmation')||
        location.match('/calculate')||
        location.match('/payment-error')||
        location.match('/payment-success')||
        location.match('/announcements')||
        location.match('/article')||
        location.match('/login-register')||location.match('/recoverPassword')" fxLayout="row"
  fxLayoutAlign="space-between center" fxLayoutAlign.sm="space-between center" fxLayoutAlign.xs="space-between center">
  <div fxHide.gt-sm>

    <button mat-icon-button (click)="onToggleSidenav()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div fxHide.gt-sm [routerLink]="['/']" (click)="closeSideNav()">

    <img src="assets/images/logo/thaicom.svg" style="height:100px;margin-top: 10px;" />

  </div>
  <div fxHide.gt-sm fxFlex="20"></div>
  <div class="navbar-center-container" fxHide.sm fxHide.xs fxLayout="row" fxLayoutAlign="space-between center">

    <div fxFlex="20" class="logo-container">
      <a [routerLink]="['/']">
        <img src="assets/images/logo/thaicom.svg" />
        <div class="bottom-bar" [ngClass]="{'thai-bar': language=='th'}"></div>
      </a>
    </div>
    <div fxFlex="80" class="links-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4%">
      <div>
        <a (click)="goto()">{{'Articles' | translate}}</a>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="language-icon" [ngStyle]="{
          'background-image': language == 'th'?'url(/assets/images/language/lang.png)': 'url(/assets/images/language/thai.png)'
          }" (click)="setLanguage(language== 'en'?'th':'en')"></div>

        <!-- <button (click)="setLanguage(language)">{{ language === "en" ? "Thai" :"English"  }}
          
        </button> -->
        <!-- <div class="dropdown"><i class="mdi mdi-chevron-down"></i>
        <div class="dropdown-content">
          <a (click)="setLanguage('en')">English</a>
          <a (click)="setLanguage('th')">Thai</a>
        </div>
      </div> -->
    </div>
    <div>
      <a *ngIf="!isAuthentic" (click)="openDialog('','myDialog')">{{'Sign in / Register' | translate}}</a>
      <a *ngIf="isAuthentic" (click)="openDialog(alreadyLogin,'alreadyLogin')">{{userProfil?.email}}</a>
    </div>
    <div>
      <a [routerLink]="['/add-listing']" class="add-listing-button">
        <i class="mdi mdi-plus-circle" style="font-size:18px;"></i>
        {{'Add Listing' | translate}}</a>
    </div>
  </div>
  </div>
</mat-toolbar>





<ng-template #alreadyLogin>
  <mat-dialog-content>
    <mat-list role="list">
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:['home-dashboard']}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Dashboard' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'profile-dashboard'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%;">
          <span>{{'My Profile' | translate}} </span>

          <span class="badge" ngif>{{percentageProfile}}%</span>
        </div>
      </mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-ads-dashboard'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Manage My Ads' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-my-fav-dashboard'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Favorite Ads' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-message'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Message' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-payment-history'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Payment History' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'manage-support'}}]"
        [routerLinkActive]="'active'" *ngIf="!isAdmin">{{'Support' | translate}}</mat-list-item>
      <mat-list-item role="listitem" [routerLink]="['/dashboard',{outlets:{dashboardOutlet:'home-dashboard'}}]"
        [routerLinkActive]="'active'" *ngIf="isAdmin">{{'Admin Dashboard' | translate}}</mat-list-item>
      <mat-list-item role="listitem" (click)="logout()">{{'Logout' | translate}}</mat-list-item>
    </mat-list>
  </mat-dialog-content>
</ng-template>

<ng-template #showFirstLoginDialog>
  <mat-dialog-content>
    <form [formGroup]="firstDiloagForm" (ngSubmit)="submitFirstLogin()">
      <div fxLayout="column" fxLayoutAlign="start start" style="margin-bottom: 50px;">
        <h5>{{'What is your role?' | translate}}</h5>
        <select placeholder="Please Select" [ngClass]="{ 'has-error': firstLoginSubmitted && f.userRole.errors }"
          formControlName="userRole">
          <option value="1">{{'Property Agent' | translate}}</option>
          <option value="2">{{'Owner' | translate}}</option>
        </select>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" *ngIf="firstDiloagForm.get('userRole').value">
        <h5>{{'Please Confirm your primary expertise (maximum of 3)' | translate}}</h5>
        <div class="available-box choose-search-type" fxLayout="row wrap" fxLayoutAlign="center">
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/residential-income.svg" />
            </div>
            <h3>{{'Multifamily' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/office.svg" />
            </div>
            <h3>{{'Office' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/retail.svg" />
            </div>
            <h3>{{'Retail' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/industrial.svg" />
            </div>
            <h3>{{'Industrial' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/hospitality.svg" />
            </div>
            <h3>{{'Hospitality' | translate}}</h3>
          </div>

          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/speciality.svg" />
            </div>
            <h3>{{'Specialty' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/land.svg" />
            </div>
            <h3>{{'Land' | translate}}</h3>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/businesses.svg" />
            </div>
            <h3>{{'Businesses' | translate}}</h3>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 70px;">
        <button mat-flat-button [color]="'primary'">{{'Submit' | translate}}</button>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>

<ng-template #languageDialog>
  <mat-dialog-content style="width:500px;">
    <div fxLayout="row" fxLayoutAlign="end end"><i class="mdi mdi-close" (click)="close()" style="cursor: pointer; font-size:20px;"></i></div>
    <div style="font-size:22px;" fxLayoutGap="20px">
      We appreciate your patience. We are working on the Thai language website now. Please check back soon
    </div>
    <div style="font-size:22px;margin-top:20px;" fxLayoutGap="20px">
      ในขณะนี้เรากำลังปรับปรุงเว็บไซต์เวอร์ชั่นภาษาไทย กรุณากลับมาใหม่ในเร็วๆนี้ ขอบคุณที่กรุณาอดทนรอ
    </div>
  </mat-dialog-content>
</ng-template>