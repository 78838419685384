<div class="layout-container" id="layout-container" (scroll)="onWindowScroll();">
  <mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav class="sideNav" (opened)="sidenavCloseOrOpen(true)" [mode]="'side'" fixedInViewport="true"
    (closed)="sidenavCloseOrOpen(false)">
      <td-sidenav (sidenavClose)="sidenav.close()"></td-sidenav>

    </mat-sidenav>
    <mat-sidenav-content>
      <app-navbar (sidenavToggle)="sidenav.toggle()" (sidenavClose)="sidenav.close()"></app-navbar>
      <main class="layout-content-container" id="layout-content-container" (click)="sidenav.close()"  [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet #o="outlet"></router-outlet>
      </main>
      
      <div class="footer-container" *ngIf="!location.match('/search')&&!(location.match('/listing-detail')&&matcher.matches)">
        <app-footer></app-footer>
      </div>
      
    </mat-sidenav-content>
  </mat-sidenav-container>


  <a id="button-go-top" class="scrollToTop" style="display: none;" (click)="topClick()"><i
    class="mdi mdi-chevron-up"></i></a>
</div>