import { NgModule } from "@angular/core";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from "@angular/common/http";

import { MatSnackBarModule } from "@angular/material";
import { AuthGuard } from './guards/auth-guard.guard';
import { IdbService } from './service/idb/idb.service';
import { AuthService } from './service/auth/auth.service';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { TokenInterceptor } from 'src/login-register/interceptor/token.interceptor';
import { PaymentModule } from 'src/payment-component/payment.module';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from "@ngx-translate/core";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageService } from './service/language/language.service';
import { WebSocketService } from './service/web-socket/web-socket';
import { EnumPropertyService } from './service/enum-property/enum-property.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}
@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    MatSnackBarModule,
    PaymentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule,HttpClientModule,PaymentModule],
  providers: [LoginRegisterService ,
  {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    WebSocketService,
    EnumPropertyService
  ]
})
export class CoreModule {
  constructor(
    private translateService: TranslateService,
    private webSocketService: WebSocketService,
    private languageService: LanguageService) {

      const language = this.languageService.getDefaultLanguage;
      this.translateService.setDefaultLang(language);
      this.translateService.use(language);
      
      // this.webSocketService.constructor();
      
  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        AuthGuard,
        IdbService,
        AuthService,
        LanguageService,
        WebSocketService,
        EnumPropertyService
      ]
    };
  }
}
