/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/footer/footer.component.ngfactory";
import * as i3 from "../components/footer/footer.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i6 from "@angular/material/sidenav";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/cdk/scrolling";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/cdk/platform";
import * as i12 from "@angular/common";
import * as i13 from "../sidenav/sidenav.component.ngfactory";
import * as i14 from "../sidenav/sidenav.component";
import * as i15 from "@angular/forms";
import * as i16 from "@angular/router";
import * as i17 from "../../login-register/service/login-register.service";
import * as i18 from "../../core/service/auth/auth.service";
import * as i19 from "../../shared/services/util.service";
import * as i20 from "../../shared/services/loading/loading.service";
import * as i21 from "../../core/service/language/language.service";
import * as i22 from "@ngx-translate/core";
import * as i23 from "../components/navbar/navbar.component.ngfactory";
import * as i24 from "../components/navbar/navbar.component";
import * as i25 from "./layout.component";
import * as i26 from "@angular/cdk/layout";
import * as i27 from "../../core/service/enum-property/enum-property.service";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "0.10s" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "0.10s" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "footer-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-footer", [], null, null, null, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(2, 114688, null, 0, i3.FooterComponent, [i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { sidenav: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "div", [["class", "layout-container"], ["id", "layout-container"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "mat-sidenav-container", [["class", "mat-drawer-container mat-sidenav-container"], ["fullscreen", ""]], [[2, "mat-drawer-container-explicit-backdrop", null]], null, null, i5.View_MatSidenavContainer_0, i5.RenderType_MatSidenavContainer)), i1.ɵdid(3, 1490944, null, 2, i6.MatSidenavContainer, [[2, i7.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i6.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i8.ANIMATION_MODULE_TYPE], [2, i9.ViewportRuler]], null, null), i1.ɵqud(603979776, 2, { _drawers: 1 }), i1.ɵqud(335544320, 3, { _content: 0 }), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "mat-sidenav", [["class", "sideNav mat-drawer mat-sidenav"], ["fixedInViewport", "true"], ["tabIndex", "-1"]], [[40, "@transform", 0], [1, "align", 0], [2, "mat-drawer-end", null], [2, "mat-drawer-over", null], [2, "mat-drawer-push", null], [2, "mat-drawer-side", null], [2, "mat-sidenav-fixed", null], [4, "top", "px"], [4, "bottom", "px"]], [[null, "opened"], [null, "closed"], ["component", "@transform.start"], ["component", "@transform.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("component:@transform.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._animationStarted.next($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@transform.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7)._animationEnd.next($event) !== false);
        ad = (pd_1 && ad);
    } if (("opened" === en)) {
        var pd_2 = (_co.sidenavCloseOrOpen(true) !== false);
        ad = (pd_2 && ad);
    } if (("closed" === en)) {
        var pd_3 = (_co.sidenavCloseOrOpen(false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_MatSidenav_0, i5.RenderType_MatSidenav)), i1.ɵdid(7, 3325952, [[2, 4], [1, 4], ["sidenav", 4]], 0, i6.MatSidenav, [i1.ElementRef, i10.FocusTrapFactory, i10.FocusMonitor, i11.Platform, i1.NgZone, [2, i12.DOCUMENT]], { mode: [0, "mode"], fixedInViewport: [1, "fixedInViewport"] }, { _openedStream: "opened", _closedStream: "closed" }), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "td-sidenav", [], null, [[null, "sidenavClose"]], function (_v, en, $event) { var ad = true; if (("sidenavClose" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_SidenavComponent_0, i13.RenderType_SidenavComponent)), i1.ɵdid(9, 245760, null, 0, i14.SidenavComponent, [i15.FormBuilder, i4.MatDialog, i16.Router, i1.ChangeDetectorRef, i16.ActivatedRoute, i17.LoginRegisterService, i18.AuthService, i19.UtilService, i20.LoadingScreenService, i21.LanguageService, i22.TranslateService], null, { sidenavClose: "sidenavClose" }), (_l()(), i1.ɵeld(10, 0, null, 1, 8, "mat-sidenav-content", [["class", "mat-drawer-content mat-sidenav-content"]], [[4, "margin-left", "px"], [4, "margin-right", "px"]], null, null, i5.View_MatSidenavContent_0, i5.RenderType_MatSidenavContent)), i1.ɵdid(11, 1294336, [[3, 4]], 0, i6.MatSidenavContent, [i1.ChangeDetectorRef, i6.MatSidenavContainer, i1.ElementRef, i9.ScrollDispatcher, i1.NgZone], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "app-navbar", [], null, [[null, "sidenavToggle"], [null, "sidenavClose"]], function (_v, en, $event) { var ad = true; if (("sidenavToggle" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).toggle() !== false);
        ad = (pd_0 && ad);
    } if (("sidenavClose" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i23.View_NavbarComponent_0, i23.RenderType_NavbarComponent)), i1.ɵdid(13, 245760, null, 0, i24.NavbarComponent, [i15.FormBuilder, i4.MatDialog, i16.Router, i1.ChangeDetectorRef, i16.ActivatedRoute, i17.LoginRegisterService, i18.AuthService, i19.UtilService, i20.LoadingScreenService, i21.LanguageService, i22.TranslateService], null, { sidenavToggle: "sidenavToggle", sidenavClose: "sidenavClose" }), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "main", [["class", "layout-content-container"], ["id", "layout-content-container"]], [[24, "@fadeAnimation", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(16, 212992, [["o", 4]], 0, i16.RouterOutlet, [i16.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LayoutComponent_1)), i1.ɵdid(18, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 1, "a", [["class", "scrollToTop"], ["id", "button-go-top"], ["style", "display: none;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.topClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "mdi mdi-chevron-up"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_10 = "side"; var currVal_11 = "true"; _ck(_v, 7, 0, currVal_10, currVal_11); _ck(_v, 9, 0); _ck(_v, 11, 0); _ck(_v, 13, 0); _ck(_v, 16, 0); var currVal_15 = (!_co.location.match("/search") && !(_co.location.match("/listing-detail") && _co.matcher.matches)); _ck(_v, 18, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._backdropOverride; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7)._animationState; var currVal_2 = null; var currVal_3 = (i1.ɵnov(_v, 7).position === "end"); var currVal_4 = (i1.ɵnov(_v, 7).mode === "over"); var currVal_5 = (i1.ɵnov(_v, 7).mode === "push"); var currVal_6 = (i1.ɵnov(_v, 7).mode === "side"); var currVal_7 = i1.ɵnov(_v, 7).fixedInViewport; var currVal_8 = (i1.ɵnov(_v, 7).fixedInViewport ? i1.ɵnov(_v, 7).fixedTopGap : null); var currVal_9 = (i1.ɵnov(_v, 7).fixedInViewport ? i1.ɵnov(_v, 7).fixedBottomGap : null); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 11)._container._contentMargins.left; var currVal_13 = i1.ɵnov(_v, 11)._container._contentMargins.right; _ck(_v, 10, 0, currVal_12, currVal_13); var currVal_14 = (i1.ɵnov(_v, 16).isActivated ? i1.ɵnov(_v, 16).activatedRoute : ""); _ck(_v, 14, 0, currVal_14); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 770048, null, 0, i25.LayoutComponent, [i16.Router, i26.MediaMatcher, i27.EnumPropertyService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("app-layout", i25.LayoutComponent, View_LayoutComponent_Host_0, {}, {}, []);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
