<div class="manage-alerts" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width:100%;">
    <h5 translate>Manage Alerts</h5>
    <span fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" *ngIf="alerts?.length">
      <mat-divider class="mat-divider mat-divider-horizontal"></mat-divider>
      <div class="message-body" fxLayout="row" fxLayoutAlign="space-between center"
        *ngFor="let alert of alerts; let i = index">
        <mat-slide-toggle class="example-margin" [color]="'primary'" [checked]="alert.enable"
          (change)="updateAlertEnable($event, alert)">{{alert.enable?'On':'Off' |translate}}
        </mat-slide-toggle>
        <div class="left-text" fxFlex="70" fxLayout="column" fxLayoutAlign="center start">
          <h5>{{'Alert'|translate}} {{i+1}}</h5>
          <p>{{getEnumById('listingType',alert?.listingType,false)?.name}} -
            {{getEnumById('residentialType',alert?.residentialType,false)?.name ? getEnumById('residentialType',alert?.residentialType,false)?.name:getEnumById('propertyType',alert?.propertyType,false)?.name}}
          </p>
        </div>
        <div class="right-text" fxFlex="10" fxLayout="column" fxLayoutAlign="space-evenly center">
          <div class="fan-buttons" fxLayout="row" fxLayoutAlign="space-evenly center">
            <eco-fab-speed-dial direction="left" animationMode="scale">
              <eco-fab-speed-dial-trigger spin="true">
                <button mat-fab [color]="'primary'"><i class="mdi mdi-dots-horizontal"
                    style="color:white;"></i></button>
              </eco-fab-speed-dial-trigger>

              <eco-fab-speed-dial-actions>
                <button class="fab-button delete" mat-mini-fab (click)="deleteAlert(alert.id)"><i
                    class="mdi mdi-delete-outline"></i></button>
                <button class="fab-button edit" mat-mini-fab (click)="openAleartDialog(alert)"><i
                    class="mdi mdi-square-edit-outline"></i></button>
              </eco-fab-speed-dial-actions>
            </eco-fab-speed-dial>
          </div>
        </div>
      </div>
      <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
    </span>
    <span *ngIf="!alerts?.length" translate>
      No alerts to show!
    </span>
  </div>

</div>

<ng-template #createAleartDialog>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button (click)="closeAlert()"><i class="mdi mdi-close"></i></button>
    </div>
    <div *ngIf="!aleartSubmitted">
      <div class="add-aleart" fxLayout="column" fxLayoutAlign="start start">
        <h3 translate>Choose Category Alert</h3>
        <div>
          <p translate>Property</p>
        </div>
        <div class="property-type" fxLayout="row" fxLayoutAlign="start start">
          <div *ngFor="let lT of [{name:'For Sale', id:1}, {name:'For Rent', id:2}]">
            <div class="box" [class.selected-box]="selectedListingType==lT.id" *ngIf="lT.id!=3"
              (click)="selectedListingType=lT.id;selectedType=null; selectedSubType=null;" fxLayout="column"
              fxLayoutAlign="center center">
              <div class="box-circle" fxLayout="column" fxLayoutAlign="center center">
                <i class="mdi mdi-home-group" *ngIf="lT.id==1"></i>
                <i class="mdi mdi-door-open" *ngIf="lT.id==2"></i>
              </div>
              <p>{{lT.name}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="selectedListingType">
          <p translate>Select Type</p>
        </div>
        <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="selectedListingType"
          style="position: relative;">

          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.disabled]="selectedListingType===2"
            [class.selected]="selectedType=='Multifamily'"
            (click)="selectedType='Multifamily';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/residential-income.svg" *ngIf="selectedListingType!==2" />
              <img src="/assets/images/icons/residential-income-disabled.svg" *ngIf="selectedListingType===2" />
            </div>
            <p translate>Multifamily</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Office'"
            (click)="selectedType='Office';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/office.svg" />
            </div>
            <p translate>Office</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Retail'"
            (click)="selectedType='Retail';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/retail.svg" />
            </div>
            <p translate>Retail</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Industrial'"
            (click)="selectedType='Industrial';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/industrial.svg" />
            </div>
            <p translate>Industrial</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center"
            [class.selected]="selectedType=='Hospitality'" (click)="selectedType='Hospitality';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/hospitality.svg" />
            </div>
            <p translate>Hospitality</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Specialty'"
            (click)="selectedType='Specialty';selectedSubType=null;">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/speciality.svg" />
            </div>
            <p translate>Specialty</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Land'"
            (click)="selectedType='Land';selectedSubType=null;">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/land.svg" />
            </div>
            <p translate>Land</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedType=='Businesses'"
            (click)="selectedType='Businesses';selectedSubType=null">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <img src="/assets/images/icons/businesses.svg" />
            </div>
            <p translate>Businesses</p>
          </div>
        </div>

        <div *ngIf="selectedType&&selectedType!=='Specialty'&&selectedType!=='Land'">
          <p>{{selectedType}}</p>
        </div>
        <div class="type" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedType=='Multifamily'">
          <div class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="selectedSubType=='Condo'"
            (click)="selectedSubType='Condo'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-office-building"></i>
            </div>
            <p translate>Condo</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='House/Villa'" (click)="selectedSubType='House/Villa'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home"></i>
            </div>
            <p translate>House/Villa</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Townhouse'" (click)="selectedSubType='Townhouse'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-city-outline"></i>
            </div>
            <p translate>Townhouse</p>
          </div>
          <div class="box" fxLayout="column" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Apartment'"
            (click)="selectedSubType='Apartment'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-modern"></i>
            </div>
            <p translate>Apartment</p>
          </div>
        </div>
        <div class="type" fxLayout="row" fxLayoutAlign="center center" style="width:100%;"
          *ngIf="selectedType=='Office'||selectedType=='Retail'">
          <div class="box" fxLayout="column" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Single Building'" (click)="selectedSubType='Single Building'"
            style="width:100%;">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-office-building"></i>
            </div>
            <p translate>Single Building</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Shophouse'" (click)="selectedSubType='Shophouse'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home"></i>
            </div>
            <p translate>Shophouse</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" *ngIf="selectedType!=='Retail'"
            fxLayoutAlign="center center" [class.selected]="selectedSubType=='Office Complex'"
            (click)="selectedSubType='Office Complex'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-city-outline"></i>
            </div>
            <p translate>Office Complex</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" *ngIf="selectedType=='Retail'"
            fxLayoutAlign="center center" [class.selected]="selectedSubType=='Convenience Center'"
            (click)="selectedSubType='Convenience Center'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-city-outline"></i>
            </div>
            <p translate>Convenience Center</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" *ngIf="selectedType=='Retail'"
            fxLayoutAlign="center center" [class.selected]="selectedSubType=='Shopping Mall'"
            (click)="selectedSubType='Shopping Mall'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-modern"></i>
            </div>
            <p translate>Shopping Mall</p>
          </div>
        </div>

        <div class="type" fxLayout="row" fxLayoutAlign="center center" style="width:100%;"
          *ngIf="selectedType=='Industrial'">

          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Single-Story'" (click)="selectedSubType='Single-Story'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-office-building"></i>
            </div>
            <p translate>Single-Story</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Multi-Story'" (click)="selectedSubType='Multi-Story'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-city-outline"></i>
            </div>
            <p translate>Multi-Story</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Factory'" (click)="selectedSubType='Factory'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-factory"></i>
            </div>
            <p translate>Factory</p>
          </div>
        </div>

        <div class="type" fxLayout="row" fxLayoutAlign="center center" style="width:100%;"
          *ngIf="selectedType=='Hospitality'">

          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Single Hotel'" (click)="selectedSubType='Single Hotel'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-office-building"></i>
            </div>
            <p translate>Single Hotel</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Resort'" (click)="selectedSubType='Resort'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-city-outline"></i>
            </div>
            <p translate>Resort</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Boutique'" (click)="selectedSubType='Boutique'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-modern"></i>
            </div>
            <p translate>Boutique Hotel</p>
          </div>
          <div class="box" fxLayout="column" style="width:100%;" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Hostel'" (click)="selectedSubType='Hostel'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-bed-empty"></i>
            </div>
            <p translate>Hostel</p>
          </div>
        </div>


        <div class="type" fxLayout="row wrap" fxLayoutAlign="center center" style="width:100%;"
          *ngIf="selectedType=='Businesses'">

          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Restaurant/Bar'" (click)="selectedSubType='Restaurant/Bar'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-glass-cocktail"></i>
            </div>
            <p translate>Restaurant/Bar</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Spa/Massage'" (click)="selectedSubType='Spa/Massage'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-spa-outline"></i>
            </div>
            <p translate>Spa/Massage</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Food/Drink Stall/Cart'"
            (click)="selectedSubType='Food/Drink Stall/Cart'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-food"></i>
            </div>
            <p translate>Food/Drink Stall/Cart</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Services'" (click)="selectedSubType='Services'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-settings-outline"></i>
            </div>
            <p translate>Services</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Health & Beauty'" (click)="selectedSubType='Health & Beauty'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-shoe-heel"></i>
            </div>
            <p translate>Health & Beauty</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Education'" (click)="selectedSubType='Education'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-school"></i>
            </div>
            <p translate>Education</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Entertainment'" (click)="selectedSubType='Entertainment'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-music-circle-outline"></i>
            </div>
            <p translate>Entertainment</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Manufacturing & Agriculture'"
            (click)="selectedSubType='Manufacturing & Agriculture'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-home-variant"></i>
            </div>
            <p translate>Manufacturing & Agriculture</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Franchisee'" (click)="selectedSubType='Franchisee'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-axis-arrow"></i>
            </div>
            <p translate>Franchisee</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Logistics'" (click)="selectedSubType='Logistics'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-worker"></i>
            </div>
            <p translate>Logistics</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Financial'" (click)="selectedSubType='Financial'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-cash-multiple"></i>
            </div>
            <p translate>Financial</p>
          </div>
          <div class="box" fxLayout="column" style="width:33%" fxLayoutAlign="center center"
            [class.selected]="selectedSubType=='Other'" (click)="selectedSubType='Other'">
            <div class="circle" fxLayout="column" fxLayoutAlign="center center">
              <i class="mdi mdi-arrow-decision-outline"></i>
            </div>
            <p translate>Other</p>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <p translate>Location:</p> &nbsp;&nbsp;&nbsp;
          <a (click)="openMapDialog()" style="color:#8cc63f;" *ngIf="!area" translate>Choose from Map</a>
          <div *ngIf="area" style="color:#8cc63f;" fxLayout="row" fxLayoutAlign="space-evenly center"
            fxLayoutGap="20px">
            <span>{{area}}</span>
            <button mat-mini-fab color="primary" fxHide.sm fxHide.xs (click)="clearSearch()">
              <i class="mdi mdi-close" style="color:white;"></i>
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 70px;">
        <button mat-flat-button [color]="'primary'" (click)="updateAlert()"
          [disabled]="!area||!selectedSubType">{{'Update' | translate}}</button>
      </div>
    </div>
    <div *ngIf="aleartSubmitted">
      <h3 translate>Your Alert has been updated. You will be notified by email every time a match is listed by an Agent or Owner/Landlord. If you wish to turn off this alert, please go to your Dashboard under the tab/icon "Manage Alerts"</h3>
    </div>
  </mat-dialog-content>
</ng-template>