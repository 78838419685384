import { OnInit, ChangeDetectorRef, EventEmitter, TemplateRef } from '@angular/core';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UtilService } from 'src/shared/services/util.service';
import { ListingService } from 'src/listing/service/listing.service';
import { paymentStatusLable } from 'src/shared/const/payment-status';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MatDialog } from '@angular/material';
var ListingCardDashboardComponent = /** @class */ (function () {
    function ListingCardDashboardComponent(enumPropertyService, router, changeDetaction, listingService, dashboardSerivce, utilService, dialog) {
        this.enumPropertyService = enumPropertyService;
        this.router = router;
        this.changeDetaction = changeDetaction;
        this.listingService = listingService;
        this.dashboardSerivce = dashboardSerivce;
        this.utilService = utilService;
        this.dialog = dialog;
        this.type = null;
        this.listing = null;
        this.deleteEvent = new EventEmitter();
        this.viewListing = new EventEmitter();
        this.getAllListing = new EventEmitter();
        this.destroy$ = new Subject();
        this.storage = environment.storageUrl;
        this.mainPackage = null;
        this.paymentLabel = paymentStatusLable;
        this.lineGroups = [];
        this.shareListingClick = false;
        this.shareDialogRef = null;
    }
    ListingCardDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.taxonomy = data;
            _this.changeDetaction.detectChanges();
        });
    };
    ListingCardDashboardComponent.prototype.delete = function (listingId) {
        this.deleteEvent.emit(listingId);
    };
    ListingCardDashboardComponent.prototype.edit = function () {
        var _this = this;
        this.dashboardSerivce.getSingleListing(this.listing.id).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            if (!data.address.subDistrictId) {
                data.address.subDistrictId = 1;
            }
            if (!data.address.districtId) {
                data.address.districtId = 1;
            }
            if (!data.address.provinceId) {
                data.address.provinceId = 1;
            }
            _this.listingService.listingData = data;
            _this.router.navigate(['/add-listing']);
        });
    };
    ListingCardDashboardComponent.prototype.getEnumById = function (firstElement, type, secondElement, id) {
        var nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
        return nameOrNumber;
    };
    ListingCardDashboardComponent.prototype.goto = function () {
        if (this.listing.listingStatus != 11) {
            this.router.navigate(["../listing-detail", this.listing.id]);
        }
    };
    ListingCardDashboardComponent.prototype.giveBackListing = function (listing) {
        this.viewListing.emit(listing);
    };
    ListingCardDashboardComponent.prototype.approveOrSuspend = function (listingId, stsusId) {
        var _this = this;
        this.dashboardSerivce.updateListingStatus(listingId, stsusId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', 'Listing Updated');
            _this.getAllListing.emit(true);
        });
    };
    ListingCardDashboardComponent.prototype.activeListingFor30Days = function (listingId) {
        var _this = this;
        this.dashboardSerivce.activeListingFor30Days(listingId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', 'Listing Validity Updated');
            _this.getAllListing.emit(true);
        });
    };
    ListingCardDashboardComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    ListingCardDashboardComponent.prototype.buildUrl = function (listing) {
        var subUrl = 'listing-detail';
        return environment.appUrl + "/" + subUrl + "/" + listing.id;
    };
    ListingCardDashboardComponent.prototype.openShareDialog = function () {
        this.shareDialogRef = this.dialog.open(this.shareDialog, {
            panelClass: 'share-dailog'
        });
    };
    ListingCardDashboardComponent.prototype.shareToFb = function () {
        var obj = {
            "og:url": this.buildUrl(this.listing),
            "og:type": "website",
            "og:title": this.listing.title,
            "og:description": this.listing.description ? this.listing.description.substr(0, 20) : null,
            "og:image": environment.storageUrl + '/listing/' + this.listing.filePath,
            "og:image:width": "450",
            "og:image:height": "298"
        };
        FB.init({
            appId: environment.facebookId,
            status: true,
            cookie: true,
            xfbml: true,
            version: "v2.4"
        });
        FB.ui({
            method: "share_open_graph",
            action_type: "og.shares",
            redirect_uri: this.buildUrl(this.listing),
            action_properties: JSON.stringify({
                object: obj
            })
        }, function (response) {
            if (response) {
            }
            else {
            }
        }, function (err) {
        });
    };
    ListingCardDashboardComponent.prototype.openLineShare = function () {
        open("https://timeline.line.me/social-plugin/share?url=" + this.buildUrl(this.listing) + "&title=" + this.listing.title);
    };
    ListingCardDashboardComponent.prototype.getGroup = function () {
        var _this = this;
        this.dashboardSerivce.getLineGroup(this.listing.id).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            console.log("data", data);
            data.forEach(function (element) {
                if (element.disable) {
                    element.checked = true;
                }
            });
            _this.lineGroups = data;
            _this.shareListingClick = true;
        });
    };
    ListingCardDashboardComponent.prototype.shareToLine = function () {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to share a listing in line groups!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                var group_1 = [];
                console.log(_this.lineGroups);
                _this.lineGroups.forEach(function (element) {
                    if (element.checked) {
                        group_1.push(element.id);
                    }
                });
                if (group_1.length) {
                    _this.dashboardSerivce.shareToLine(_this.listing.id, group_1).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                        var data = _a.data;
                        console.log("data", data);
                        _this.shareListingClick = false;
                        _this.utilService.showToast('success', 'Your Listing Share To Selected Line Group.');
                    });
                }
                else {
                    _this.utilService.showToast('error', 'No group selected!');
                }
            }
        });
    };
    return ListingCardDashboardComponent;
}());
export { ListingCardDashboardComponent };
