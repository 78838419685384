import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'td-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() pageInfo :any={};
  @Output() pageClickEvent = new EventEmitter<number>();
  numberOfpage:any={};
  constructor(private changeDetection: ChangeDetectorRef) { }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    function range(lowEnd, highEnd) {
      var arr = [],
        c = highEnd - lowEnd + 1;
      while (c--) {
        arr[c] = highEnd--
      }
      return arr;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = range(startPage, endPage);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  ngOnChanges() {
    
    this.numberOfpage = this.getPager(this.pageInfo.total, this.pageInfo.currentPage);
    console.log("this.this.numberOfpage",this.numberOfpage);
    this.changeDetection.detectChanges();
  }
  onClickPage(pageNumber) {

    this.pageClickEvent.emit(pageNumber);
  }
}
