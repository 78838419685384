import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "nino-accept-decline",
  styleUrls: ["accept-decline.component.scss"],
  template: `
    <div class="accept-decline-dialog-container">
      <div>
        <h1 class="text-center">{{ data.message }}</h1>
      </div>
      <p>{{data.subMessage}}</p>
      <div
        class="button-container"
        mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="center"
      >
        <button
          mat-raised-button
          [mat-dialog-close]="false"
          cdkFocusInitial
          color="default"
        >
          {{'No'|translate}}
        </button>
        <button mat-raised-button [mat-dialog-close]="true" color="primary">
          {{'Yes'|translate}}
        </button>
      </div>
    </div>
  `
})
export class AceptDeclineComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, subMessage:string }) {}

  ngOnInit() {}
}
