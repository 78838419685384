import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';
import { ContactService } from '../service/contact.service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'td-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactComponent implements OnInit, AfterViewInit {
  @Input() userId: number = null;
  @Input() userOwnListing: boolean;
  contactFormHtml: FormGroup = this.fb.group({
    name: [{ value: null, disabled: this.userOwnListing }, [Validators.required]],
    email: [{ value: null, disabled: this.userOwnListing }, [Validators.required, Validators.email]],
    telephone: [{ value: null, disabled: this.userOwnListing }, [
      Validators.required,
      Validators.pattern("^[0-9]*$")
    ]],
    detail: [{ value: null, disabled: this.userOwnListing }, Validators.required],
    UserIdReceived: [null],
    subject: ['Message recived on your listing']
  })
  submitted: boolean = false;
  destroy$: Subject<boolean> = new Subject();
  constructor(private changeDetection: ChangeDetectorRef,
    private authService: AuthService,
    private utilService: UtilService,
    private fb: FormBuilder,
    private contactService: ContactService, public dialogRef: MatDialogRef<ContactComponent>) { }

  ngOnInit() {
    
    if (this.userOwnListing) {
      this.contactFormHtml.controls.name.disable();
      this.contactFormHtml.controls.email.disable();
      this.contactFormHtml.controls.telephone.disable();
      this.contactFormHtml.controls.detail.disable();
    } else {
      this.contactFormHtml.controls.name.enable();
      this.contactFormHtml.controls.email.enable();
      this.contactFormHtml.controls.telephone.enable();
      this.contactFormHtml.controls.detail.enable();
    }
  }

  ngAfterViewInit() {
    
    if (this.userOwnListing) {
      this.contactFormHtml.controls.name.disable();
      this.contactFormHtml.controls.email.disable();
      this.contactFormHtml.controls.telephone.disable();
      this.contactFormHtml.controls.detail.disable();
    } else {
      this.contactFormHtml.controls.name.enable();
      this.contactFormHtml.controls.email.enable();
      this.contactFormHtml.controls.telephone.enable();
      this.contactFormHtml.controls.detail.enable();
    }

  }

  get f(): any { return this.contactFormHtml.controls; }


  captchaResolve(ev) {
    
  }

  onSubmit() {
    
    
    // stop here if form is invalid
    if (this.contactFormHtml.invalid) {
      this.submitted = false;
      return;
    }
    if (this.userId) {
      
      this.contactFormHtml.controls['UserIdReceived'].setValue(this.userId);
    }
    this.contactService.sendMessage(this.contactFormHtml.value).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.contactFormHtml.reset()
      this.utilService.showToast('success','Message Send!')
      this.submitted = true;
      this.changeDetection.detectChanges();
      // this.dialogRef.close();
    }, (err) => {
      
      if (err.error.error[0] == 'Can not send message to your own listing.') {
        this.utilService.showToast('error','Can not send message to your own listing');
      } else {
        this.utilService.showToast('error','Error sending message! Try again later');
      }

    })

  }
}
