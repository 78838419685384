
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/core/service/auth/auth.service';
import { RegisterUser } from '../interface/loging-register.interface';
import { tokenData } from '../interface/token.interface';
import { UtilService } from 'src/shared/services/util.service';
import { Router } from '@angular/router';
@Injectable()
export class LoginRegisterService {
  private authenticated = false;
  private redirectUrl: string;
  constructor(public http: HttpClient, private authService: AuthService, private utilService: UtilService, private router: Router, ) {
  }
  makeUrlEncoded(params) {
    return Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }).join('&');
  }
  login(userObj: RegisterUser) {
    
    const options = { headers: { 'Content-Type': 'application/x-www-form-urlencoded', skip: "true" } };
    const params = {
      userName: userObj.email,
      password: userObj.password,
      grant_type: 'password',
      client_id: 'thaiPropWebsite'
    };
    return this.http.post(`${environment.serverUrl}connect/token`, this.makeUrlEncoded(params), options)
      .pipe(
        switchMap((data: tokenData) => this.authService.saveAuthToken(data)));
  }


  register(userObj: RegisterUser) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}account/register`, userObj)
  }
  getProfile() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}account/me`)
      .pipe(
        switchMap((profileData: any) => this.authService.saveProfile(profileData.data)
        ))

  }

  public setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  public isAuthenticated(): boolean {
    // get the token
    
    const token = this.authService.token;
    // return a boolean reflecting
    // whether or not the token is expired
    return token ? this.authenticated = true : this.authenticated = false;
  }

  refreshToken(): Observable<any> {
    let tokenObj = this.authService.token;
    const options = { headers: { 'Content-Type': 'application/x-www-form-urlencoded', skip: "true" } };
    let params = null;
    if (tokenObj.refresh_token) {
      params = {
        refresh_token: tokenObj.refresh_token,
        grant_type: 'refresh_token',
        client_id: 'thaiPropWebsite'
      };
    } else {
      this.router.navigate([`/`]);
      this.utilService.showToast('error','Please login to view this page!');
      return;
    }
    return this.http.post(`${environment.serverUrl}connect/token`, this.makeUrlEncoded(params), options).pipe(
      switchMap((data: tokenData) => this.authService.saveAuthToken(data))
    );

  }

  logingWithLinkdin() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}account/LinkedIn/Url`)
  }
  sendLinkdinCodeToLogin(code: String, state: string) {
    return this.http.post(`${environment.serverUrl}account/LinkedIn/Login`, { code: code, state: state }).pipe(
      switchMap(({ data }: any) => this.authService.saveAuthToken(data)));
  }
  getPercentageOfProfile() {
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}account/me/profile/percentage`)
  }

  logingWithFacebook(obj) {
    const options = { headers: { skip: "true" } };
    return this.http.post(`${environment.serverUrl}ExternalAuth/Login`, obj, options).pipe(
      switchMap((data: tokenData) => this.authService.saveAuthToken(data))
    )
  }

  forgetPassword(obj){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}account/ForgotPassword`,obj)
  }

  resetPassword(obj){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}account/ResetPassword`,obj)
  }

}
