import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/media/service/media.service';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'td-dashboard-manage-testimonials',
  templateUrl: './dashboard-admin-manage-testimonials.component.html',
  styleUrls: ['./dashboard-admin-manage-testimonials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminManageTestimonialsComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject();
  dialogRef = null;
  slectedLanguage: number = 1;
  submited: boolean = false;
  testimonialAddOrUpdateSuccess: boolean = false;
  updatingTestimonial: boolean = false;
  addTestimonial: FormGroup = this.fb.group({
    engTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],

    message: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
    jobPosition: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
    location: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
  });
  fileupload: any;
  fileUploadProgress = null;
  mediaIds = [];
  testimonialData = [];
  storage: string = environment.storageUrl;
  @ViewChild('addNewTestimonial', {static: false}) addNewTestimonial: TemplateRef<any>;
  currentPage: number = 1;
  assDec = true;
  filter = null;
  testimonialId = null;
  pageInfo = {
    "total": 0,
    "totalPages": 1,
    "pageSize": 0,
    "currentPage": 0
  }
  constructor(private dashboardService: DashboardService, private changeDetection: ChangeDetectorRef,
    private utilService: UtilService, private dialog: MatDialog, private fb: FormBuilder, private mediaService: MediaService) { }

  ngOnInit() {
    this.getAllTestimonial();
  }

  getAllTestimonial() {
    this.dashboardService.getAllTestimonial(this.currentPage, 10, this.assDec, this.filter).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {

      
      this.testimonialData = data;
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();

    })

  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllTestimonial();
  }
  get f(): any { return this.addTestimonial.controls; }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addNew(templateRef) {
    imageUploadConst.files = [];
    this.updatingTestimonial = false;
    this.testimonialAddOrUpdateSuccess = false;
    this.submited = false;
    this.addTestimonial.setValue({ engTitle: '', message: '', jobPosition: '', location: '' });
    this.dialogRef = this.dialog.open(templateRef, {
      panelClass: 'add-new-dialog',

    });
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.fileUploadProgress = null;
      this.mediaIds = [];
      this.assignFile();
    });
  }
  assignFile(): any {
    let _this = this;
    imageUploadConst.limit = 1;
    imageUploadConst.onFileRead = function (item, listEl, parentEl, newInputEl, inputEl) {
      
      if (item.input) {
        _this.fileUploadStart(item)
      } else {
        item.id = item.data.imageId;
      }
    };
    imageUploadConst.onRemove = function (item) {
      
      _this.deletePhoto(item)
    };

    imageUploadConst.dialogs.alert = function (message) {
      _this.utilService.showToast('success',message)
    }
    imageUploadConst.getAllEvents = function (something) {
      
    }
    imageUploadConst.editor.onSave = function (dataUrl, item) {
      
      
      _this.removeFromFiles(item);
      let blob = _this.mediaService.dataURLtoFile(dataUrl, 'a.png');

      item.file = blob;
      if (blob) {
        _this.fileUploadStart(item);
      }

    }
    setTimeout(() => {
      this.fileupload = (<any>window).$('input[name="files"]').fileuploader(
        imageUploadConst
      );
    }, 100)

  }

  fileUploadStart(item) {
    let _this = this;
    
    const formData = new FormData();
    formData.append('file', item.file);
    // callback will go here
    
    _this.fileUploadProgress = '0';
    _this.mediaService.addMedia(50, formData).pipe(takeUntil(_this.destroy$)).subscribe((events: any) => {
      
      if (events.type === HttpEventType.UploadProgress) {
        _this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
        _this.changeDetection.detectChanges();
        
        var progressBar = item.html.find('.progress-bar2');
        if (progressBar.length > 0) {
          progressBar.show();
          progressBar.find('span').html(_this.fileUploadProgress + "%");
          progressBar.find('.fileuploader-progressbar .bar').width(_this.fileUploadProgress + "%");
        }
      } else if (events.type === HttpEventType.Response) {
        _this.fileUploadProgress = '';
        
        item.name = events.body.data.name;
        item.id = events.body.data.id;
        imageUploadConst.files.push({ id: events.body.data.id });
        item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
        setTimeout(function () {
          item.html.find('.progress-bar2').fadeOut(400);
        }, 400);
        _this.mediaIds = [..._this.mediaIds, ...events.body.data.id];
        
        _this.changeDetection.detectChanges();
      }
    }, ({ error }) => {
      
      error ? _this.utilService.showToast('error',error.error) : _this.utilService.showToast('error','Please login to upload photo');
    })
  }

  removeFromFiles(fileName) {
    
    imageUploadConst.files.forEach((element, index) => {
      
      if (element.id == fileName.id) {
        
        imageUploadConst.files.splice(index, 1);
      }
    })
    this.mediaIds.forEach((element, index) => {
      
      if (element == fileName.id) {
        
        this.mediaIds.splice(index, 1);
      }
    })
    
    this.changeDetection.detectChanges();
  }

  deletePhoto(fileName: any) {
    this.mediaService.deleteMedia(fileName.id, 10).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.mediaIds = this.mediaIds.filter((item: any) => item !== fileName.id);
      this.changeDetection.detectChanges();
    }, (err) => {
      this.utilService.showToast('error','Error on media update');
    })
  }
  addTestimonials() {
    
    this.submited = true;

    let obj = {
      title: this.addTestimonial.value.engTitle,
      message: this.addTestimonial.value.message,
      jobPosition: this.addTestimonial.value.jobPosition,
      location: this.addTestimonial.value.location,
      "mediaIds": this.mediaIds
    };
    
    if (!this.mediaIds.length) {
      this.utilService.showToast('success','Add one image!');
      return;
    }
    this.dashboardService.saveTestimonial(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.testimonialAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Testimonial Created!');
      this.close();
    }, (err) => {
      this.submited = false;
      this.utilService.showToast('error','Try again!');
    })


  }
  close() {
    this.dialogRef.close();
    this.currentPage = 0;
    this.pageInfo.pageSize = 1;
    this.getAllTestimonial();
    this.updatingTestimonial = false;
    this.testimonialAddOrUpdateSuccess = false;
    this.submited = false;
  }

  delete(ev) {
    
    this.utilService.showAcceptDeclineDialog("You are about to delete a testimonial!", "Are you sure ?", "400px")
      .pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
        
        if (accepet) {
          this.dashboardService.deleteTestimonial(ev).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
            
            if (data) {
              this.utilService.showToast('success',"Testimonial Deleted!")
              this.currentPage = 0;
              this.pageInfo.pageSize = 1;
              this.getAllTestimonial();
            } else {
              this.utilService.showToast('error',"Try again!")
            }
          })
        }
      })
  }
  edit(ev) {
    
    this.testimonialId = ev.id;
    this.updatingTestimonial = true;
    this.testimonialAddOrUpdateSuccess = false;

    this.addTestimonial.controls['engTitle'].setValue(ev.title);
    this.addTestimonial.controls['message'].setValue(ev.message);
    this.addTestimonial.controls['jobPosition'].setValue(ev.jobPosition);
    this.addTestimonial.controls['location'].setValue(ev.location);
    if (ev.medias.length) {
      let prepairMedia = [];
      prepairMedia.push({
        name: ev.medias[0].filePath, // file name
        type: 'image/*', // file MIME type
        id: ev.medias[0].id,
        file: this.storage + '/testimonial/' + ev.medias[0].filePath + '?' + new Date().getTime(), // file path
        data: {
          imageId: ev.medias[0].id,
          readerCrossOrigin: "Anonymous"
        },
        uploaded: true
      })
      this.mediaIds = [parseInt(ev.medias[0].id)];
      imageUploadConst.files = prepairMedia;
    }
    this.dialogRef = this.dialog.open(this.addNewTestimonial, {
      panelClass: 'add-new-dialog'
    })

    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.assignFile();
    });

  }
  updateTestimonial() {
    
    this.testimonialAddOrUpdateSuccess = false;
    this.submited = true;
    if (!this.mediaIds.length) {

      return;
    }
    let obj = {
      title: this.addTestimonial.value.engTitle,
      message: this.addTestimonial.value.message,
      jobPosition: this.addTestimonial.value.jobPosition,
      location: this.addTestimonial.value.location,
      "mediaIds": this.mediaIds
    };

    this.dashboardService.updateTestimonial(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.testimonialAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Testimonial updated!')
    }, (err) => {
      this.submited = false;
      this.testimonialAddOrUpdateSuccess = false;
      this.utilService.showToast('error','Try Again')
    })
  }
}
