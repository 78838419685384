import { OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
var LoginRegisterComponent = /** @class */ (function () {
    function LoginRegisterComponent(loginRegisterService, utilService, dialogRef, fb, changeDetection, recaptchaV3Service) {
        this.loginRegisterService = loginRegisterService;
        this.utilService = utilService;
        this.dialogRef = dialogRef;
        this.fb = fb;
        this.changeDetection = changeDetection;
        this.recaptchaV3Service = recaptchaV3Service;
        this.notDialog = false;
        this.loginResponse = new EventEmitter(false);
        this.loginRegisterForm = this.fb.group({
            firstName: [''],
            lastName: [''],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(6)]]
        });
        this.destroy$ = new Subject();
        this.selectedLoginTab = 'signIn';
        this.submitted = false;
        this.forgetPassword = false;
        this.isAuthentic = null;
        this.reCaptchaKey = environment.reCaptchaKey;
    }
    LoginRegisterComponent.prototype.ngOnInit = function () {
        FB.init({
            appId: '3281595045192563',
            cookie: true,
            xfbml: true,
            version: 'v5.0' // Use this Graph API version for this call.
        });
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id))
                return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    };
    Object.defineProperty(LoginRegisterComponent.prototype, "f", {
        get: function () { return this.loginRegisterForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginRegisterComponent.prototype.checkAuthentication = function () {
        this.isAuthentic = this.loginRegisterService.isAuthenticated();
        this.loginResponse.emit(this.isAuthentic);
        this.changeDetection.detectChanges();
    };
    LoginRegisterComponent.prototype.requestApiToken = function (token, type) {
        var _this_1 = this;
        var request = {
            AccessToken: token,
            Provider: type
        };
        this.loginRegisterService.logingWithFacebook(request).pipe(switchMap(function () { return _this_1.loginRegisterService.getProfile(); })).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.submitted = false;
            _this_1.checkAuthentication();
            if (!_this_1.notDialog) {
                _this_1.dialogRef.close(true);
            }
        });
    };
    LoginRegisterComponent.prototype.loginWithLinkdinAccount = function () {
        this.submitted = true;
        var redirect_uri = environment.appUrl + '/linkedinlogin';
        var client_id = environment.linkedinId;
        var url = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=" + client_id + "&redirect_uri=" + redirect_uri + "&scope=r_liteprofile%20r_emailaddress%20w_member_social";
        window.location.href = url;
    };
    LoginRegisterComponent.prototype.onSubmit = function () {
        // 
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginRegisterForm.invalid) {
            this.submitted = false;
            return;
        }
        if (!this.forgetPassword) {
            this.selectedLoginTab == 'signIn' ? this.login(this.loginRegisterForm.value) : this.register(this.loginRegisterForm.value);
        }
        else {
            this.forgetPasswordFn(this.loginRegisterForm.value);
        }
    };
    LoginRegisterComponent.prototype.login = function (userObj) {
        var _this_1 = this;
        this.loginRegisterService.login(userObj).pipe(switchMap(function () { return _this_1.loginRegisterService.getProfile(); }))
            .pipe(takeUntil(this.destroy$))
            .subscribe(function () {
            _this_1.checkAuthentication();
            if (!_this_1.notDialog) {
                _this_1.dialogRef.close(true);
            }
            else {
                window.location.reload();
            }
            _this_1.submitted = false;
        }, function (_a) {
            var error = _a.error;
            _this_1.submitted = false;
            _this_1.utilService.showToast('error', 'Invalid email or password!');
        });
    };
    LoginRegisterComponent.prototype.register = function (userObj) {
        var _this_1 = this;
        this.loginRegisterService.register(userObj).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.login(userObj);
        }, function (e) {
            _this_1.utilService.showToast('error', e.message);
            _this_1.submitted = false;
        });
    };
    LoginRegisterComponent.prototype.forgetPasswordFn = function (userObj) {
        var _this_1 = this;
        this.invisibleCaptcha.execute();
        this.invisibleCaptcha.resolved.subscribe(function (token) {
            if (token) {
                _this_1.submitted = true;
                var obj = {
                    "email": userObj.email,
                    "ReCaptchaToken": token
                };
                _this_1.loginRegisterService.forgetPassword(obj).pipe(takeUntil(_this_1.destroy$)).subscribe(function (data) {
                    _this_1.submitted = false;
                    _this_1.forgetPassword = false;
                }, function (e) {
                    // this.utilService.showToast('error',e.message);
                    _this_1.submitted = false;
                });
            }
        });
    };
    LoginRegisterComponent.prototype.loginWithFacebook = function () {
        this.submitted = true;
        var _this = this;
        FB.login(function (response) {
            if (response.authResponse) {
                //login success
                //login success code here
                //redirect to home page
                if (response.status === 'connected') {
                    var token = response.authResponse.accessToken;
                    _this.requestApiToken(token, 'Facebook');
                }
            }
            else {
                _this.utilService.showToast('error', response);
                _this.submitted = false;
            }
            // window.open(response, '_self');
        }, { scope: 'public_profile,email' });
    };
    LoginRegisterComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    return LoginRegisterComponent;
}());
export { LoginRegisterComponent };
