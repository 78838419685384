
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Medias } from 'src/app/dashboard/interface/dashboard-user';

@Injectable()
export class MediaService {

  constructor(private http: HttpClient) { }

  addMedia(mediaType: number = 10, formDataImage: FormData) {
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}Media/Upload?MediaType=${mediaType}`, formDataImage, {
      reportProgress: true,
      observe: 'events',
    });

  }
  deleteMedia(mediaId: number, mediaType: number = 10) {

    return this.http.delete(`${environment.serverUrl}${environment.apiUrl}Media?mediaId=${mediaId}&MediaType=${mediaType}`)

  }

  getProfileImage(imageArray) {
    
    let imageElement = null;
    if (imageArray.length) {
      imageArray.forEach((element: Medias) => {
        if (element.mediaTypeId == 20) {
          imageElement = element;
        }
      });
    }
    
    return imageElement;
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}