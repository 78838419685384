import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { RegisterUser } from 'src/login-register/interface/loging-register.interface';
import { switchMap, takeUntil } from 'rxjs/operators';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialogRef } from '@angular/material';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

declare const FB;

@Component({
  selector: 'td-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
  @Input() notDialog: boolean = false;
  @Output() loginResponse = new EventEmitter<any>(false);
  loginRegisterForm: FormGroup = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]]
  })
  destroy$: Subject<boolean> = new Subject();
  selectedLoginTab: string = 'signIn';
  submitted = false;
  forgetPassword = false;
  isAuthentic = null;
  @ViewChild('invisibleCaptcha', {static: false}) invisibleCaptcha: any;
  reCaptchaKey:string=environment.reCaptchaKey;
  constructor(private loginRegisterService: LoginRegisterService,
    private utilService: UtilService,
    private dialogRef: MatDialogRef<LoginRegisterComponent>,
    private fb: FormBuilder, private changeDetection: ChangeDetectorRef, private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit() {

    FB.init({
      appId: '3281595045192563',
      cookie: true,
      xfbml: true,
      version: 'v5.0'          // Use this Graph API version for this call.
    });


    (function (d, s, id) {                      // Load the SDK asynchronously
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }

  get f(): RegisterUser { return this.loginRegisterForm.controls; }


  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated()
    this.loginResponse.emit(this.isAuthentic);
    this.changeDetection.detectChanges();
  }

  requestApiToken(token, type) {

    var request = {
      AccessToken: token,
      Provider: type
    };

    this.loginRegisterService.logingWithFacebook(request).pipe(switchMap(() => this.loginRegisterService.getProfile())).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submitted = false;
      this.checkAuthentication();
      if (!this.notDialog) {
        this.dialogRef.close(true);
      }

    })
  }


  loginWithLinkdinAccount() {
    this.submitted = true;
    var redirect_uri = environment.appUrl + '/linkedinlogin';
    var client_id = environment.linkedinId;
    var url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    window.location.href = url;

  }





  onSubmit() {
    // 
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginRegisterForm.invalid) {
      this.submitted=false;
      return;
    }
    if (!this.forgetPassword) {
      this.selectedLoginTab == 'signIn' ? this.login(this.loginRegisterForm.value) : this.register(this.loginRegisterForm.value)
    } else {
      this.forgetPasswordFn(this.loginRegisterForm.value);
    }


  }


  login(userObj: RegisterUser) {
    this.loginRegisterService.login(userObj).pipe(switchMap(() => this.loginRegisterService.getProfile()))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkAuthentication();
        if (!this.notDialog) {
          this.dialogRef.close(true);
        }else{
          window.location.reload();
        }
        this.submitted = false;
      }, ({ error }) => {
        
        this.submitted = false;
        this.utilService.showToast('error','Invalid email or password!');
      })
  }
  register(userObj: RegisterUser) {
    this.loginRegisterService.register(userObj).pipe(takeUntil(this.destroy$)).subscribe(data => {
      
      this.login(userObj);
    }, (e) => {
      this.utilService.showToast('error',e.message);
      this.submitted = false;
      
    })
  }
  forgetPasswordFn(userObj: RegisterUser) {
    this.invisibleCaptcha.execute();
    this.invisibleCaptcha.resolved.subscribe(
      (token: string) => {

        
        if (token) {
          this.submitted = true;
          let obj = {
            "email": userObj.email,
            "ReCaptchaToken": token
          }
          this.loginRegisterService.forgetPassword(obj).pipe(takeUntil(this.destroy$)).subscribe(data => {
            
            this.submitted = false;
            this.forgetPassword = false;
          }, (e) => {
            // this.utilService.showToast('error',e.message);
            this.submitted = false;
            
          })
        }
      })
  }

  loginWithFacebook() {
    this.submitted = true;
    let _this = this;
    FB.login((response) => {
      
      if (response.authResponse) {
        //login success
        //login success code here
        //redirect to home page
        
        if (response.status === 'connected') {
          var token = response.authResponse.accessToken
          _this.requestApiToken(token, 'Facebook');
        }
      }
      else {
        
        
        _this.utilService.showToast('error',response);
        _this.submitted = false;
      }
      // window.open(response, '_self');

    }, { scope: 'public_profile,email' })
  }



  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
