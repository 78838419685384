import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/payment-component/services/payment.service';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { MatDialog } from '@angular/material';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { UtilService } from 'src/shared/services/util.service';
import { PaymentSuccessComponent } from '../payment-success/payment-success.component';
import { environment } from 'src/environments/environment';
var moment = _rollupMoment || _moment;
var PaymentConfirmationComponent = /** @class */ (function () {
    function PaymentConfirmationComponent(route, paymentService, changeDetaction, fb, utilService, enumPropertyService, dialog, router) {
        var _this_1 = this;
        this.route = route;
        this.paymentService = paymentService;
        this.changeDetaction = changeDetaction;
        this.fb = fb;
        this.utilService = utilService;
        this.enumPropertyService = enumPropertyService;
        this.dialog = dialog;
        this.router = router;
        this.mobilePaymentMathedClicked = false;
        this.destroy$ = new Subject();
        this.selectedPaymentDiv = 1;
        this.selectedPaymentBank = 'BAY';
        this.minDate = new Date();
        this.cardDetailsFormGroup = this.fb.group({
            cardNumber: [null, [Validators.required, Validators.maxLength(19)]],
            nameOnCard: [null, [Validators.required, Validators.maxLength(30), Validators.minLength(2)]],
            expiredMonth: [moment().month(), Validators.required],
            expiredYear: [moment().year(), Validators.required],
            expiredFullDate: [null, Validators.required],
            securityCode: [null, [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$")]]
        });
        this.selectedPackage = {
            orderItems: []
        };
        this.taxonomy = {};
        this.customeText = null;
        this.submited = false;
        this.isProduction = environment.production;
        this.fullPaymentObj = null;
        this.fees = 0;
        this.fixAmount = false;
        this.paymentMethods = null;
        this.finalAmount = 1;
        this.billId = null;
        this.paypalButtonAdded = false;
        this.barCodeImage = null;
        this.paypalId = null;
        this.paypalConfig = {
            env: environment.paypalEnv,
            client: {
                sandbox: environment.paypalKey,
                production: environment.paypalKey
            },
            commit: true,
            payment: function (data, actions) {
                return _this_1.getValueFromObservable(_this_1.billId).then(function (data) {
                    return data;
                })
                    .catch(function (val) {
                    //Logic after failure
                });
                // return actions.request.post(this.paymentService.paypalPayment(this.billId))
                //   .then(function(res) {
                //     // 3. Return res.id from the response
                //     return res.id;
                //   });
                // return actions.payment.create({
                //   payment: {
                //     transactions: [
                //       { amount: { total: this.getSum(7 * this.getSum(0) / 100) + this.fees * this.getSum(0) / 100, currency: 'THB' } }
                //     ]
                //   },
                //   token:this.paypalId
                // });
            },
            onAuthorize: function (data, actions) {
                return actions.payment.execute().then(function (payment) {
                    //Do something when payment is successful.
                    var paymentObj = {
                        paymentMethodId: _this_1.selectedPaymentDiv,
                        offSiteType: null,
                        token: null,
                        automaticDebit: true,
                        cardId: null,
                        paypalPayId: data.paymentID,
                        paypalPayerId: data.payerID,
                        fee: _this_1.fees ? _this_1.fees : 0
                    };
                    // paymentObj.token = payment.id;
                    _this_1.paymentService.payForBill(paymentObj, _this_1.billId).pipe(takeUntil(_this_1.destroy$)).subscribe(function (paymentResponse) {
                        _this_1.utilService.showToast('success', 'Payemnet Successfully done!');
                        _this_1.router.navigate(["/payment-success/" + _this_1.billId + "/" + _this_1.selectedPaymentDiv]);
                        _this_1.submited = false;
                    });
                    // })
                });
            },
            onError: function (err) {
            }
        };
    }
    PaymentConfirmationComponent.prototype.getValueFromObservable = function (billId) {
        var _this_1 = this;
        return new Promise(function (resolve) {
            _this_1.paymentService.paypalPayment(billId).pipe(takeUntil(_this_1.destroy$)).subscribe(function (_a) {
                var data = _a.data;
                resolve(data);
            });
        });
    };
    Object.defineProperty(PaymentConfirmationComponent.prototype, "f", {
        get: function () { return this.cardDetailsFormGroup.controls; },
        enumerable: true,
        configurable: true
    });
    PaymentConfirmationComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        window.Omise.publicKey = "pkey_5il69jga91xkzaxsoay";
        this.taxonomy = this.enumPropertyService.getEnumPropertyForClient();
        this.fullPaymentObj = this.paymentService.fullPaymentObj;
        this.route.params.subscribe(function (params) {
            _this_1.billId = params.id;
        });
        if (this.paymentService.fullPaymentObj) {
            this.listingId = this.paymentService.fullPaymentObj.listingId;
        }
        this.getBillingDetails();
    };
    PaymentConfirmationComponent.prototype.getBillingDetails = function () {
        var _this_1 = this;
        this.paymentService.getBillDetails(this.billId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this_1.selectedPackage = data.productsOrder;
            _this_1.paymentMethods = data.paymentMethods;
            _this_1.paymentMethods.forEach(function (element) {
                if (element.id == _this_1.selectedPaymentDiv) {
                    _this_1.fees = element.fee;
                    _this_1.fixAmount = element.fixAmount;
                }
            });
            _this_1.changeDetaction.detectChanges();
        });
    };
    PaymentConfirmationComponent.prototype.getSum = function (amountToAdd) {
        if (amountToAdd === void 0) { amountToAdd = 0; }
        // 
        var sum = 0;
        for (var i = 0; i < this.selectedPackage.orderItems.length; i++) {
            sum += this.selectedPackage.orderItems[i].amount;
        }
        // 
        // 
        return sum + amountToAdd;
    };
    PaymentConfirmationComponent.prototype.submit = function () {
        var _this_1 = this;
        var _this = this;
        this.submited = true;
        if (this.selectedPaymentDiv == 1 && this.cardDetailsFormGroup.invalid) {
            return;
        }
        var paymentObj = {
            paymentMethodId: this.selectedPaymentDiv,
            offSiteType: null,
            token: null,
            automaticDebit: true,
            cardId: null,
            paypalPayId: null,
            paypalPayerId: null,
            fee: this.fees ? this.fees : 0
        };
        var slectedBank = null;
        switch (this.selectedPaymentDiv) {
            case 1:
                console.log("submit 1");
                var card = {
                    name: this.cardDetailsFormGroup.value.nameOnCard,
                    number: parseInt(this.cardDetailsFormGroup.value.cardNumber.replace(/ /g, "")),
                    expiration_month: this.cardDetailsFormGroup.value.expiredMonth,
                    expiration_year: this.cardDetailsFormGroup.value.expiredYear,
                    security_code: parseInt(this.cardDetailsFormGroup.value.securityCode),
                };
                window.Omise.createToken("card", card, function (statusCode, response) {
                    console.log("statusCode, response", statusCode, response);
                    if (response.object == "error" || !response.card.security_code_check) {
                        // Display an error message.
                        var message_text = "SECURITY CODE CHECK FAILED!";
                        if (response.object == "error") {
                            message_text = response.message;
                            _this.utilService.showToast('error', response.message);
                        }
                        else {
                            _this.utilService.showToast('error', message_text);
                        }
                    }
                    else {
                        paymentObj.token = response.id;
                        _this.payBill(paymentObj, slectedBank);
                    }
                    ;
                });
                break;
            case 2:
                switch (this.selectedPaymentBank) {
                    case 'internet_banking_bay':
                        slectedBank = 1;
                        break;
                    case 'internet_banking_bbl':
                        slectedBank = 2;
                        break;
                    case 'internet_banking_ktb':
                        slectedBank = 3;
                        break;
                    case 'internet_banking_scb':
                        slectedBank = 4;
                        break;
                }
                paymentObj.offSiteType = slectedBank;
                window.Omise.createSource(this.selectedPaymentBank, {
                    "amount": this.getSum(7 % this.getSum(0)),
                    "currency": "THB"
                }, function (statusCode, response) {
                    paymentObj.token = response.id;
                    _this.payBill(paymentObj, slectedBank);
                });
                break;
            case 3:
                paymentObj.offSiteType = 5;
                this.payBill(paymentObj, 5);
                break;
            case 4:
                paymentObj.offSiteType = 6;
                // this.paymentService.paypalPayment(this.billId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
                // this.paypalId=data;
                // 
                setTimeout(function () {
                    _this_1.generatePaypalButton();
                }, 100);
                // })
                break;
        }
    };
    PaymentConfirmationComponent.prototype.payBill = function (paymentObj, slectedBank) {
        var _this_1 = this;
        console.log("paymentObj, slectedBank", paymentObj, slectedBank);
        setTimeout(function () {
            console.log("paymentObj, slectedBank", paymentObj, slectedBank);
            _this_1.paymentService.payForBill(paymentObj, _this_1.billId).pipe(takeUntil(_this_1.destroy$)).subscribe(function (paymentResponse) {
                if (slectedBank && slectedBank != 5) {
                    window.location.href = paymentResponse.data.data;
                }
                else if (slectedBank && slectedBank == 5) {
                    _this_1.barCodeImage = paymentResponse.data.data;
                    _this_1.changeDetaction.detectChanges();
                }
                else {
                    _this_1.utilService.showToast('success', 'Payemnet Successfully done!');
                    _this_1.router.navigate(["/payment-success/" + paymentResponse.data.billId + "/" + _this_1.selectedPaymentDiv]);
                }
                _this_1.submited = false;
            }, function (err) {
                if (err.error.errors.find(function (element) { return element == 'transactionAlreadyInProgress'; })) {
                    _this_1.utilService.showToast('info', 'You already paid for this order.');
                }
                else if (err.error.errors.find(function (element) { return element == 'IncorrectBillOrAlreadyPaid'; })) {
                    _this_1.utilService.showToast('info', 'You already paid for this order.');
                }
                else if (err.error.errors.find(function (element) { return element == 'expiration date cannot be in the past'; })) {
                    _this_1.utilService.showToast('error', 'Expiration date is incorrect.');
                }
                else if (typeof err.error == 'string') {
                    _this_1.utilService.showToast('error', 'Server error! Try again later!');
                }
                else {
                    _this_1.utilService.showToast('error', err);
                    _this_1.submited = false;
                    _this_1.router.navigate(["/payment-error", paymentObj.billId], { skipLocationChange: false });
                }
            });
        }, 100);
    };
    PaymentConfirmationComponent.prototype.monthSelected = function (params) {
        this.cardDetailsFormGroup.controls['expiredFullDate'].setValue(params);
        var month = moment(params).format('MM');
        var year = moment(params).format('YYYY');
        this.cardDetailsFormGroup.controls['expiredMonth'].setValue(month);
        this.cardDetailsFormGroup.controls['expiredYear'].setValue(year);
        this.picker.close();
    };
    PaymentConfirmationComponent.prototype.showResponse = function () {
        var dialogRef = this.dialog.open(PaymentSuccessComponent, {
        // data: pckg
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
            }
        });
    };
    PaymentConfirmationComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    PaymentConfirmationComponent.prototype.removePaypal = function () {
        $('.paypal-button').remove();
        this.paypalButtonAdded = false;
        this.changeDetaction.detectChanges();
    };
    PaymentConfirmationComponent.prototype.generatePaypalButton = function () {
        var paypalEnv;
        this.paypalButtonAdded = true;
        this.changeDetaction.detectChanges();
        if (this.isProduction) {
            paypalEnv = "production";
        }
        else {
            paypalEnv = "sandbox";
        }
        paypal.Button.render(this.paypalConfig, "#paypal-button");
    };
    PaymentConfirmationComponent.prototype.printQr = function () {
        var win = window.open('');
        var img = win.document.createElement("img");
        img.src = this.barCodeImage;
        win.document.body.appendChild(img);
        img.onload = function () {
            win.print();
        };
    };
    return PaymentConfirmationComponent;
}());
export { PaymentConfirmationComponent };
