import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContactFormFields } from '../interface/ContactForm';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

constructor(public http: HttpClient) { }

sendMessage(conactObj:ContactFormFields){
  return this.http.post(`${environment.serverUrl}${environment.apiUrl}SupportMessage`, conactObj)
}

}
