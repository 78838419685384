import { OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/core/service/auth/auth.service';
import { UtilService } from 'src/shared/services/util.service';
import { ContactService } from '../service/contact.service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(changeDetection, authService, utilService, fb, contactService, dialogRef) {
        this.changeDetection = changeDetection;
        this.authService = authService;
        this.utilService = utilService;
        this.fb = fb;
        this.contactService = contactService;
        this.dialogRef = dialogRef;
        this.userId = null;
        this.contactFormHtml = this.fb.group({
            name: [{ value: null, disabled: this.userOwnListing }, [Validators.required]],
            email: [{ value: null, disabled: this.userOwnListing }, [Validators.required, Validators.email]],
            telephone: [{ value: null, disabled: this.userOwnListing }, [
                    Validators.required,
                    Validators.pattern("^[0-9]*$")
                ]],
            detail: [{ value: null, disabled: this.userOwnListing }, Validators.required],
            UserIdReceived: [null],
            subject: ['Message recived on your listing']
        });
        this.submitted = false;
        this.destroy$ = new Subject();
    }
    ContactComponent.prototype.ngOnInit = function () {
        if (this.userOwnListing) {
            this.contactFormHtml.controls.name.disable();
            this.contactFormHtml.controls.email.disable();
            this.contactFormHtml.controls.telephone.disable();
            this.contactFormHtml.controls.detail.disable();
        }
        else {
            this.contactFormHtml.controls.name.enable();
            this.contactFormHtml.controls.email.enable();
            this.contactFormHtml.controls.telephone.enable();
            this.contactFormHtml.controls.detail.enable();
        }
    };
    ContactComponent.prototype.ngAfterViewInit = function () {
        if (this.userOwnListing) {
            this.contactFormHtml.controls.name.disable();
            this.contactFormHtml.controls.email.disable();
            this.contactFormHtml.controls.telephone.disable();
            this.contactFormHtml.controls.detail.disable();
        }
        else {
            this.contactFormHtml.controls.name.enable();
            this.contactFormHtml.controls.email.enable();
            this.contactFormHtml.controls.telephone.enable();
            this.contactFormHtml.controls.detail.enable();
        }
    };
    Object.defineProperty(ContactComponent.prototype, "f", {
        get: function () { return this.contactFormHtml.controls; },
        enumerable: true,
        configurable: true
    });
    ContactComponent.prototype.captchaResolve = function (ev) {
    };
    ContactComponent.prototype.onSubmit = function () {
        var _this = this;
        // stop here if form is invalid
        if (this.contactFormHtml.invalid) {
            this.submitted = false;
            return;
        }
        if (this.userId) {
            this.contactFormHtml.controls['UserIdReceived'].setValue(this.userId);
        }
        this.contactService.sendMessage(this.contactFormHtml.value).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.contactFormHtml.reset();
            _this.utilService.showToast('success', 'Message Send!');
            _this.submitted = true;
            _this.changeDetection.detectChanges();
            // this.dialogRef.close();
        }, function (err) {
            if (err.error.error[0] == 'Can not send message to your own listing.') {
                _this.utilService.showToast('error', 'Can not send message to your own listing');
            }
            else {
                _this.utilService.showToast('error', 'Error sending message! Try again later');
            }
        });
    };
    return ContactComponent;
}());
export { ContactComponent };
