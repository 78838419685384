import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';

@Component({
  selector: 'dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardHomeComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject();
  user: any = null;
  admin = false;
  editTips = false;
  tipsOfDay = null;
  generalMeters = null;
  constructor(private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private utilService: UtilService,
    private changeDetection: ChangeDetectorRef) { }

  ngOnInit() {

    this.user = this.route.snapshot.data.routeResolver;
    this.getTipsOfTheDay();
    if (this.user.isAdmin) {
      this.getGeneralMeters();
    }
  }
  getGeneralMeters() {
    this.dashboardService.generalMetersForAdmin().pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      this.generalMeters = data;
      this.changeDetection.detectChanges();
    })
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getTipsOfTheDay() {
    this.dashboardService.getTipsOfTheDay().pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {

      this.tipsOfDay = data.tip;
      this.changeDetection.detectChanges();
    })
  }

  updateTipsOfTheDay() {
    this.editTips = false;
    let obj = {
      id: 1,
      tip: this.tipsOfDay
    }
    this.dashboardService.updateTipsOfTheDay(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.utilService.showToast('success', "Tips updated!");

    }, (err) => {
      this.utilService.showToast('error', "Error try again!")

    })
  }

}
