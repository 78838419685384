import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/media/service/media.service';
import { MustMatch } from 'src/shared/custom-function/helper';
import { UtilService } from 'src/shared/services/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'td-dashboard-manage-users',
  templateUrl: './dashboard-admin-manage-users.component.html',
  styleUrls: ['./dashboard-admin-manage-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminManageUsersComponent implements OnInit {
  searchClicked = false;
  userForm: FormGroup = this.fb.group({
    firstName: [{ value: '', disabled: true }],
    lastName: [{ value: '', disabled: true }],
    email: [{ value: '', disabled: true }],
    telephone: [{ value: '', disabled: true }],
    role: '1',
    status: '',
    password: '',
    cPassword: '',
  }, {
    validator: MustMatch('password', 'cPassword')
  })
  dialogRef: any;
  submited: boolean;
  user = null;
  users: any[] = [];
  @ViewChild('updateUserDialog', { static: false }) updateUserDialog: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject();
  storage = environment.storageUrl + '/user/';
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  };
  filter = null;
  assDec = false;
  platformRoles = [
    { id: 3, name: 'User', checked: true, disabled: true },
    { id: 2, name: 'Admin', checked: false, disabled: false },
    { id: 4, name: 'Support', checked: false, disabled: false }];
  platform = null;
  constructor(private dialog: MatDialog, private fb: FormBuilder,
    private dashboardService: DashboardService,
    private changeDetection: ChangeDetectorRef,
    private mediaService: MediaService,
    private utilService: UtilService,
    private router: Router) {
    this.getAllUsers();
  }

  ngOnInit() {


  }
  get publicMediaService(): any {
    return this.mediaService;
  };
  get f(): any { return this.userForm.controls; }
  openUserDialog(user) {
    user.color = this.getRoleColor(user.roles);
    this.userForm = this.fb.group({
      firstName: [{ value: user.firstName, disabled: true }],
      lastName: [{ value: user.lastName, disabled: true }],
      email: [{ value: user.userName, disabled: true }],
      telephone: [{ value: user.telephone, disabled: true }],
      role: user.userRoleId,
      status: user.status,
      password: '',
      cPassword: '',
    })
    this.platformRoles.forEach(element => {
      user.roles.forEach(subElement => {
        if (subElement == element.id) {
          element.checked = true;
        }
      })
    });
    this.user = user;
    this.dialogRef = this.dialog.open(this.updateUserDialog, {
      panelClass: 'user-update-dialog',
      data: { user: user }
    })
  }
  close() {
    this.dialogRef.close();
    this.currentPage = 1;
    this.platformRoles = [
      { id: 3, name: 'User', checked: true, disabled: true },
      { id: 2, name: 'Admin', checked: false, disabled: false },
      { id: 4, name: 'Support', checked: false, disabled: false }];
    this.getAllUsers();
  }
  getRoleColor(roles) {
    var color = "";
    //Admin
    if (roles.includes(2)) {
      color = "red";
    }
    //Support
    else if (roles.includes(4)) {
      color = "purple";
    }
    //User
    else {
      color = "#8cc63f";
    }
    return color;
  }

  updateUser() {

    let profileObj = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      email: this.userForm.value.email,
      password: this.userForm.value.password ? this.userForm.value.password : null,
      userRoleId: this.userForm.value.userRoleId,
      phoneNumber: this.userForm.value.phoneNumber,
      status: parseInt(this.userForm.value.status),
      roles: [3],
    }
    this.platformRoles.forEach(element => {
      if (element.checked && element.id != 3) {
        profileObj.roles.push(element.id);
      }
    })

    this.dashboardService.updateProfile(profileObj, this.user.id).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.utilService.showToast('success', "Profile updated!");
    }, (err) => {

      this.utilService.showToast('error', "Error updating your profile, try again later!");
    })
  }


  getAllUsers() {
    let obj = {
      id: null,
      filter: this.filter,
      platform: this.platform?parseInt(this.platform):null
    }
    this.dashboardService.getAllUserForAdmin(this.currentPage, 10, obj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {



      this.users = data;

      this.users.forEach(element => {
        element.color = this.getRoleColor(element.roles);
      });
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();


    }, (err) => {

    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllUsers();
  }


  deleteUser(userId) {
    this.utilService.showAcceptDeclineDialog("You are about to delete user!", "Are you sure ?", "400px")
      .pipe(takeUntil(this.destroy$)).subscribe((accepet) => {

        if (accepet) {
          this.dashboardService.deleteUserForAdmin(userId).pipe(takeUntil(this.destroy$)).subscribe((data) => {

            this.close();
            this.utilService.showToast('success', 'User deleted!')
          })
        }
      })
  }

  seeListing(user) {
    this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: `admin-listings-management-dashboard/${user.id}` } }]);
  }

}
