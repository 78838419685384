<div class="manage-my-ads" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
  <h4>Approve Listing</h4>
  <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
    <div fxFlex="50" fxLayout="column" style="border: 1px solid #e4e4e4;height: 200px;">
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="space-between center">
        <a fxLayout="row" fxLayoutAlign="center center" (click)="changeDiv=1" class="tabs"
          [class.selected-tabs]="changeDiv==1">Day</a>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="changeDiv=2" class="tabs"
          [class.selected-tabs]="changeDiv==2">Month</a>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="changeDiv=3" class="tabs"
          [class.selected-tabs]="changeDiv==3">Year</a>
        <a fxLayout="row" fxLayoutAlign="center center" (click)="changeDiv=4" class="tabs"
          [class.selected-tabs]="changeDiv==4">Total</a>
      </div>
      <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" *ngIf="changeDiv==1">
        <span style="font-size: 50px;color: #8cc63f;margin-bottom: 10px;">{{newListingsToday| number: '1.0-0'}}</span>
        <span>Listing today</span>
      </div>
      <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" *ngIf="changeDiv==2">
        <span style="font-size: 50px;color: #8cc63f;margin-bottom: 10px;">{{newListingsMonth| number: '1.0-0'}}</span>
        <span>Listing Monthly</span>
      </div>
      <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" *ngIf="changeDiv==3">
        <span style="font-size: 50px;color: #8cc63f;margin-bottom: 10px;">{{newListingsYear| number: '1.0-0'}}</span>
        <span>Listing Yearly</span>
      </div>
      <div fxFlex="80" fxLayout="column" fxLayoutAlign="center center" *ngIf="changeDiv==4">
        <span style="font-size: 50px;color: #8cc63f;margin-bottom: 10px;">{{totalListings| number: '1.0-0'}}</span>
        <span>Total Listing</span>
      </div>
    </div>
    <div fxFlex="50" fxLayout="column" fxLayoutAlign="space-evenly center"
      style="border: 1px solid #e4e4e4;height: 200px;">
      <span>You have</span>
      <span style="font-size: 50px;color: #8cc63f;">{{listingToApprove| number: '1.0-0'}}</span>
      <span>waiting to Approve</span>

    </div>
  </div>
  <div *ngIf="taxonomy&&listingData.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
    <div *ngFor="let listing of listingData " style="width:99%;">
      <div class="manage-listing-card">
        <div fxLayout="column" fxLayoutAlign="start start">
          <div class="listing-card-inside-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
            <ng-container *ngFor="let billingItem of listing.listingProducts;let i = index;">
              <div [class.highlighted]="billingItem.productId==4" *ngIf="billingItem.productId==4"></div>
            </ng-container>
            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50" style="z-index: 1;">
              <div class="image-div" fxFlex="50" fxLayout="column" fxLayoutAlign="start stretch"
                (click)="viewListingEventCalled(listing)" style="cursor: pointer;">
                <div class="image"
                  [ngStyle]="{'background-image': 'url(' + storage + '/listing/' + listing?.filePath + ')'}"></div>

                <ng-container *ngFor="let billingItem of listing.listingProducts;let i = index;">

                  <div class="top-banner" *ngIf="billingItem.productId==1">
                    {{billingItem?.bannerText}}
                  </div>
                  <div class="digonal-banner" *ngIf="billingItem.productId==2">
                    {{billingItem?.bannerText}}
                  </div>
                  <div class="bottom-banner" *ngIf="billingItem.productId==3">
                    {{billingItem?.bannerText}}
                  </div>


                </ng-container>

              </div>
              <ng-container *ngFor="let billingItem of listing.listingProducts;let i = index;">
                <span style="color: #f44336; padding: 10px;font-weight:bold;"
                  *ngIf="billingItem.isCustomBannerText">CUSTOM BANNER</span>
              </ng-container>
              <span style="color: #f44336; padding: 10px;">From:
                {{listing.platformDescription!=''?listing.platformDescription:'ThaiComm'}}</span>
              <span style="color: #f44336; padding: 10px;">Listing Id:
                {{listing.id}}</span>
            </div>
            <div class="listing-side-body" fxFlex="50" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="start start">
              <div fxLayout="row" fxLayoutAlign="space-between start">

                <div fxFlex="80" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
                  <h5 (click)="viewListingEventCalled(listing)" style="cursor: pointer;">{{listing.title}}</h5>
                  <p (click)="viewListingEventCalled(listing)" style="cursor: pointer;"><i
                      class="mdi mdi-mdi mdi-map-marker"></i>{{listing.formattedAddress}}</p>
                </div>
                <div fxFlex="20" style="margin-top: 10px;margin-right: -33px;position: relative;">
                  <div class="round-image"
                    [ngStyle]="{
                      'background-image': listing.agentProfileImage?'url('+storage+'/user/'+listing.agentProfileImage+')': ''}"
                    (mouseenter)="listing.showInfo=true"></div>
                  <div *ngIf="listing.showInfo" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px"
                    (mouseleave)="listing.showInfo = false"
                    style="position: absolute;width: 500px;background: #8cc63f;padding: 20px;right: -90px;top: 69px;color:white;z-index: 9;">
                    <div class="arrow-up"></div>
                    <div>Name: {{listing.agentFirstName}} {{listing.agentLastName}}</div>
                    <div>Phone: {{listing.agentPhoneNumber}}</div>
                    <div fxLayout="row" fxLayoutAlign="start space-between" fxLayoutGap="20px">
                      <div>Email: {{listing?.agentEmail}} </div> <a (click)="copy(listing?.agentEmail)"
                        style="color:white;">Copy Email</a>
                    </div>

                  </div>
                </div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.propertyType">
                <div>Category </div>
                <div mat-flat-button (click)="openPropertypeChangeDialog(listing)"
                  style="cursor: pointer;text-align: center;padding: 5px;width: 163px;background: #8cc63f;color: white;border-radius: 2px;">
                  Add more category</div>
                <!--<span>{{getEnumById('propertyType',listing?.propertyType,false).name}}</span> -->
                <div class="text-div-right-text" fxLayout="row" fxLayoutAlign="center center"
                  style="background:#8cc63f; color:white;">
                  <button color="primary" mat-icon-button [matMenuTriggerFor]="menu"><i class="mdi mdi-pencil"
                      style="color:white;"></i></button>
                  <span [matMenuTriggerFor]="menu"
                    style="cursor: pointer;">{{getEnumById('propertyType',null,listing?.propertyType,false).name}}</span>
                  <mat-menu #menu="matMenu" class="propertyTypeMenu">
                    <mat-list role="list" style="padding: 20px;max-width:600px;width:600px;" fxLayout="row wrap"
                      fxLayoutAlign="space-between center">
                      <mat-list-item *ngFor="let item of taxonomy.propertyTypeResidentialType"
                        (click)="updatePropertyTypeListing(listing.id,item)" style="cursor: pointer;">{{item.name}}
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start"
                *ngIf="listing?.residentialType||selectedPropertyType?.sub?.length">
                <div>Type</div>
                <!-- <span
                  *ngIf="listing?.residentialType">{{getEnumById('residentialType',listing?.residentialType,false).name}}</span> -->
                <div class="text-div-right-text" style="background:#8cc63f; color:white;">
                  <button color="primary" mat-icon-button [matMenuTriggerFor]="menu1" [matMenuTriggerFor]="menu1"><i
                      class="mdi mdi-pencil" style="color:white;"></i></button>
                  <span [matMenuTriggerFor]="menu1" style="cursor: pointer;"
                    *ngIf="listing?.residentialType">{{getEnumById('residentialType',null,listing?.residentialType,false).name}}</span>
                  <mat-menu #menu1="matMenu" class="subPropertyTypeMenu">
                    <mat-list role="list" style="padding: 20px;max-width:600px;width:600px;" fxLayout="row wrap"
                      fxLayoutAlign="space-between center">
                      <mat-list-item *ngFor="let item of selectedPropertyType?.sub"
                        (click)="updateResidentialTypeListing(listing.id,item)" style="cursor: pointer;">{{item.name}}
                      </mat-list-item>
                    </mat-list>
                  </mat-menu>
                </div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.listingAdditionalTypes.length">
                <div>See more categories</div>
                <div class="text-div-right-text"  style="position: relative;"
                  (mouseenter)="listing.showAdditionalTypes=true" (mouseleave)="listing.showAdditionalTypes = false">
                  <span>{{listing?.listingAdditionalTypes.length}}</span>
                  <div class="additionaltypes" *ngIf="listing.showAdditionalTypes">
                    <div *ngFor="let item of listing?.listingAdditionalTypes">
                      {{getEnumById('propertyType',null,item.propertyType,false).name}}-{{getEnumById('residentialType',null,item?.residentialType,false).name}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.propertySize">
                <div>Usable Area (sm)</div>
                <div class="text-div-right-text">{{listing?.propertySize | number: '1.0-0'}}</div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="listing?.numUnits">
                <div>#Units/Rooms</div>
                <div class="text-div-right-text">{{listing?.numUnits | number: '1.0-0'}}</div>
              </div>
              <div class="text-div" fxLayout="row" fxLayoutAlign="space-between start"
                *ngIf="listing?.propertySize&&listing?.price">
                <div>Price/SqM (baht)</div>
                <div class="text-div-right-text">{{listing?.price/listing?.propertySize | number: '1.0-0'}}</div>
              </div>
            </div>
          </div>
          <div class="listing-card-price" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between start"
            style="margin-top:10px;">
            <div fxFlex="30" fxLayout="column" fxLayoutAlign="start start">
              <p *ngIf="listing?.createdAt">Date Listed {{listing?.createdAt | date }}</p>
              <div class="price" *ngIf="listing?.price">{{listing?.price | number: '1.0-0' }} THB</div>
              <div class="price" *ngIf="!listing?.price">Price Upon Request</div>
            </div>
            <div fxFlex="70" fxLayout="row" fxLayoutGap="20px" style="padding-right: 10px;"
              fxLayoutAlign="space-between center">

              <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                <button mat-flat-button [color]="'primary'" style="width: 100px;margin-right:10px;"
                  (click)="viewListingEventCalled(listing)">View</button>
                <button mat-flat-button [color]="'primary'" style="width: 100px;margin-right:10px;"
                  (click)="approveOrSuspend(listing.id,1)">Approve</button>

                <button mat-flat-button [color]="'primary'" style="width: 100px;"
                  (click)="approveOrSuspend(listing.id,12)">Suspend</button>
                <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
                  (click)="edit(listing.id)">Edit</button>
                <button mat-flat-button [color]="'primary'" style="margin-left:10px;width: 100px;"
                  (click)="deleteEventCalled(listing.id)">Delete</button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
  </div>
  <div *ngIf="!listingData.length">
    No listing to show.
  </div>
</div>

<ng-template #viewListingDialog>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button (click)="close()"><i class="mdi mdi-close" style="font-size: 22px;"></i></button>
    </div>
    <div class="listing-div">
      <app-listing-details [listingId]="listingId"></app-listing-details>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin-top:20px;">

      <div fxFlex></div>
      <button mat-flat-button [color]="'primary'" (click)="approveOrSuspend(listingId,1)">Approve</button>
      <button mat-flat-button (click)="approveOrSuspend(listingId,12)">Suspend</button>
      <div fxFlex></div>
      <!-- <eco-fab-speed-dial direction="left" animationMode="scale" style="margin: 10px;">
        <eco-fab-speed-dial-trigger spin="true"> -->
      <button mat-fab mat-button [color]="'primary'" [matMenuTriggerFor]="animals"><i class="mdi mdi-dots-horizontal"
          style="color:white;"></i></button>
      <mat-menu #animals="matMenu" yPosition="above">
        <button mat-menu-item style="background:white;font-size: 18px;" fxLayout="row"
          fxLayoutAlign="space-between center"> <i class="mdi mdi-delete-outline"
            style="color:black;"></i><span>Delete</span></button>
        <button mat-menu-item style="background:white;font-size: 18px;" fxLayout="row"
          fxLayoutAlign="space-between center"><i class="mdi mdi-square-edit-outline" (click)="edit()"
            style="color:black;"></i><span>Edit</span></button>
        <button mat-menu-item style="background:white;font-size: 18px;" [matMenuTriggerFor]="appMenu" fxLayout="row"
          fxLayoutAlign="space-between center">
          <i class="mdi mdi-eye-settings-outline" style="color:black;"></i>
          <span>Change Status</span>
        </button>
      </mat-menu>
      <mat-menu #appMenu="matMenu" yPosition="above">
        <button mat-menu-item (click)="approveOrSuspend(listingId,1)">Active</button>
        <button mat-menu-item (click)="approveOrSuspend(listingId,12)">Suspend</button>
      </mat-menu>

      <!-- </eco-fab-speed-dial-trigger> -->

      <!-- <eco-fab-speed-dial-actions>
          <button class="fab-button delete" mat-mini-fab style="background:white"><i class="mdi mdi-delete-outline"
              style="color:black;"></i></button>
          <button class="fab-button edit" mat-mini-fab style="background:white"><i class="mdi mdi-square-edit-outline"
              style="color:black;"></i></button>
          <button class="fab-button edit" mat-mini-fab style="background:white"
          [matMenuTriggerFor]="appMenu"><i class="mdi mdi-eye-settings-outline"
              style="color:black;"></i></button>
              <mat-menu #appMenu="matMenu">
                <button mat-menu-item>Settings</button>
                <button mat-menu-item>Help</button>
              </mat-menu>
        </eco-fab-speed-dial-actions>
      </eco-fab-speed-dial> -->
    </div>

  </mat-dialog-content>
</ng-template>

<ng-template #updatePropertyTypeDialog>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button (click)="close()"><i class="mdi mdi-close" style="font-size: 22px;"></i></button>
    </div>
    <div class="listing-div">
      <form [formGroup]="advanceSearch">
        <div class="accordion-div" *ngFor="let property of taxonomy.propertyTypeResidentialType; let i = index">
          <mat-accordion (click)="$event.stopPropagation();" *ngIf="property.id!==9&&property.id!==10"
            (change)="changeCount = changeCount + 1">
            <mat-expansion-panel>
              <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-between center">
                <mat-panel-title>
                  <mat-checkbox color="primary" (change)="onChange($event,property)"
                    [(indeterminate)]="property.indeterminate" [value]="property.id">{{property.name}}
                  </mat-checkbox>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div *ngFor="let residential of property.sub ">
                <mat-checkbox color="primary" [value]="residential.id"
                  (change)="onChangeResident($event,[],null,property.id)"
                  [checked]="advanceSearch.get('residentialType').value.indexOf(residential.id) > -1">
                  {{residential.name}}</mat-checkbox>
              </div>

            </mat-expansion-panel>
          </mat-accordion>

        </div>

      </form>
    </div>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-flat-button [color]="'primary'" (click)="propertyTypeUpdated()">Update</button>
  </div>
</ng-template>