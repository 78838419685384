import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { FlexLayoutModule, CoreModule } from '@angular/flex-layout';
import { SharedModule } from 'src/shared/shared.module';
import { AddListingComponent } from './containers/add-listing/component/add-listing.component';
import { ReactiveFormsModule  } from '@angular/forms';
import { MediaModule } from 'src/media/media.module';
import { PaymentModule } from 'src/payment-component/payment.module';
import { GenericDialogComponent } from './containers/generic-dialog/generic-dialog.component';
import { LocationModule } from 'src/location/location.module';
import { ListingService } from './service/listing.service';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { AgmCoreModule } from '@agm/core';
import { ContactModule } from 'src/contact/contact.module';
import { GalleryModule } from 'src/gallery/gallery.module';
import { UserRouteResolver } from 'src/app/user-route-resolver';
import { LoginRegisterModule } from 'src/login-register/login-register.module';
import { CommonModule } from '@angular/common';

const routes: Routes = [
    {
      path: "add-listing",
      component: AddListingComponent,
      resolve: {
        userRouteResolver: UserRouteResolver
      }
    }
  ];


@NgModule({
  declarations: [
    AddListingComponent,
    GenericDialogComponent
  ],
  entryComponents:[GenericDialogComponent],
  exports: [ AddListingComponent],
  imports: [
    CoreModule,
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    MediaModule,
    PaymentModule,
    LocationModule,
    NgxScrollToFirstInvalidModule,
    AgmCoreModule,
    ContactModule,
    GalleryModule,
    LoginRegisterModule,
    RouterModule.forChild(routes),
  ],
  providers:[ListingService, UserRouteResolver]
})
export class ListingModule {}
