import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ListingService } from 'src/listing/service/listing.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'td-dashboard-approve-listings',
  templateUrl: './dashboard-admin-approve-listings.component.html',
  styleUrls: ['./dashboard-admin-approve-listings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('.35s ease-out',
              style({ height: 70, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 70, opacity: 1 }),
            animate('.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class DashboardAdminApproveListingsComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject();
  listingData: any[] = [];
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  search: FormGroup = this.fb.group({
    searchText: [''],
    listingType: [null]
  })
  dialogRef = null;
  listingId = null;
  listing = null;
  taxonomy: any;
  storage: string = environment.storageUrl;
  newListingsToday = 0;
  newListingsMonth = 0;
  newListingsYear = 0;
  totalListings = 0;
  listingToApprove = 0;
  changeDiv = 1;
  showInfo = false;
  selectedPropertyType = {
    id: null,
    name: null,
    sub: []
  };
  selectedSubPropertyType = {
    id: null,
    name: null,
    sub: []
  };

  advanceSearch: FormGroup = this.fb.group({
    residentialType: this.fb.array([]),
    propertyType: this.fb.array([])
  });
  @ViewChild('viewListingDialog', { static: false }) viewListingDialog: TemplateRef<any>;
  @ViewChild('updatePropertyTypeDialog', { static: false }) updatePropertyTypeDialog: TemplateRef<any>;
  listingAdditionalTypes = [];
  constructor(private dashboardService: DashboardService,
    private changeDetection: ChangeDetectorRef,
    private utilService: UtilService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private enumPropertyService: EnumPropertyService,
    private router: Router, private listingService: ListingService) { }


  ngOnInit() {

    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.taxonomy = data;
      this.changeDetection.detectChanges();

      this.getAllListing();
    });
  }

  getAllListing() {
    this.dashboardService.getNumberOfListingInApproveListing().pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
      this.newListingsToday = data.newListingsToday;
      this.newListingsMonth = data.newListingsMonth;
      this.newListingsYear = data.newListingsYear;
      this.totalListings = data.totalListings;
      this.listingToApprove = data.listingToApprove;
      this.changeDetection.detectChanges();
    })
    let obj = {
      listingStatus: 11
    }
    this.dashboardService.getApproveListing(this.currentPage, 10, obj)
      .pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {


        this.listingData = data;
        this.changeDetection.detectChanges();
        this.pageInfo = pageInfo;
        this.changeDetection.detectChanges();
      })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllListing();
  }

  deleteEventCalled(ev: number) {
    this.utilService.showAcceptDeclineDialog("You are about to delete a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$))
      .subscribe((accepet) => {

        if (accepet) {
          this.dashboardService.deleteListing(ev).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
            this.utilService.showToast('success', `#${ev}Listing Deleted!`);
            this.getAllListing();
          }, (err) => {
            console.error(err)
            this.utilService.showToast('error', `There is an error deleting listing! Try again later.`);
          })
        }
      })
  }
  openPropertypeChangeDialog(ev) {

    this.listingId = ev.id;
    this.listing = ev;
    this.dialogRef = this.dialog.open(this.updatePropertyTypeDialog, {
      panelClass: 'openPropertypeChangeDialog',
      data: { listingId: ev.id }
    })
  }

  viewListingEventCalled(ev) {

    this.listingId = ev.id;
    this.listing = ev;
    this.dialogRef = this.dialog.open(this.viewListingDialog, {
      panelClass: 'updatePropertyTypeDialog',
      data: { listingId: ev.id }
    })
  }
  approveOrSuspend(listingId, stsusId) {
    this.dashboardService.updateListingStatus(listingId, stsusId).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.getAllListing();
      this.utilService.showToast('success', `Listing status changed!`);
    })
  }
  close() {
    this.dialogRef.close();
  }
  searchForListing() {
    this.currentPage = 1;
    this.getAllListing()
  }
  getEnumById(firstElement, type, secondElement, id) {
    let nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);

    return nameOrNumber;
  }
  goto(listingId) {
    this.router.navigate(["../listing-detail", listingId]);
  }
  edit(listingId) {
    this.dashboardService.getSingleListing(listingId).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {

      if (!data.address.subDistrictId) {
        data.address.subDistrictId = 1;
      }
      if (!data.address.districtId) {
        data.address.districtId = 1;
      }
      if (!data.address.provinceId) {
        data.address.provinceId = 1;
      }
      this.listingService.listingData = data;

      this.router.navigate(['/add-listing']);
    })

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  updatePropertyTypeListing(listingId, propertyType) {
    this.dashboardService.adminUpdatePropertyTypeOfListing(listingId, propertyType.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.selectedPropertyType = propertyType;
        this.updateResidentialTypeListing(listingId, null);
        this.utilService.showToast('success', `Property Type Updated!`);
      });
  }
  updateResidentialTypeListing(listingId, residentialType) {
    this.dashboardService.adminUpdateResidentialTypeIdOfListing(listingId, residentialType ? residentialType.id : null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.selectedSubPropertyType = residentialType;
        this.getAllListing();
        this.utilService.showToast('success', `Type Updated!`);
      });
  }

  copy(item) {
    console.log("item", item);
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
    };
    console.log("listener", listener);
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.utilService.showToast('success', 'Email Coppied!')
  }


  onChange(event, property) {
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    let residentArray = [];
    if (property.sub) {
      property.sub.forEach(element => {
        residentArray.push(element.id);
      });
    }
    if (event.checked) {
      propertyType.push(new FormControl(event.source.value));
      if (residentArray.length) {
        this.onChangeResident(null, residentArray, 'add')
      }
    } else {
      const i = propertyType.controls.findIndex(x => x.value === event.source.value);
      propertyType.removeAt(i);
      if (residentArray.length) {
        this.onChangeResident(null, residentArray, 'delete')
      }
    }

  }
  onChangeResident(event = null, array = [], type = null, mainPropertyId = null) {
    const residentType = <FormArray>this.advanceSearch.get('residentialType') as FormArray;
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    if (array.length && type == 'add') {
      array.forEach(x => residentType.push(new FormControl(x)));
      this.changeDetection.detectChanges();
    } else if (array.length && type == 'delete') {
      array.forEach(mainElement => {
        const i = residentType.controls.findIndex(x => x.value === mainElement);
        residentType.removeAt(i);
        this.changeDetection.detectChanges();
      })
    } else {
      if (event.checked) {
        const i = residentType.controls.findIndex(x => x.value === event.source.value);
        if (i == -1) {
          this.taxonomy.propertyTypeResidentialType.map((data: any) => {
            if (data.id == mainPropertyId) {
              data.indeterminate = true;
              this.changeDetection.detectChanges();
            }
          });
          propertyType.push(new FormControl(mainPropertyId));
          residentType.push(new FormControl(event.source.value));
        }
      } else {
        const i = residentType.controls.findIndex(x => x.value === event.source.value);
        residentType.removeAt(i);

        this.taxonomy.propertyTypeResidentialType.map((data: any) => {

          if (data.id == mainPropertyId) {
            data.indeterminate = true;
            this.changeDetection.detectChanges();
          }
        })
      }
    }
  }


  propertyTypeUpdated() {
    const propertyType = <FormArray>this.advanceSearch.get('propertyType') as FormArray;
    const residentType = <FormArray>this.advanceSearch.get('residentialType') as FormArray;
    this.listingAdditionalTypes = [];
    
    this.taxonomy.propertyTypeResidentialType.forEach(element => {
      propertyType.value.forEach(propertyTypeValue => {
        if (element.id == propertyTypeValue) {
          if (element.sub&&element.sub.length) {
            element.sub.forEach(subElement => {
              residentType.value.forEach(residentTypeSubElement => {
                if (subElement.id == residentTypeSubElement) {
                  let a = {
                    propertyType: element.id,
                    residentialType: subElement.id
                  }
                  this.listingAdditionalTypes.push(a);
                }
              });
            });
          } else {
            let a = {
              propertyType: element.id,
              residentialType: null
            }
            this.listingAdditionalTypes.push(a);
          }
        }
      })
    });
    let obj = {
      listingId: this.listingId,
      listingAdditionalTypes: this.listingAdditionalTypes
    };
    setTimeout(() => {
      this.dashboardService.updatePropertyType(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        console.log("data", data);
        this.getAllListing();
        this.utilService.showToast('success', `Additional property type added to listing!`);
        this.dialogRef.close();
      })
    },200)
  }

}
