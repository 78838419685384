<div class="manage-messages" fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width:100%;">
      <h4>Message</h4>
      <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
        <input type="text" placeholder="Search" />
        <select fxFlex="20" [(ngModel)]="assDec" (change)="currentPage=0;getListOfMessages()">
          <option value='false'>Newest</option>
          <option value='true'>Oldest</option>
        </select>
      </div>
      <span *ngIf="messageList.length" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" >
        <div *ngFor="let message of messageList">
          <div class="message-body" [class.unread]="message.status ==1" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div class="left-text" fxFlex="70" fxLayout="column" fxLayoutAlign="space-around start"
              (click)="showMessageDialog(message)">
              <h5>{{message.subject}}</h5>
              <p>{{message.detail}}</p>
            </div>
            <div class="right-text" fxFlex="10" fxLayout="column" fxLayoutAlign="space-evenly center">
              <div class="data" (click)="showMessageDialog(message)">
                <p>{{message.createdAt| date }}</p>
              </div>
              <button type="button" class="fab-button" mat-mini-fab aria-label="Example icon-button with a heart icon"
                (click)="deleteEventCalled(message.id)">
                <i class="mdi mdi-delete-outline"></i>
              </button>
            </div>
          </div>
        </div>
        <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
      </span>
      <span *ngIf="!messageList.length">
        No message to show!
      </span>
    </div>
  
  </div>
  
  <ng-template #messageDialog>
    <h3 matDialogTitle>{{message.subject}}</h3>
    <mat-dialog-content>
      <q>{{message.detail}}</q>
      <div fxLayout="column" fxLayoutAlign="end end">
        <h5>{{message.name}}</h5>
        <h5>{{message.email}}</h5>
        <h5>{{message.telephone}}</h5>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
      <button type="button" mat-button matDialogClose color="primary">Dismiss</button>
    </mat-dialog-actions>
  </ng-template>