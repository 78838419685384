import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ContactComponent } from 'src/contact/component/contact.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  constructor(private dialog: MatDialog,) { }

  ngOnInit() {
  }

  openMapDialog() {
    
    this.dialog.open(ContactComponent, {
      minWidth: '453px',
      panelClass: 'contact-dialog',
    }).afterClosed().subscribe(data => {
      
      if (data) {
        
      }
    })
  }
}
