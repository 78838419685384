
    <div class="cropperd-dialog-container">
      <div
        class="button-container"
        fxLayout="row"
        fxLayoutAlign="end"
        fxLayoutGap="4%"
      >
        <button mat-raised-button mat-dialog-close color="accent">
          Cancel
        </button>
        <button
          mat-raised-button
          [disabled]="!file"
          [mat-dialog-close]="file"
          color="primary"
        >
          Accept Image
        </button>
      </div>
      <image-cropper
        [imageChangedEvent]="data.fileEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="data.aspectRatio"
        [resizeToWidth]="data.width"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (loadImageFailed)="loadImageFailed()"
      >
      </image-cropper>
    </div>
  