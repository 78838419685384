import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, AfterViewInit, AfterContentChecked, AfterContentInit, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'td-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit, AfterViewInit {
  @Input() medias: [];
  storage = environment.storageUrl;


  constructor(private el: ElementRef) {

  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    setTimeout(() => {
      jQuery(document).ready(function () {

        $('#my-slider').sliderPro({
          arrows: true,
          waitForLayers: true,
          fade: false,
          autoplay: true,
          autoScaleLayers: false,
          imageScaleMode: "exact",
          visibleSize: '100%',
          slideDistance: 1,
          buttons: false,
          slide: 1,
          lazyLoading: true,
          lazyLoadingInPrevNext: true,
          // width: '100%',
          // width: 960,
          // height: 500,
          autoHeight: false,
          slideAnimationDuration: 500,
          fadeArrows: true,
          thumbnailPointer: true,
          thumbnailArrows: true,
          thumbnailTouchSwipe: true,
          fullScreen: true,
          breakpoints: {
            320: {
              width:500,
              height:300,
              thumbnailWidth: 120,
              thumbnailHeight: 120
            },
            480: {
              width:500,
              height:300,
              thumbnailWidth: 120,
              thumbnailHeight: 120
            },
            640: {
              width:500,
              height:300,
              thumbnailWidth: 120,
              thumbnailHeight: 120
            },
            960: {
              width:500,
              height:300,
              thumbnailWidth: 120,
              thumbnailHeight: 120
            }
          },
          init: function () {
            //alert(1);
            //$( 'img' ).show();
            $('p').show();
            setTimeout(function () {
              $('#loading-mask ').fadeOut('fast');
            }, 500);
          }
        });
      });
    }, 100)
  }

}
