import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  fullPaymentObj:any=null;
  constructor(private http: HttpClient) { }

  getPackageDetails(packageId=null, packageItemId=null, packageItemDetailId=null) {
    
    
    
    let packageObject = `{"query" : "{ packages(id:${packageId}) {  id name description packageTypeId createdAt updatedAt medias{id filePath } packageItems(id:${packageItemId}){id name order createdAt updatedAt packageId  medias{id filePath } packageItemDetails(id:${packageItemDetailId}){ id order name price days createdAt updatedAt packageItemId  medias{id filePath } }} } }   "}`;
    return this.http.post(`${environment.serverUrl}graphql/Package`, packageObject, this.options);
  }

  getBillDetails(billId:number){
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Bills/${billId}/PaymentPreview`);
  }

  createOrder(orderObj){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}orders`,orderObj , this.options);
  }

  payForBill(paymentObj,billid){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}payments/bills/${billid}/pay`,paymentObj , this.options);
    
  }
  
  // getBillDetails(billId){
  //   let billObject=`{
  //     "query" : '{ billPackageDetails ( billId: \"${billId}\") 
  //     {  price listing{id}   }}'
  // }`
  //   // let billObject = `{"query" : "{ billPackageDetails (billId : \"${billId}\"   ){  billId  listingId   } }  "}`;
  //   return this.http.post(`${environment.serverUrl}graphql/billPackageDetail`, billObject, this.options)
  // }

  updateNewText(listingId, billId, from, to){
    return this.http.put(`${environment.serverUrl}${environment.apiUrl}listing/${listingId}/bill/${billId}/${from}/${to}`,{})
  }

  getProducts(){
    return this.http.get(`${environment.serverUrl}${environment.apiUrl}Products`)
  }
  paypalPayment(billId){
    return this.http.post(`${environment.serverUrl}${environment.apiUrl}payments/paypal/${billId}/payment`,{});
  }
}
