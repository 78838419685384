<div class="listing-detail" fxLayout="column" fxLayoutAlign="start center" *ngIf="listing"
    fxLayoutAlign.xs="start stretch" [ngStyle]="listingId&&{'background': 'white'}">
    <div class="listing-detail-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center start" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <div class="listing-details-box">
            <span fxShow fxHide.xs fxHide.gt-xs fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
                <h3>
                    {{listing?.title}}</h3>
                <div fxLayout="row" fxLayoutAlign="space-between center" style="padding-right: 6px;">
                    <p style="width:400px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        class="dim-color" [matTooltip]="listing?.formattedAddress" [matTooltipPosition]="'above'"
                        matTooltipClass="dark-tooltip"><i class="mdi mdi-map-marker"></i>
                        {{listing?.formattedAddress}}</p>
                    <p class="dim-color"><i class="mdi mdi-calendar-edit"></i> {{'Date Listed'|translate}}
                        {{listing?.createdAt | date }}
                    </p>
                    <p class="dim-color"><i class="mdi mdi-eye-outline"></i> {{'Ad Views:'|translate}}
                        {{listing?.listingViews | number: '1.0-0'}}</p>
                </div>
            </span>
            <td-gallery [medias]="listing?.medias"></td-gallery>
            <div class="mobile-price-div" fxHide fxShow.xs fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md
                fxHide.lg fxHide.gt-lg>
                <div class="price-div" fxLayout="row" fxLayoutAlign="start center"
                    *ngIf="!listing.askingPriceUponRequest">
                    <h3 style="margin-right:10px;color:#8cc63f;">{{listing?.price | number}} THB</h3>

                </div>
                <div class="price-div" fxLayout="row" fxLayoutAlign="space-between center"
                    *ngIf="listing.askingPriceUponRequest">
                    <h3 style="margin-right:10px;" translate>Price Upon Request</h3>
                </div>
                <div class="title-address-div" fxLayout="column" fxLayoutAlign="start start">
                    <h5>
                        {{listing?.title}}</h5>
                    <p style="width:400px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        class="dim-color" [matTooltip]="listing?.formattedAddress" [matTooltipPosition]="'above'"
                        matTooltipClass="dark-tooltip"><i class="mdi mdi-map-marker"></i>
                        {{listing?.formattedAddress}}</p>
                </div>
                <div class="date-view-div" fxLayout="row" fxLayoutAlign="space-between center">
                    <p class="dim-color"><i class="mdi mdi-calendar-edit"></i> {{'Date Listed'|translate}}
                        {{listing?.createdAt | date }}
                    </p>
                    <p class="dim-color"><i class="mdi mdi-eye-outline"></i> {{'Ad Views:'|translate}}
                        {{listing?.listingViews | number: '1.0-0'}}</p>
                </div>
            </div>
            <div [ngClass]="{'showMore': showMore||!matcher.matches,'showLess':!showMore&&matcher.matches}">
                <div class="description-div" fxLayout="row" fxLayoutAlign="space-between center">
                    <h4 translate>Description</h4>
                    <p fxFlex></p>
                    <a style="margin-right: 35px;" class="report-share"
                        (click)="openReportDialog(listing?.id,listing?.title)">
                        <i class="mdi mdi-alert"></i>{{'Report Ad'|translate}}</a>
                    <a class="report-share" (click)="openShareDialog()" fxShow fxHide.xs fxHide.gt-xs fxHide.sm
                        fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg><i class="mdi mdi-share-variant"></i>
                        {{'Share ad'|translate}}
                    </a>

                </div>
                <p *ngIf="listing.isOwner" translate>Owner Posted</p>
                <div class="description">
                    {{listing?.description}}
                </div>
                <div class="type-div" fxLayout="row wrap" fxLayoutAlign="space-betwee center" fxLayout.xs="column"
                    fxLayoutAlign="start start">
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.listingType">
                        <p translate>Listing Type</p>
                        <p>{{getEnumById('listingType',null,listing?.listingType,false).name}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.propertyType">
                        <p translate>Property Type</p>
                        <p *ngIf="listing?.listingAdditionalTypes.length">Multiple</p>
                        <p *ngIf="!listing?.listingAdditionalTypes.length">
                            {{getEnumById('propertyType',null,listing?.propertyType,false).name}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.residentialType&&getEnumById('propertyType',null,listing?.propertyType,false).id!=7
                    &&listing?.residentialType&&getEnumById('propertyType',null,listing?.propertyType,false).id!=6">
                        <p translate>Business Type</p>
                        <p style="text-align: right;" *ngIf="listing?.listingAdditionalTypes.length">Multiple</p>
                        <p style="text-align: right;" *ngIf="!listing?.listingAdditionalTypes.length">
                            {{getEnumById('residentialType',null,listing?.residentialType,false).name}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.holdType&&listing?.listingType==1">
                        <p translate>Freehold or Leasehold</p>
                        <p>{{getEnumById('holdType',null,listing?.holdType,false).name}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.landSize">
                        <p translate>Land Size</p>
                        <p>{{listing?.landSize}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.propertySize">
                        <p translate>Usable Area (sm)</p>
                        <p>{{listing?.propertySize| number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing.propertySize&&listing.price&&getEnumById('propertyType',null,listing?.propertyType,false).id!=8">
                        <p>{{listing?.listingType==1?'Price':'Rent' |translate}}/Sqm (Baht)</p>
                        <p>{{listing?.price/listing?.propertySize| number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.numUnits">
                        <p translate>Units/Rooms</p>
                        <p>{{listing?.numUnits}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.numBeds">
                        <p translate>Bedroom</p>
                        <p>{{listing?.numBeds}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.numBaths">
                        <p translate>Bathroom</p>
                        <p>{{listing.numBaths}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.numParking">
                        <p translate>Parking</p>
                        <p>{{listing?.numParking}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.funishedState&&getEnumById('propertyType',null,listing?.propertyType,false).id!=7
                    &&listing?.funishedState
                    &&getEnumById('propertyType',null,listing?.propertyType,false).id!=8
                    &&getEnumById('propertyType',null,listing?.propertyType,false).id!=4
                    &&listing?.listingType==1">
                        <p translate>Furnish </p>
                        <p>{{getEnumById('funishedState',null,listing.funishedState,false).name}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.occupancyRate">
                        <p translate>Occupancy Rate (%) </p>
                        <p>{{listing?.occupancyRate| number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.zoningUses">
                        <p translate>Zoning Uses </p>
                        <p>{{listing?.zoningUses}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="listing?.ageYear">
                        <p translate>Age (years)</p>
                        <p>{{listing?.ageYear}}</p>
                    </div>

                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.price&&listing?.numUnits">
                        <p>{{listing?.listingType==1?'Price':'Rent'|translate}}/{{'Room (Baht)'|translate}}</p>
                        <p>{{(listing?.price / listing?.numUnits) | number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="getEnumById('propertyType',null,listing?.propertyType,false).id==1">
                        <p translate>Total Rent/Mo</p>
                        <p>{{listing?.uponRequestPremisesRent?'Upon Request':listing?.totalRentPerMonth | number: '1.0-0'}}
                        </p>
                    </div>

                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.transferCost!=null&&listing?.transferCost>=0">
                        <p translate>Key Money (Baht)</p>
                        <p>{{listing?.transferCost | number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="listing?.minimumYearContract">
                        <p translate>Minimum Contract (yrs.)</p>
                        <p>{{listing?.minimumYearContract | number: '1.0-0'}}</p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="getEnumById('propertyType',null,listing?.propertyType,false).id==8">
                        <p translate>Annual Cash Flow (Baht)</p>
                        <p>{{listing?.uponRequestAnnualCashFlow?'Upon Request':listing?.annualCashFlow | number: '1.0-0'}}
                        </p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="getEnumById('propertyType',null,listing?.propertyType,false).id==8">
                        <p translate>Annual Revenue (Baht)</p>
                        <p>{{listing?.uponRequestAnnualRevenu?'Upon Request':listing?.annualRevenue | number: '1.0-0'}}
                        </p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="getEnumById('propertyType',null,listing?.propertyType,false).id==8">
                        <p translate>Premises Rent (Baht)</p>
                        <p>{{listing?.uponRequestPremisesRent?'Upon Request':listing?.totalRentPerMonth | number: '1.0-0'}}
                        </p>
                    </div>
                    <div class="box-div" fxLayout="row" fxLayoutAlign="space-between center"
                        *ngIf="getEnumById('propertyType',null,listing?.propertyType,false).id==5">
                        <p translate>Gross Income/Mo. (Baht)</p>
                        <p>{{listing?.uponRequestGrossIncomePerMonth?'Upon Request':listing?.grossIncomePerMonth | number: '1.0-0'}}
                        </p>
                    </div>
                </div>
                <div style="padding-right: 6px;">
                    <agm-map [streetViewControl]="false" [gestureHandling]="'greedy'" [latitude]="listing?.latitude"
                        [longitude]="listing?.longitude" [zoom]="15" [disableDefaultUI]="true" [zoomControl]="true"
                        [clickableIcons]="false">
                        <agm-marker [markerDraggable]="false" [latitude]="listing?.latitude"
                            [longitude]="listing?.longitude"></agm-marker>
                    </agm-map>
                </div>
            </div>
            <div class="contact-box" fxHide fxShow.xs fxShow.gt-xs fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md
                fxHide.lg fxHide.gt-lg style="height: 100px;">
                <div class="semitransparent" fxLayout="row" fxLayoutAlign="center center" *ngIf="!showMore"
                    (click)="showMore=true">Read More..
                </div>
                <div class="semitransparent" fxLayout="row" fxLayoutAlign="center center" *ngIf="showMore"
                    (click)="showMore=false">Show Less..
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img src="assets/images/user/user.png" alt="">
                    <div class="contact-text-box" fxLayout="column" fxLayoutAlign="center start">
                        <span translate>Contact Owner/Agent</span>
                        <span>{{listing?.contact?.name}}</span>
                    </div>
                </div>

            </div>
            <div class="button-div" fxLayout="row" fxLayoutAlign="space-around center" fxHide fxShow.xs fxShow.gt-xs
                fxShow.sm fxHide.gt-sm fxHide.md fxHide.gt-md fxHide.lg fxHide.gt-lg>
                <button mat-fab class="share-button" (click)="openShareDialog()"><i
                        class="mdi mdi-share-variant"></i></button>
                <a mat-fab class="call-button" href="tel:{{listing?.contact?.phoneNumber}}"><i
                        class="mdi mdi-phone-outgoing"></i></a>
                <a mat-fab class="mail-button" href="mailto:{{listing?.contact?.email}}"><i
                        class="mdi mdi-email-outline"></i></a>
            </div>
        </div>
        <div fxShow fxHide.xs fxHide.gt-xs fxHide.sm fxShow.gt-sm fxShow.md fxShow.gt-md fxShow.lg fxShow.gt-lg>
            <div class="floating-div">
                <div class="price-div" fxLayout="row" fxLayoutAlign="space-between center"
                    *ngIf="!listing.askingPriceUponRequest">
                    <h3>฿</h3>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <h3 style="margin-right:10px;">{{listing?.price | number}}</h3>
                        <h3>THB</h3>
                    </div>
                    <div fxFlex="10"></div>
                </div>
                <div class="price-div" fxLayout="row" fxLayoutAlign="space-between center"
                    *ngIf="listing.askingPriceUponRequest">
                    <h3 style="margin-right:10px;" translate>Price Upon Request</h3>
                </div>
                <div class="call-div" fxLayout="row" fxLayoutAlign="space-between center">
                    <h3><i class="mdi mdi-phone"></i></h3>
                    <div fxLayout="column" fxLayoutAlign="center start" style="width: 80%;">
                        <h3 *ngIf="listing?.contact?.hidePhoneNumber&&listing?.contact?.phoneNumber">
                            {{listing?.contact?.phoneNumber.slice(0, -4)}}XXXX</h3>
                        <h3 *ngIf="!listing?.contact?.hidePhoneNumber">{{listing?.contact?.phoneNumber}}</h3>
                        <a (click)="listing?.contact.hidePhoneNumber=!listing?.contact?.hidePhoneNumber" translate>Click
                            here to see number</a>
                    </div>
                </div>
                <div class="contact-box" fxLayout="column" fxLayoutAlign="start start">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <img src="assets/images/user/user.png" alt="">
                        <div class="contact-text-box" fxLayout="column" fxLayoutAlign="center start">
                            <span translate>Contact Owner/Agent</span>
                            <span>{{listing?.contact?.name}}</span>
                        </div>
                    </div>
                    <div style="width: 100%;margin-top:15px;" *ngIf="!listingId">
                        <td-contact [userId]="listing?.user?.id" [userOwnListing]="ownListing"></td-contact>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #reportDialog>
    <mat-dialog-content>

        <div class="login-bg" fxLayout="column" fxLayoutAlign="center center" *ngIf="!isAuthentic">
            <div fxLayout="row" fxLayoutAlign="space-between center" style="width:95%;color:white;">
                <h3 style="color:white;" translate>Report this listing</h3>
                <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
            </div>
            <h2>{{'Sign in or Register'|translate}}<br> {{'to report this listing.'|translate}}</h2>
            <div class="loging-register-dialog">
                <td-login-register [notDialog]="true" (loginResponse)="checkAuthentication()"></td-login-register>
            </div>
        </div>
        <form [formGroup]="reportForm" *ngIf="!reportSend&&isAuthentic">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h3 translate>Report this listing</h3>
                <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
            </div>
            <div class="property-overview-fields" fxLayout="column" fxLayoutGap="20px">
                <label translate>Title</label>
                <input type="text" formControlName="reportTitle" />

                <div *ngIf="submited && f.reportTitle.errors" class="invalid-feedback" fxFlex="70">
                    <div *ngIf="f.reportTitle.errors.required" translate>Title is required
                    </div>
                </div>
                <label translate>Subject</label>
                <select formControlName="reportType">
                    <option value="Spam" translate>Spam</option>
                    <option value="Abusive" translate>Abusive</option>
                    <option value="Dangerous" translate>Dangerous</option>
                    <option value="Copied" translate>Copied</option>
                    <option value="Illegal" translate>Illegal</option>
                    <option value="Others" translate>Others </option>
                </select>
                <div *ngIf="submited && f.reportType.errors" class="invalid-feedback" fxFlex="70">
                    <div *ngIf="f.reportType.errors.required" translate>Subject is required
                    </div>
                </div>
                <div class="price-fields" fxLayout="row" fxLayoutAlign="start start">
                    <div class="asking-price" style="width: 100%;">
                        <textarea placeholder="Message" [ngClass]="{ 'has-error': submited && f.message.errors }"
                            formControlName="message" maxlength="2000"></textarea>
                        <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">

                            <div *ngIf="submited && f.message.errors" class="invalid-feedback" fxFlex="70">
                                <div *ngIf="f.message.errors.required" translate>Description is required
                                </div>
                                <div *ngIf="f.message.errors.minlength" translate>Minimum 2 characters
                                </div>
                                <div *ngIf="f.message.errors.maxlength" translate>Maximum 2,000 Character
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-flat-button type="button" color="primary" (click)="reportListing()"
                        *ngIf="true">{{'Report'|translate}}</button>
                </div>
            </div>

        </form>
        <div *ngIf="reportSend">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <h3 translate>Report this listing</h3>
                <button mat-icon-button (click)="close()"><i class="mdi mdi-close"></i></button>
            </div>
            <h5 translate>Thank you for your Reporting the listing. We will contact you back soon!</h5>
        </div>
    </mat-dialog-content>
</ng-template>


<ng-template #shareDialog>
    <mat-dialog-content>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div translate>Share To</div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <a (click)="shareToFb()"><img src="assets/images/social-icons/facebook.png" /></a>
                <a (click)="openLineShare()"> <img src="assets/images/social-icons/line.png" /></a>
                <a target="_blank"
                    href="https://twitter.com/intent/tweet?url={{buildUrl(listing)}}&text={{listing?.title}}%0A{{listing?.description.substr(0, 50)}}%0A&hashtags=thailandcommercial"><img
                        src="assets/images/social-icons/twitter.png" /></a>
                <a target="_blank"
                    href="http://www.linkedin.com/shareArticle?mini=true&url={{buildUrl(listing)}}&title={{listing?.title}}&summary={{listing?.description.substr(0, 50)}}&source=thailandcommercial.com"><img
                        src="assets/images/social-icons/linkedin.png" /></a>
            </div>
            
        </div>
    </mat-dialog-content>
</ng-template>