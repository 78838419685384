
<div class="package-div" *ngFor="let package of packages">
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayout.xs="row wrap" fxLayout.sm="row wrap"> 
        <div class="image-div">
            <img src="{{storage}}/package/{{item}}" *ngFor="let item of package.medias" />

        </div>
        <div class="payment-description" fxLayout="column" fxLayoutAlign="start start">
            <h1>{{package.name}}</h1>
            <p>{{package.description}}</p>
        </div>
        <div class="button-div" fxLayout="column" fxLayoutAlign="space-evenly center" fxLayoutAlign.xs="space-evenly stretch"  fxLayoutAlign.sm="space-evenly stretch" fxLayoutGap="10px">
            
            <div *ngIf="package.selected=='old'&&package.id!=4" fxLayout="column" fxLayoutAlign="start center">
                <div>Current Text :</div>
                <span *ngFor="let selectedItem of packageFromListingData ; index as i; first as isFirst">
                   
                    <ng-container *ngIf="selectedItem.productId==package.id" class="selected-currect-text">
                        
                        <span *ngFor="let packageItem of package.availableBannerTexts">
                                <ng-container *ngIf="packageItem==selectedItem.bannerText">
                                    {{packageItem}}
                                </ng-container>
                        </span>
                    </ng-container>
                </span>
            </div>
            <button type="button" mat-flat-button [color]="'primary'" *ngIf="package.selected"
                [disabled]="package.selected=='old'"
                (click)="remove(package)" >
                <i class="mdi mdi-check"></i></button>

            <button type="button" mat-flat-button [color]="'primary'" *ngIf="!package.selected" 
                (click)="openDialogForAdd(package)" style="font-size: 16px;">
                {{'Add'|translate}}
            </button>
        </div>
        <div></div>
    </div>
</div>