import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(http) {
        this.http = http;
    }
    ContactService.prototype.sendMessage = function (conactObj) {
        return this.http.post("" + environment.serverUrl + environment.apiUrl + "SupportMessage", conactObj);
    };
    ContactService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
