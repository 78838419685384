<div>
  <form [formGroup]="updateProfileForm" ngxScrollToFirstInvalid (ngSubmit)="onSubmit()">
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <div class="myprofile">
        <h5 translate>My Profile</h5>
        <mat-divider></mat-divider>
        <div class="input-div" fxLayout="row" fxLayout.md="column" fxLayoutAlign.md="start start"  fxLayoutGap="20px" fxLayoutAlign="start center">
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label >{{'First Name'|translate}}<sup>*</sup></label>
            <input type="text" placeholder="First Name" [ngClass]="{ 'has-error': submited && f.firstName.errors }"
              formControlName="firstName" />

            <div *ngIf="submited && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors.required" translate>First Name is required</div>
              <div *ngIf="f.firstName.errors.minlength" translate>Minimum 2 characters</div>
              <div *ngIf="f.firstName.errors.maxlength" translate>Maximum 70 Character</div>
            </div>
          </div>
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label>{{'Last Name'|translate}}<sup>*</sup></label>
            <input type="text" placeholder="Last Name" [ngClass]="{ 'has-error': submited && f.lastName.errors }"
              formControlName="lastName" />
            <div *ngIf="submited && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors.required" translate>Last Name is required</div>
              <div *ngIf="f.lastName.errors.minlength" translate>Minimum 2 characters</div>
              <div *ngIf="f.lastName.errors.maxlength" translate>Maximum 70 Character</div>
            </div>
          </div>
        </div>
        <div class="input-div" fxLayout="row" fxLayout.md="column" fxLayoutAlign.md="start start"  fxLayoutGap="20px" fxLayoutAlign="start center">
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label>{{'Email'|translate}}<sup>*</sup></label>
            <input type="text" placeholder="Email" [ngClass]="{ 'has-error': submited && f.email.errors }"
              formControlName="email" />
            <div *ngIf="submited && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required" translate>Email is required</div>
              <div *ngIf="f.email.errors.minlength" translate>Minimum 2 characters</div>
              <div *ngIf="f.email.errors.maxlength" translate>Maximum 70 Character</div>
            </div>
          </div>
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label>{{'Telephone'|translate}}<sup>*</sup></label>
            <input type="text" placeholder="Telephone" [ngClass]="{ 'has-error': submited && f.phoneNumber.errors }"
              formControlName="phoneNumber" />
            <div *ngIf="submited && f.phoneNumber.errors" class="invalid-feedback">
              <div *ngIf="f.phoneNumber.errors.required" translate>Telephone is required</div>
              <div *ngIf="f.phoneNumber.errors.minlength" translate>Minimum 10 characters</div>
              <div *ngIf="f.phoneNumber.errors.maxlength" translate>Maximum 70 Character</div>
            </div>
          </div>
        </div>
        <div class="input-div" fxLayout="row" fxLayoutAlign.md="start start" fxLayoutAlign="start center">
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label translate>What is Your Role?</label>
            <select placeholder="Please Select" formControlName="userRoleId" [ngClass]="{ 'has-error': submited && f.userRoleId.errors }">
              <option value="" disabled selected translate>Please Select</option>
              <option value="{{holdType.value}}"
                *ngFor="let holdType of [{value:1,name:'Property Agent'}, {value:2,name:'Owner/Landlord'}, {value:3,name:'Tenant'}, {value:4,name:'Just Looking'}]">
                {{holdType.name}}</option>
            </select>
            <div *ngIf="submited && f.userRoleId.errors" class="invalid-feedback">
                <div *ngIf="f.userRoleId.errors.required" translate>Select your role</div>
                <div *ngIf="f.userRoleId.errors.minlength" translate>Minimum 2 characters</div>
                <div *ngIf="f.userRoleId.errors.maxlength" translate>Maximum 70 Character</div>
              </div>
          </div>
        </div>
      </div>

      <div class="myprofile">
        <h4 translate>My 3 Specialities</h4> 
        <p *ngIf="type.length === 3" style="color:red; font-size:12px;" translate>Please unselect (1) old Speciality to select new one. </p>
        <div class="available-box choose-search-type" fxLayout="row wrap" fxLayoutAlign="center">
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(1)!==-1" (click)="chnageUserSpecialities(1)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/residential-income.svg" />
            </div>
            <h3>{{'Multifamily' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(2)!==-1" (click)="chnageUserSpecialities(2)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/office.svg" />
            </div>
            <h3>{{'Office' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(3)!==-1" (click)="chnageUserSpecialities(3)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/retail.svg" />
            </div>
            <h3>{{'Retail' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(4)!==-1" (click)="chnageUserSpecialities(4)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/industrial.svg" />
            </div>
            <h3>{{'Industrial' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(5)!==-1" (click)="chnageUserSpecialities(5)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/hospitality.svg" />
            </div>
            <h3>{{'Hospitality' | translate}}</h3>
          </a>

          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(6)!==-1" (click)="chnageUserSpecialities(6)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/speciality.svg" />
            </div>
            <h3>{{'Specialty' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(7)!==-1" (click)="chnageUserSpecialities(7)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/land.svg" />
            </div>
            <h3>{{'Land' | translate}}</h3>
          </a>
          <a class="box" fxLayout="column" fxLayoutAlign="center center" [class.selected]="type.indexOf(8)!==-1" (click)="chnageUserSpecialities(8)">
            <div class="circle" fxLayout="row" fxLayoutAlign="center center">
              <img src="/assets/images/icons/businesses.svg" />
            </div>
            <h3>{{'Businesses' | translate}}</h3>
          </a>
        </div>
        <div *ngIf="submited && f.userSpecialities.errors" class="invalid-feedback">
            <div *ngIf="f.userSpecialities.errors.required" translate>Select minimum 1 type and maximum of 3</div>
            <div *ngIf="f.userSpecialities.errors.minlength" translate>Minimum 2 characters</div>
            <div *ngIf="f.userSpecialities.errors.maxlength" translate>Maximum 70 Character</div>
          </div>
      </div>

      <div class="myprofile">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h4 translate>Change Password</h4>
          <sup translate>* Enter only when you want to change the password</sup>
        </div>
        <div class="input-div" fxLayout="row" fxLayout.md="column" fxLayoutAlign.md="start start"  fxLayoutGap="20px" fxLayoutAlign="start center">
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label translate>Password</label>
            <input type="text" placeholder="Password" [ngClass]="{ 'has-error': submited && f.password.errors }"
              formControlName="password" />
            <div *ngIf="submited && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required" translate>Password is required</div>
              <div *ngIf="f.password.errors.minlength" translate>Minimum 2 characters</div>
              <div *ngIf="f.password.errors.maxlength" translate>Maximum 70 Character</div>
            </div>
          </div>
          <div class="input-box" fxLayout="column" fxLayoutAlign="start start">
            <label translate>Re-Type Password</label>
            <input type="text" placeholder="Re-Type Password"
              [ngClass]="{ 'has-error': submited && f.confirmPassword.errors }" formControlName="confirmPassword" />
            <div *ngIf="submited && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required" translate>Re-Type Password is required</div>
              <div *ngIf="f.confirmPassword.errors.minlength" translate>Minimum 2 characters</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch" translate>Passwords must match</div>
            </div>
          </div>
        </div>

      </div>
      <div class="myprofile">

        <h4 translate>Language</h4>

        <div class="input-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
          <mat-radio-group aria-label="Select an option" formControlName="languageId"  >
            <mat-radio-button value="1" [color]="'primary'" style="color: #444444;">{{'English'|translate}}
            </mat-radio-button>
            <mat-radio-button value="2" [color]="'primary'" style="color: #444444;">{{'Thai'|translate}}
            </mat-radio-button>
          </mat-radio-group>
          
        </div>
        <div *ngIf="submited && f.languageId.errors" class="invalid-feedback">
            <div *ngIf="f.languageId.errors.required" translate>Select 1 language</div>
            <div *ngIf="f.languageId.errors.minlength" translate>Minimum 2 characters</div>
            <div *ngIf="f.languageId.errors.mustMatch" translate>Passwords must match</div>
          </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="padding: 50px;">
      <button mat-flat-button [color]="'primary'">{{'Update'|translate}}</button>
    </div>
  </form>
</div>