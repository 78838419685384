import { OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { UtilService } from 'src/shared/services/util.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ListingService } from 'src/listing/service/listing.service';
var DashboardAdminApproveListingsComponent = /** @class */ (function () {
    function DashboardAdminApproveListingsComponent(dashboardService, changeDetection, utilService, fb, dialog, enumPropertyService, router, listingService) {
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.utilService = utilService;
        this.fb = fb;
        this.dialog = dialog;
        this.enumPropertyService = enumPropertyService;
        this.router = router;
        this.listingService = listingService;
        this.destroy$ = new Subject();
        this.listingData = [];
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.search = this.fb.group({
            searchText: [''],
            listingType: [null]
        });
        this.dialogRef = null;
        this.listingId = null;
        this.listing = null;
        this.storage = environment.storageUrl;
        this.newListingsToday = 0;
        this.newListingsMonth = 0;
        this.newListingsYear = 0;
        this.totalListings = 0;
        this.listingToApprove = 0;
        this.changeDiv = 1;
        this.showInfo = false;
        this.selectedPropertyType = {
            id: null,
            name: null,
            sub: []
        };
        this.selectedSubPropertyType = {
            id: null,
            name: null,
            sub: []
        };
        this.advanceSearch = this.fb.group({
            residentialType: this.fb.array([]),
            propertyType: this.fb.array([])
        });
        this.listingAdditionalTypes = [];
    }
    DashboardAdminApproveListingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.taxonomy = data;
            _this.changeDetection.detectChanges();
            _this.getAllListing();
        });
    };
    DashboardAdminApproveListingsComponent.prototype.getAllListing = function () {
        var _this = this;
        this.dashboardService.getNumberOfListingInApproveListing().pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            _this.newListingsToday = data.newListingsToday;
            _this.newListingsMonth = data.newListingsMonth;
            _this.newListingsYear = data.newListingsYear;
            _this.totalListings = data.totalListings;
            _this.listingToApprove = data.listingToApprove;
            _this.changeDetection.detectChanges();
        });
        var obj = {
            listingStatus: 11
        };
        this.dashboardService.getApproveListing(this.currentPage, 10, obj)
            .pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.listingData = data;
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        });
    };
    DashboardAdminApproveListingsComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllListing();
    };
    DashboardAdminApproveListingsComponent.prototype.deleteEventCalled = function (ev) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete a listing!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$))
            .subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteListing(ev).pipe(takeUntil(_this.destroy$)).subscribe(function (_a) {
                    var data = _a.data;
                    _this.utilService.showToast('success', "#" + ev + "Listing Deleted!");
                    _this.getAllListing();
                }, function (err) {
                    console.error(err);
                    _this.utilService.showToast('error', "There is an error deleting listing! Try again later.");
                });
            }
        });
    };
    DashboardAdminApproveListingsComponent.prototype.openPropertypeChangeDialog = function (ev) {
        this.listingId = ev.id;
        this.listing = ev;
        this.dialogRef = this.dialog.open(this.updatePropertyTypeDialog, {
            panelClass: 'openPropertypeChangeDialog',
            data: { listingId: ev.id }
        });
    };
    DashboardAdminApproveListingsComponent.prototype.viewListingEventCalled = function (ev) {
        this.listingId = ev.id;
        this.listing = ev;
        this.dialogRef = this.dialog.open(this.viewListingDialog, {
            panelClass: 'updatePropertyTypeDialog',
            data: { listingId: ev.id }
        });
    };
    DashboardAdminApproveListingsComponent.prototype.approveOrSuspend = function (listingId, stsusId) {
        var _this = this;
        this.dashboardService.updateListingStatus(listingId, stsusId).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.getAllListing();
            _this.utilService.showToast('success', "Listing status changed!");
        });
    };
    DashboardAdminApproveListingsComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    DashboardAdminApproveListingsComponent.prototype.searchForListing = function () {
        this.currentPage = 1;
        this.getAllListing();
    };
    DashboardAdminApproveListingsComponent.prototype.getEnumById = function (firstElement, type, secondElement, id) {
        var nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
        return nameOrNumber;
    };
    DashboardAdminApproveListingsComponent.prototype.goto = function (listingId) {
        this.router.navigate(["../listing-detail", listingId]);
    };
    DashboardAdminApproveListingsComponent.prototype.edit = function (listingId) {
        var _this = this;
        this.dashboardService.getSingleListing(listingId).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data;
            if (!data.address.subDistrictId) {
                data.address.subDistrictId = 1;
            }
            if (!data.address.districtId) {
                data.address.districtId = 1;
            }
            if (!data.address.provinceId) {
                data.address.provinceId = 1;
            }
            _this.listingService.listingData = data;
            _this.router.navigate(['/add-listing']);
        });
    };
    DashboardAdminApproveListingsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.complete();
    };
    DashboardAdminApproveListingsComponent.prototype.updatePropertyTypeListing = function (listingId, propertyType) {
        var _this = this;
        this.dashboardService.adminUpdatePropertyTypeOfListing(listingId, propertyType.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var data = _a.data;
            _this.selectedPropertyType = propertyType;
            _this.updateResidentialTypeListing(listingId, null);
            _this.utilService.showToast('success', "Property Type Updated!");
        });
    };
    DashboardAdminApproveListingsComponent.prototype.updateResidentialTypeListing = function (listingId, residentialType) {
        var _this = this;
        this.dashboardService.adminUpdateResidentialTypeIdOfListing(listingId, residentialType ? residentialType.id : null)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (_a) {
            var data = _a.data;
            _this.selectedSubPropertyType = residentialType;
            _this.getAllListing();
            _this.utilService.showToast('success', "Type Updated!");
        });
    };
    DashboardAdminApproveListingsComponent.prototype.copy = function (item) {
        console.log("item", item);
        var listener = function (e) {
            e.clipboardData.setData('text/plain', (item));
            e.preventDefault();
        };
        console.log("listener", listener);
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.utilService.showToast('success', 'Email Coppied!');
    };
    DashboardAdminApproveListingsComponent.prototype.onChange = function (event, property) {
        var propertyType = this.advanceSearch.get('propertyType');
        var residentArray = [];
        if (property.sub) {
            property.sub.forEach(function (element) {
                residentArray.push(element.id);
            });
        }
        if (event.checked) {
            propertyType.push(new FormControl(event.source.value));
            if (residentArray.length) {
                this.onChangeResident(null, residentArray, 'add');
            }
        }
        else {
            var i = propertyType.controls.findIndex(function (x) { return x.value === event.source.value; });
            propertyType.removeAt(i);
            if (residentArray.length) {
                this.onChangeResident(null, residentArray, 'delete');
            }
        }
    };
    DashboardAdminApproveListingsComponent.prototype.onChangeResident = function (event, array, type, mainPropertyId) {
        var _this = this;
        if (event === void 0) { event = null; }
        if (array === void 0) { array = []; }
        if (type === void 0) { type = null; }
        if (mainPropertyId === void 0) { mainPropertyId = null; }
        var residentType = this.advanceSearch.get('residentialType');
        var propertyType = this.advanceSearch.get('propertyType');
        if (array.length && type == 'add') {
            array.forEach(function (x) { return residentType.push(new FormControl(x)); });
            this.changeDetection.detectChanges();
        }
        else if (array.length && type == 'delete') {
            array.forEach(function (mainElement) {
                var i = residentType.controls.findIndex(function (x) { return x.value === mainElement; });
                residentType.removeAt(i);
                _this.changeDetection.detectChanges();
            });
        }
        else {
            if (event.checked) {
                var i = residentType.controls.findIndex(function (x) { return x.value === event.source.value; });
                if (i == -1) {
                    this.taxonomy.propertyTypeResidentialType.map(function (data) {
                        if (data.id == mainPropertyId) {
                            data.indeterminate = true;
                            _this.changeDetection.detectChanges();
                        }
                    });
                    propertyType.push(new FormControl(mainPropertyId));
                    residentType.push(new FormControl(event.source.value));
                }
            }
            else {
                var i = residentType.controls.findIndex(function (x) { return x.value === event.source.value; });
                residentType.removeAt(i);
                this.taxonomy.propertyTypeResidentialType.map(function (data) {
                    if (data.id == mainPropertyId) {
                        data.indeterminate = true;
                        _this.changeDetection.detectChanges();
                    }
                });
            }
        }
    };
    DashboardAdminApproveListingsComponent.prototype.propertyTypeUpdated = function () {
        var _this = this;
        var propertyType = this.advanceSearch.get('propertyType');
        var residentType = this.advanceSearch.get('residentialType');
        this.listingAdditionalTypes = [];
        this.taxonomy.propertyTypeResidentialType.forEach(function (element) {
            propertyType.value.forEach(function (propertyTypeValue) {
                if (element.id == propertyTypeValue) {
                    if (element.sub && element.sub.length) {
                        element.sub.forEach(function (subElement) {
                            residentType.value.forEach(function (residentTypeSubElement) {
                                if (subElement.id == residentTypeSubElement) {
                                    var a = {
                                        propertyType: element.id,
                                        residentialType: subElement.id
                                    };
                                    _this.listingAdditionalTypes.push(a);
                                }
                            });
                        });
                    }
                    else {
                        var a = {
                            propertyType: element.id,
                            residentialType: null
                        };
                        _this.listingAdditionalTypes.push(a);
                    }
                }
            });
        });
        var obj = {
            listingId: this.listingId,
            listingAdditionalTypes: this.listingAdditionalTypes
        };
        setTimeout(function () {
            _this.dashboardService.updatePropertyType(obj).pipe(takeUntil(_this.destroy$)).subscribe(function (data) {
                console.log("data", data);
                _this.getAllListing();
                _this.utilService.showToast('success', "Additional property type added to listing!");
                _this.dialogRef.close();
            });
        }, 200);
    };
    return DashboardAdminApproveListingsComponent;
}());
export { DashboardAdminApproveListingsComponent };
