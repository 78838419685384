<div class="slider-pro sp-horizontal" id="my-slider">
  <div class="sp-slides">
    <div class="sp-slide" *ngFor="let media of medias;">
      <div class="sp-image swiper-lazy" [ngStyle]="{'background-image': 'url(' + storage + '/listing/' + media + ')'}">
      </div>

    </div>
    <div class="sp-thumbnails">
      <img data-src="{{storage}}/listing/{{media}}" class="sp-thumbnail swiper-lazy"
        src="{{storage}}/listing/{{media}}" *ngFor="let media of medias;" />

    </div>
  </div>

</div>