<div class="{{internalClass}}">
  <h1 *ngIf="title">{{title}}</h1>
<form [formGroup]="myFormGroup" (ngSubmit)="onSubmit()" fxLayout="row wrap">
  <div *ngFor="let form_elem of formTemplate">
    <div [ngSwitch]="form_elem.type||form_elem.childrenType" >
      <div *ngSwitchCase="'textBox'">
        <input type="text" formControlName="{{form_elem.formControl}}"  placeholder="{{form_elem.placeholder}}"/>
      </div>
      <div *ngSwitchCase="'number'">
        <input type="number" formControlName="{{form_elem.formControl}}"  placeholder="{{form_elem.placeholder}}"/>
      </div>
      <div *ngSwitchCase="'textArea'">
          <textarea formControlName="{{form_elem.formControl}}" placeholder="{{form_elem.placeholder}}"></textarea>
        </div>
        <div *ngSwitchCase="'radio'" >
            radio
          </div>
      <div *ngSwitchCase="'select'">
        <select formControlName="{{form_elem.formControl}}">
          <option *ngIf="form_elem.placeholder">{{form_elem.placeholder}}</option>
          <option *ngFor="let opt of form_elem.options"  value="{{opt.id}}">
            {{opt.name}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <input type="submit" value="save" />
</form>
</div>