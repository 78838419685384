import { OnInit, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/media/service/media.service';
import { MustMatch } from 'src/shared/custom-function/helper';
import { UtilService } from 'src/shared/services/util.service';
import { Router } from '@angular/router';
var DashboardAdminManageUsersComponent = /** @class */ (function () {
    function DashboardAdminManageUsersComponent(dialog, fb, dashboardService, changeDetection, mediaService, utilService, router) {
        this.dialog = dialog;
        this.fb = fb;
        this.dashboardService = dashboardService;
        this.changeDetection = changeDetection;
        this.mediaService = mediaService;
        this.utilService = utilService;
        this.router = router;
        this.searchClicked = false;
        this.userForm = this.fb.group({
            firstName: [{ value: '', disabled: true }],
            lastName: [{ value: '', disabled: true }],
            email: [{ value: '', disabled: true }],
            telephone: [{ value: '', disabled: true }],
            role: '1',
            status: '',
            password: '',
            cPassword: '',
        }, {
            validator: MustMatch('password', 'cPassword')
        });
        this.user = null;
        this.users = [];
        this.destroy$ = new Subject();
        this.storage = environment.storageUrl + '/user/';
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 1
        };
        this.filter = null;
        this.assDec = false;
        this.platformRoles = [
            { id: 3, name: 'User', checked: true, disabled: true },
            { id: 2, name: 'Admin', checked: false, disabled: false },
            { id: 4, name: 'Support', checked: false, disabled: false }
        ];
        this.platform = null;
        this.getAllUsers();
    }
    DashboardAdminManageUsersComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(DashboardAdminManageUsersComponent.prototype, "publicMediaService", {
        get: function () {
            return this.mediaService;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(DashboardAdminManageUsersComponent.prototype, "f", {
        get: function () { return this.userForm.controls; },
        enumerable: true,
        configurable: true
    });
    DashboardAdminManageUsersComponent.prototype.openUserDialog = function (user) {
        user.color = this.getRoleColor(user.roles);
        this.userForm = this.fb.group({
            firstName: [{ value: user.firstName, disabled: true }],
            lastName: [{ value: user.lastName, disabled: true }],
            email: [{ value: user.userName, disabled: true }],
            telephone: [{ value: user.telephone, disabled: true }],
            role: user.userRoleId,
            status: user.status,
            password: '',
            cPassword: '',
        });
        this.platformRoles.forEach(function (element) {
            user.roles.forEach(function (subElement) {
                if (subElement == element.id) {
                    element.checked = true;
                }
            });
        });
        this.user = user;
        this.dialogRef = this.dialog.open(this.updateUserDialog, {
            panelClass: 'user-update-dialog',
            data: { user: user }
        });
    };
    DashboardAdminManageUsersComponent.prototype.close = function () {
        this.dialogRef.close();
        this.currentPage = 1;
        this.platformRoles = [
            { id: 3, name: 'User', checked: true, disabled: true },
            { id: 2, name: 'Admin', checked: false, disabled: false },
            { id: 4, name: 'Support', checked: false, disabled: false }
        ];
        this.getAllUsers();
    };
    DashboardAdminManageUsersComponent.prototype.getRoleColor = function (roles) {
        var color = "";
        //Admin
        if (roles.includes(2)) {
            color = "red";
        }
        //Support
        else if (roles.includes(4)) {
            color = "purple";
        }
        //User
        else {
            color = "#8cc63f";
        }
        return color;
    };
    DashboardAdminManageUsersComponent.prototype.updateUser = function () {
        var _this = this;
        var profileObj = {
            firstName: this.userForm.value.firstName,
            lastName: this.userForm.value.lastName,
            email: this.userForm.value.email,
            password: this.userForm.value.password ? this.userForm.value.password : null,
            userRoleId: this.userForm.value.userRoleId,
            phoneNumber: this.userForm.value.phoneNumber,
            status: parseInt(this.userForm.value.status),
            roles: [3],
        };
        this.platformRoles.forEach(function (element) {
            if (element.checked && element.id != 3) {
                profileObj.roles.push(element.id);
            }
        });
        this.dashboardService.updateProfile(profileObj, this.user.id).pipe(takeUntil(this.destroy$)).subscribe(function (data) {
            _this.utilService.showToast('success', "Profile updated!");
        }, function (err) {
            _this.utilService.showToast('error', "Error updating your profile, try again later!");
        });
    };
    DashboardAdminManageUsersComponent.prototype.getAllUsers = function () {
        var _this = this;
        var obj = {
            id: null,
            filter: this.filter,
            platform: this.platform ? parseInt(this.platform) : null
        };
        this.dashboardService.getAllUserForAdmin(this.currentPage, 10, obj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.users = data;
            _this.users.forEach(function (element) {
                element.color = _this.getRoleColor(element.roles);
            });
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.changeDetection.detectChanges();
        }, function (err) {
        });
    };
    DashboardAdminManageUsersComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllUsers();
    };
    DashboardAdminManageUsersComponent.prototype.deleteUser = function (userId) {
        var _this = this;
        this.utilService.showAcceptDeclineDialog("You are about to delete user!", "Are you sure ?", "400px")
            .pipe(takeUntil(this.destroy$)).subscribe(function (accepet) {
            if (accepet) {
                _this.dashboardService.deleteUserForAdmin(userId).pipe(takeUntil(_this.destroy$)).subscribe(function (data) {
                    _this.close();
                    _this.utilService.showToast('success', 'User deleted!');
                });
            }
        });
    };
    DashboardAdminManageUsersComponent.prototype.seeListing = function (user) {
        this.router.navigate(['/dashboard', { outlets: { dashboardOutlet: "admin-listings-management-dashboard/" + user.id } }]);
    };
    return DashboardAdminManageUsersComponent;
}());
export { DashboardAdminManageUsersComponent };
