import { OnInit, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ArticleService } from 'src/article-announcemnets/services/article.service';
import { ActivatedRoute } from '@angular/router';
var AnnouncementComponent = /** @class */ (function () {
    function AnnouncementComponent(articleService, changeDetection, activatedRoute) {
        this.articleService = articleService;
        this.changeDetection = changeDetection;
        this.activatedRoute = activatedRoute;
        this.announcementsData = [];
        this.announcement = null;
        this.destroy$ = new Subject();
        this.storage = environment.storageUrl;
        this.assDec = false;
        this.currentPage = 1;
        this.pageInfo = {
            currentPage: 0,
            pageSize: 0,
            total: 0,
            totalPages: 2
        };
        this.numberOfpage = [];
        this.announcementId = null;
        window.scrollTo(0, 0);
    }
    AnnouncementComponent.prototype.ngOnInit = function () {
        this.getAllAnnouncment();
        this.announcementId = this.activatedRoute.snapshot.paramMap.get('id');
    };
    AnnouncementComponent.prototype.getAllAnnouncment = function () {
        var _this = this;
        var obj = {
            id: null,
            filter: null
        };
        this.articleService.getAllAnnouncementPublic(this.currentPage, 12, this.assDec, obj).pipe(takeUntil(this.destroy$)).subscribe(function (_a) {
            var data = _a.data, pageInfo = _a.pageInfo;
            _this.announcementsData = data;
            if (_this.announcementId) {
                _this.announcementsData.forEach(function (element) {
                    if (element.id == _this.announcementId) {
                        _this.announcement = element;
                    }
                });
            }
            _this.changeDetection.detectChanges();
            _this.pageInfo = pageInfo;
            _this.numberOfpage = [];
            for (var i = 0; i < _this.pageInfo.totalPages; i++) {
                _this.numberOfpage.push(i + 1);
                _this.changeDetection.detectChanges();
            }
        });
    };
    AnnouncementComponent.prototype.onClickPage = function (pageNumber) {
        this.currentPage = pageNumber;
        this.getAllAnnouncment();
    };
    AnnouncementComponent.prototype.showFull = function (item) {
        this.announcement = item;
    };
    AnnouncementComponent.prototype.buildUrl = function (announcement) {
        var subUrl = 'announcements';
        return environment.appUrl + "/" + subUrl + "/" + announcement.id;
    };
    AnnouncementComponent.prototype.shareToLine = function (announcement) {
        open("https://timeline.line.me/social-plugin/share?url=" + this.buildUrl(announcement) + "&title=" + announcement.title);
    };
    AnnouncementComponent.prototype.shareToFb = function (announcement) {
        var obj = {
            "og:url": this.buildUrl(announcement),
            "og:type": "website",
            "og:title": announcement.title,
            "og:description": announcement.description ? announcement.description.substr(0, 20) : null,
            "og:image": environment.storageUrl + '/announcement/' + announcement.media,
            "og:image:width": "450",
            "og:image:height": "298"
        };
        FB.init({
            appId: environment.facebookId,
            status: true,
            cookie: true,
            xfbml: true,
            version: "v2.4"
        });
        FB.ui({
            method: "share_open_graph",
            action_type: "og.shares",
            redirect_uri: this.buildUrl(announcement),
            action_properties: JSON.stringify({
                object: obj
            })
        }, function (response) {
            if (response) {
            }
            else {
            }
        }, function (err) {
        });
    };
    return AnnouncementComponent;
}());
export { AnnouncementComponent };
