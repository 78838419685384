import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import form_template from 'src/shared/const/form_template';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'td-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormComponent implements OnInit {
  myFormGroup:FormGroup;
  @Input()
  formTemplate:any; 
  @Input()
  title:string = 'Change Title'; 
  @Input()
  internalClass:string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DynamicFormComponent>) { }

  ngOnInit() {
    let group={}   
    if(this.data){
      
      this.data.formTemplate.forEach(input_template=>{
        
        group[input_template.formControl]=new FormControl('');  
      })
      this.formTemplate= this.data.formTemplate;
      this.title=this.data.title;
      this.internalClass=this.data.internalClass;
    } else{
    this.formTemplate=form_template.forEach(input_template=>{
      group[input_template.formControl]=new FormControl('');  
    })
    this.formTemplate=form_template
  }
    this.myFormGroup = new FormGroup(group);
  }
  onSubmit(){
    
    this.dialogRef.close(this.myFormGroup);
  }
}
