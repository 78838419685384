import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { ArticleService } from '../../services/article.service';
import { UtilService } from 'src/shared/services/util.service';
import { ActivatedRoute } from '@angular/router';
declare var FB: any;
@Component({
  selector: 'td-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class PublicArticleComponent implements OnInit {
  articleData = [];
  article = null;
  destroy$: Subject<boolean> = new Subject();
  storage: string = environment.storageUrl;
  assDec = false;
  filterObj = {
    id: null,
    filter: null
  }
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 2
  }
  numberOfpage = [];
  articleId = null;
  constructor(private articleService: ArticleService, private changeDetection: ChangeDetectorRef, private utilService: UtilService, private activatedRoute: ActivatedRoute) { window.scrollTo(0, 0) }

  ngOnInit() {
    this.getAllArticle();
    this.articleId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  getAllArticle() {
    this.articleService.getPublicArticle(this.currentPage, 12, this.filterObj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
      this.articleData = data;
      if (this.articleId) {
        this.articleData.forEach((element) => {
          if (element.id == this.articleId) {
            this.article = element;
          }
        })
      }
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.numberOfpage = [];
      for (var i = 0; i < this.pageInfo.totalPages; i++) {
        this.numberOfpage.push(i + 1);
        this.changeDetection.detectChanges();
      }

    })

  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllArticle();
  }

  showFull(item) {
    this.article = item;
  }

  buildUrl(article) {
    const subUrl = 'article';
    return `${environment.appUrl}/${subUrl}/${article.id}`;
  }
  shareToLine(article) {
    open(`https://timeline.line.me/social-plugin/share?url=${this.buildUrl(article)}&title=${article.title}`);
  }
  shareToFb(article) {
    let obj={
      "og:url" : this.buildUrl(article),
      "og:type": "website",
      "og:title": article.title,
      "og:description": article.description?article.description.substr(0, 20):null,
      "og:image": environment.storageUrl + '/article/' + article.media,
      "og:image:width": "450",
      "og:image:height": "298"
    };
    FB.init({
      appId: environment.facebookId,
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4"
    });
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        redirect_uri: this.buildUrl(article),
        action_properties: JSON.stringify({
          object: obj
        })
      },
      (response) => {

        if (response) {

        } else {

        }
      }, (err) => {

      }
    );
  }
}
