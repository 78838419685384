<div class="manage-my-articles" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start stretch" style="width: 100%;">
    <h5>My articles</h5>
    <div class="filter-div" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <input type="text" placeholder="Search" [(ngModel)]="filter" onblur=""/>
      <button mat-mini-fab color="primary" fxHide.sm fxHide.xs style="right: 70px;bottom: 1px;"
            (click)="currentPage=0;getAllArticle()" *ngIf="filter?.length">
            <i class="mdi mdi-magnify" style="color:white;"></i>
          </button>
     
      <button mat-flat-button [color]="'primary'" (click)="addNew(addNewAnn)">Add New</button>
    </div>
    <div  style="width:100%;" *ngIf="articleData.length" fxLayoutGap="20px">
        <div class="article-card" *ngFor="let item of articleData" > 
            <div fxLayout="row" fxLayoutGap="20px"  fxLayoutAlign="start start" >
              <img fxFlex="50"
                src="{{storage}}/article/{{item.media}}"
                alt="">
              <div fxFlex="50" class="article-card-body" fxLayout="column" fxLayoutAlign="start start">
                <div class="date" fxLayout="row" fxLayoutAlign="end end">
                  <p>Date Listed {{item.date | date}}</p>
                </div>
                <div *ngFor="let item of item.articleDetailLanguages">
                  <h5>{{item.title}} </h5>
                  <p class="description">{{item.description}}
                  </p>
                </div>
                <div class="fab-div" fxLayout="row" fxLayoutAlign="end end">
                  <eco-fab-speed-dial direction="left" animationMode="scale">
                    <eco-fab-speed-dial-trigger spin="true">
                      <button mat-fab [color]="'primary'"><i class="mdi mdi-dots-horizontal" style="color:white;"></i></button>
                    </eco-fab-speed-dial-trigger>
          
                    <eco-fab-speed-dial-actions>
                      <button class="fab-button" mat-mini-fab (click)="delete(item)"><i class="mdi mdi-delete-outline"></i></button>
                      <button class="fab-button" mat-mini-fab (click)="edit(item)"><i
                          class="mdi mdi-square-edit-outline"></i></button>
                    </eco-fab-speed-dial-actions>
                  </eco-fab-speed-dial>
                </div>
              </div>
            </div>
          </div>
    </div>
    <div  style="width:100%;" *ngIf="!articleData.length" fxLayoutGap="20px">
      No articles posted yet!
    </div>
    <td-pagination [pageInfo]="pageInfo" (pageClickEvent)="onClickPage($event)"> </td-pagination>
  </div>

</div>

<ng-template #addNewAnn>
  <mat-dialog-content>
    <form [formGroup]="addarticle" *ngIf="!articleAddOrUpdateSuccess">
      <div class="property-overview-fields">
        <div fxLayout="row" fxLayoutAlign="space-between center"> 
          <h3>Articles</h3>
          <button mat-icon-button (click)="close()"><i class="mdi mdi-close" style="font-size:32px;"></i></button>
        </div>
        <div class="help-div" fxLayout="row" fxLayoutAlign="start start">
          <div class="help">
            Articles are meant to inform. Keep your message short. And provide the information in an easy and
            straightforward manner. No personal, political, religious, or harmful text will be tolerated. You agree that
            thailandcommercial.com can remove ANY post at any time for any reason.
          </div>
        </div>
        <h3>Ad Title</h3>
        <div class="chosse-language" fxLayout="row" fxLayoutAlign="start center">
          <a [class.selected]="slectedLanguage==1" (click)="slectedLanguage=1">English</a>
          <a [class.selected]="slectedLanguage==2" (click)="slectedLanguage=2">Thai (optional)</a>
        </div>
        <div class="property-title" fxLayout="row" fxLayoutAlign="start center" *ngIf="slectedLanguage==1">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
            <input type="text" placeholder="Title" [ngClass]="{ 'has-error': submited && f.engTitle.errors }"
              formControlName="engTitle" maxlength="70">
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
              <div fxFlex="70">
                <div *ngIf="submited && f.engTitle.errors" class="invalid-feedback">
                  <div *ngIf="f.engTitle.errors.required">Title is required</div>
                  <div *ngIf="f.engTitle.errors.minlength">Minimum 2 characters</div>
                  <div *ngIf="f.engTitle.errors.maxlength">Maximum 70 Character</div>
                </div>
              </div>
              <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                {{70-f.engTitle.value.length}} Character left</div>
            </div>
          </div>

        </div>
        <div class="property-title" fxLayout="row" fxLayoutAlign="start center" *ngIf="slectedLanguage==2">
          <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
            <input type="text" placeholder="Title" [ngClass]="{ 'has-error': submited && f.thTitle.errors }"
              formControlName="thTitle" maxlength="70">
            <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
              <div fxFlex="70">
                <div *ngIf="submited && f.thTitle.errors" class="invalid-feedback">
                  <div *ngIf="f.thTitle.errors.required">Title is required</div>
                  <div *ngIf="f.thTitle.errors.minlength">Minimum 2 characters</div>
                  <div *ngIf="f.thTitle.errors.maxlength">Maximum 70 Character</div>
                </div>
              </div>
              <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                {{70-f.thTitle.value.length}} Character left</div>
            </div>
          </div>

        </div>
        <div class="price-fields" fxLayout="row" fxLayoutAlign="start start">
          <div class="asking-price " fxLayout="column" fxLayoutAlign="start start">
            <h3>Description</h3>
            <div class="chosse-language" fxLayout="row" fxLayoutAlign="start center" *ngIf="slectedLanguage==1">
              <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
                <textarea placeholder="Description" [ngClass]="{ 'has-error': submited && f.engDescription.errors }"
                  formControlName="engDescription" ></textarea>
                <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                  <div fxFlex="70">
                    <div *ngIf="submited && f.engDescription.errors" class="invalid-feedback">
                      <div *ngIf="f.engDescription.errors.required">Description is required
                      </div>
                      <div *ngIf="f.engDescription.errors.minlength">Minimum 2 characters
                      </div>
                      <!-- <div *ngIf="f.engDescription.errors.maxlength">Maximum 2,000 Character
                      </div> -->
                    </div>
                  </div>
                  <!-- <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                    {{2000-f.engDescription.value.length}} Character left</div> -->
                </div>
              </div>

            </div>
            <div class="chosse-language" fxLayout="row" fxLayoutAlign="start center" *ngIf="slectedLanguage==2">
              <div class="input-div" fxLayout="column" fxLayoutAlign="start end">
                <textarea placeholder="Description" [ngClass]="{ 'has-error': submited && f.thDescription.errors }"
                  formControlName="thDescription" ></textarea>
                <div fxLayout="row" fxLayoutAlign="space-between end" style="width:100%;">
                  <div fxFlex="70">
                    <div *ngIf="submited && f.thDescription.errors" class="invalid-feedback">
                      <div *ngIf="f.thDescription.errors.required">Description is required
                      </div>
                      <div *ngIf="f.thDescription.errors.minlength">Minimum 2 characters</div>
                      <!-- <div *ngIf="f.thDescription.errors.maxlength">Maximum 2,000 Character
                      </div> -->
                    </div>
                  </div>
                  <!-- <div class="input-limit" style="margin-top: 10px;" fxFlex="15">
                    {{2000-f.thDescription.value.length}} Character left</div> -->
                </div>
              </div>

            </div>
          </div>

        </div>

        <div class="media-fields" fxLayout="column" fxLayoutAlign="start start">
          <h3>Upload Photos (Up to 1 Images)</h3>

          <div fxLayout="row" fxLayoutAlign="space-between center" style="width:100%;">
            <div class="input-div " fxLayout="column" fxLayoutAlign="space-around stretch" style="width:100%;"
              [ngClass]="{ 'has-error': submited && !mediaId }">
              <input type="file" name="files" />
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-flat-button type="button" color="primary" (click)="addArticle()" *ngIf="!updatingarticle">Post</button>
          <button mat-flat-button type="button" color="primary" (click)="updateArticle()" *ngIf="updatingarticle">Update</button>
        </div>
      </div>

    </form>
    <div *ngIf="articleAddOrUpdateSuccess">
      <div fxLayout="row" fxLayoutAlign="space-between center"> 
        <h3>Articles</h3>
        <button mat-icon-button (click)="close()"><i class="mdi mdi-close" style="font-size:32px;"></i></button>
      </div>
      <div>Thank you for your article. It has been uploaded to the "articles" section on the home page.</div>
    </div>
  </mat-dialog-content>
</ng-template>