import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: `
    <app-loading-screen></app-loading-screen>
    <app-layout></app-layout>
  `
})
export class AppComponent {
  title = 'thaidoor';
  constructor() { 
   
  }

  ngOnInit() {
    
  }
}
