import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { environment } from "src/environments/environment";
import { Coords } from "src/core/interfaces/coords.interface";
import { map } from "rxjs/operators";

@Injectable()
export class GooglemapsService {
  constructor() {}
  reverseGeocoding({ lat, lng }: Coords, language: string = "en") {
    return from(
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${language}&key=${
          environment.googleMapApiKey
        }`,
        { method: "GET" }
      ).then(response => response.json())
    ).pipe(map(({ results }: any) => (!!results.length ? results[0] : null)));
  }
  buildGoogleMapsAddress(
    adresses: {
      long_name: string;
      short_name: string;
      types: string[];
    }[]
  ) {
    return adresses
      .filter(
        address =>
          !address.types.some(
            type =>
              type === "street_number" ||
              type === "route" ||
              type === "country" ||
              type === "postal_code" ||
              type === "bus_station" ||
              type === "establishment" ||
              type === "point_of_interest" ||
              type === "transit_station"
          )
      )
      .reduce(
        (acu, { long_name }, index, arr) =>
          acu + `${long_name}${index !== arr.length - 1 ? ", " : ""}`,
        ""
      );
  }
}
