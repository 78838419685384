import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MediaService } from 'src/media/service/media.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'td-new-image-upload',
  templateUrl: './new-image-upload.component.html',
  styleUrls: ['./new-image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewImageUploadComponent implements OnInit {
  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  destory$: Subject<boolean> = new Subject;
  constructor(private http: HttpClient, private changeDetect: ChangeDetectorRef, private mediaService: MediaService) { }

  ngOnInit() {
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.changeDetect.detectChanges();
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('files', this.fileData);

    this.fileUploadProgress = '0%';
    this.mediaService.addMedia(10, formData).pipe(takeUntil(this.destory$))
      .subscribe(events => {
        
        if (events.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
          this.changeDetect.detectChanges();
          
        } else if (events.type === HttpEventType.Response) {
          this.fileUploadProgress = '';
          
          alert('SUCCESS !!');
        }

      })
  }
}