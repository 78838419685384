import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { UtilService } from 'src/shared/services/util.service';
import { MatDialog } from '@angular/material';
import { GenericDialogComponent } from 'src/listing/containers/generic-dialog/generic-dialog.component';
@Component({
  selector: 'td-dashboard-alerts',
  templateUrl: './dashboard-alerts.component.html',
  styleUrls: ['./dashboard-alerts.component.scss']
})
export class DashboardAlertsComponent implements OnInit, OnDestroy {
  alertOnOff = false;
  destroy$: Subject<boolean> = new Subject();
  alerts = [];
  taxonomy: any = {};
  selectedListingType = null;
  selectedType = null;
  selectedSubType = null;
  lat = null;
  lng = null;
  area = null;
  dialogRef = null;
  aleartSubmitted = false;
  alert = null;
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 2
  }
  @ViewChild('createAleartDialog', {static: false}) createAleartDialog;
  constructor(private dashboardService: DashboardService,
    private enumPropertyService: EnumPropertyService,
    private changeDetection: ChangeDetectorRef,
    private utilService: UtilService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        
        this.taxonomy = data;
        this.changeDetection.detectChanges();
        this.getAllALerts();
        
      });
    
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  getAllALerts() {
    this.dashboardService.getAlerts(this.currentPage, 10).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
      
      this.alerts = data;
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
      
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllALerts();
  }
  getEnumById(firstElement,type, secondElement, id) {
    const nameOrNumber = this.enumPropertyService.getEnumById(firstElement,type, secondElement, id);
    return nameOrNumber;
  }
  openMapDialog() {

    this.dialog.open(GenericDialogComponent, {
      panelClass: 'map-dialog',
      minHeight: '500px',
      minWidth: '453px',
      data: { showMap: false, initialPosition: { lat: this.lat, lng: this.lng, area: this.area }, text: 'Start typing the location or drop the pin in the map' },
    }).afterClosed().subscribe(data => {
      
      if (data) {
        this.lat = data[0].latitude;
        this.lng = data[0].longitude;
        this.area = data[0].area;
      }

    })
  }
  clearSearch() {
    this.area = null;
    this.lat = null;
    this.lng = null;
  }

  updateAlertEnable(sliderEvent, alert) {
    
    const obj = {
      propertyType: alert.propertyType,
      listingType: alert.listingType,
      enable: sliderEvent.checked,
      latitude: alert.latitude,
      longitude: alert.longitude,
      residentialType: alert.residentialType,
      area: alert.area,
      id: alert.id
    }
    this.dashboardService.updateAlerts(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.getAllALerts();
      this.utilService.showToast('success',`Alert has been ${sliderEvent.checked ? 'On' : 'Off'}`);
    }, (err) => {
      
      this.utilService.showToast('error',`Try again!`);
    })
  }

  deleteAlert(alertId) {
    this.dashboardService.deleteAlerts(alertId).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.currentPage = 1;
      this.getAllALerts();
      this.utilService.showToast('success','Alert Deleted!')
    }, (err) => {
      
      this.utilService.showToast('error',`Try again!`);
    })
  }

  openAleartDialog(alert) {
    this.selectedListingType = alert.listingType;
    this.selectedType = this.getEnumById('propertyTypeResidentialType', null,alert.propertyType, false).name;
    this.selectedSubType = this.getEnumById('propertyTypeResidentialType', alert.propertyType,alert.residentialType, false).name;
    this.lat = alert.latitude;
    this.lng = alert.longitude;
    this.area = alert.area;
    this.alert = alert;
    this.changeDetection.detectChanges();
    
    this.dialogRef = this.dialog.open(this.createAleartDialog, {
      panelClass: 'create-aleart-dialog',
      width: '857px'
    });
  }



  closeAlert() {
    this.selectedListingType = null;
    this.selectedType = null;
    this.selectedSubType = null;
    this.area = null;
    this.lat = null;
    this.lng = null;
    this.aleartSubmitted = false;
    this.currentPage = 1;
    this.dialogRef.close();
    this.getAllALerts();
  }
  updateAlert() {
    const obj = {
      propertyType: this.getEnumById('propertyType',null, this.selectedType, true).id,
      listingType: this.selectedListingType,
      enable: true,
      latitude: this.lat,
      longitude: this.lng,
      residentialType: this.getEnumById('propertyTypeResidentialType', this.selectedType,this.selectedSubType, true).id,
      area: this.alert.area,
      id: this.alert.id
    }
    this.dashboardService.updateAlerts(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.aleartSubmitted = true;
      this.utilService.showToast('success',`Alert has been updated!`);
    }, (err) => {
      
      this.utilService.showToast('error',`Try again!`);
    })
  }
}
