import { Injectable } from "@angular/core";
import {  openDb, deleteDb, UpgradeDB, DB  } from "idb";
import { from, Observable } from "rxjs";
@Injectable()
export class IdbService {
  constructor() {}
  instance(): Observable<DB> {
    
    return from(
      openDb("thaidoor", 1, upgradeDB  => {
        
        upgradeDB.createObjectStore("taxonomy", { keyPath: "idbuid" });
        upgradeDB.createObjectStore("saveSearches", { keyPath: "id" });
        upgradeDB.createObjectStore("placesHistory", { keyPath: "idbuid" });
        upgradeDB.createObjectStore("favorites", { keyPath: "id" });
        upgradeDB.createObjectStore("profile", { keyPath: "idbuid" });
           
      })
    );
  }
}
