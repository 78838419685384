import { NgModule } from "@angular/core";
import { RouterModule, Routes, } from "@angular/router";
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { PublicArticleComponent } from './containers/article/article.component';
import { CoreModule } from '@angular/flex-layout';
import { AnnouncementComponent } from './containers/announcements/announcement.component';

const routes: Routes = [
    {
        path: "announcements",
        component: AnnouncementComponent
    },
    {
        path: "announcements/:id",
        component: AnnouncementComponent
    },
    {
        path: "article",
        component: PublicArticleComponent
    },
    {
        path: "article/:id",
        component: PublicArticleComponent
    }
];


@NgModule({
    declarations: [
        AnnouncementComponent, PublicArticleComponent
    ],
    entryComponents: [
        AnnouncementComponent, PublicArticleComponent
    ],
    exports: [],
    imports: [
        CoreModule,
        RouterModule,
        FormsModule,
        SharedModule,
        RouterModule.forChild(routes),
    ]
})
export class AppArticleAnnouncmentsModule { }
