import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  from as fromPromise,
  throwError as _throw,
  Subject,
  of
} from 'rxjs';
import { switchMap, mergeMap, catchError, tap, finalize } from 'rxjs/operators';
import { LoginRegisterService } from '../service/login-register.service';
import { LoadingScreenService } from 'src/shared/services/loading/loading.service';
import { UtilService } from 'src/shared/services/util.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  rTokenProccessing: boolean = false;
  refreshSubject$: Subject<any> = new Subject();
  skippUrls = [
    '/refresh',
    '/EnumProperty',
    '/token',
    '/register',
    'graphql/GenericUser'
  ];
  constructor(private loadingScreenService: LoadingScreenService,
    private utilService: UtilService,
    private loginService: LoginRegisterService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    for (const skippUrl of this.skippUrls) {
      // 
      // 
      if (new RegExp(skippUrl).test(req.url)) {
        // 
        break;
      }
    }
    if (req.headers.get("skip")) {
      return next.handle(req);
    }
    this.loadingScreenService.startLoading();

    return <any>of(JSON.parse(localStorage.getItem('token'))).pipe(
      mergeMap((authData: any) => this.setToken(authData, req, next))
    );

  }

  private setToken(authData, req, next) {
    // 
    for (const skippUrl of this.skippUrls) {
      if (authData && req.url.search(skippUrl) === -1) {
        // 
        req = this.getReqWToken(req, authData);
      }
    }
    // if (authData && (req.url.search('/token') === -1||req.url.search('/token') === -1)) {
    //   req = this.getReqWToken(req, authData);
    // }
    return next
      .handle(req)
      .pipe(
        finalize(() => {
          this.loadingScreenService.stopLoading();
        }), catchError(err => this.httpErrorHandler(err, authData, req, next)));
  }

  private httpErrorHandler(err, authData, req, next) {
    if (err.status === 401) {
      return this.rTokenProccessing
        ? this.refreshSubject$.pipe(
          switchMap(data => next.handle(this.getReqWToken(req, data)))
        )
        : this.unauthorizedHandler(authData, req, next);
    }
    if (err.status === 0) {
      
      this.utilService.showToast('error', 'Something went wrong please try again later!');
      return _throw(err);
    }
    
    if (err.status === 404) {
      window.location.href = '404';
      this.utilService.showToast('error', err.error.error);
      return _throw(err.error);

    }
    if (err.status === 400) {
      this.utilService.showToast('error', err.error.error);
      return _throw(err);
    }
    if (err.status === 500) {
      this.utilService.showToast('error', err.error.error);
      return _throw(err);
    } else {
      return _throw(err);
    }
  }

  private unauthorizedHandler(authData, req, next) {
    this.rTokenProccessing = true;
    return this.loginService.refreshToken().pipe(
      switchMap((authData) => {
        this.refreshSubject$.next(authData);
        this.rTokenProccessing = false;
        return next.handle(this.getReqWToken(req, authData));
      }
      )
    );
  }

  private getReqWToken(req, authData) {
    return req.clone({
      setHeaders: {
        authorization: `${authData.token_type} ${authData.access_token}`,
        "Access-Control-Allow-Origin": location.origin,
        Vary: "Origin"
      }
    });
  }
}
