import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import imageUploadConst from 'src/listing/const/image-upload.cost';
import { takeUntil } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { MediaService } from 'src/media/service/media.service';
import { HttpEventType } from '@angular/common/http';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'td-dashboard-admin-announcements',
  templateUrl: './dashboard-admin-announcements.component.html',
  styleUrls: ['./dashboard-admin-announcements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminAnnouncementsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject();
  dialogRef = null;
  slectedLanguage: number = 1;
  submited: boolean = false;
  announcementAddOrUpdateSuccess: boolean = false;
  updatingAnnouncement: boolean = false;
  addAnnouncement: FormGroup = this.fb.group({
    engTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    thTitle: ['', [Validators.required, Validators.maxLength(70), Validators.minLength(2)]],
    engDescription: ['', [Validators.required,  Validators.minLength(2)]],
    thDescription: ['', [Validators.required, Validators.minLength(2)]],
    status: [1, [Validators.required]]
  });
  fileupload: any;
  fileUploadProgress = null;
  mediaIds = [];
  announcementData = [];
  storage: string = environment.storageUrl;
  @ViewChild('addNewAnn', {static: false}) addNewAnn: TemplateRef<any>;
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  assDec = false;
  filter = null;
  announcementId = null;
  constructor(private dialog: MatDialog, private fb: FormBuilder, private dashboardService: DashboardService, private mediaService: MediaService,
    private changeDetection: ChangeDetectorRef, private utilService: UtilService) { }

  ngOnInit() {
    this.getAllAnnouncment();
  }
  getAllAnnouncment() {
    let obj = {
      id: null,
      filter: this.filter
    }
    
    this.dashboardService.getAllAnnouncement(this.currentPage, 10, obj, this.assDec).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {

      
      this.announcementData = data;
      this.changeDetection.detectChanges();
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
    })

  }

  get f(): any { return this.addAnnouncement.controls; }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  addNew(templateRef) {
    imageUploadConst.files = [];
    this.updatingAnnouncement = false;
    this.announcementAddOrUpdateSuccess = false;
    this.submited = false;
    this.addAnnouncement.patchValue({ engTitle: '', thTitle: '', engDescription: '', thDescription: '', status:1 });
    this.dialogRef = this.dialog.open(templateRef, {
      panelClass: 'add-new-dialog',

    });
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.fileUploadProgress = null;
      this.mediaIds = [];
      this.assignFile();
    });
  }
  assignFile(): any {
    let _this = this;
    imageUploadConst.limit = 1;
    imageUploadConst.onFileRead = function (item, listEl, parentEl, newInputEl, inputEl) {
      
      if (item.input) {
        _this.fileUploadStart(item)
      } else {
        item.id = item.data.imageId;
      }
    };
    imageUploadConst.onRemove = function (item) {
      
      _this.deletePhoto(item)
    };

    imageUploadConst.dialogs.alert = function (message) {
      _this.utilService.showToast('info',message)
    }
    imageUploadConst.getAllEvents = function (something) {
      
    }
    imageUploadConst.editor.onSave = function (dataUrl, item) {
      
      
      _this.removeFromFiles(item);
      let blob = _this.mediaService.dataURLtoFile(dataUrl, 'a.png');

      item.file = blob;
      if (blob) {
        _this.fileUploadStart(item);
      }

    }
    setTimeout(() => {
      this.fileupload = (<any>window).$('input[name="files"]').fileuploader(
        imageUploadConst
      );
    }, 100)

  }

  fileUploadStart(item) {
    let _this = this;
    
    const formData = new FormData();
    formData.append('file', item.file);
    // callback will go here
    
    _this.fileUploadProgress = '0';
    _this.mediaService.addMedia(40, formData).pipe(takeUntil(_this.destroy$)).subscribe((events: any) => {
      
      if (events.type === HttpEventType.UploadProgress) {
        _this.fileUploadProgress = Math.round(events.loaded / events.total * 100);
        _this.changeDetection.detectChanges();
        
        var progressBar = item.html.find('.progress-bar2');
        if (progressBar.length > 0) {
          progressBar.show();
          progressBar.find('span').html(_this.fileUploadProgress + "%");
          progressBar.find('.fileuploader-progressbar .bar').width(_this.fileUploadProgress + "%");
        }
      } else if (events.type === HttpEventType.Response) {
        _this.fileUploadProgress = '';
        
        item.name = events.body.data.name;
        item.id = events.body.data.id;
        imageUploadConst.files.push({ id: events.body.data.id });
        item.html.find('.fileuploader-action-remove').addClass('fileuploader-action-success');
        setTimeout(function () {
          item.html.find('.progress-bar2').fadeOut(400);
        }, 400);
        _this.mediaIds = [..._this.mediaIds, ...events.body.data.id];
        
        _this.changeDetection.detectChanges();
      }
    }, ({ error }) => {
      
      error ? _this.utilService.showToast('error',error.error) : _this.utilService.showToast('error','Please login to upload photo');
    })
  }

  removeFromFiles(fileName) {
    
    imageUploadConst.files.forEach((element, index) => {
      
      if (element.id == fileName.id) {
        
        imageUploadConst.files.splice(index, 1);
      }
    })
    this.mediaIds.forEach((element, index) => {
      
      if (element == fileName.id) {
        
        this.mediaIds.splice(index, 1);
      }
    })
    
    this.changeDetection.detectChanges();
  }

  deletePhoto(fileName: any) {
    this.mediaService.deleteMedia(fileName.id, 40).pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.mediaIds = this.mediaIds.filter((item: any) => item !== fileName.id);
      this.changeDetection.detectChanges();
    }, (err) => {
      this.utilService.showToast('error','Error on media update');
    })
  }
  addAnnouncemnet() {
    
    this.submited = true;

    let obj = {
      "announcementDetailLanguage": [],
      "mediaIds": this.mediaIds
    };
    let engLang = {
      "title": this.addAnnouncement.value.engTitle,
      "description": this.addAnnouncement.value.engDescription,
      "languageId": 1
    }
    obj.announcementDetailLanguage = [engLang];
    if (this.addAnnouncement.value.thTitle && this.addAnnouncement.value.thDescription) {
      let thLang = {
        "title": this.addAnnouncement.value.thTitle,
        "description": this.addAnnouncement.value.thDescription,
        "languageId": 2
      }
      obj.announcementDetailLanguage.push(thLang);
    }
    if (!this.mediaIds.length) {
      this.utilService.showToast('success','Add one image!');
      return;
    }
    this.dashboardService.saveAnnouncement(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.announcementAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Announcemnet Created!')
    }, (err) => {
      this.submited = false;
      this.utilService.showToast('error','Try again!')
    })


  }
  close() {
    this.dialogRef.close();
    this.currentPage = 1;
    this.getAllAnnouncment();
    this.updatingAnnouncement = false;
    this.announcementAddOrUpdateSuccess = false;
    this.submited = false;
  }

  delete(ev) {
    
    this.utilService.showAcceptDeclineDialog("You are about to delete a announcement!", "Are you sure ?", "400px").pipe(takeUntil(this.destroy$)).subscribe((accepet) => {
      
      if (accepet) {
        this.dashboardService.deleteAnnouncement(ev.id).pipe(takeUntil(this.destroy$)).subscribe(({ data }: any) => {
          
          if (data) {
            this.utilService.showToast('success',"Announcement Deleted!")
            this.currentPage = 1;
            this.getAllAnnouncment();
          } else {
            this.utilService.showToast('error',"Try again!")
          }
        })
      }
    })
  }
  edit(ev) {
    
    this.announcementId = ev.id;
    this.updatingAnnouncement = true;
    this.announcementAddOrUpdateSuccess = false;
    ev.announcementDetailLanguages.forEach(element => {
      if (element.languageId == 1) {
        this.addAnnouncement.controls['engTitle'].setValue(element.title);
        this.addAnnouncement.controls['engDescription'].setValue(element.description);
      } else {
        this.addAnnouncement.controls['thTitle'].setValue(element.title);
        this.addAnnouncement.controls['thDescription'].setValue(element.title);
      }
    });
    this.addAnnouncement.controls['status'].setValue(ev.status);
    this.changeDetection.detectChanges();
    if (ev.media) {
      let prepairMedia = [];
      prepairMedia.push({
        name: ev.media, // file name
        type: 'image/*', // file MIME type
        id: ev.mediaId,
        file: this.storage + '/announcement/' + ev.media + '?' + new Date().getTime(), // file path
        data: {
          imageId: ev.mediaId,
          readerCrossOrigin: "Anonymous"
        },
        uploaded: true
      })
      this.mediaIds = [parseInt(ev.mediaId)];
      imageUploadConst.files = prepairMedia;
    }
    this.dialogRef = this.dialog.open(this.addNewAnn, {
      panelClass: 'add-new-dialog'
    })

    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.assignFile();
    });

  }
  updateAnnouncemnet() {
    
    this.announcementAddOrUpdateSuccess = false;
    this.submited = true;
    if (!this.mediaIds.length) {

      return;
    }
    let obj = {
      "announcementDetailLanguage": [],
      "mediaIds": this.mediaIds,
      id: this.announcementId,
      status: 1
    };
    let engLang = {
      "title": this.addAnnouncement.value.engTitle,
      "description": this.addAnnouncement.value.engDescription,
      "languageId": 1
    }
    obj.announcementDetailLanguage = [engLang];
    if (this.addAnnouncement.value.thTitle && this.addAnnouncement.value.thDescription) {
      let thLang = {
        "title": this.addAnnouncement.value.thTitle,
        "description": this.addAnnouncement.value.thDescription,
        "languageId": 2
      }
      obj.announcementDetailLanguage.push(thLang);
    }
    obj.status = this.addAnnouncement.value.status;

    this.dashboardService.updateAnnouncement(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submited = false;
      this.announcementAddOrUpdateSuccess = true;
      this.utilService.showToast('success','Announcemnet updated!')
    }, (err) => {
      this.submited = false;
      this.announcementAddOrUpdateSuccess = false;
      this.utilService.showToast('error','Try Again')
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllAnnouncment();
  }
}
