import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from 'src/app/dashboard/services/dashboard.service';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/shared/services/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'td-dashboard-admin-calculate-history',
  templateUrl: './dashboard-admin-calculate-history.component.html',
  styleUrls: ['./dashboard-admin-calculate-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminCalculateHistoryComponent implements OnInit {
  dataSource: any[] = [
  ];
  currentPage = 1;
  pageInfo = {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 1
  }
  displayedColumns: string[] = ['clculateFor', 'languag', 'area', 'requiredMinSqm', 'requiredMaxSqm', 'date', 'action'];
  storage = environment.storageUrl;
  destroy$: Subject<boolean> = new Subject;
  constructor(private dashboardService: DashboardService, private changeDetection: ChangeDetectorRef, private utilService: UtilService) { }

  ngOnInit() {
    this.getAllLeads();
  }
  getAllLeads() {
    this.dashboardService.getAllLeads().pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
      
      this.dataSource = data;
      this.pageInfo = pageInfo;
      this.changeDetection.detectChanges();
    })
  }
  sendLeadToAgent() {
    let obj = {
      "Id": null,
      "AssignedAgentId": null
    }
    this.dashboardService.sendLeadToAgent(obj).pipe(takeUntil(this.destroy$)).subscribe(({ data, pageInfo }: any) => {
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  downloadFile = (blob, fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true });
  }
  delete(element) {
    
    this.dashboardService.deleteLeads(element.id).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.currentPage = 0;
      this.getAllLeads();
      this.utilService.showToast('success',"Lead deleted!")
    }, (err) => {
      this.utilService.showToast('error',"Error try tagain!")
    })
  }
  sentToAgent(element) {
    this.dashboardService.sentToAgent(element.id,element.sentToAgent?false:true).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.currentPage = 0;
      this.getAllLeads();
      this.utilService.showToast('success',"Updated!")
    }, (err) => {
      this.utilService.showToast('error',"Error try tagain!")
    })
  }
  downloadLead(leadId) {
    this.dashboardService.downloadLead(leadId).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.downloadFile(data, 'Lead.pdf');
    })
  }
  onClickPage(pageNumber) {
    this.currentPage = pageNumber;
    this.getAllLeads();
  }
}
