import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from 'src/shared/custom-function/helper';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilService } from 'src/shared/services/util.service';

@Component({
  selector: 'td-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  isAuthentic = null;
  resetPasswordForm: FormGroup = this.fb.group({
    password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
    cPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]]
  }, {
    validator: MustMatch('password', 'cPassword')
  });
  code = null;
  email = null;
  submitted = false;
  destroy$: Subject<boolean> = new Subject();
  constructor(private loginRegisterService: LoginRegisterService,
    private changeDetection: ChangeDetectorRef, private fb: FormBuilder, private route: ActivatedRoute,
    private router: Router, private utilService: UtilService) { }

  ngOnInit() {
    this.checkAuthentication();
    
    

    this.code = this.route.snapshot.paramMap.get("code");
    this.email = this.route.snapshot.paramMap.get("email");

    if (!this.code && !this.email) {
      this.router.navigate(['/']).then(() => {
        this.utilService.showToast('error','Invalid Code! Try to get a new code!');
      });
    }


  }
  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated();
    this.changeDetection.detectChanges();
  }
  get f() { return this.resetPasswordForm.controls; }
  onSubmit() {
    
    this.submitted = true;
    let obj = {
      "email": this.email,
      "code": this.code,
      "password": this.resetPasswordForm.value.cPassword
    }
    this.loginRegisterService.resetPassword(obj).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      
      this.submitted = false;
      this.utilService.showToast('success','Your password reset! login with your new password!');
      this.router.navigate(['/']);
    }, (err) => {
      this.submitted = false;
      this.changeDetection.detectChanges();
      this.utilService.showToast('error','Try again!');
      
    })
  }
}
