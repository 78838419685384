import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
declare const signalR: any;
@Injectable()
export class WebSocketService {
  constructor() {

    if ("WebSocket" in window) {
      var socket = new WebSocket(environment.websocketUrl);
      console.log("socket", socket)
      socket.onopen=(event)=>{
        console.log("event", event);
        socket.send('demo send thailand property');
      }
      // socket.onopen(event: any) {
      //   console.log("event", event)
      //   socket.send('demo send thailand property');
      // };

      socket.onerror = function (error) {
        console.log("error websocket", error)
      };


      socket.onmessage = function (event:any) {
        // JSON.parse(event)
        var message = event.data;
        console.log("JSON.parse(event)",JSON.parse(event))
      };
      socket.onclose = function (event) {
        if (event.wasClean) {
          alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
          // e.g. server process killed or network down
          // event.code is usually 1006 in this case
          alert('[close] Connection died');
        }
        console.log("event", event)
      };
    } else {
      // The browser doesn't support WebSocket
      alert("WebSocket NOT supported by your Browser!");
    }
  }
}