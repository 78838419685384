<div class="announcement-card">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start">
    <img fxFlex="50"
      src="{{storage}}/{{type}}/{{announcement.medias&&announcement.medias.length?announcement.medias[0]?.filePath:announcement.media}}"
      alt="">
    <div fxFlex="50" class="announcement-card-body" fxLayout="column" fxLayoutAlign="start start">
      <div class="date" fxLayout="column" fxLayoutAlign="end end">
        <p>{{'Date Listed'|translate}} {{announcement.createdAt | date}}</p>
        <p *ngIf="announcement?.status">Status : {{announcement?.status==1?'Active':'Suspended'|translate}}</p>
      </div>
      <div *ngFor="let item of announcement.announcementDetailLanguages">
        <h5>{{item.title}} </h5>
        <p class="description">{{item.description}}
        </p>
      </div>
      <div class="fab-div" fxLayout="row" fxLayoutAlign="end end">
        <eco-fab-speed-dial direction="left" animationMode="scale">
          <eco-fab-speed-dial-trigger spin="true">
            <button mat-fab [color]="'primary'"><i class="mdi mdi-dots-horizontal" style="color:white;"></i></button>
          </eco-fab-speed-dial-trigger>

          <eco-fab-speed-dial-actions>
            <button class="fab-button" mat-mini-fab (click)="delete(announcement)"><i class="mdi mdi-delete-outline"></i></button>
            <button class="fab-button" mat-mini-fab (click)="edit(announcement)"><i
                class="mdi mdi-square-edit-outline"></i></button>
          </eco-fab-speed-dial-actions>
        </eco-fab-speed-dial>
      </div>
    </div>
  </div>
</div>