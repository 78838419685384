import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { DashboardComponent } from './container/components/dashboard/dashboard.component';
import { DashboardHomeComponent } from './container/components/dashboard-home/dashboard-home.component';
import { DashboardSideBarComponent } from './container/components/dashboard-side-bar/dashboard-side-bar.component';
import { DashboardMyProfileComponent } from './container/components/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardManageMyAdsComponent } from './container/components/dashboard-manage-my-ads/dashboard-manage-my-ads.component';
import { DashboardFavoriteAdsComponent } from './container/components/dashboard-favorite-ads/dashboard-favorite-ads.component';
import { DashboardAnnouncementsComponent } from './container/components/dashboard-announcements/dashboard-announcements.component';
import { DashboardMessageComponent } from './container/components/dashboard-message/dashboard-message.component';
import { DashboardSupportComponent } from './container/components/dashboard-support/dashboard-support.component';
import { DashboardPaymentHistoryComponent } from './container/components/dashboard-payment-history/dashboard-payment-history.component';

import { DashboardAdminCalculateHistoryComponent } from './container/components/admin/dashboard-admin-calculate-history/dashboard-admin-calculate-history.component';
import { DashboardAdminExtraComponent } from './container/components/admin/dashboard-admin-extra/dashboard-admin-extra.component';
import { DashboardAdminManageListingsComponent } from './container/components/admin/dashboard-admin-manage-listings/dashboard-admin-manage-listings.component';
import { DashboardAdminManageTestimonialsComponent } from './container/components/admin/dashboard-admin-manage-testimonials/dashboard-admin-manage-testimonials.component';
import { DashboardAdminManageUsersComponent } from './container/components/admin/dashboard-admin-manage-users/dashboard-admin-manage-users.component';
import { DashboardAdminMessagesComponent } from './container/components/admin/dashboard-admin-messages/dashboard-admin-messages.component';
import { DashboardAdminPaymentHistoriesComponent } from './container/components/admin/dashboard-admin-payment-histories/dashboard-admin-payment-histories.component';
import { DashboardAdminXmlFeedComponent } from './container/components/admin/dashboard-admin-xml-feed/dashboard-admin-xml-feed.component';


import { ImageCropModule } from 'src/image-crop/image-crop.module'
import { DashboardService } from './services/dashboard.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { ListingDetailsModule } from 'src/listing-details/listing-details.module';
import { DashboardAlertsComponent } from './container/components/dashboard-alerts/dashboard-alerts.component';
import { DashboardAnalyticsComponent } from './container/components/dashboard-analytics/dashboard-analytics.component';
import { DashboardAdminReportedListingsComponent } from './container/components/admin/dashboard-admin-reported-listings/dashboard-admin-reported-listings.component';
import { DashboardArticlesComponent } from './container/components/admin/dashboard-admin-article/dashboard-admin-article.component';
import { DashboardAdminApproveListingsComponent } from './container/components/admin/dashboard-admin-approve-listings/dashboard-admin-approve-listings.component';
import { DashboardListingReportedComponent } from './container/components/dashboard-listing-reported/dashboard-listing-reported.component';
import { DashboardAdminAnnouncementsComponent } from './container/components/admin/dashboard-admin-announcements/dashboard-admin-announcements.component';

@NgModule({
  declarations: [
    DashboardSideBarComponent,
    DashboardComponent,
    DashboardHomeComponent,
    DashboardMyProfileComponent,
    DashboardManageMyAdsComponent,
    DashboardFavoriteAdsComponent,
    DashboardAnnouncementsComponent,
    DashboardMessageComponent,
    DashboardSupportComponent,
    DashboardPaymentHistoryComponent,
    DashboardAlertsComponent,
    DashboardAnalyticsComponent,
    DashboardListingReportedComponent,
    DashboardAdminCalculateHistoryComponent,
    DashboardAdminExtraComponent,
    DashboardAdminApproveListingsComponent,
    DashboardAdminManageListingsComponent,
    DashboardAdminManageTestimonialsComponent,
    DashboardAdminManageUsersComponent,
    DashboardAdminMessagesComponent,
    DashboardAdminPaymentHistoriesComponent,
    DashboardAdminXmlFeedComponent,
    DashboardAdminReportedListingsComponent,
    DashboardArticlesComponent,
    DashboardAdminAnnouncementsComponent
  ],
  entryComponents:[
    DashboardAdminCalculateHistoryComponent,
    DashboardAdminExtraComponent,
    DashboardAdminApproveListingsComponent,
    DashboardAdminManageListingsComponent,
    DashboardAdminManageTestimonialsComponent,
    DashboardAdminManageUsersComponent,
    DashboardAdminMessagesComponent,
    DashboardAdminPaymentHistoriesComponent,
    DashboardAdminXmlFeedComponent,
    DashboardAdminReportedListingsComponent,
    DashboardArticlesComponent,
    DashboardAdminAnnouncementsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    ImageCropModule,
    NgxScrollToFirstInvalidModule,
    ListingDetailsModule
  ],
  providers: [DashboardService]
})
export class DashboardModule { }
