import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'td-dashboard-admin-xml-feed',
  templateUrl: './dashboard-admin-xml-feed.component.html',
  styleUrls: ['./dashboard-admin-xml-feed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardAdminXmlFeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
