import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FourZeroFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
