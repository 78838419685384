import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild, TemplateRef, OnDestroy, HostListener } from "@angular/core";

import { EnumPropertyService } from 'src/core/service/enum-property/enum-property.service';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, Observable, Subscription, fromEvent } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingService } from 'src/listing/service/listing.service';
import { GetListing } from 'src/listing/interface/get-listing.interface';
import * as $ from 'jquery';
import { LanguageService } from 'src/core/service/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { LoginRegisterService } from 'src/login-register/service/login-register.service';
import { AuthService } from 'src/core/service/auth/auth.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { UtilService } from 'src/shared/services/util.service';
declare var FB: any;

@Component({
  selector: "app-listing-details",
  templateUrl: "./listing-details.component.html",
  styleUrls: ["./listing-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingDetailsComponent implements OnInit, OnDestroy {

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  @Input() listingId: string = null;
  destroy$: Subject<boolean> = new Subject();
  listing: GetListing;
  taxonomy: any;
  showShare: boolean = false;
  language = null;
  dialogRef = null;
  shareDialogRef = null;
  reportSend = false;
  reportForm: FormGroup = this.fb.group({
    reportTitle: ['', [Validators.required]],
    reportType: ['', [Validators.required]],
    message: ['', [Validators.required, Validators.maxLength(2000), Validators.minLength(2)]],
  });
  submited = false;
  reportId = null;
  isAuthentic = false;
  showMore = false;
  ownListing = null;
  matcher = {
    media: null,
    matches: false,
    onchange: null
  };
  shareListingClick = false;
  lineGroups = [];
  @ViewChild('reportDialog', { static: false }) reportDialog: TemplateRef<any>;
  @ViewChild('shareDialog', { static: false }) shareDialog: TemplateRef<any>;
  constructor(public enumPropertyService: EnumPropertyService,
    private activatedRoute: ActivatedRoute,
    private listingService: ListingService,
    private changeDetection: ChangeDetectorRef,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private utilService: UtilService,
    private loginRegisterService: LoginRegisterService, private authService: AuthService, public mediaMatcher: MediaMatcher) {

  }
  get f(): any { return this.reportForm.controls; }
  ngOnInit() {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      console.log('event: ', evt);
      this.onResize();
    })
  }
  onResize() {
    this.matcher = this.mediaMatcher.matchMedia('(max-width: 960px)');
    this.changeDetection.detectChanges();
  }
  ngAfterViewInit() {
    this.onResize();
    this.checkAuthentication();

    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ lang }) => {
        this.language = lang == 'en' ? 1 : 2;
        this.changeDetection.detectChanges();
      });

    this.enumPropertyService.getEnumPropertyForClient().pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {

        this.taxonomy = data;

        if (this.activatedRoute.snapshot.paramMap.get('id') || this.listingId) {
          let id = this.listingId || this.activatedRoute.snapshot.paramMap.get('id');
          this.listingService.getListing(id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ data }: any) => {
              this.ownListing = this.authService.profileState.value && data.agentId == this.authService.profileState.value.id;
              this.changeDetection.detectChanges();
              this.listing = data;
              this.changeDetection.detectChanges();
              // this.loginRegisterService.getProfile().pipe(takeUntil(this.destroy$)).subscribe((data) => {



              // })
              $(function () {

                var $sidebar = $('.floating-div'),
                  $window = $(window),
                  offset = $sidebar.offset(),
                  pageHeight = $('.listing-detail').height(),
                  topPadding = 15;
                $window.scroll(function () {
                  if ($window.scrollTop() > offset.top) {
                    if ($window.scrollTop() <= pageHeight) {
                      $sidebar.stop().animate({
                        marginTop: $window.scrollTop() - offset.top + topPadding
                      });
                    } else {
                      return;
                    }
                  } else {
                    $sidebar.stop().animate({
                      marginTop: 0
                    });
                  }
                });

              });
            })
        }
      }, (err) => {

      });

  }
  checkAuthentication() {
    this.isAuthentic = this.loginRegisterService.isAuthenticated();
    this.changeDetection.detectChanges();
  }
  getEnumById(firstElement, type, secondElement, id) {
    let nameOrNumber = this.enumPropertyService.getEnumById(firstElement, type, secondElement, id);
    return nameOrNumber;
  }

  openReportDialog(listingId, listingName) {
    this.submited = false;
    this.reportSend = false;
    this.reportId = listingId;
    if (listingName.length) {
      this.reportForm.controls.reportTitle.setValue(`Reporting #${listingId} ${listingName}`);
    } else {
      this.reportForm.controls.reportTitle.setValue(`Reporting #${listingId}`);
    }
    this.reportForm.controls.reportTitle.disable();
    this.dialogRef = this.dialog.open(this.reportDialog, {
      panelClass: 'report-dailog'
    });
    this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data) => {

      this.submited = false;
      this.reportSend = false;
      this.reportForm.reset();
    });
  }
  close() {
    this.dialogRef.close();
  }
  reportListing() {
    this.submited = true;
    if (this.reportForm.invalid) {
      return;
    }
    const obj = {
      message: this.reportForm.value.message,
      response: null,
      id: null,
      title: this.reportForm.value.reportTitle,
      reportType: this.reportForm.value.reportType
    }

    this.listingService.reportListing(this.reportId, obj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {

        this.reportForm.controls.message.setValue('');
        this.reportSend = true;
      }, (err) => {
        this.reportSend = false;
      });
  }
  openShareDialog() {
    this.shareDialogRef = this.dialog.open(this.shareDialog, {
      panelClass: 'share-dailog'
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }


  buildUrl(listing) {
    const subUrl = 'listing-detail';
    return `${environment.appUrl}/${subUrl}/${listing.id}`;
  }
  openLineShare() {
    open(`https://timeline.line.me/social-plugin/share?url=${this.buildUrl(this.listing)}&title=${this.listing.title}`);
  }
  
  shareToFb() {
    let obj={
      "og:url" : this.buildUrl(this.listing),
      "og:type": "website",
      "og:title": this.listing.title,
      "og:description": this.listing.description?this.listing.description.substr(0, 20):null,
      "og:image": environment.storageUrl + '/listing/' + this.listing.medias[0],
      "og:image:width": "450",
      "og:image:height": "298"
    };
    console.log("obj",obj);
    FB.init({
      appId: environment.facebookId,
      status: true,
      cookie: true,
      xfbml: true,
      version: "v2.4"
    });
    FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        redirect_uri: this.buildUrl(this.listing),
        action_properties: JSON.stringify({
          object:obj
        })
      },
      (response) => {
        console.log("response",response);
        if (response) {

        } else {

        }
      }, (err) => {
        console.log("err",err);
      }
    );
  }
}
