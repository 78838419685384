import { DashboardAdminCalculateHistoryComponent } from '../components/admin/dashboard-admin-calculate-history/dashboard-admin-calculate-history.component';
import { DashboardAdminManageUsersComponent } from '../components/admin/dashboard-admin-manage-users/dashboard-admin-manage-users.component';
import { DashboardAdminManageListingsComponent } from '../components/admin/dashboard-admin-manage-listings/dashboard-admin-manage-listings.component';
import { DashboardAdminPaymentHistoriesComponent } from '../components/admin/dashboard-admin-payment-histories/dashboard-admin-payment-histories.component';
import { DashboardAdminManageTestimonialsComponent } from '../components/admin/dashboard-admin-manage-testimonials/dashboard-admin-manage-testimonials.component';
import { DashboardAdminMessagesComponent } from '../components/admin/dashboard-admin-messages/dashboard-admin-messages.component';
import { DashboardAdminXmlFeedComponent } from '../components/admin/dashboard-admin-xml-feed/dashboard-admin-xml-feed.component';
import { DashboardAdminExtraComponent } from '../components/admin/dashboard-admin-extra/dashboard-admin-extra.component';
import { DashboardHomeComponent } from '../components/dashboard-home/dashboard-home.component';
import { DashboardAdminReportedListingsComponent } from '../components/admin/dashboard-admin-reported-listings/dashboard-admin-reported-listings.component';
import { DashboardArticlesComponent } from '../components/admin/dashboard-admin-article/dashboard-admin-article.component';
import { DashboardAdminApproveListingsComponent } from '../components/admin/dashboard-admin-approve-listings/dashboard-admin-approve-listings.component';
import { DashboardAdminAnnouncementsComponent } from '../components/admin/dashboard-admin-announcements/dashboard-admin-announcements.component';

const adminRouterParms = [
    {
        path: 'home-dashboard',
        component: DashboardHomeComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-users-dashboard",
        component: DashboardAdminManageUsersComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-listings-approve-dashboard",
        component: DashboardAdminApproveListingsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-listings-management-dashboard/:id",
        component: DashboardAdminManageListingsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-listings-management-dashboard",
        component: DashboardAdminManageListingsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-payments-dashboard",
        component: DashboardAdminPaymentHistoriesComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-testimonials-dashboard",
        component: DashboardAdminManageTestimonialsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-message-dashboard",
        component: DashboardAdminMessagesComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-calculate-dashboard",
        component: DashboardAdminCalculateHistoryComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-xml-feed-dashboard",
        component: DashboardAdminXmlFeedComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-extra-dashboard",
        component: DashboardAdminExtraComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-reported-listings-dashboard",
        component: DashboardAdminReportedListingsComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-article-dashboard",
        component: DashboardArticlesComponent,
        outlet: 'dashboardOutlet'
    },
    {
        path: "admin-announcement-dashboard",
        component: DashboardAdminAnnouncementsComponent,
        outlet: 'dashboardOutlet'
    }
]
export default adminRouterParms;