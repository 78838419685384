import { NgModule } from "@angular/core";
import { LayoutComponent } from "./containers/layout.component";
import { RouterModule, Routes,  } from "@angular/router";
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from 'src/shared/shared.module';
import { ListingModule } from 'src/listing/listing.module';
import { DashboardModule } from 'src/app/dashboard/dashboard.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LoginRegisterModule } from 'src/login-register/login-register.module';
import { AppArticleAnnouncmentsModule } from 'src/article-announcemnets/article-announcements.module';

const routes: Routes = [
  {
    path: "linkedinlogin",
    redirectTo : "",
    component: NavbarComponent
  }
];



@NgModule({
  declarations: [
    LayoutComponent,
    NavbarComponent,
    FooterComponent,
    SidenavComponent
  ],
  exports: [LayoutComponent],
  imports: [
    AppArticleAnnouncmentsModule,
    DashboardModule,
    SharedModule,
    ListingModule,
    ReactiveFormsModule,
    LoginRegisterModule,
    RouterModule.forChild(routes),
  ]
})
export class AppLayoutModule {}
